import React from 'react';
import './GroupedBarChart.css';
import PropTypes from 'prop-types';
import { Bar, XAxis, Tooltip, Legend, YAxis, CartesianGrid, ResponsiveContainer, ComposedChart, Label } from 'recharts';
import colors from '../../../utils/colors';

/**
 * @param {string} value legend text
 * @returns {React.Component} customized legend span
 */
const LegendText = (value) => {
  return <span className="legend-text">{value}</span>;
};

/**
 * @param {*} props props
 * @param {Array} props.barData data contains bar details
 * @returns {React.ReactElement}  - required props
 */
const GroupedBarChart = ({ barData }) => {
  return (
    <div>
      <ResponsiveContainer width="95%" height={500}>
        <ComposedChart data={barData} padding={10} fontSize={10}>
          <XAxis
            dataKey="name"
            tickLine={false}
            tick={barData?.length > 0}
            interval={0}
            angle={-35}
            textAnchor="end"
            style={{ fill: colors.graph }}
          >
            <Label value="Missing Reports from Districts" dy={60} dx={-30} style={{ fill: colors.graph }} />
          </XAxis>
          <YAxis yAxisId={0} tickLine={false} tick={barData?.length > 0} style={{ fill: colors.graph }}>
            <Label value="Delay in months" angle={-90} dx={-15} style={{ fill: colors.graph }} />
          </YAxis>
          <CartesianGrid horizontal stroke="#DFE2E6" vertical={false} />
          <Tooltip cursor={false} active />
          <Legend
            // layout="horizontal"
            // verticalAlign="bottom"
            // right="180"
            // align="center"
            iconSize={10}
            iconType="rect"
            wrapperStyle={{
              width: '100%',
              paddingTop: 55,
            }}
            formatter={LegendText}
          />
          {/* <Bar dataKey="<1M" barSize={10} fill={colors.blue.quaternary} radius={[10, 10, 10, 10]} /> */}
          <Bar dataKey="1-2M" barSize={10} fill={colors.pink.tertiary} radius={[10, 10, 10, 10]} />
          <Bar dataKey="2-3M" barSize={10} fill={colors.green.quinary} radius={[10, 10, 10, 10]} />
          <Bar dataKey="3-6M" barSize={10} fill={colors.yellow.quinary} radius={[10, 10, 10, 10]} />
          <Bar dataKey=">6M" barSize={10} fill={colors.pink.secondary} radius={[10, 10, 10, 10]} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
GroupedBarChart.propTypes = {
  barData: PropTypes.arrayOf().isRequired,
};
export default GroupedBarChart;
