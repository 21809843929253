import { takeLatest } from 'redux-saga/effects';
import {
  INSERT_DRUG,
  GET_DRUG_AVILABILITY,
  UPDATE_DRUG_AVILABILITY,
  UPLOAD_DRUG_AVILABILITY,
} from '../../constants/actionType/index';
import {
  insertDrugHandler,
  getDrugAvailabilityHandler,
  updateDrugAvailabilityHandler,
  uploadDrugAvailabilityHandler,
} from '../handler/drugHandler';

/**
 * @name nationalBoardSaga used for dashboard api call
 * @yields nationalPretasOverviewHandler
 */
function* insertDrugSaga() {
  yield takeLatest(INSERT_DRUG, insertDrugHandler);
  yield takeLatest(GET_DRUG_AVILABILITY, getDrugAvailabilityHandler);
  yield takeLatest(UPDATE_DRUG_AVILABILITY, updateDrugAvailabilityHandler);
  yield takeLatest(UPLOAD_DRUG_AVILABILITY, uploadDrugAvailabilityHandler);
}
export default insertDrugSaga;
