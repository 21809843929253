/* eslint-disable default-param-last */
import {
  GET_GEOGRAPHIC_DIMENSION_RESULT,
  UPDATE_GEOGRAPHIC_DIMENSION_RESULT,
  INSERT_GEOGRAPHIC_DIMENSION_RESULT,
  BULK_UPLOAD_GEOGRAPHIC_DIMENSION_RESULT,
  GEOGRAPHY_STATUS,
} from '../../constants/actionType';
import { geographicInitialState } from '../initialStates';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const geographicReducer = (state = geographicInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_GEOGRAPHIC_DIMENSION_RESULT:
      return {
        ...state,
        getGeographicDimension: payload?.data,
      };
    case UPDATE_GEOGRAPHIC_DIMENSION_RESULT:
      return {
        ...state,
        updateGeographicDimension: payload.data,
      };
    case INSERT_GEOGRAPHIC_DIMENSION_RESULT:
      return {
        ...state,
        status: payload.data,
      };
    case BULK_UPLOAD_GEOGRAPHIC_DIMENSION_RESULT:
      return {
        ...state,
        bulkUploadGeographicDimension: payload.data,
      };
    case GEOGRAPHY_STATUS:
      return {
        ...state,
        status: payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default geographicReducer;
