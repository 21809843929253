/* eslint-disable import/prefer-default-export */
/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import {
  GET_STATE_PROGRESS_STATUS_RESULT,
  GET_STATE_PROFILE_RESULT,
  DISTRICT_MDA_PROFILE_RESULT,
  GET_DISTRICT_PROFILE_WIDGET_RESULT,
  GET_DISTRICT_PROFILE_WIDGET_GRAPH_RESULT,
  LOADER,
  GET_DISTRICT_TAS_PROFILE_RESULT,
  GET_DISTRICT_DECISION_MATRIX_RESULT,
  STATE_PROFILE_CHART_RESULT,
  STATE_DATA_QUALITY_TABLE_RESULT,
  GET_DISTRICT_MDA_BLOCKS_RESULT,
} from '../../constants/actionType/index';
import {
  getStateProgressStatusRequest,
  getStateProfileRequest,
  getDistrictMdaProfileRequest,
  getDistrictProfileWidgetRequest,
  getDistrictProfileWidgetGraphRequest,
  getDistrictTasProfileRequest,
  getDistrictDecisionMatrixRequest,
  getStateProfileChartRequest,
  getDataQualityRequest,
  getDistrictMDABlocksRequest,
} from '../request/stateDashboardRequest';
// import { CATCH, ERROR, SUCCESS, FAILED } from '../../constants/common';
/**
 * @name nationalPretasOverviewHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalPretasOverviewHandler
 */
export function* stateProgressStatusHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getStateProgressStatusRequest, payload);
    yield put({
      type: GET_STATE_PROGRESS_STATUS_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name nationalPretasOverviewHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalPretasOverviewHandler
 */
export function* stateProfileHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getStateProfileRequest, payload);
    yield put({
      type: GET_STATE_PROFILE_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name nationalPretasOverviewHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalPretasOverviewHandler
 */
export function* districtMdaProfileHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getDistrictMdaProfileRequest, payload);
    yield put({
      type: DISTRICT_MDA_PROFILE_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name getDistrictProfileWidgeHandler used for dashboard api call
 * @param {number} action payload data
 * @yields getDistrictProfileWidgeHandler
 */
export function* getDistrictProfileWidgetHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getDistrictProfileWidgetRequest, payload);
    yield put({
      type: GET_DISTRICT_PROFILE_WIDGET_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @name getDistrictProfileWidgetGraphHandler used for dashboard api call
 * @param {number} action payload data
 * @yields getDistrictProfileWidgeHandler
 */
export function* getDistrictProfileWidgetGraphHandler(action) {
  const { payload } = action;
  try {
    // yield put({ type: LOADER, payload: true });
    const response = yield call(getDistrictProfileWidgetGraphRequest, payload);
    yield put({
      type: GET_DISTRICT_PROFILE_WIDGET_GRAPH_RESULT,
      payload: response?.data,
    });
  } catch (err) {
    yield put({ type: LOADER, payload: false });
  }
}

/**
 * @name getDistrictProfileWidgeHandler used for dashboard api call
 * @param {number} action payload data
 * @yields getDistrictProfileWidgeHandler
 */
export function* getDistrictTasProfileHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getDistrictTasProfileRequest, payload);
    yield put({
      type: GET_DISTRICT_TAS_PROFILE_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @name getDistrictDecisionMatrixHandler used for dashboard api call
 * @param {number} action payload data
 * @yields getDistrictDecisionMatrixHandler
 */
export function* getDistrictDecisionMatrixHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getDistrictDecisionMatrixRequest, payload);
    yield put({
      type: GET_DISTRICT_DECISION_MATRIX_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @name getStateProfileChartHandler used for dashboard api call
 * @param {number} action payload data
 * @yields getDistrictDecisionMatrixHandler
 */
export function* getStateProfileChartHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getStateProfileChartRequest, payload);
    yield put({
      type: STATE_PROFILE_CHART_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @name getStateDataQualityHandler used for dashboard api call
 * @param {number} action payload data
 * @yields getDistrictDecisionMatrixHandler
 */
export function* getStateDataQualityHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getDataQualityRequest, payload);
    yield put({
      type: STATE_DATA_QUALITY_TABLE_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name getDistrictMDABlocksHandler used for dashboard api call
 * @param {object} action payload
 * @yields getDistrictMDABlocksHandler
 */
export function* getDistrictMDABlocksHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getDistrictMDABlocksRequest, payload);
    yield put({
      type: GET_DISTRICT_MDA_BLOCKS_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
