/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import {
  GET_DOSSIER_RESULT,
  // INSERT_DOSSIER_RESULT,
  // UPDATE_DOSSIER_RESULT,
  // UPLOAD_DOSSIER_RESULT,
  DOSSIER_STATUS,
  GET_DOSSIER,
} from '../../constants/actionType';

import {
  getDossierRequest,
  updateDossierRequest,
  insertDossierRequest,
  bulkUploadDossierRequest,
} from '../request/dossierRequest';
import { CATCH, ERROR, SUCCESS, FAILED, UPDATED, SUBMITTED, BULK_UPLOAD_SUCCESS } from '../../constants/common';

/**
 * @function getDossierHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields  getDossierHandler
 */
export function* getDossierHandler() {
  try {
    const response = yield call(getDossierRequest);
    yield put({
      type: GET_DOSSIER_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @param {object} action payload data
 * @function insertDossierHandler handler function to handle program activity post request
 * @yields insertDossierHandler
 */
export function* insertDossierHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(insertDossierRequest, payload);

    if (response.status === 200) {
      yield put({
        type: DOSSIER_STATUS,
        payload: { type: SUCCESS, message: SUBMITTED },
      });
    } else {
      yield put({
        type: DOSSIER_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: DOSSIER_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function updateDossierHandler handler function to handle program activity update request
 * @yields updateDossierHandler
 */
export function* updateDossierHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(updateDossierRequest, payload);
    if (response.status === 200) {
      yield put({
        type: DOSSIER_STATUS,
        payload: { type: SUCCESS, message: UPDATED },
      });
    } else {
      yield put({
        type: DOSSIER_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: DOSSIER_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function updateDossierHandler handler function to handle program activity update request
 * @yields updateDossierHandler
 */
export function* uploadDossierHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(bulkUploadDossierRequest, payload);
    if (response.status === 200) {
      yield put({
        type: DOSSIER_STATUS,
        payload: { type: SUCCESS, message: BULK_UPLOAD_SUCCESS },
      });
      yield put({
        type: GET_DOSSIER,
      });
    } else {
      yield put({
        type: DOSSIER_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: DOSSIER_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
