import { range } from '../../../utils/common';

const nextYear = new Date().getFullYear() + 1;

const currentYear = new Date().getFullYear();
const YEAR_RANGE = range(currentYear - 2, currentYear - 18, -1);

const cards = [
  {
    firstValue: 'xx',
    firstContent: 'States Under MDA',
    secondValue: '30,000,000',
    secondContent: 'Population at Risk',
    backgroundColor: 'primary',
    cardTitle: '2022',
    type: 'under MDA',
  },
  {
    firstValue: 'xx',
    firstContent: 'States Under Pre-TAS',
    secondValue: '12,000,000',
    secondContent: 'Population at Risk',
    backgroundColor: 'secondary',
    cardTitle: '2022',
    type: 'Under Pre-TAS',
  },
  {
    firstValue: 'xx',
    firstContent: 'States Under TAS 1',
    secondValue: '29,000,000',
    secondContent: 'Population at Risk',
    backgroundColor: 'tertiary',
    cardTitle: '2022',
    type: 'Under Pre-TAS1',
  },
  {
    firstValue: 'xx',
    firstContent: 'States Under TAS 1',
    secondValue: '29,000,000',
    secondContent: 'Population at Risk',
    backgroundColor: 'tertiary',
    cardTitle: '2022',
    type: 'Under Pre-TAS2',
  },
  {
    firstValue: 'xx',
    firstContent: 'States Under TAS 1',
    secondValue: '29,000,000',
    secondContent: 'Population at Risk',
    backgroundColor: 'tertiary',
    cardTitle: '2022',
    type: 'Under Pre-TAS3',
  },
  {
    firstValue: 'xx',
    firstContent: 'States Under TAS 1',
    secondValue: '29,000,000',
    secondContent: 'Population at Risk',
    backgroundColor: 'quaternary',
    cardTitle: '2022',
    type: 'Under MDA',
  },
  {
    firstValue: 'xx',
    firstContent: 'States Under TAS 1',
    secondValue: '29,000,000',
    secondContent: 'Population at Risk',
    backgroundColor: 'quaternary',
    cardTitle: '2022',
    type: 'Completed MDA',
  },
  {
    firstValue: 'xx',
    firstContent: 'States Under Pre-TAS',
    secondValue: '12,000,000',
    secondContent: 'Population at Risk',
    backgroundColor: 'secondary',
    cardTitle: '2022',
    type: 'Completed Pre-TAS',
  },
  {
    firstValue: 'xx',
    firstContent: 'States Under TAS 1',
    secondValue: '29,000,000',
    secondContent: 'Population at Risk',
    backgroundColor: 'tertiary',
    cardTitle: '2022',
    type: 'Completed TAS1',
  },
  {
    firstValue: 'xx',
    firstContent: 'States Under TAS 1',
    secondValue: '29,000,000',
    secondContent: 'Population at Risk',
    backgroundColor: 'tertiary',
    cardTitle: '2022',
    type: 'Completed TAS2',
  },
  {
    firstValue: 'xx',
    firstContent: 'States Under TAS 1',
    secondValue: '29,000,000',
    secondContent: 'Population at Risk',
    backgroundColor: 'tertiary',
    cardTitle: '2022',
    type: 'Completed TAS3',
  },
  {
    firstValue: 'xx',
    firstContent: 'States Under TAS 1',
    secondValue: '29,000,000',
    secondContent: 'Population at Risk',
    backgroundColor: 'quinary',
    cardTitle: '2022',
    type: 'Under Dossier Preparation',
  },
];
const countryHeader = [
  'State / District',
  'Endemic District',
  'Pending DA',
  'Pending IDA',
  'DA Completed',
  'IDA Completed',
  'Pending Pre TAS',
  'Pre TAS Completed',
  'Pending TAS1',
  'Pending TAS2',
  'Pending TAS3',
  'TAS1 Completed',
  'TAS2 Completed',
  'TAS3 Completed',
  'PMS1 TAS1',
  'PMS2 TAS2',
  'PMS3 TAS3',
  // 'Dossier Prep',
];
const predictionCountryProfileHeader = [
  'State / District',
  'Endemic District',
  'Pending DA',
  'Pending IDA',
  'Pending Pre-Tas',
  'Pending TAS1',
  'Pending TAS2',
  'Pending TAS3',
  'PMS1 TAS1',
  'PMS2 TAS2',
  'PMS3 TAS3',
  'Dossier Prep',
];
const progressHeader = [
  'State/ District',
  'Endemic District',
  'Activity Due',
  'Activity Delayed',
  'Activity Completed',
  'Activity Pending',
];
// const decisionMatrixHeader = [
//   { name: 'State' },
//   { name: 'District' },
//   { name: 'Population Under TAS' },
//   { name: 'EUs Under TAS' },
//   // { name: 'Constituent Blocks' },
//   { name: 'Activity' },
//   { name: 'Current Status' },
//   { name: 'Next Year Activity' },
//   // { name: '% EU Passed in a State', data: ['<20%', '20-50%', '>50%'] },
// ];
const decisionMatrixHeader = [
  'State',
  'District',
  'Population Pending TAS',
  'EUs Pending TAS',
  'Constituent Blocks',
  'Activity',
  'Current Status',
  'Next Year Activity',
];
const decisionMatrixData = [
  {
    State: 'TamilNadu',
    District: 'Salem',
    eu: '10',
    Blocks: 'Chennai',
    Activity: 'TAS1',
    Current: 'passed',
    status: 'PSM1',
  },
  {
    State: 'Kerala',
    District: 'Salem',
    eu: '10',
    Blocks: 'Chennai',
    Activity: 'TAS1',
    Current: 'passed',
    status: 'PSM',
  },
  {
    State: 'Karnataka',
    District: 'Salem',
    eu: '10',
    Blocks: 'Chennai',
    Activity: 'TAS1',
    Current: 'failed',
    status: 'PSM',
  },
  {
    State: 'Delhi',
    District: 'Salem',
    eu: '10',
    Blocks: 'Chennai',
    Activity: 'TAS1',
    Current: 'passed',
    status: 'PSM2',
  },
  {
    State: 'West Bengal',
    District: 'Salem',
    eu: '10',
    Blocks: 'Chennai',
    Activity: 'TAS1',
    Current: 'failed',
    status: 'Pre-Tas',
  },
];
const progressData = [
  {
    state: 'Tamil Nadu',
    district: '25',
    activity: '40',
    status: '40',
    next: '45',
    TAS3: '45',
  },
  {
    state: 'Uttar Pradesh',
    district: '25',
    activity: '40',
    status: '40',
    next: '45',
    TAS3: '45',
  },
  {
    state: 'Kerala',
    district: '25',
    activity: '40',
    status: '40',
    next: '45',
    TAS3: '45',
  },
];
const countryData = [
  {
    state: 'Utter Pradesh',
    district: '25',
    da: '40',
    ida: '40',
    tas1: '45',
    tas2: '45',
    tas3: '60',
    cleared1: '60',
    cleared2: '60',
    cleared3: '60',
    dosage: '60',
  },
  {
    state: 'Tamil Nadu',
    district: '25',
    da: '40',
    ida: '40',
    tas1: '45',
    tas2: '45',
    tas3: '60',
    cleared1: '60',
    cleared2: '60',
    cleared3: '60',
    dosage: '60',
  },
  {
    state: 'Bihar',
    district: '25',
    da: '40',
    ida: '40',
    tas1: '45',
    tas2: '45',
    tas3: '60',
    cleared1: '60',
    cleared2: '60',
    cleared3: '60',
    dosage: '60',
  },
];
const barData = [
  {
    name: 'MDA',
    '<1M': 2,
    '1-2M': 6,
    '2-3M': 4,
    '3-6M': 5,
  },
  {
    name: 'FTS Data',
    '<1M': 8,
    '1-2M': 3,
    '2-3M': 5,
    '3-6M': 2,
  },
  {
    name: 'Pre-TAS',
    '<1M': 6,
    '1-2M': 1,
    '2-3M': 5,
    '3-6M': 3,
  },
  {
    name: 'TAS 1',
    '<1M': 1,
    '1-2M': 4,
    '2-3M': 8,
    '3-6M': 3,
  },
  {
    name: 'Tas 2',
    '<1M': 5,
    '1-2M': 3,
    '2-3M': 6,
    '3-6M': 4,
  },
  {
    name: 'TAS 3',
    '<1M': 2,
    '1-2M': 3,
    '2-3M': 5,
    '3-6M': 9,
  },
];
const AllDropDownList = [
  {
    placeholder: 'Year',
    value: '',
    list: [
      {
        id: 1,
        value: '2019',
      },
      {
        id: 2,
        value: '2020',
      },
      { id: 3, value: '2021' },
      { id: 4, value: '2022' },
    ],
  },
  {
    placeholder: 'Month',
    value: '',
    list: [
      {
        id: 1,
        value: 'January',
      },
      {
        id: 2,
        value: 'June',
      },
      { id: 3, value: 'May' },
    ],
  },
  {
    placeholder: 'State',
    value: '',
    list: [
      {
        id: 1,
        value: 'Tamil Nadu',
      },
      {
        id: 2,
        value: 'Kerala',
      },
      {
        id: 3,
        value: 'Uttar Pradesh',
      },
      {
        id: 4,
        value: 'Madhya Pradesh',
      },
      {
        id: 5,
        value: 'Rajesthan',
      },
      {
        id: 6,
        value: 'Arunachal Pradesh',
      },
    ],
  },
  {
    placeholder: 'District',
    value: '',
    list: [
      {
        id: 1,
        value: 'Chennai',
      },
      {
        id: 2,
        value: 'Salem',
      },
      { id: 3, value: 'Tirupur' },
    ],
  },
  {
    placeholder: 'Activity',
    value: '',
    list: [
      {
        id: 1,
        value: 'DA',
      },
      {
        id: 2,
        value: 'IDA',
      },
      { id: 3, value: 'TAS1' },
    ],
  },
  {
    placeholder: 'Status',
    value: '',
    list: [
      {
        id: 1,
        value: 'Completed',
      },
      {
        id: 2,
        value: 'Pending',
      },
      { id: 3, value: 'Delayed' },
    ],
  },
  {
    placeholder: 'Sort',
    value: '',
    list: [
      {
        id: 1,
        value: 'A-Z',
      },
      {
        id: 2,
        value: 'Z-A',
      },
    ],
  },
  {
    placeholder: 'Strategy',
    value: '',
    list: [
      {
        id: 1,
        value: 'Block Strategy',
      },
      {
        id: 2,
        value: 'District Strategy',
      },
    ],
  },
  {
    placeholder: 'Data Status',
    value: '',
    list: [],
  },
  {
    placeholder: 'Reports',
    value: '',
    list: [],
  },
];
const sort = [
  {
    placeholder: 'Sort',
    value: '',
    list: [
      {
        id: 1,
        value: 'A-Z',
      },
      {
        id: 2,
        value: 'Z-A',
      },
    ],
  },
];

const mdaHeader = [
  { name: 'State' },
  { name: 'District' },
  { name: 'Completed MDA' },
  { name: 'Pending MDA' },
  { name: 'WHO Coverage' },
  { name: '%Completed' },
  { name: 'Coverage (%) (Abs. #)', data: ['DA <65%', 'DA >65%', 'IDA <85%', 'IDA >85%'] },
];
// const tasSummary = [
//   'State',
//   'EU Under TAS',
//   'TAS Passed EUs',
//   'TAS Failed EUs',
//   // { name: 'Delayed TAS' },
//   'Population Under TAS',
// ];

// const tasSummary = [
//   { name: 'State' },
//   { name: 'EU Under TAS' },
//   { name: 'TAS Passed EUs' },
//   { name: 'TAS Failed EUs' },
//   // { name: 'Delayed TAS' },
//   { name: 'Population Under TAS' },
//   // { name: '% EU Passed in a State', data: ['<20%', '20-50%', '>50%'] },
// ];
const tasSummary = ['State', 'EU Pending TAS', 'TAS Passed EUs', 'TAS Failed EUs', 'Population Pending TAS'];
const mdaRowdata = [
  {
    state: 'Utter Pradesh',
    district: 'district',
    completed: 'completed',
    pending: 'pending',
    delay: 'delay',
    percent: '%',
    data: { fiftyCov: '19', fifty_sixtyCov: '25', sixty_seventyCov: '34', seventyfiveCov: '44' },
  },
  {
    state: 'Utter Pradesh',
    district: 'district',
    completed: 'completed',
    pending: 'pending',
    delay: 'delay',
    percent: '%',
    data: { fiftyCov: '19', fifty_sixtyCov: '25', sixty_seventyCov: '34', seventyfiveCov: '44' },
  },
  {
    state: 'Utter Pradesh',
    district: 'district',
    completed: 'completed',
    pending: 'pending',
    delay: 'delay',
    percent: '%',
    data: { fiftyCov: '19', fifty_sixtyCov: '25', sixty_seventyCov: '34', seventyfiveCov: '44' },
  },
  {
    state: 'Utter Pradesh',
    district: 'district',
    completed: 'completed',
    pending: 'pending',
    delay: 'delay',
    percent: '%',
    data: { fiftyCov: '19', fifty_sixtyCov: '25', sixty_seventyCov: '34', seventyfiveCov: '44' },
  },
];
const preTasDataHead = ['State', 'Status', 'Pre-TAS', 'TAS 1', 'TAS 2', 'TAS 3'];
const preTasData = [
  {
    state: 'Uttar Pradesh',
    data: [
      { status: '', preTas: '7', tas1: '7', tas2: '5', tas3: '9' },
      { status: 'Completed', preTas: '7', tas1: '7', tas2: '5', tas3: '9' },
      { status: 'Failed', preTas: '7', tas1: '7', tas2: '5', tas3: '9' },
      { status: 'Progress', preTas: '7', tas1: '7', tas2: '5', tas3: '9' },
    ],
  },
  {
    state: 'West Bengal',
    data: [
      { status: '', preTas: '7', tas1: '7', tas2: '5', tas3: '9' },
      { status: 'Completed', preTas: '7', tas1: '7', tas2: '5', tas3: '9' },
      { status: 'Failed', preTas: '7', tas1: '7', tas2: '5', tas3: '9' },
      { status: 'Progress', preTas: '7', tas1: '7', tas2: '5', tas3: '9' },
    ],
  },
];
const TypeCode = [
  { type: 'DA', code: '#FC5830' },
  { type: 'IDA', code: '#FD9727' },
  { type: 'Pre TAS', code: 'rgb(148 117 255)' },
  { type: 'TAS 1', code: '#8cc152' },
  { type: 'TAS 2', code: '#1EAAF1' },
  { type: 'TAS 3', code: '#587BF8' },
  { type: 'TAS1 Completed', code: '#8CC152' },
  { type: 'TAS2 Completed', code: '#2C992D' },
  { type: 'TAS3 Completed', code: '#023020' },
  { type: 'Under Pre TAS', code: '#FED700' },
  { type: 'Under TAS 1', code: '#1EAAF1' },
  { type: 'Under TAS 2', code: '#587BF8' },
  { type: 'Under TAS 3', code: '#159588' },
  { type: 'New Endemic', code: '#800000' },
  { type: 'Do Dossier Prep', code: '#FFB6C1' },
  { type: 'Result Awaiting', code: '#00008B' },
];

const ActivityTypeCode = [
  { type: 'DA', code: '#FC5830' },
  { type: 'IDA', code: '#FD9727' },
  { type: 'Pre TAS', code: 'rgb(148 117 255)' },
  { type: 'TAS 1', code: '#8cc152' },
  { type: 'TAS 2', code: '#1EAAF1' },
  { type: 'TAS 3', code: '#587BF8' },
];

const activityHeader = [
  'State',
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
  'Total',
];
const DistrictactivityHeader = [
  'District',
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
  'Total',
];
const activityHeaderState = [
  'Block',
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
  'Total',
];
const activityDetails = [
  {
    name: 'Uttar Pradesh',
    data: [
      {
        total: 18,
        type: 'DA',
        data: [
          {
            month: 'March',
            details: 'Agra',
            countDate: 4,
          },
        ],
      },
      {
        total: 20,
        type: 'Pre-TAS',
        data: [
          {
            month: 'January',
            details: 'Tenkasi',
            countDate: 4,
          },
        ],
      },
      {
        total: 11,
        type: 'IDA',
        data: [
          {
            month: 'November',
            details: 'Erode',
            countDate: 4,
          },
        ],
      },
      {
        total: 10,
        type: 'TAS2',
        data: [
          {
            month: 'May',
            details: 'Kadaloor',
            countDate: 4,
          },
        ],
      },
      {
        total: 10,
        type: 'TAS3',
        data: [
          {
            month: 'July',
            details: 'Theni',
            countDate: 3,
          },
        ],
      },
    ],
  },
  {
    name: 'Tamil Nadu',
    data: [
      {
        total: 15,
        type: 'TAS1',
        data: [
          {
            month: 'April',
            details: 'Karur',
            countDate: 2,
          },
        ],
      },
    ],
  },
];
const DistrictactivityDetails = [
  {
    name: 'Mayiladuthurai',
    data: [
      {
        total: 18,
        type: 'DA',
        data: [
          {
            month: 'March',
            details: 'Agra',
            countDate: 4,
          },
        ],
      },
      {
        total: 20,
        type: 'Pre-TAS',
        data: [
          {
            month: 'January',
            details: 'Tenkasi',
            countDate: 4,
          },
        ],
      },
      {
        total: 11,
        type: 'IDA',
        data: [
          {
            month: 'November',
            details: 'Erode',
            countDate: 4,
          },
        ],
      },
      {
        total: 10,
        type: 'TAS2',
        data: [
          {
            month: 'May',
            details: 'Kadaloor',
            countDate: 4,
          },
        ],
      },
      {
        total: 10,
        type: 'TAS3',
        data: [
          {
            month: 'July',
            details: 'Theni',
            countDate: 3,
          },
        ],
      },
    ],
  },
  {
    name: 'Chennai',
    data: [
      {
        total: 15,
        type: 'TAS1',
        data: [
          {
            month: 'April',
            details: 'Karur',
            countDate: 2,
          },
        ],
      },
    ],
  },
];
const DataQualityHeader = [
  'District Name',
  'Activity Type/Count',
  'Report Count',
  'Expected Date',
  'Delayed Report Count',
  'On-Track',
];
const NationalDataQualityHeader = [
  'State',
  'Total Report Count',
  'Expected Report Count',
  'Delayed Report Count',
  'On-Track',
];
const DataQualityContent = [
  {
    state: 'Agra',
    ActivityTypeCount: 'TAS/10',
    reportCount: '2,3,4,5',
    ExpectedDate: 'Expected Date',
    delayedDate: '12 Days',
    onTrack: 0,
  },
  {
    state: '',
    ActivityTypeCount: '',
    reportCount: '2,3,4,5',
    ExpectedDate: '1 Apr 2022',
    delayedDate: '',
    onTrack: 0,
  },
  {
    state: '',
    ActivityTypeCount: '',
    reportCount: '10',
    ExpectedDate: '1 Apr 2022',
    delayedDate: '12 Days',
    onTrack: 0,
  },
  {
    state: '',
    ActivityTypeCount: 'MDA/35',
    reportCount: '',
    ExpectedDate: '',
    delayedDate: '',
    onTrack: '',
  },
  {
    state: '',
    ActivityTypeCount: 'Pre - TAS/43',
    reportCount: '',
    ExpectedDate: '',
    delayedDate: '',
    onTrack: '',
  },
  {
    state: 'Mayiladuthurai',
    report: '25',
    date: '40',
    status: '04 Apr 2022',
    delayed: '45',
    ontrack: '3',
  },
  {
    state: 'Selam',
    report: '25',
    date: '40',
    status: '04 Apr 2022',
    delayed: '45',
    ontrack: '0',
  },
];
const activityTableHead = [
  'State',
  'District',
  'Activity',
  'Current Status',
  'Date Proposed by NCVBDC',
  'Date Proposed by State',
];
const activityTableContent = [
  {
    state: 'Uttar Pradesh',
    report: 'Jalaun',
    date: 'DA',
    status: 'Completed',
    delayed: '13 Apr 2022',
    track: '15 Feb 2022',
  },
  {
    state: 'Arunachal Pradesh',
    report: 'Changlang',
    date: 'IDA',
    status: 'Completed',
    delayed: '13 Apr 2022',
    track: '15 Feb 2022',
  },
  {
    state: 'Tamil Nadu',
    report: 'Chennai',
    date: 'Pre TAS',
    status: 'Pending',
    delayed: '13 Apr 2022',
    track: '15 Feb 2022',
  },
  {
    state: 'Rajesthan',
    report: 'Sikar',
    date: 'TAS1',
    status: 'Progress',
    delayed: '13 Apr 2022',
    track: '15 Feb 2022',
  },
  {
    state: 'Madhya Pradesh',
    report: 'Alirajpur ',
    date: 'TAS2',
    status: 'Completed',
    delayed: '13 Apr 2022',
    track: '15 Feb 2022',
  },
  {
    state: 'Bihar',
    report: 'Bhojpur',
    date: 'TAS3',
    status: 'Completed',
    delayed: '13 Apr 2022',
    track: '15 Feb 2022',
  },
];

const PredictionActivityHeader = [
  'State',
  'District',
  `Expected Activity (${nextYear})`,
  `Current Activity (${currentYear})`,
  `Current Year Status (${currentYear})`,
  `Expected Due Date Activity (${nextYear})`,
];
const PredictionProgressHeader = [
  'State/District',
  'District',
  `Expected Activity Due (${nextYear}) `,
  `Expected Activity Due Date (${nextYear})`,
  `Current Year Activity (${currentYear})`,
  `Current Year Status (${currentYear})`,
];
const DistrictTableHeader = [
  'District',
  'Activity Planned',
  'Planned Date',
  'Activity Status',
  'Date Conducted',
  'Block Strategy Implemented',
  // 'Population under MDA/TAS (%)',
  'Data Status',
];
const BlockTableHeader = [
  'District',
  'Activity Planned',
  'Planned Date',
  'Activity Status',
  'Date Conducted',
  'Block Strategy Implemented',
  'Population under MDA/TAS (%)',
  'Data Status',
];
const DistrictTableContent = [
  {
    district: 'Agra',
    planned: 'TAS1',
    date: '13th Feb,22',
    activity: 'Completed',
    conduct: '13th Feb,22',
    strategy: 'Yes',
    population: '25',
    status: 'On-Track',
  },
  {
    district: 'Agra',
    planned: 'TAS1',
    date: '13th Feb,22',
    activity: 'Completed',
    conduct: '13th Feb,22',
    strategy: 'Yes',
    population: '25',
    status: 'On-Track',
  },
  {
    district: 'Agra',
    planned: 'TAS1',
    date: '13th Feb,22',
    activity: 'Completed',
    conduct: '13th Feb,22',
    strategy: 'Yes',
    population: '25',
    status: 'On-Track',
  },
  {
    district: 'Agra',
    planned: 'TAS1',
    date: '13th Feb,22',
    activity: 'Completed',
    conduct: '13th Feb,22',
    strategy: 'Yes',
    population: '25',
    status: 'On-Track',
  },
  {
    district: 'Agra',
    planned: 'TAS1',
    date: '13th Feb,22',
    activity: 'Completed',
    conduct: '13th Feb,22',
    strategy: 'Yes',
    population: '25',
    status: 'On-Track',
  },
  {
    district: 'Agra',
    planned: 'TAS1',
    date: '13th Feb,22',
    activity: 'Completed',
    conduct: '13th Feb,22',
    strategy: 'Yes',
    population: '25',
    status: 'On-Track',
  },
  {
    district: 'Agra',
    planned: 'TAS1',
    date: '13th Feb,22',
    activity: 'Completed',
    conduct: '13th Feb,22',
    strategy: 'Yes',
    population: '25',
    status: 'On-Track',
  },
];
const StateProgressHeader = ['District', 'Activity Planned', 'Date Planned', 'Date Completed', 'Data Status'];
const StateProgressContent = [
  {
    district: 'Uttar Pradesh',
    activity: 'TAS1',
    planned: '13th Feb,22',
    completed: '13th Feb,22',
    ontrack: 'On-Track',
  },
  {
    district: 'Uttar Pradesh',
    activity: 'TAS1',
    planned: '13th Feb,22',
    completed: '13th Feb,22',
    ontrack: 'On-Track',
  },
  {
    district: 'Uttar Pradesh',
    activity: 'TAS1',
    planned: '13th Feb,22',
    completed: '13th Feb,22',
    ontrack: 'On-Track',
  },
];

// const DistrictProfileHeader = [
//   'State',
//   'District',
//   'Block Count',
//   'Current Year Activity',
//   'Previous Year Activity',
//   ...YEAR_RANGE,
// ];
const DistrictProfileContent = [
  {
    district: 'Uttar Pradesh',
    strategy: 'Yes',
    activity: 'TAS1',
    pre_activity: 'MDA',
    year_2010: 'MDA',
    year_2011: 'MDA',
    year_2012: 'MDA',
    year_2013: 'MDA',
  },
  {
    district: 'Uttar Pradesh',
    strategy: 'Yes',
    activity: 'TAS1',
    pre_activity: 'MDA',
    year_2010: 'MDA',
    year_2011: 'MDA',
    year_2012: 'MDA',
    year_2013: 'MDA',
  },
  {
    district: 'Uttar Pradesh',
    strategy: 'Yes',
    activity: 'TAS1',
    pre_activity: 'MDA',
    year_2010: 'MDA',
    year_2011: 'MDA',
    year_2012: 'MDA',
    year_2013: 'MDA',
  },
];
const DistrictMDAHeader = ['Blocks', 'Activity Planned', 'Planned Date', 'Current Status', 'Completion Date'];

const DistrictMDAHeaderDis = ['Sites', 'Activity Planned', 'Planned Date', 'Current Status', 'Completion Date'];
const DistrictMDAContent = [
  {
    blocks: 'Agra Block',
    activity: 'TAS1',
    planned: '13th Feb,22',
    status: 'Delayed',
    date: 'NA',
  },
];

const districtTasProfileHeader = [
  'State',
  'District',
  'EU Name',
  'Mapped Blocks',
  'Current Year Activity',
  'Previous Year Activity',
  ...YEAR_RANGE,
];
const TASContent = [
  {
    eu: 'EU 2',
    map: 'ASD, DFG, GHJ',
    curr_yr_activity: 'TAS 2',
    pre_yr_activity: 'TAS 2',
    year_2010: 'MDA',
    year_2011: 'MDA',
    year_2012: 'MDA',
    year_2013: 'MDA',
    year_20xx: 'MDA',
    year_20xy: 'MDA',
  },
  {
    eu: 'EU 2',
    map: 'ASD, DFG, GHJ',
    curr_yr_activity: 'TAS 2',
    pre_yr_activity: 'TAS 2',
    year_2010: 'MDA',
    year_2011: 'MDA',
    year_2012: 'MDA',
    year_2013: 'MDA',
    year_20xx: 'MDA',
    year_20xy: 'MDA',
  },
  {
    eu: 'EU 2',
    map: 'ASD, DFG, GHJ',
    curr_yr_activity: 'TAS 2',
    pre_yr_activity: 'TAS 2',
    year_2010: 'MDA',
    year_2011: 'MDA',
    year_2012: 'MDA',
    year_2013: 'MDA',
    year_20xx: 'MDA',
    year_20xy: 'MDA',
  },
];
const MatrixHeader = [
  'Population of District',
  'No of Blocks',
  'No of EU',
  'Current Year Activity',
  'Population Pending Current Activity',
  'Next Year Activity',
  'Population Pending Next Year Activity',
];
const MatrixContent = [
  {
    eu: 'EU 2',
    map: 'ASD, DFG, GHJ',
    curr_yr_activity: 'TAS 2',
    coverage: '85%',
    mf_rate: '2%',
    compliance: 'Yes',
    mf_compliance: 'Yes',
  },
];
const LineChartData = [
  { name: '', 2021: 0.0, 2022: 0.0 },
  { name: 'S1', 2021: 0.7, 2022: 0.3 },
  { name: 'R2', 2021: 0.1, 2022: 0.6 },
];
const DistrictLineChartData = [
  { name: '', 2018: 0.0, 2019: 0.0, 2020: 0.0, 2021: 0.0, 2022: 0.0 },
  { name: 'S1', 2018: 0.2, 2019: 0.4, 2020: 0.6, 2021: 0.7, 2022: 0.3 },
  { name: 'S2', 2018: 0.7, 2019: 0.2, 2020: 0.4, 2021: 0.1, 2022: 0.6 },
  { name: 'S3', 2018: 0.2, 2019: 0.4, 2020: 0.6, 2021: 0.8, 2022: 0.0 },
  { name: 'S4', 2018: 0.2, 2019: 0.4, 2020: 0.6, 2021: 0.65, 2022: 0.0 },
  { name: 'R1', 2018: 0.2, 2019: 0.4, 2020: 0.6, 2021: 0.65, 2022: 0.0 },
  { name: 'R2', 2018: 0.2, 2019: 0.4, 2020: 0.6, 2021: 0.65, 2022: 0.0 },
  { name: 'R3', 2018: 0.2, 2019: 0.4, 2020: 0.6, 2021: 0.65, 2022: 0.0 },
  { name: 'R4', 2018: 0.2, 2019: 0.4, 2020: 0.6, 2021: 0.65, 2022: 0.0 },
];
const VerticalChartData = [
  {
    name: '2018',
    value: 20,
  },
  {
    name: '2019',
    value: 40,
  },
  {
    name: '2020',
    value: 30,
  },
  {
    name: '2021',
    value: 80,
  },
  {
    name: '2022',
    value: 90,
  },
];
const DistrictMDAVerticalChartData = [
  {
    name: 'S1',
    value: 0.02,
  },
  {
    name: 'S2',
    value: 0.04,
  },
  {
    name: 'S3',
    value: 0.03,
  },
  {
    name: 'S4',
    value: 0.08,
  },
  {
    name: 'S5',
    value: 0.05,
  },
];
const HorizontalChartData = [
  {
    name: 'Awaited Prev. Results',
    value: 10,
  },
  {
    name: 'TAS 3',
    value: 20,
  },
  {
    name: 'TAS 2',
    value: 40,
  },
  {
    name: 'Pre-TAS / TAS 1',
    value: 30,
  },
  {
    name: 'MDA',
    value: 60,
  },
];
const StackData = [
  {
    label: 'MDA',
    completed: 25,
    delayed: 40,
    pending: 15,
  },
  {
    label: 'TAS1',
    completed: 15,
    delayed: 30,
    pending: 50,
  },
  {
    label: 'TAS2',
    completed: 20,
    delayed: 30,
    pending: 40,
  },
  {
    label: 'TAS3',
    completed: 10,
    delayed: 45,
    pending: 45,
  },
];
const SingelLineChartData = [
  { name: 'S1', percent: 0.5 },
  { name: 'S2', percent: 1.8 },
  { name: 'S3', percent: 0.1 },
  { name: 'S4', percent: 0.15 },
];
const districtMDACoverage = {
  aaa: [
    { name: '2010', percent: 70 },
    { name: '2011', percent: 80 },
    { name: '2012', percent: 72 },
    { name: '2013', percent: 80 },
    { name: '2014', percent: 0 },
    { name: '2015', percent: 70 },
  ],
  bbb: [
    { name: 'S1', 2021: 0.02, 2022: 0.5 },
    { name: null, 2021: 0, 2022: 0.4 },
    { name: null, 2021: 0.24, 2022: 0.3 },
    { name: null, 2021: 0.22, 2022: 0.2 },
    { name: 'R1', 2021: 0.3, 2022: 0 },
    { name: null, 2021: 0.0, 2022: 0.15 },
    { name: null, 2021: 0.2, 2022: 0.2 },
    { name: null, 2021: 0.0, 2022: 0.2 },
  ],
  xxx: [
    { name: '2016', percent: 70 },
    { name: '2017', percent: 80 },
    { name: '2018', percent: 72 },
    { name: '2019', percent: 80 },
    { name: '2020', percent: 0 },
    { name: '2021', percent: 70 },
  ],
  yyy: [
    { name: 'S1', percent: 0.5 },
    { name: 'S2', percent: 1.8 },
    { name: 'S3', percent: 0.1 },
    { name: 'S4', percent: 0.15 },
  ],
};
const countryProfile = [
  {
    type: 'IDA',
    district: 'Chennai',
    state: 'Tamil Nadu',
    state_code: 'TN',
    tooltip: [
      [
        { description: 'Total Districts under MDA', count: '6' },
        { description: 'MDA rounds completed', count: '6' },
        { description: 'Last round Coverage', count: '6' },
        { description: 'Consecutive 5 MDA Rounds', count: '6' },
        { description: 'Total Population', count: '6' },
        { description: 'Total EUs', count: '6' },
        { description: 'Average Coverage', count: '6%' },
      ],
    ],
  },
  {
    type: 'DA',
    district: 'Vellore',
    state: 'Tamil Nadu',
    state_code: 'TN',
    'Endemic Districts': '6',
    'Endemic Blocks': '6',
    'Endemic Population': '6',
    'MDA Districts': '6',
    'Pre TAS Districts': '6',
    'TAS cleared Districts': '6',
  },
  {
    type: 'PMS TAS 1',
    district: 'Theni',
    state: 'Tamil Nadu',
    state_code: 'TN',
    tooltip: [
      [
        { description: 'Total Districts under MDA', count: '6' },
        { description: 'MDA rounds completed', count: '6' },
        { description: 'Last round Coverage', count: '6' },
        { description: 'Consecutive 5 MDA Rounds', count: '6' },
        { description: 'Total Population', count: '6' },
        { description: 'Total EUs', count: '6' },
        { description: 'Average Coverage', count: '6%' },
      ],
    ],
  },
  {
    type: 'Pre TAS/TAS 1',
    district: 'Thoothukudi',
    state: 'Tamil Nadu',
    state_code: 'TN',
    tooltip: [
      [
        { description: 'Total Districts under MDA', count: '6' },
        { description: 'MDA rounds completed', count: '6' },
        { description: 'Last round Coverage', count: '6' },
        { description: 'Consecutive 5 MDA Rounds', count: '6' },
        { description: 'Total Population', count: '6' },
        { description: 'Total EUs', count: '6' },
        { description: 'Average Coverage', count: '6%' },
      ],
    ],
  },
  {
    type: 'Do Dossier Prep',
    district: 'Virudhunagar',
    state: 'Tamil Nadu',
    state_code: 'TN',
    tooltip: [
      [
        { description: 'Total Districts under MDA', count: '6' },
        { description: 'MDA rounds completed', count: '6' },
        { description: 'Last round Coverage', count: '6' },
        { description: 'Consecutive 5 MDA Rounds', count: '6' },
        { description: 'Total Population', count: '6' },
        { description: 'Total EUs', count: '6' },
        { description: 'Average Coverage', count: '6%' },
      ],
    ],
  },
];
const dummyDistrict = [
  { id: 1, value: 'Chennai' },
  { id: 2, value: 'Kanniyakumari' },
  { id: 3, value: 'Trichy' },
  { id: 4, value: 'Nagapattinam' },
];
const dummyState = [
  { id: 1, value: 'Tamil Nadu' },
  { id: 2, value: 'Kerala' },
];
const DistrictData = [
  {
    state: 'Ariyalur',
    totalEndemicDistricts: 70,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underIDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 30 },
    ],
  },
  {
    state: 'Chengalpattu',
    totalEndemicDistricts: 100,
    data: [
      { name: 'districtsClearedTAS3', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 60 },
    ],
  },
  {
    state: 'Chennai',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'Coimbatore',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 50 },
      { name: 'underIDA', value: 10 },
    ],
  },
  {
    state: 'Cuddalore',
    totalEndemicDistricts: 80,
    data: [
      { name: 'districtsClearedTAS2', value: 10 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 50 },
    ],
  },
  {
    state: 'Dharmapuri',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'Dindigul',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS3', value: 30 },
      { name: 'underIDA', value: 30 },
    ],
  },
  {
    state: 'Erode',
    totalEndemicDistricts: 70,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underIDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 30 },
    ],
  },
  {
    state: 'Kallakurichi',
    totalEndemicDistricts: 100,
    data: [
      { name: 'districtsClearedTAS3', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 60 },
    ],
  },
  {
    state: 'Kanchipuram',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'Kanyakumari',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 50 },
      { name: 'underIDA', value: 10 },
    ],
  },
  {
    state: 'Karur',
    totalEndemicDistricts: 80,
    data: [
      { name: 'districtsClearedTAS2', value: 10 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 50 },
    ],
  },
  {
    state: 'Krishnagiri',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'Madurai',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS3', value: 30 },
      { name: 'underIDA', value: 30 },
    ],
  },
  {
    state: 'Nagapattinam',
    totalEndemicDistricts: 70,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underIDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 30 },
    ],
  },
  {
    state: 'Namakkal',
    totalEndemicDistricts: 100,
    data: [
      { name: 'districtsClearedTAS3', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 60 },
    ],
  },
  {
    state: 'Nilgiris',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'Perambalur',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 50 },
      { name: 'underIDA', value: 10 },
    ],
  },
  {
    state: 'Pudukkottai',
    totalEndemicDistricts: 80,
    data: [
      { name: 'districtsClearedTAS2', value: 10 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 50 },
    ],
  },
  {
    state: 'Ramanathapuram',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'Ranipet',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS3', value: 30 },
      { name: 'underIDA', value: 30 },
    ],
  },
  {
    state: 'Salem',
    totalEndemicDistricts: 70,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underIDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 30 },
    ],
  },
  {
    state: 'Sivaganga',
    totalEndemicDistricts: 100,
    data: [
      { name: 'districtsClearedTAS3', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 60 },
    ],
  },
  {
    state: 'Tenkasi',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'Thanjavur',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 50 },
      { name: 'underIDA', value: 10 },
    ],
  },
  {
    state: 'Theni',
    totalEndemicDistricts: 80,
    data: [
      { name: 'districtsClearedTAS2', value: 10 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 50 },
    ],
  },
  {
    state: 'Thoothukudi',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'Tiruchirappalli',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS3', value: 30 },
      { name: 'underIDA', value: 30 },
    ],
  },
  {
    state: 'Tirunelveli',
    totalEndemicDistricts: 70,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underIDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 30 },
    ],
  },
  {
    state: 'Tirupathur',
    totalEndemicDistricts: 100,
    data: [
      { name: 'districtsClearedTAS3', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 60 },
    ],
  },
  {
    state: 'Tiruppur',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'Tiruvallur',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 50 },
      { name: 'underIDA', value: 10 },
    ],
  },
  {
    state: 'Tiruvannamalai',
    totalEndemicDistricts: 80,
    data: [
      { name: 'districtsClearedTAS2', value: 10 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 50 },
    ],
  },
  {
    state: 'Tiruvarur',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'Vellore',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS3', value: 30 },
      { name: 'underIDA', value: 30 },
    ],
  },
  {
    state: 'Viluppuram',
    totalEndemicDistricts: 100,
    data: [
      { name: 'districtsClearedTAS3', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 60 },
    ],
  },
  {
    state: 'Virudhunagar',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
];

const StateData = [
  {
    state: 'HARYANA',
    totalEndemicDistricts: 70,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underIDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 30 },
    ],
  },
  {
    state: 'UTTAR PRADESH',
    totalEndemicDistricts: 100,
    data: [
      { name: 'districtsClearedTAS3', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 60 },
    ],
  },
  {
    state: 'TRIPURA',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'GUJARAT',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 50 },
      { name: 'underIDA', value: 10 },
    ],
  },
  {
    state: 'UTTARAKHAND',
    totalEndemicDistricts: 80,
    data: [
      { name: 'districtsClearedTAS2', value: 10 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 50 },
    ],
  },
  {
    state: 'JHARKHAND',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'GOA',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS3', value: 30 },
      { name: 'underIDA', value: 30 },
    ],
  },
  {
    state: 'LADAKH',
    totalEndemicDistricts: 70,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underIDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 30 },
    ],
  },
  {
    state: 'JAMMU AND KASHMIR',
    totalEndemicDistricts: 100,
    data: [
      { name: 'districtsClearedTAS3', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 60 },
    ],
  },
  {
    state: 'BIHAR',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'MADHYA PRADESH',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 50 },
      { name: 'underIDA', value: 10 },
    ],
  },
  {
    state: 'PUDUCHERRY',
    totalEndemicDistricts: 80,
    data: [
      { name: 'districtsClearedTAS2', value: 10 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 50 },
    ],
  },
  {
    state: 'ODISHA',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'MIZORAM',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS3', value: 30 },
      { name: 'underIDA', value: 30 },
    ],
  },
  {
    state: 'HIMACHAL PRADESH',
    totalEndemicDistricts: 70,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underIDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 30 },
    ],
  },
  {
    state: 'PUNJAB',
    totalEndemicDistricts: 100,
    data: [
      { name: 'districtsClearedTAS3', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 60 },
    ],
  },
  {
    state: 'TAMIL NADU',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'DELHI',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 50 },
      { name: 'underIDA', value: 10 },
    ],
  },
  {
    state: 'RAJASTHAN',
    totalEndemicDistricts: 80,
    data: [
      { name: 'districtsClearedTAS2', value: 10 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 50 },
    ],
  },
  {
    state: 'ANDHRA PRADESH',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'MANIPUR',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS3', value: 30 },
      { name: 'underIDA', value: 30 },
    ],
  },
  {
    state: 'KERALA',
    totalEndemicDistricts: 70,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underIDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 30 },
    ],
  },
  {
    state: 'CHHATTISGARH',
    totalEndemicDistricts: 100,
    data: [
      { name: 'districtsClearedTAS3', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 60 },
    ],
  },
  {
    state: 'NAGALAND',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'SIKKIM',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 50 },
      { name: 'underIDA', value: 10 },
    ],
  },
  {
    state: 'KARNATAKA',
    totalEndemicDistricts: 80,
    data: [
      { name: 'districtsClearedTAS2', value: 10 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 50 },
    ],
  },
  {
    state: 'MAHARASHTRA',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'MEGHALAYA',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS3', value: 30 },
      { name: 'underIDA', value: 30 },
    ],
  },
  {
    state: 'ANDAMAN AND NICOBAR ISLANDS',
    totalEndemicDistricts: 70,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underIDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 30 },
    ],
  },
  {
    state: 'CHANDIGARH',
    totalEndemicDistricts: 100,
    data: [
      { name: 'districtsClearedTAS3', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 60 },
    ],
  },
  {
    state: 'DADRA AND NAGAR HAVELI',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'ARUNACHAL PRADESH',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS1', value: 50 },
      { name: 'underIDA', value: 10 },
    ],
  },
  {
    state: 'ARUNACHAL PRADESH',
    totalEndemicDistricts: 80,
    data: [
      { name: 'districtsClearedTAS2', value: 10 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 50 },
    ],
  },
  {
    state: 'DAMAN AND DIU',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'districtsClearedTAS3', value: 30 },
      { name: 'underIDA', value: 30 },
    ],
  },
  {
    state: 'LAKSHADWEEP',
    totalEndemicDistricts: 100,
    data: [
      { name: 'districtsClearedTAS3', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 60 },
    ],
  },
  {
    state: 'TELANGANA',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
  {
    state: 'ASSAM',
    totalEndemicDistricts: 100,
    data: [
      { name: 'districtsClearedTAS3', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 60 },
    ],
  },
  {
    state: 'WEST BENGAL',
    totalEndemicDistricts: 80,
    data: [
      { name: 'underDA', value: 20 },
      { name: 'underPre-TAS', value: 20 },
      { name: 'underIDA', value: 40 },
    ],
  },
];
const StackedLineChart = [
  {
    Years: '2017',
    'TAS 1 Completed': 50,
    'LF Endemic District': 20,
    'New Endemic District': null,
  },
  {
    Years: '2018',
    'TAS 1 Completed': 30,
    'LF Endemic District': 50,
    'New Endemic District': null,
  },
  {
    Years: '2019',
    'TAS 1 Completed': 60,
    'LF Endemic District': 30,
    'New Endemic District': null,
  },
  {
    Years: '2020',
    'TAS 1 Completed': 70,
    'LF Endemic District': 40,
    'New Endemic District': 5,
  },
  {
    Years: '2021',
    'TAS 1 Completed': 30,
    'LF Endemic District': 30,
    'New Endemic District': 3,
  },
  {
    Years: '2022',
    'TAS 1 Completed': 30,
    'LF Endemic District': 30,
    'New Endemic District': 0,
  },
];
const DistrictDecisionHeader = [
  'State',
  'District',
  // 'No of Blocks',
  'Population',
  'No of EUs',
  // 'Current Stage',
  'Current Year Activity',
  'MDA coverage %',
  'Next Year Activity',
  // 'Population Under Next Year Activity',
];
const BlockDecisionHeader = [
  'State',
  'District',
  'No of Blocks',
  'Population',
  'No of EUs',
  // 'Current Stage',
  'Current Year Activity',
  'MDA coverage %',
  'Next Year Activity',
  // 'Population Under Next Year Activity',
];
const barLineData = [
  {
    year: '2016',
    'MDA Coverage': 50,
    'No. of sites with > 1mF rate': 5,
    'Pre-TAS': null,
    TAS1: null,
    TAS2: null,
  },
  {
    year: '2017',
    'MDA Coverage': 30,
    'No. of sites with > 1mF rate': 3,
    'Pre-TAS': null,
    TAS1: null,
    TAS2: null,
  },
  {
    year: '2018',
    'MDA Coverage': 60,
    'No. of sites with > 1mF rate': 5,
    'Pre-TAS': null,
    TAS1: null,
    TAS2: null,
  },
  {
    year: '2019',
    'MDA Coverage': 70,
    'No. of sites with > 1mF rate': 3,
    'Pre-TAS': null,
    TAS1: null,
    TAS2: null,
  },
  {
    year: '2020',
    'MDA Coverage': null,
    'No. of sites with > 1mF rate': null,
    'Pre-TAS': 40,
    TAS1: null,
    TAS2: null,
  },
  {
    year: '2021',
    'MDA Coverage': null,
    'No. of sites with > 1mF rate': null,
    'Pre-TAS': null,
    TAS1: 30,
    TAS2: null,
  },
  {
    year: '2022',
    'MDA Coverage': null,
    'No. of sites with > 1mF rate': null,
    'Pre-TAS': null,
    TAS1: null,
    TAS2: 10,
  },
];
export {
  cards,
  countryHeader,
  progressHeader,
  decisionMatrixHeader,
  decisionMatrixData,
  progressData,
  countryData,
  barData,
  AllDropDownList,
  sort,
  mdaHeader,
  tasSummary,
  mdaRowdata,
  preTasDataHead,
  preTasData,
  TypeCode,
  ActivityTypeCode,
  activityHeader,
  DistrictactivityHeader,
  activityDetails,
  DistrictactivityDetails,
  NationalDataQualityHeader,
  DataQualityHeader,
  DataQualityContent,
  activityTableHead,
  activityTableContent,
  PredictionActivityHeader,
  PredictionProgressHeader,
  DistrictTableHeader,
  DistrictTableContent,
  StateProgressHeader,
  StateProgressContent,
  // DistrictProfileHeader,
  BlockTableHeader,
  DistrictMDAHeaderDis,
  DistrictProfileContent,
  DistrictMDAHeader,
  DistrictMDAContent,
  MatrixContent,
  MatrixHeader,
  TASContent,
  districtTasProfileHeader,
  LineChartData,
  VerticalChartData,
  DistrictMDAVerticalChartData,
  HorizontalChartData,
  StackData,
  SingelLineChartData,
  DistrictLineChartData,
  countryProfile,
  dummyState,
  dummyDistrict,
  activityHeaderState,
  districtMDACoverage,
  DistrictData,
  StateData,
  StackedLineChart,
  DistrictDecisionHeader,
  BlockDecisionHeader,
  barLineData,
  predictionCountryProfileHeader,
};
