/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import './Table.css';
import { useDispatch, useSelector } from 'react-redux';
import { thDatas } from './TableEnteries';
import Table from '../../../../components/organisms/Table/index';
import {
  DELETE_USERS_GROUP_AND_TYPE,
  READ_USERS_GROUP_AND_TYPE,
  GET_USERS_GROUP_AND_TYPE,
  USER_ROLE_TOGGLE_FORM,
} from '../../../../constants/actionType';

/**
 * @name InputTable
 * @returns {React.ReactElement} return the user role component
 */
const RoleTable = (props) => {
  const { setForm } = props;
  const dispatch = useDispatch();
  const { userRoleTableArr, userRoleIdArr } = useSelector((store) => store.userRole);
  useEffect(() => {
    dispatch({ type: GET_USERS_GROUP_AND_TYPE });
  }, [dispatch]);

  /**
   *
   * @param {*} key
   * @param {*} data
   */
  const onTableActionCall = (key, data) => {
    const idObj = userRoleIdArr.filter((item) => item.sno === data.sno)[0];
    console.log(idObj?.rolePrivilegeId, 'jjdkjert');

    switch (key) {
      case 'VIEW':
        dispatch({ type: READ_USERS_GROUP_AND_TYPE, payload: { id: idObj?.rolePrivilegeId, isRoleEdit: false } });
        break;
      case 'EDIT':
        dispatch({ type: READ_USERS_GROUP_AND_TYPE, payload: { id: idObj?.rolePrivilegeId, isRoleEdit: true } });
        dispatch({
          type: USER_ROLE_TOGGLE_FORM,
          payload: {
            toggleForm: true,
            isRoleEdit: true,
            roleReadOnly: false,
          },
        });
        setForm(false);
        break;
      case 'DELETE':
        dispatch({ type: DELETE_USERS_GROUP_AND_TYPE, payload: idObj?.rolePrivilegeId });
        break;
      default:
        break;
    }
  };
  return (
    <Table
      header={thDatas}
      rows={userRoleTableArr}
      masterSetup={['Downloader', 'Search']}
      optional
      view
      onViewData={(obj) => onTableActionCall('VIEW', obj)}
      edit
      onEditData={(obj) => onTableActionCall('EDIT', obj)}
      deleteData
      onDeleteData={(obj) => onTableActionCall('DELETE', obj)}
      Activity="User Role"
      screen="masters"
    />
  );
};
export default RoleTable;
