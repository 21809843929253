import axios from 'axios';
import BaseUrl from '../../config/config';
import {
  getGeographicDimension,
  insertGeographicDimension,
  updateGeographicDimension,
  bulkUploadGeographicDimension,
} from '../../utils/api';

const userAurhId = localStorage.getItem('UserLoginId');

/**
 * @name getGeographicDimensionRequest function which make api request to get program activities
 * @returns {*} returns axios response data
 */
export const getGeographicDimensionRequest = () => {
  return axios.get(`${BaseUrl}/${getGeographicDimension}`);
};

/**
 * @param {object} data payload data
 * @name updateGeographicDimensionRequest function which make api request to update program activities
 * @returns {*} returns axios response data
 */
export const updateGeographicDimensionRequest = (data) => {
  return axios({
    method: 'put',
    url: `${BaseUrl}/${updateGeographicDimension}`,
    data: {
      state_id: data.state_id,
      district_id: data.district_id,
      State: data.state,
      District: data.district,
      strategy_id: data.Strategy,
      year: data.Year,
      from_date: data.from_date,
      to_date: data.to_date,
      active_status: data.status === 'Active' ? 1 : 0,
      geographic_dimension_id: data.geographic_dimension_id,
      created_by: userAurhId && userAurhId,
    },
  });
};

/**
 * @param {object} data payload data
 * @name insertGeographicDimensionRequest function which make api request to add program activity
 * @returns {*} returns axios response data
 */
export const insertGeographicDimensionRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertGeographicDimension}`,
    data: {
      state_id: data.state_id,
      district_id: data.district_id,
      State: data.state,
      District: data.district,
      strategy_id: data.Strategy,
      year: data.Year,
      from_date: data.from_date,
      to_date: data.to_date,
      active_status: data.status === 'Active' ? 1 : 0,
      geographic_dimension_id: data.geographic_dimension_id,
      created_by: userAurhId && userAurhId,
    },
  });
};
/**
 *
 * @param  {object} data payload data
 * @returns {*} returns axios response data
 */
export const bulkUploadGeographicDimensions = (data) => {
  // const formData = new FormData();
  // formData.append('file_upload', data);
  // formData.set('created_by', 1);
  return axios({
    method: 'post',
    url: `${BaseUrl}/${bulkUploadGeographicDimension}`,
    data,
  });
};
