import { takeLatest } from 'redux-saga/effects';
import {
  DEMOGRAPHIC_LIST,
  INSERT_DEMOGRAPHIC_DIMENSIONS,
  DEMOGRAPHIC_UPDATE,
  UPLOAD_DEMOGRAPHIC_DIMENSION,
} from '../../constants/actionType';
import {
  getDemographicDimensionsHandler,
  insertDemographicDimensionsHandler,
  updateDemographicDimensionsHandler,
  uploadDemographicDimensionsHandler,
} from '../handler/demographicsHandler';

/**
 * @name DemographicDimensionsSaga used for program activities api call
 * @yields DemographicDimensionsSaga
 */
function* DemographicDimensionsSaga() {
  yield takeLatest(DEMOGRAPHIC_LIST, getDemographicDimensionsHandler);
  yield takeLatest(INSERT_DEMOGRAPHIC_DIMENSIONS, insertDemographicDimensionsHandler);
  yield takeLatest(DEMOGRAPHIC_UPDATE, updateDemographicDimensionsHandler);
  yield takeLatest(UPLOAD_DEMOGRAPHIC_DIMENSION, uploadDemographicDimensionsHandler);
}
export default DemographicDimensionsSaga;
