import { takeLatest } from 'redux-saga/effects';
import {
  GET_STATE_DROPDOWN,
  GET_DISTRICT_DROPDOWN,
  GET_PROGRAM_ACTIVITY_TYPE,
  GET_STRATEGY_DROPDOWN,
  GET_TESTKITTYPE_DROPDOWN,
} from '../../constants/actionType';
import {
  getStateDropdownListHandler,
  getDistrictDropdownListHandler,
  getProgramActivityTypeDropdownListHandler,
  getStrategyHandler,
  getTestKitTypeHandler,
} from '../handler/dropdownHandler';

/**
 * @name DemographicDimensionsSaga used for program activities api call
 * @yields DemographicDimensionsSaga
 */
function* DropDownsSaga() {
  yield takeLatest(GET_STATE_DROPDOWN, getStateDropdownListHandler);
  yield takeLatest(GET_DISTRICT_DROPDOWN, getDistrictDropdownListHandler);
  yield takeLatest(GET_PROGRAM_ACTIVITY_TYPE, getProgramActivityTypeDropdownListHandler);
  yield takeLatest(GET_STRATEGY_DROPDOWN, getStrategyHandler);
  yield takeLatest(GET_TESTKITTYPE_DROPDOWN, getTestKitTypeHandler);
}
export default DropDownsSaga;
