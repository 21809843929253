/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './DatePicker.css';

/**
 *
 * @param {object} props - required props in date picker component
 * @returns {React.ReactElement} - returns the date picker
 */
const CustomDatePicker = (props) => {
  const { label, year, onHandleChange, date, disabled } = props;
  console.log(date,"datedate");
  

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        // minDate={minDate && new Date(minDate)}
        // maxDate={maxDate && new Date(maxDate)}
        className="date_comp"
        // inputFormat="dd MMM yyyy"
        // disableMaskedInput
        // views={year ? ['year'] : ['year', 'month', 'day']}
        // views={year ? ['year'] : ['month', 'year']}
        label={label}
        // value={date !== undefined ? (date !== '' ? date : null) : null}
        {...(date !== undefined && date !== '' ? { value: date } : {})}

        onChange={(e) => {
          onHandleChange(e);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            color="secondary"
            sx={{ width: '100%' }}
          />
        )}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;

CustomDatePicker.propTypes = {
  label: PropTypes.string,
  year: PropTypes.bool,
  onHandleChange: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  disabled: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
};

CustomDatePicker.defaultProps = {
  label: '',
  year: false,
  disabled: false,
  minDate: '',
  maxDate: '',
};
