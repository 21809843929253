import { range } from '../../../utils/common';

const currentYear = new Date().getFullYear();
const YEAR = range(currentYear, currentYear - 18, -1);
const YEARS = YEAR?.map((item) => {
  return `${item}(%)`;
});
const headers = [
  [
    'S.No',
    'State',
    'Bifurcated list of Endemic Districts',
    'New /Old district',
    'Population',
    'No of Eus',
    'Activity due in previous year ',
    'Activity Done in previous year ',
    'Month activity done in previous year ',
    'Exact date of activity in previous year ',
    'Activity Done for Current Year ',
    'Month in which activity done in Current Year ',
    'Month Proposed by State in Current Year ',
    'Status of Activity done in Current Year',
  ],
  [
    'S.No',
    'State',
    // 'Old Endemic districts',
    'Bifurcated list of Endemic Districts',
    'Block Name ',
    'New /Old district ',
    'Population',
    'No of Eus',
    'Activity due in previous year ',
    'Activity Done in previous year ',
    'Month activity done in previous year ',
    'Exact date of activity in previous year ',
    'Activity Done for Current Year ',
    'Month in which activity done in Current Year ',
    'Month Proposed by State in Current Year ',
    'Status of Activity done in Current Year',
  ],
  [
    [
      'S.No',
      'State',
      'District name',
      'Block Name (R1)',
      'Site Name (R1)',
      'Block Name (R2)',
      'Site Name (R2)',
      'Block Name (R3)',
      'Site Name (R3)',
      'Block Name (R4)',
      'Site Name (R4)',
      'Block Name (S1)',
      'Site Name (S1)',
      'Block Name (S2)',
      'Site Name (S2)',
      'Block Name (S3)',
      'Site Name (S3)',
      'Block Name (S4)',
      'Site Name (S4)',
      'R1',
      'R2',
      'R3',
      'R4',
      'S1',
      'S2',
      'S3',
      'S4',
      'R1_BSE',
      'R2_BSE',
      'R3_BSE',
      'R4_BSE',
      'BSE_Random',
      'S1_BSE',
      'S2_BSE',
      'S3_BSE',
      'S4_BSE',
      'BSE_Fixed ',
      'FTS/ NBS',
      'NBS Survey Date',
      'Year',
      'DA/IDA',
      'MDA date ',
      // 'MDA done     2',
      'R1 %',
      'R2 %',
      'R3 %',
      'R4 %',
      'S1 %',
      'S2 %',
      'S3 %',
      'S4 %',
      'MF criteria met for all sites',
    ],
    [
      'S.No',
      'State',
      'District name',
      'Block Name ',
      'DA/IDA',
      'Site Name (S1)',
      'Site Name (R1)',
      'S1',
      'R1',
      'S1_BSE',
      'R1_BSE',
      'FTS/ NBS',
      'NBS Survey Date',
      'MDA date ',
      'S1 %',
      'R1 %',
      'MF criteria met for all sites',
    ],
  ],
  [
    ['S.No', 'State', 'District ', ...YEARS, 'Total MDA rounds'],
    ['S.No', 'State', 'District ', 'Block', ...YEARS, 'Total MDA rounds'],
  ],
  [
    ['S.No', 'State', 'District ', 'Population', 'No of EUs', 'Date of TAS', 'Failed Round'],
    ['S.No', 'State', 'District ', 'Block', 'Population', 'No of EUs', 'Date of TAS', 'Failed Round'],
  ],
  [
    [
      'S.No',
      'State',
      'District',
      'Month ',
      'Year ',
      'NBS/ FTS',
      'MF positive_AR1',
      'MF positive_AR2',
      'MF positive_AR3',
      'MF positive_AR4',
      'MF positive_AR5',
      'MF positive_AR6',
      'MF positive_AR7',
      'MF positive_AR8',
      'MF positive_AR9',
      'MF positive_AR10',
      'BSE_AR1',
      'BSE_AR2',
      'BSE_AR3',
      'BSE_AR4',
      'BSE_AR5',
      'BSE_AR6',
      'BSE_AR7',
      'BSE_AR8',
      'BSE_AR9',
      'BSE_AR10',
      'MF Rate_AR1',
      'MF Rate_AR2',
      'MF Rate_AR3',
      'MF Rate_AR4',
      'MF Rate_AR5',
      'MF Rate_AR6',
      'MF Rate_AR7',
      'MF Rate_AR8',
      'MF Rate_AR9',
      'MF Rate_AR10',
      'Result of Pre-TAS',
    ],
    // [
    //   'S.No.',
    //   'State',
    //   'District',
    //   'Block',
    //   'Month ',
    //   'Year ',
    //   'NBS/ FTS',
    //   'MF positive_AR1',
    //   'MF positive_AR2',
    //   'MF positive_AR3',
    //   'BSE_AR1',
    //   'BSE_AR2',
    //   'BSE_AR3',
    //   'MF Rate_AR1',
    //   'MF Rate_AR2',
    //   'MF Rate_AR3',
    //   'Result of Pre-TAS',
    // ],

    // change
    [
      'S.No.',
      'State',
      'District',
      'Block',
      'Month ',
      'Year ',
      'NBS/ FTS',
      'MF positive_AR1',
      'BSE_AR1',
      'MF Rate_AR1',
      'Result of Pre-TAS',
    ],
  ],

  [
    'S.No',
    'Type of survey',
    'State',
    'District',
    'EU Name ',
    'Block Names',
    'Survey(month)',
    'Survey(year)',
    'Target sample size',
    'Number of people examined',
    'Number of people positive',
    'Critical cut off',
    'Report pending',
    'Status',
  ],
  [
    'S.No',
    'Type of survey',
    'State',
    'District',
    'EU Name ',
    'Survey(month)',
    'Survey(year)',
    'Target sample size',
    'Number of people examined',
    'Number of people positive',
    'Critical cut off',
    'Report pending ',
    'Status',
  ],
  [
    'S.No.',
    'State',
    'District',
    'No. of blocks',
    'No of EUs',
    'Activity proposed in previous year',
    'Activity Done in previous year',
  ],
  [
    'S.No.',
    'State',
    'District',
    'Block name',
    'Name of EU',
    'Activity proposed in previous year',
    'Activity Done in previous year',
  ],
  [
    'S.No.',
    'State',
    'District',
    'Activity due in current year',
    'Name of EU',
    'Name of block',
    'Month in which activity due in Current Year',
  ],
  [
    'S.No.',
    'State',
    'District',
    'Name of block',
    'Name of EU',
    'Activity due in current year',
    'Month in which activity due in Current Year',
  ],
  [
    'S.No.',
    'State',
    'Total no of districts Pending MDA',
    'Total districts proposed for Pre TAS/ TAS 1',
    'Total districts cleared TAS 1',
    'Total districts cleared TAS 2',
    'Total districts cleared TAS 3',
    'Popn of districts Pending MDA',
    'Popn. of districts proposed for Pre TAS/ TAS 1',
    'Popn of districts that cleared TAS 1',
    'Popn. of districts cleared TAS 2',
    'Popn. of districts that cleared TAS 3',
  ],
  [
    'S.No.',
    'State',
    'District',
    'No of blocks Pending MDA',
    'Total population',
    'Total persons consumed drugs',
    'Reported admin coverage (%)',
  ],
  [
    'S.No.',
    'State',
    'District',
    'Block name',
    'Total population',
    'Total persons consumed drugs',
    'Reported admin coverage (%)',
  ],
  [
    'S.No.',
    'State',
    'District',
    'Total MDA rounds',
    'Y1',
    // 'Total no of blocks under MDA',
    'Reported admin coverage (%)',
    'Y2',
    // 'Total no of blocks under MDA',
    'Reported admin coverage (%)',
    'Y3',
    // 'Total no of blocks under MDA',
    'Reported admin coverage (%)',
    'Y4',
    // 'Total no of blocks under MDA',
    'Reported admin coverage (%)',
    'Y5',
    // 'Total no of blocks under MDA',
    'Reported admin coverage (%)',
  ],
  [
    'S.No.',
    'State',
    'District',
    'Block name',
    'Total MDA rounds',
    'Y1',
    // 'Total no of blocks under MDA',
    'Reported admin coverage (%)',
    'Y2',
    // 'Total no of blocks under MDA',
    'Reported admin coverage (%)',
    'Y3',
    // 'Total no of blocks under MDA',
    'Reported admin coverage (%)',
    'Y4',
    // 'Total no of blocks under MDA',
    'Reported admin coverage (%)',
    'Y5',
    // 'Total no of blocks under MDA',
    'Reported admin coverage (%)',
  ],
  [
    'S.No',
    'State',
    'District name',
    'Block Name (R1)',
    'Site Name (R1)',
    'Block Name (R2)',
    'Site Name (R2)',
    'Block Name (R3)',
    'Site Name (R3)',
    'Block Name (R4)',
    'Site Name (R4)',
    'Block Name (S1)',
    'Site Name (S1)',
    'Block Name (S2)',
    'Site Name (S2)',
    'Block Name (S3)',
    'Site Name (S3)',
    'Block Name (S4)',
    'Site Name (S4)',
    'R1',
    'R2',
    'R3',
    'R4',
    'S1',
    'S2',
    'S3',
    'S4',
    'R1_BSE',
    'R2_BSE',
    'R3_BSE',
    'R4_BSE',
    'BSE_Random',
    'S1_BSE',
    'S2_BSE',
    'S3_BSE',
    'S4_BSE',
    'BSE_Fixed ',
    'FTS/ NBS',
    'NBS Survey Date',
    'Year',
    'DA/IDA',
    'MDA date ',
    // 'MDA done     2',
    'R1 %',
    'R2 %',
    'R3 %',
    'R4 %',
    'S1 %',
    'S2 %',
    'S3 %',
    'S4 %',
    'MF criteria met for all sites',
  ],
  [
    'S.No',
    'State',
    'District name',
    'Block Name ',
    'DA/IDA',
    'Site Name (R1)',
    'Site Name (S1)',
    'R1',
    'S1',
    'R1_BSE',
    'S1_BSE',
    'FTS/ NBS',
    'NBS Survey Date',
    'MDA date ',
    'R1 %',
    'S1 %',
    'MF criteria met for all sites',
  ],
  [
    'S.No',
    'State',
    'District',
    'Month ',
    'Year ',
    'NBS/ FTS',
    'MF positive_AR1',
    'MF positive_AR2',
    'MF positive_AR3',
    'MF positive_AR4',
    'MF positive_AR5',
    'MF positive_AR6',
    'MF positive_AR7',
    'MF positive_AR8',
    'MF positive_AR9',
    'MF positive_AR10',
    'BSE_AR1',
    'BSE_AR2',
    'BSE_AR3',
    'BSE_AR4',
    'BSE_AR5',
    'BSE_AR6',
    'BSE_AR7',
    'BSE_AR8',
    'BSE_AR9',
    'BSE_AR10',
    'MF Rate_AR1',
    'MF Rate_AR2',
    'MF Rate_AR3',
    'MF Rate_AR4',
    'MF Rate_AR5',
    'MF Rate_AR6',
    'MF Rate_AR7',
    'MF Rate_AR8',
    'MF Rate_AR9',
    'MF Rate_AR10',
    'Result of Pre-TAS',
  ],
  // channge
  [
    'S.No.',
    'State',
    'District',
    'Block',
    'Month ',
    'Year ',
    'NBS/ FTS',
    'MF positive_AR1',
    // 'MF positive_AR2',
    // 'MF positive_AR3',
    'BSE_AR1',
    // 'BSE_AR2',
    // 'BSE_AR3',
    'MF Rate_AR1',
    // 'MF Rate_AR2',
    // 'MF Rate_AR3',
    'Result of Pre-TAS',
  ],
  [
    'S.No.',
    'Type of survey',
    'State',
    'EU Name ',
    'Survey(month)',
    'Survey(year)',
    'Target sample size',
    'Number of people examined',
    'Number of people positive',
    'Critical cut off',
    'Report pending ',
    'Status',
  ],
  [
    'S.No.',
    'Type of survey',
    'State',
    'EU Name ',
    'Block names',
    'Survey(month)',
    'Survey(year)',
    'Target sample size',
    'Number of people examined',
    'Number of people positive',
    'Critical cut off',
    'Report pending ',
    'Status',
  ],
  ['S.No.', 'State', 'District', 'EU name', ...range(currentYear, currentYear - 12, -1)],
  ['S.No.', 'State', 'District', 'EU name', 'Block', ...range(currentYear, currentYear - 12, -1)],

  ['S.No.', 'State', 'District', 'Lymphedema Cases', 'Hydrocele Cases', 'Hydrocele Surgeries'],
];
export default headers;