import axios from 'axios';
import BaseUrl from '../../config/config';
import { getFilterDrivenExtractReports, getCustomReportsByType, customStandardReport } from '../../utils/api';

/**
 * @param {object} data payload
 * @param {string} filterType filter type
 * @name filterDrivenExtractReportsRequest used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const filterDrivenExtractReportsRequest = (data, filterType) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${filterType === 'Standard Reports' ? customStandardReport : getFilterDrivenExtractReports}`,
    data,
    // {
    //   years: data?.Year || '',
    //   state: data?.State || '',
    //   district: data?.District || '',
    //   activity: data?.Activity || '',
    //   data_status: data?.Data_Status || '',
    //   strategy: data?.Strategy || '',
    //   report: data?.Report || '',
    // },
  });
};
// export default filterDrivenExtractReportsRequest;

/**
 * @param {object} data payload
 * @name customReportByTypeRequest used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const customReportByTypeRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getCustomReportsByType}`,
    data: {
      type_of_custom: data,
    },
    // {
    //   years: data?.Year || '',
    //   state: data?.State || '',
    //   district: data?.District || '',
    //   activity: data?.Activity || '',
    //   data_status: data?.Data_Status || '',
    //   strategy: data?.Strategy || '',
    //   report: data?.Report || '',
    // },
  });
};
// export default customReportByTypeRequest;
