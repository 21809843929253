/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import BaseUrl from '../../config/config';
import {
  getPreTasAndTasOverView,
  getMdaCoverage,
  getDistrictMovedtoTASvsTotalEndemicDistrict,
  getTASsummary,
} from '../../utils/api';

/**
 * @param {object} data payload
 * @name getPreTasOverviewRequest used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const getPreTasOverviewRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getPreTasAndTasOverView}`,
    data: {
      years: data.Year || '',
      states: data.State || '',
      sort: data.Sort || '',
      statusLevel: data.Status || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @param {object} data payload
 * @name getMdaCoverageRequest used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const getMdaCoverageRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getMdaCoverage}`,
    data: {
      years: data.Year || '',
      states: data.State || '',
      sort: data.Sort || '',
      activity: data.Activity || data.Activity_MDA || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @name getDistrictMovedtoTASvsTotalEndemicDistrictRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getDistrictMovedtoTASvsTotalEndemicDistrictRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getDistrictMovedtoTASvsTotalEndemicDistrict}`,
    data: {
      years: '', // years filter removed
      state: data.State || '',
      district: data.District || '',
      activity: data.Activity || '',
      status: data.Status || '',
      strategy: data.Strategy || '',
    },
  });
};
/**
 * @name getTASsummaryRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getTASsummaryRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getTASsummary}`,
    data: {
      years: data.Year || '',
      state: data.State || '',
      district: data.District || '',
      sort: data.Sort || '',
      status: data.Status || '',
      strategy: data.Strategy || '',
    },
  });
};
