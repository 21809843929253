export const formEntries = [
  {
    count: 'true',
    entryName: 'state',
    placeholder: 'State',
    dropdown: true,
    data: [
      { id: 1, value: 'Kerala' },
      { id: 2, value: 'Goa' },
      { id: 3, value: 'TamilNadu' },
    ],
    validation: [{ isRequired: true }],
  },
  {
    count: 'true',
    entryName: 'district',
    placeholder: 'District',
    disabled: true,
    dropdown: true,
    data: [
      { id: 1, value: 'Kerala' },
      { id: 2, value: 'Goa' },
      { id: 3, value: 'TamilNadu' },
    ],
    validation: [{ isRequired: true }],
  },
  {
    count: true,
    date: true,
    entryName: 'drug_available_date_DEC',
    placeholder: 'Drug Available Date -DEC',
    validation: [{ isRequired: true }],
  },
  {
    count: true,
    date: true,
    entryName: 'drug_available_date_ALB',
    placeholder: 'Drug Available Date -ALB',
    validation: [{ isRequired: true }],
  },
  {
    count: true,
    date: true,
    entryName: 'drug_available_date_IVR',
    placeholder: 'Drug Available Date -IVR',
    validation: [{ isRequired: false }],
  },
];
export const formDefaultValues = {
  state: '',
  district: '',
  Strategy: '',
  drug_available_date_DEC: "",
  drug_available_date_ALB: "",
  drug_available_date_IVR: "",
};
