import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import './CountRange.css';
/**
 * @name CountRange
 * @param {string} props  required props
 * @returns {React.ReactElement} returns the count range component
 */
const CountRange = (props) => {
  const { labelText, PCount, NCount, initialCount, defaultCount } = props;
  const [count, setCount] = React.useState(1);
  useEffect(() => {
    if (initialCount) {
      setCount(1);
    }
  }, [initialCount]);
  useEffect(() => {
    if (defaultCount) {
      setCount(defaultCount);
    }
  }, [defaultCount]);
  /**
   *
   */
  const PositiveCount = () => {
    setCount(count + 1);
    PCount(count + 1);
  };
  /**
   *
   */
  const NegativeCount = () => {
    setCount(count - 1);
    NCount(count - 1);
  };

  return (
    <Grid>
      <Typography text={labelText} type="link" customClass="countText" colorType="quaternary" />
      <Grid className="rootView">
        <Grid
          className={count > 1 ? 'buttonViewDelete' : 'buttonViewSeperate'}
          onClick={(index) => NegativeCount(index)}
        >
          -
        </Grid>

        <Typography text={count} colorType="quaternary" customClass="countStyle" disabled />
        <Grid className="buttonViewAdd" onClick={PositiveCount}>
          +
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CountRange;
CountRange.propTypes = {
  labelText: PropTypes.string.isRequired,
  PCount: PropTypes.func,
  NCount: PropTypes.func,
  initialCount: PropTypes.string,
  defaultCount: PropTypes.number,
};
CountRange.defaultProps = {
  PCount: () => {},
  NCount: () => {},
  initialCount: null,
  defaultCount: null,
};
