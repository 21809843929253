import { takeLatest } from 'redux-saga/effects';
import {
  GET_STATE_BY_SEARCH,
  GET_DISTRICT_BY_SEARCH,
} from '../../constants/actionType';
import {
  getStateSearchHandler,
  getDistrictSearchHandler,
} from '../handler/dropdownHandler';

/**
 * @name DemographicDimensionsSaga used for program activities api call
 * @yields DemographicDimensionsSaga
 */
function* DropDownSearchSaga() {

  yield takeLatest(GET_STATE_BY_SEARCH, getStateSearchHandler);
  yield takeLatest(GET_DISTRICT_BY_SEARCH, getDistrictSearchHandler);
}
export default DropDownSearchSaga;