/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Grid, InputAdornment, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomIcons from '../../utils/icons';
import './styleSheet.css';
import CustomTypography from '../../components/atoms/Typography';
import TextField from '../../components/atoms/TextField';
import CustomButton from '../../components/atoms/CustomButton';
import { USER_LOGIN, FORGET_PASSWORD } from '../../constants/actionType';
import Toaster from '../../components/atoms/ToastMessage';

/**
 *
 * @returns {React.ReactElement} -- LoginScreen
 */
const LoginScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [forgetPassword, setForgetPassword] = React.useState(false);
  const [loginContent, setLoginContent] = React.useState(true);
  console.log(loginContent,"loginContent");
  const [toaster, setToaster] = React.useState(false);
  const { loginDetails, forgetPasseordDetails, status } = useSelector((state) => state?.loginDetails);
  const [loginInfo, setLoginInfo] = React.useState({
    email: '',
    password: '',
    forgetMail: '',
  });
  /**
   *
   * @param {*} value
   */
  const getScreenValidation = (value) => {
    const arr = [];
    value?.map((item) => {
      const { bulk_upload, download, edit, manual_data_input, view } = item;
      if (bulk_upload || download || edit || manual_data_input || view === 1) {
        arr.push(item?.screen_name);
      }
    });
    return arr;
  };
  /**
   *
   */
  const actionValidation = (item) => {
    console.log(item, 'testItesm');
    const Input = [];
    const Masters = [];
    const Dashboard = [];
    const SendMail = [];
    item?.map((val) => {
      if (val?.screen_name === 'data_input') {
        Input.push({
          View: val?.view,
          Edit: val?.edit,
          Download: val?.download,
          BulkUpload: val?.bulk_upload,
          ManualDataInput: val?.manual_data_input,
        });
      }
      if (val?.screen_name === 'master_setup') {
        Masters.push({
          View: val?.view,
          Edit: val?.edit,
          Download: val?.download,
          BulkUpload: val?.bulk_upload,
          ManualDataInput: val?.manual_data_input,
        });
      }
      if (val?.screen_name === 'output_dashboard') {
        Dashboard.push({
          View: val?.view,
          Edit: val?.edit,
          Download: val?.download,
          BulkUpload: val?.bulk_upload,
          ManualDataInput: val?.manual_data_input,
        });
      }
      if (val?.screen_name === 'send_mail') {
        SendMail.push({
          View: val?.view,
          Edit: val?.edit,
          Download: val?.download,
          BulkUpload: val?.bulk_upload,
          ManualDataInput: val?.manual_data_input,
        });
      }
    });
    return { input: Input, masters: Masters, DashBoard: Dashboard, Sendmail: SendMail };
  };
  React.useEffect(() => {
    if (loginDetails && Number(localStorage.getItem('loggedIn')) === 1) {
      setToaster(true)
      if (loginDetails?.status === 1) {
        localStorage.setItem('userName', JSON.stringify(loginDetails?.data[0]?.userDetails[0].user_name));
        localStorage.setItem('Email', JSON.stringify(loginDetails?.data[0]?.userDetails[0].email));

        localStorage.setItem('userId', JSON.stringify(loginDetails?.data[0]?.userDetails[0]?.user_id));
        localStorage.setItem('userGroupId', JSON.stringify(loginDetails?.data[0]?.userDetails[0]?.user_group_id));
        localStorage.setItem('userGroup', JSON.stringify(loginDetails?.data[0]?.userDetails[0]?.user_group));
        localStorage.setItem('userTypeId', JSON.stringify(loginDetails?.data[0]?.userDetails[0]?.user_type_id));
        localStorage.setItem('userType', JSON.stringify(loginDetails?.data[0]?.userDetails[0]?.user_type));
        localStorage.setItem('inputScreen', JSON.stringify(loginDetails?.data[0]?.userDetails[0]?.input_screen));
        localStorage.setItem(
          'dashboardPrivilege',
          JSON.stringify(loginDetails?.data[0]?.userDetails[0]?.dashboard_screen),
        );
        localStorage.setItem(
          'ScreenAccessRole',
          JSON.stringify(getScreenValidation(loginDetails?.data[0]?.userDetails[0]?.input_screen)),
        );
        localStorage.setItem(
          'ActionAccess',
          JSON.stringify(actionValidation(loginDetails?.data[0]?.userDetails[0]?.input_screen)),
        );
        localStorage.setItem('UserLoginId', JSON.stringify(loginDetails?.data?.[0]?.userDetails?.[0]?.user_id));
        history.push('/dashboard');
      }
    }
    if (forgetPasseordDetails?.status === 1) {
      setLoginContent(true);
      setForgetPassword(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetails, forgetPasseordDetails]);
  /**
   *
   */
  const onClickHandle = () => {
    // setToaster(true);
    localStorage.setItem('loggedIn', 1);
    dispatch({ type: USER_LOGIN, payload: loginInfo });
  };
  /**
   *
   */
  const onClick = () => {
    setForgetPassword(true);
    setLoginContent(false);
  };
  console.log(status?.type, 'lksdsdeerw');
  /**
   *
   */
  const loginHandle = () => {
    dispatch({ type: FORGET_PASSWORD, payload: loginInfo });
  };
  /**
   *
   * @param {string} e - event on onchange
   * @param {string} key - event name
   */
  const onHandleChangeMail = (e, key) => {
    setLoginInfo((prevState) => ({
      ...prevState,
      [key]: e.target.value,
    }));
  };
  React.useEffect(() => {
    if (status === 0) {
      if (status.type === 'INITIAL') setToaster(false);
      setToaster(true);
    }
  }, [status]);
  /**
   *  onToasterClose used for toaser close func
   */
  const onToasterClose = () => {
    setToaster(false);
  };
  console.log(loginContent, toaster, 'sadfasdrewer');
  return (
    <Grid className="rootContainer">
      <Grid>
        <img src={CustomIcons.Banner} alt="" className="loginHeaderStyle" />
      </Grid>
      <Toaster
        open={toaster}
        severity="success"
        message="Please choose State,District and Strategy"
        close={() => setToaster(false)}
      />
      <Grid>
        <CustomTypography
          text="Lymphatic Filariasis Tracking Tool"
          type="header"
          customStyle={{ textAlign: 'center' }}
        />
      </Grid>
      {/* {loginContent && toaster && ( */}
      {toaster && (
        <Toaster
          open
          // severity={status?.type !== undefined ? status?.type : 'error'}
          // message={status?.msg !== undefined ? status?.msg : 'Server Error'}
          severity={status?.status === 1 ? 'success' : 'error'}
          // message={status?.msg !== undefined ? status?.msg : 'Server Error'}
          message={status?.msg !== undefined ? status?.msg : status?.message}
          close={onToasterClose}
        />
      )}

      <Grid item container md={12} sx={{ pt: 3 }} direction="row">
        <Grid item md={4} />
        <Grid item md={4} container rowGap={10} className="commonStyle">
          <Paper sx={{ pt: 5, paddingBottom: 5, pl: 11, pr: 11, borderRadius: 4 }}>
            {loginContent && (
              <Grid>
                <Grid item container direction="column" rowGap={3} className="commonStyle">
                  <TextField
                    value={loginInfo.email}
                    onChange={(e) => onHandleChangeMail(e, 'email')}
                    placeholder="Email"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src={CustomIcons.LoginMail} alt="" className="iconStyle" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    value={loginInfo.password}
                    type="password"
                    onChange={(e) => onHandleChangeMail(e, 'password')}
                    placeholder="Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src={CustomIcons.ChangePassword} alt="" className="iconStyle" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Grid md={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item container direction="row">
                      <input type="checkbox" style={{ backgroundColor: 'green' }} />
                      <CustomTypography
                        text="Remember Me"
                        colorType="senary"
                        customStyle={{ fontSize: '12px', whiteSpace: 'noWrap', paddingTop: 0.5 }}
                      />
                    </Grid>
                    <Grid onClick={onClick} className="onClick">
                      <CustomTypography
                        text="Forget Password?"
                        colorType="senary"
                        customStyle={{ fontSize: '12px', whiteSpace: 'noWrap', pt: 0.3 }}
                      />
                    </Grid>
                  </Grid>
                  <CustomButton
                    btnTitle="LOGIN"
                    variants="contained"
                    color="primary"
                    customStyle={{ width: '250px', fontSize: 16, boxShadow: 'unset' }}
                    onClickHandle={onClickHandle}
                  />
                </Grid>
              </Grid>
            )}

            {forgetPassword && (
              <Grid>
                <Grid item container direction="column" rowGap={4} className="commonStyle">
                  <CustomTypography
                    text="Forget Password"
                    type="subHeading"
                    colorType="senary"
                    customClass="commonStyle"
                  />
                  <Grid sx={{ width: '250px' }}>
                    <TextField
                      value={loginInfo.forgetMail}
                      onChange={(e) => onHandleChangeMail(e, 'forgetMail')}
                      placeholder="Email"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img src={CustomIcons.LoginMail} alt="" className="iconStyle" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <CustomButton
                    btnTitle="SUBMIT"
                    variants="contained"
                    color="primary"
                    customStyle={{ width: '250px', fontSize: 16, boxShadow: 'unset' }}
                    onClickHandle={loginHandle}
                  />
                </Grid>
                <Grid item md={12} className="backToLogin" sx={{ pt: 1.4 }} onClick={loginHandle}>
                  <CustomTypography text="Back to Login" customStyle={{ fontSize: '12px' }} colorType="senary" />
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
        <Grid item md={4} />
      </Grid>
    </Grid>
  );
};
export default LoginScreen;
