/* eslint-disable default-param-last */
import {
  GET_PRE_TAS_RESULT,
  INSERT_PRE_TAS_RESULT,
  UPDATE_PRE_TAS_ID_RESULT,
  UPDATE_PRE_TAS_RESULT,
  GET_PROPOSED_PRE_TAS_RESULT,
  LOADER_PRE_TAS,
  PRE_TAS_STATUS,
  PRE_TAS_UPLOAD,
  INSERT_PRE_TAS_BLOCK_RESULT,
  BLOCK_NAME_RESULT,
  EU_NAME_RESULT,
} from '../../constants/actionType';
import { preTas } from '../initialStates';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const preTasReducer = (state = preTas, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PRE_TAS_RESULT:
      return {
        ...state,
        getPreTas: payload.data,
      };
    case LOADER_PRE_TAS:
      return {
        ...state,
        loading: payload,
      };
    case INSERT_PRE_TAS_BLOCK_RESULT:
      return {
        ...state,
        loading: payload,
      };
    case INSERT_PRE_TAS_RESULT:
      return {
        ...state,
        status: payload.data,
      };
    case UPDATE_PRE_TAS_ID_RESULT:
      return {
        ...state,
        updatePreTasId: payload.data,
      };
    case UPDATE_PRE_TAS_RESULT:
      return {
        ...state,
        updatePreTas: payload.data,
      };
    case GET_PROPOSED_PRE_TAS_RESULT:
      return {
        ...state,
        ProposedData: payload.data,
      };
    case BLOCK_NAME_RESULT:
      return {
        ...state,
        blockName: payload.data,
      };
    case EU_NAME_RESULT:
      return {
        ...state,
        EUName: payload.data,
      };
    case PRE_TAS_STATUS:
      return {
        ...state,
        status: payload,
        loading: false,
      };
    case PRE_TAS_UPLOAD:
      return {
        ...state,
        status: payload,
        loading: false,
      };

    default:
      return state;
  }
};
export default preTasReducer;
