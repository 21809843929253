/* eslint-disable prefer-const */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as _ from 'loadsh';
import CustomForm from '../../../../components/organisms/CustomForm';
import { PreTASFormEntries, PreTASFormDefaultValues, EditEntries, PreTASFormEntries1 } from './PreTASFormEntries';
import {
  INSERT_PRE_TAS,
  GET_STATE_DROPDOWN,
  GET_DISTRICT_DROPDOWN,
  GET_STRATEGY_DROPDOWN,
  GET_PROPOSED_PRE_TAS,
  UPDATE_PRE_TAS_ID,
  GET_TESTKITTYPE_DROPDOWN,
  INSERT_PRE_TAS_BLOCK,
  EU_NAME,
  BLOCK_NAME,
} from '../../../../constants/actionType';

/**
 * @param {object} props - required props in IDA form input
 * @returns {React.ReactComponent} BuyForm
 */
const PreTASFormInput = (props) => {
  const { OnView, notify } = props;
  const [btn, setBtn] = React.useState(2);
  const [entries, setEntries] = React.useState(btn === 1 ? PreTASFormEntries1 : PreTASFormEntries);
  const [countRangeData, setHideCountRange] = React.useState([
    { labelText: 'Additional Random Site', disabled: false, defaultCount: 10 },
  ]);
  console.log(entries, 'asd;liewiwer');
  const [countRangeDataRange, setCountRange] = React.useState(true);
  const dispatch = useDispatch();
  const dropdownList = useSelector((state) => state.dropDownReducer);
  const { ProposedData } = useSelector((state) => state?.preTas);
  const { blockName } = useSelector((state) => state?.preTas);
  const [data1, setData1] = React.useState(0);
  const [randomCount, setRandomCount] = React.useState(10);
  const [isEdit, setEdit] = React.useState(false);
  const [block, setBlock] = React.useState(false);
  const [EditDefaults, setEditDefaults] = React.useState({});
  const [values, setValues] = React.useState([]);
  const [blackCount, setBlackCount] = React.useState(1);
  const dataValue = blackCount * 3;
  const [blockAdd, setBlockAdd] = React.useState();
  const [countBlock, setCountBlock] = React.useState(2);
  const [dividers, setDividers] = React.useState([]);
  const [indexvalue, setIndexValue] = React.useState([]);
  const [objectValue, setObjectValue] = React.useState(1);
  const [finalObjectValue, setFinalObjectValue] = React.useState([]);
  const [refresh, setCountRefresh] = React.useState(0);

  /**
   * @function handleCancel to cancel form
   */
  const handleCancel = () => {
    countRangeData[0].defaultCount = 1;
    setRandomCount(1);
    setEntries(PreTASFormEntries);
  };
  useEffect(() => {
    const index1 = entries.findIndex((x) => x.labelText === 'NBS');
    const val2 = entries.slice(index1 + 1, index1 + 12);

    const index2 = entries?.findIndex((x) => x.divider === true);
    const val3 = entries.slice(index2 + 1, index2 + 12);
  }, []);

  /**
   *
   * @param {*} keyValue
   * @param {*} start
   * @returns
   */
  function lastIndexOf(keyValue, start) {
    for (let i = entries.length - 1; i >= start; i--) {
      if (entries[i].divider === keyValue) {
        return i;
      }
    }
    return null;
  }
  React.useEffect(() => {
    const arr = [];
    entries?.map((value, index) => {
      if (value.divider) {
        arr.push(index);
      }
    });
    setIndexValue(arr);
  }, [entries, randomCount]);
  // useEffect(() => {
  //   dispatch({ type: GET_STATE_DROPDOWN });
  //   dispatch({ type: GET_STRATEGY_DROPDOWN });
  //   dispatch({ type: GET_TESTKITTYPE_DROPDOWN });
  // }, [dispatch]);

  React.useMemo(() => {
    if (ProposedData?.length > 0) {
      if (Object.keys(ProposedData[0])?.length > 1) {
        const sites = [];
        let defaultSites = {};
        for (let i = 1; i <= ProposedData[0]?.random; i += 1) {
          const siteName = 'random_name';
          const bloodCount = 'random_blood_count';
          const positive = 'random_possitive';
          sites.push(
            {
              value: ProposedData[0]?.details[i - 1][siteName] || '',
              default: true,
              textField: true,
              entryName: `BSE_R${i}_Name`,
              placeholder: 'Site Name',
              textInputType: 'default',
              validation: [{ isRequired: false }],
            },
            {
              value: ProposedData[0]?.details[i - 1][bloodCount] || '',
              default: true,
              textField: true,
              entryName: `BSE_R${i}`,
              placeholder: `BSE R${i}`,
              textInputType: 'default',
              validation: [{ isRequired: false }],
            },
            {
              remove: i !== 1,
              value: ProposedData[0]?.details[i - 1][positive] || '',
              default: true,
              textField: true,
              entryName: `Positive_R${i}`,
              placeholder: `Positive R${i}`,
              textInputType: 'default',
              validation: [{ isRequired: false }],
            },
          );
          defaultSites = {
            ...defaultSites,
            [`BSE_R${i}_Name`]: ProposedData[0]?.details[i - 1][siteName],
            [`BSE_R${i}`]: ProposedData[0]?.details[i - 1][bloodCount],
            [`Positive_R${i}`]: ProposedData[0]?.details[i - 1][positive],
          };
        }
        countRangeData[0].defaultCount = ProposedData[0]?.sentinal;
        countRangeData[1].defaultCount = ProposedData[0]?.random;
        const InsertPos = entries.findIndex((p) => p.entryName === 'Block_Name');
        setEntries([...entries.slice(0, InsertPos + 1), ...sites]);
        setCountRange(countRangeData);
        setRandomCount(ProposedData[0]?.random);
        setEditDefaults({
          State: EditDefaults.State,
          District: EditDefaults.District,
          Strategy: EditDefaults.Strategy,
          Date_Proposed_by_NVBDCP: '223',
          Date_Planned: ProposedData[0]?.date_planned || '',
          Date_Completed: ProposedData[0]?.date_completed || '',
          Covered_Population: ProposedData[0]?.covered_population || '',
          Population: ProposedData[0]?.population || '',
          Test_Type: ProposedData[0]?.details[0]?.test_type || '',
          NBS_Survey_Date: ProposedData[0]?.details[0]?.nbs_survey_date || '',
          Block_Name: ProposedData[0]?.details[0]?.block_name || '',
          nbs_date_completed: ProposedData[0]?.nbs_date_completed || '',
          ...defaultSites,
        });
      }
      // if (Object.keys(ProposedData[0]).length === 1) {
      //   setEditDefaults({
      //     State: EditDefaults.State,
      //     District: EditDefaults.District,
      //     Strategy: EditDefaults.Strategy,
      //     Date_Proposed: ProposedData[0]?.date_proposed || '',
      //     Date_Planned: '',
      //     Date_Completed: '',
      //     Covered_Population: '',
      //     Population: '',
      //     Test_Type: '',
      //     NBS_Survey_Date: '',
      //     Block_Name: '',
      //     nbs_date_completed: '',
      //   });
      //   handleCancel();
      // }
    } else {
      // setEditDefaults({
      //   State: EditDefaults.State,
      //   District: EditDefaults.District,
      //   Strategy: EditDefaults.Strategy,
      // });
      // handleCancel();
    }
    // if (block === true) {
    //   entries.splice(entries.length - 5, 5);
    //   setBlackCount(blackCount + 1);
    //   setEntries([
    //     ...entries,
    //     <br />,
    //     {
    //       default: true,
    //       entryName: 'block 1',
    //       placeholder: 'Block 1',
    //       dropdown: true,
    //       returnValue: true,
    //       data: [],
    //       validation: [{ isRequired: true }],
    //     },
    //     <br />,
    //     {
    //       default: true,
    //       entryName: 'Test_Type1',
    //       placeholder: 'Test Type1',
    //       dropdown: true,
    //       data: [
    //         { id: 1, value: 'Test 1' },
    //         { id: 2, value: 'Test 2' },
    //       ],
    //       validation: [{ isRequired: false }],
    //     },
    //     {
    //       default: true,
    //       textField: true,
    //       entryName: 'Pre_TAS_surveyDate1',
    //       placeholder: 'Pre-TAS Survey Date1',
    //       textInputType: 'default',
    //       validation: [{ isRequired: false }],
    //     },
    //     {
    //       value: '',
    //       default: true,
    //       textField: true,
    //       entryName: 'BSE_R1_Name',
    //       placeholder: 'BSE R1 Name',
    //       textInputType: 'default',
    //       validation: [{ isRequired: false }],
    //     },
    //     {
    //       value: '',
    //       isCombined: true,
    //       textField: true,
    //       entryName: 'BSE_R1',
    //       placeholder: 'BSE R1',
    //       textInputType: 'default',
    //       validation: [{ isRequired: false }],
    //     },
    //     {
    //       value: '',
    //       isCombined: true,
    //       textField: true,
    //       entryName: 'Positive_R1',
    //       placeholder: 'Positive R1',
    //       textInputType: 'default',
    //       validation: [{ isRequired: false }],
    //     },
    //     {
    //       default: true,
    //       entryName: 'Test_Type2',
    //       placeholder: 'Test Type2',
    //       dropdown: true,
    //       data: [
    //         { id: 1, value: 'Test 1' },
    //         { id: 2, value: 'Test 2' },
    //       ],
    //       validation: [{ isRequired: false }],
    //     },
    //     {
    //       default: true,
    //       textField: true,
    //       entryName: 'Pre_TAS_surveyDate2',
    //       placeholder: 'Pre-TAS Survey Date2',
    //       textInputType: 'default',
    //       validation: [{ isRequired: false }],
    //     },
    //     {
    //       value: '',
    //       default: true,
    //       textField: true,
    //       entryName: 'BSE_R2_Name',
    //       placeholder: 'BSE R2 Name',
    //       textInputType: 'default',
    //       validation: [{ isRequired: false }],
    //     },
    //     {
    //       value: '',
    //       isCombined: true,
    //       textField: true,
    //       entryName: 'BSE_R2',
    //       placeholder: 'BSE R2',
    //       textInputType: 'default',
    //       validation: [{ isRequired: false }],
    //     },
    //     {
    //       value: '',
    //       isCombined: true,
    //       textField: true,
    //       entryName: 'Positive_R2',
    //       placeholder: 'Positive R2',
    //       textInputType: 'default',
    //       validation: [{ isRequired: false }],
    //     },
    //     {
    //       default: true,
    //       entryName: 'Test_Type3',
    //       placeholder: 'Test Type3',
    //       dropdown: true,
    //       data: [
    //         { id: 1, value: 'Test 1' },
    //         { id: 2, value: 'Test 2' },
    //       ],
    //       validation: [{ isRequired: false }],
    //     },
    //     {
    //       default: true,
    //       textField: true,
    //       entryName: 'Pre_TAS_surveyDate3',
    //       placeholder: 'Pre-TAS Survey Date3',
    //       textInputType: 'default',
    //       validation: [{ isRequired: false }],
    //     },
    //     {
    //       value: '',
    //       default: true,
    //       textField: true,
    //       entryName: 'BSE_R3_Name',
    //       placeholder: 'BSE R3 Name',
    //       textInputType: 'default',
    //       validation: [{ isRequired: false }],
    //     },
    //     {
    //       value: '',
    //       isCombined: true,
    //       textField: true,
    //       entryName: 'BSE_R3',
    //       placeholder: 'BSE R3',
    //       textInputType: 'default',
    //       validation: [{ isRequired: false }],
    //     },
    //     {
    //       value: '',
    //       isCombined: true,
    //       textField: true,
    //       entryName: 'Positive_R3',
    //       placeholder: 'Positive R3',
    //       textInputType: 'default',
    //       validation: [{ isRequired: false }],
    //     },
    //   ]);
    // }
  }, [ProposedData]);

  /**
   *
   * @param {*} value - value of event on onchange
   * @param {*} entryName - entry name of form entries
   * @param {*} keyPair - keyPair value of selected dropdowns
   */
  const getValues = (value, entryName, keyPair) => {
    console.log(keyPair, 'kkdikeur');
    if (entryName === 'State') {
      dispatch({ type: GET_DISTRICT_DROPDOWN, payload: { state: value } });
      setEditDefaults({ ...EditDefaults, State: value, District: '', Strategy: '' });
      entries.find((item) => item.entryName === 'District').disabled = false;
      entries.find((item) => item.entryName === 'Strategy').disabled = false;
    }
    if (entryName === 'District') {
      entries.find((item) => item.entryName === 'Strategy').disabled = false;
      setEditDefaults({ ...EditDefaults, District: value, Strategy: '' });
      dispatch({
        type: BLOCK_NAME,
        payload: { state_id: keyPair.State, district_id: keyPair.District, BlockName: '' },
      });
    }
    if (entryName === 'Strategy') {
      dispatch({ type: GET_PROPOSED_PRE_TAS, payload: { params: keyPair, type: 'Pre-Tas' } });
      setEditDefaults({ ...EditDefaults, Strategy: value });
      setBtn(value);
      if (value === 1) {
        setRandomCount(3);
        setHideCountRange([{ labelText: 'Block Count', disabled: false, defaultCount: 2 }]);
        setEntries([...PreTASFormEntries1]);
        entries.find((item) => item?.entryName === 'block').disabled = false;
      } else {
        entries.find((item) => item?.entryName === 'Block_Name').disabled = false;
      }
      setEdit(true);
    }
  };

  React.useEffect(() => {
    if (btn === 2) {
      setEntries([...PreTASFormEntries]);
    }
    if (btn === 1) {
      // setRandomCount(3);
      // setHideCountRange([{ labelText: 'Block Count', disabled: false, defaultCount: 2 }]);
      // setEntries([...PreTASFormEntries1]);
    }
  }, [btn]);
  /**
   *
   */
  const handleRandom = () => {
    for (let i = 1; i <= randomCount; i++) {
      const val = i * 5 + 6;
      entries[val].data = _.assign(blockName && blockName);
      setEntries(entries);
    }
  };
  /**
   *
   */
  const handlePretasBlock = () => {
    for (let i = 1; i <= countBlock; i++) {
      const InsertPos = entries.findIndex((p) => p.entryName === `Block_Name_B${i}`);
      entries[InsertPos > 0 ? InsertPos : 8].data = _.assign(blockName);
      setEntries(entries);
    }
  };

  useEffect(() => {
    setCountRefresh(refresh + 1);
    if (refresh > 1) {
      if (btn === 1) {
        handlePretasBlock();
      }
      if (btn === 2) {
        handleRandom();
      }
    }
  }, [blockName, randomCount, btn, countBlock, entries]);
  // /**
  //  * @function handleCancel to cancel form
  //  */
  // const handleCancel = () => {
  //   countRangeData[0].defaultCount = 1;
  //   countRangeData[1].defaultCount = 1;
  //   setRandomCount(1);
  //   setEntries(PreTASFormEntries);
  // };

  /**
   * @param {number} count count value
   * @param {string} item selected count label
   */
  const handlePositiveCount = (count, item, index, page, page1) => {
    const ItemsValue = index + count * 4;
    if (item.labelText === 'Additional Random Site') {
      const found = indexvalue.find((element) => element > index);
      if (btn === 1) {
        if (found === undefined) {
          setRandomCount(count + 1);
          setObjectValue(count);
          setEntries([
            ...entries,
            // {
            //   isCombined: true,
            //   textField: true,
            //   entryName: `Block_R${count + 1}_B${countBlock}`,
            //   placeholder: `Block R${count + 1}`,
            //   textInputType: 'default',
            //   validation: [{ isRequired: true }],
            // },
            {
              default: true,
              textField: true,
              entryName: `Site_R${count + 1}_B${countBlock}`,
              placeholder: `Site Name R${count + 1}`,
              textInputType: 'default',
              validation: [{ isRequired: true }],
            },
            {
              isCombined: true,
              textField: true,
              entryName: `BSE_R${count + 1}_B${countBlock}`,
              placeholder: `BSE R${count + 1}`,
              textInputType: 'number',
              validation: [{ isRequired: true }],
            },
            {
              isCombined: true,
              textField: true,
              entryName: `Positive_R${count + 1}_B${countBlock}`,
              placeholder: `Positive R${count + 1}`,
              textInputType: 'number',
              validation: [{ isRequired: true }],
            },
            {
              default: true,
              textField: true,
              disabled: true,
              entryName: `MF_Rate_R${count + 1}_B${countBlock}`,
              placeholder: `MF Rate R${count + 1} (Auto Populate)`,
              textInputType: 'number',
              validation: [{ isRequired: true }],
            },
          ]);
        } else {
          setRandomCount(randomCount + 1);
          const newItems = [
            // {
            //   isCombined: true,
            //   textField: true,
            //   entryName: `Block_R${count + 1}_B${countBlock}`,
            //   placehoFlder: `Block R${count + 1}`,
            //   textInputType: 'default',
            //   validation: [{ isRequired: true }],
            // },
            {
              default: true,
              textField: true,
              entryName: `Site_R${count + 1}_B${countBlock}`,
              placeholder: `Site Name R${count + 1}`,
              textInputType: 'default',
              validation: [{ isRequired: true }],
            },
            {
              isCombined: true,
              textField: true,
              entryName: `BSE_R${count + 1}_B${countBlock}`,
              placeholder: `BSE R${count + 1}`,
              textInputType: 'number',
              validation: [{ isRequired: true }],
            },
            {
              isCombined: true,
              textField: true,
              entryName: `Positive_R${count + 1}_B${countBlock}`,
              placeholder: `Positive R${count + 1}`,
              textInputType: 'number',
              validation: [{ isRequired: true }],
            },
            {
              default: true,
              textField: true,
              disabled: true,
              entryName: `MF_Rate_R${count + 1}_B${countBlock}`,
              placeholder: `MF Rate R${count + 1} (Auto Populate)`,
              textInputType: 'number',
              validation: [{ isRequired: true }],
            },
          ];
          entries.splice(ItemsValue + 4, 0, ...newItems);
          setEntries([...entries]);
        }
      } else {
        setRandomCount(randomCount + 1);
        setEntries([
          ...entries,
          // {
          //   isCombined: true,
          //   textField: true,
          //   disabled: true,
          //   entryName: `Block_R${count}`,
          //   placeholder: `Block R${count}`,
          //   textInputType: 'default',
          //   validation: [{ isRequired: true }],
          // },
          {
            ComboBox: true,
            default: true,
            isCombined: true,
            entryName: `Block_R${count}`,
            placeholder: `Block R${count}`,
            data: [],
          },
          {
            isCombined: true,
            textField: true,
            // disabled: true,
            entryName: `Site_R${count}`,
            placeholder: `Site R${count}`,
            textInputType: 'default',
            validation: [{ isRequired: true }],
          },
          {
            isCombined: true,
            textField: true,
            // disabled: true,
            entryName: `BSE_R${count}`,
            placeholder: `BSE R${count}`,
            textInputType: 'number',
            validation: [{ isRequired: true }],
          },
          {
            isCombined: true,
            textField: true,
            // disabled: true,
            entryName: `Positive_R${count}`,
            placeholder: `Positive R${count}`,
            textInputType: 'number',
            validation: [{ isRequired: true }],
          },
          {
            default: true,
            textField: true,
            // disabled: true,
            entryName: `MF_Rate_R${count}`,
            placeholder: `MF Rate R${count} (Auto Populate)`,
            textInputType: 'number',
            validation: [{ isRequired: true }],
          },
        ]);
      }

      setValues([
        ...values,
        {
          value: '',
          default: true,
          textField: true,
          entryName: `BSE_R${count}_Name`,
          placeholder: `BSE R${count} Name`,
          textInputType: 'default',
          validation: [{ isRequired: false }],
        },
        {
          value: '',
          isCombined: true,
          textField: true,
          entryName: `BSE_R${count}`,
          placeholder: `BSE R${count}`,
          textInputType: 'number',
          validation: [{ isRequired: false }],
        },
        {
          value: '',
          remove: true,
          isCombined: true,
          textField: true,
          entryName: `Positive_R${count}`,
          placeholder: `Positive R${count}`,
          textInputType: 'default',
          validation: [{ isRequired: false }],
        },
      ]);
    }
    if (item.labelText === 'Block Count') {
      setCountBlock(countBlock + 1);
      // setRandomCount(1);

      setFinalObjectValue([...finalObjectValue, objectValue]);
      setObjectValue(1);
      if (count !== 2) {
        setEntries([
          ...entries,
          {
            divider: true,
            validation: { isRequired: false },
            fullView: true,
          },
          // {
          //   default: true,
          //   textField: true,
          //   entryName: `Block_Name_B${count}`,
          //   placeholder: `Block Name ${count}`,
          //   textInputType: 'default',
          //   validation: [{ isRequired: true }],
          // },
          {
            ComboBox: true,
            default: true,
            isCombined: true,
            entryName: `Block_Name_B${count}`,
            placeholder: `Block Name ${count}`,
            data: [],
          },
          {
            default: true,
            textField: true,
            entryName: `Total_population_of_block_B${count}`,
            placeholder: `Total Population Of Block ${count}`,
            textInputType: 'number',
            validation: [{ isRequired: true }],
          },
          {
            CountRangePreTas: true,
            default: true,
            labelText: 'Additional Random Site',
          },
          {
            default: true,
            entryName: 'Test_Type',
            placeholder: 'Test Type',
            dropdown: true,
            data: ['Block Strategy', 'District Strategy'],
            validation: [{ isRequired: true }],
          },
          {
            default: true,
            date: true,
            entryName: `survey_Date_B${count}`,
            placeholder: 'Pre-TAS Survey Date',
            textInputType: 'default',
            validation: [{ isRequired: true }],
          },
          {
            isEmptySpace: true,
            breakpoint: 4,
          },
          // {
          //   isCombined: true,
          //   textField: true,
          //   entryName: `Block_R1_B${count}`,
          //   placeholder: 'Block R1',
          //   textInputType: 'default',
          //   validation: [{ isRequired: true }],
          // },
          {
            default: true,
            textField: true,
            entryName: `Site_R1_B${count}`,
            placeholder: 'Site R1',
            textInputType: 'default',
            validation: [{ isRequired: true }],
          },
          {
            isCombined: true,
            textField: true,
            entryName: `BSE_R1_B${count}`,
            placeholder: 'BSE R1',
            textInputType: 'number',
            validation: [{ isRequired: true }],
          },
          {
            isCombined: true,
            textField: true,
            entryName: `Positive_R1_B${count}`,
            placeholder: 'Positive R1',
            textInputType: 'number',
            validation: [{ isRequired: true }],
          },
          {
            default: true,
            textField: true,
            disabled: true,
            entryName: `MF_Rate_R1_B${count}`,
            placeholder: 'MF Rate R1 (Auto Populate)',
            textInputType: 'default',
            validation: [{ isRequired: true }],
          },
        ]);
      } else {
        setEntries([
          ...entries,
          {
            divider: true,
            validation: { isRequired: false },
            fullView: true,
          },
          {
            default: true,
            textField: true,
            entryName: `Block_Name_B${count}`,
            placeholder: `Black Name`,
            textInputType: 'default',
            validation: [{ isRequired: true }],
          },
          {
            default: true,
            textField: true,
            entryName: `Total_population_of_block_B${count}`,
            placeholder: `Total population of block`,
            // textInputType: 'default',
            textInputType: 'number',
            validation: [{ isRequired: true }],
          },
          {
            CountRangePreTas: true,
            default: true,
            labelText: 'Additional Random Site',
          },
          {
            default: true,
            entryName: 'Test_Type',
            placeholder: 'Test Type',
            dropdown: true,
            data: ['Block Strategy', 'District Strategy'],
            validation: [{ isRequired: true }],
          },
          {
            default: true,
            textField: true,
            disabled: true,
            entryName: `survey_Date_B${count}`,
            placeholder: 'Survey Date (Auto Populate)',
            textInputType: 'default',
            validation: [{ isRequired: true }],
          },
          {
            isEmptySpace: true,
            breakpoint: 4,
          },
          {
            isCombined: true,
            textField: true,
            entryName: `Block_R1_B${count}`,
            placeholder: 'Block R1',
            textInputType: 'default',
            validation: [{ isRequired: true }],
          },
          {
            isCombined: true,
            textField: true,
            entryName: `Site_R1_B${count}`,
            placeholder: 'Site R1',
            textInputType: 'number',
            validation: [{ isRequired: true }],
          },
          {
            isCombined: true,
            textField: true,
            entryName: `BSE_R1_B${count}`,
            placeholder: 'BSE R1',
            textInputType: 'number',
            validation: [{ isRequired: true }],
          },
          {
            isCombined: true,
            textField: true,
            entryName: `Positive_R1_B${count}`,
            placeholder: 'Positive R1',
            textInputType: 'default',
            validation: [{ isRequired: true }],
          },
          {
            default: true,
            textField: true,
            disabled: true,
            entryName: `MF_Rate_R1_B${count}`,
            placeholder: 'MF Rate R1 (Auto Populate)',
            textInputType: 'default',
            validation: [{ isRequired: true }],
          },
        ]);
      }
    }
  };
  /**
   *
   */
  const handleNegativeCount = (count, item, index) => {
    if (item?.labelText === 'Additional Random Site') {
      const found1 = indexvalue.find((element) => element > index);
      if (btn === 2) {
        if (found1 === undefined) {
          setRandomCount(randomCount - 1);
          entries.splice(entries.length - 5, 5);
          setEntries([...entries]);
        } else {
          setRandomCount(randomCount - 1);
          const found = indexvalue.find((element) => element > index);
          entries.splice(found - 5, 5);
          setEntries([...entries]);
        }
      }
      if (btn === 1) {
        if (found1 === undefined) {
          setRandomCount(randomCount - 1);
          entries.splice(entries.length - 4, 4);
          setEntries([...entries]);
        } else {
          setRandomCount(randomCount - 1);
          const found = indexvalue.find((element) => element > index);
          entries.splice(found - 4, 4);
          setEntries([...entries]);
        }
      }
    }
    if (item?.labelText === 'Block Count') {
      setCountBlock(countBlock - 1);
      entries.splice(lastIndexOf(true, 2), entries.length);
      setEntries([...entries]);
    }
  };
  /**
   * @param {object} formData - submitted formData
   */
  const onSubmitData = (formData) => {
    console.log(formData, 'ddsertre');
    if (formData) {
      setFinalObjectValue([...finalObjectValue, objectValue]);
      const preTasSurvey1 = [];
      const valuues = [];
      if (btn === 2) {
        for (let i = 1; i <= randomCount; i += 1) {
          preTasSurvey1.push({
            block_name: formData[`Block_R${i}`],
            random_name: formData[`Site_R${i}`],
            random_blood_count: formData[`BSE_R${i}`] || 0,
            random_possitive: formData[`Positive_R${i}`],
            site_id: `R${i}`,
            mf_rate: parseInt(formData[`MF_Rate_R${i}`], 10),
          });
        }
        dispatch({
          type: INSERT_PRE_TAS,
          payload: { ...formData, preTasDistrictSurvey: preTasSurvey1, activity: 'Pre-TAS', randomCount },
        });
      }
      if (btn === 1) {
        const Block = [];
        console.log(Block, 'Bl22ock');
        for (let i = 1; i <= countBlock; i += 1) {
          const blockSurvey = [];
          const count = [];
          if (i < countBlock) {
            count.push(finalObjectValue[i - 1]);
            for (let j = 1; j <= finalObjectValue[i - 1]; j += 1) {
              blockSurvey.push({
                site_id: formData[`Site_R${j}_B${i}`],
                random_blood_count: formData[`BSE_R${j}_B${i}`] || 0,
                random_name: formData[`Block_Name_B${i}`] || 0,
                random_possitive: formData[`Positive_R${j}_B${i}`],
                mf_rate: parseInt(formData[`MF_Rate_R${j}_B${i}`], 10),
              });
            }
          }
          if (i === countBlock) {
            count.push(objectValue);
            for (let j = 1; j <= objectValue; j += 1) {
              console.log(j, i, 'sdfksdjr');
              blockSurvey.push({
                site_id: formData[`Site_R${j}_B${i}`],
                random_name: formData[`Block_Name_B${j}`],
                random_blood_count: formData[`BSE_R${j}_B${i}`] || 0,
                random_possitive: formData[`Positive_R${j}_B${i}`],
                mf_rate: parseInt(formData[`MF_Rate_R${j}_B${i}`], 10),
              });
            }
          }

          Block.push({
            block_name: formData[`Block_Name_B${i}`],
            total_population: formData[`Total_population_of_block_B${i}`],
            test_type_id: '1',
            pre_tas_survey_date: formData[`survey_Date_B${i}`],
            block_survey: blockSurvey,
            // random: count[0],
          });
        }
        dispatch({
          type: INSERT_PRE_TAS_BLOCK,
          payload: { ...formData, preTasDistrictSurvey: Block, activity: 'Pre-TAS', countBlock },
        });
      }
    } else {
      dispatch({
        type: UPDATE_PRE_TAS_ID,
        payload: {
          ...formData,
          pre_tas_id: ProposedData[0].pre_tas_id,
          // coverage: preTasSurvey,
          random: randomCount,
          activity: 'Pre-TAS',
        },
      });
      notify(true);
    }

    if (ProposedData?.length > 0) {
      const ProposedDataLength = Object.keys(ProposedData)?.length;
      let newObj = {};
      const preTasSurvey = [];
      const random = ProposedDataLength === 1 ? randomCount : Math.max(ProposedData[0]?.random, randomCount);
      // const max = Math.max(sentinal, random);
      entries.map((data) => {
        newObj = { ...newObj, [`${data.entryName}`]: (data.value === '' && ' ') || formData[`${data.entryName}`] };
        return null;
      });
      const Block = [];

      for (let i = 1; i <= randomCount; i += 1) {
        const updateKeys =
          ProposedDataLength > 1
            ? {
                mda_sites_blocks_id: random[0]?.details[i - 1]?.mda_sites_blocks_id || 0,
                delete_status: newObj[`R${i}_Site_Name`] ? 0 : 1,
              }
            : {};
        preTasSurvey.push({
          // test_type_id: newObj.Test_Type,
          // pre_tas_survey_date: newObj.Pre_TAS_surveyDate,
          // // block_name: newObj.Block_Name,
          // random_name: newObj[`BSE_R${i}_Name`] || '',
          // random_blood_count: Number(newObj[`BSE_R${i}`]) || null,
          // random_possitive: Number(newObj[`Positive_R${i}`]) || null,
          // block_name: Block,
          block_name: 'te123',
          random_name: 'B1 Block',
          random_blood_count: '2',
          random_possitive: '30',
          site_id: 'R1',
          mf_rate: 2,
          ...updateKeys,
        });
      }
      for (let j = 0; j < blackCount - 1; j += 1) {
        Block.push([
          {
            block_name: newObj[`Block${j}_Name`],
            test_type_id: newObj[`Test_Type${j}`],
            pre_tas_survey_date: newObj[`Pre_TAS_surveyDate${j}`],
            random_name: newObj[`BSE_R1_Name_${j}`],
            random_blood_count: Number(newObj[`BSE_R1_${j}`]) || null,
            random_possitive: Number(newObj[`Positive_R1_${j}`]) || null,
          },
          {
            block_name: newObj[`Block${j}_Name`],
            test_type_id: newObj[`Test_Type${j + 1}`],
            pre_tas_survey_date: newObj[`Pre_TAS_surveyDate${j + 1}`],
            random_name: newObj[`BSE_R1_Name_${j + 1}`],
            random_blood_count: Number(newObj[`BSE_R1_${j + 1}`]) || null,
            random_possitive: Number(newObj[`Positive_R1_${j + 1}`]) || null,
          },
          {
            block_name: newObj[`Block${j}_Name`],
            test_type_id: newObj[`Test_Type${j + 2}`],
            pre_tas_survey_date: newObj[`Pre_TAS_surveyDate${j + 2}`],
            random_name: newObj[`BSE_R1_Name_${j + 2}`],
            random_blood_count: Number(newObj[`BSE_R1_${j + 2}`]) || null,
            random_possitive: Number(newObj[`Positive_R1_${j + 2}`]) || null,
          },
        ]);
      }

      // if (ProposedDataLength === 1) {
      //   dispatch({
      //     // type: INSERT_PRE_TAS,
      //     payload: { ...formData, Sites: preTasSurvey, random: randomCount, activity: 'Pre-TAS' },
      //   });
      // } else {
      //   // dispatch({
      //   //   type: UPDATE_PRE_TAS_ID,
      //   //   payload: {
      //   //     ...formData,
      //   //     pre_tas_id: ProposedData[0].pre_tas_id,
      //   //     Sites: preTasSurvey,
      //   //     random: randomCount,
      //   //     activity: 'Pre-TAS',
      //   //   },
      //   // });
      // }
    } else {
      // notify(true);
    }
    handleCancel();
    setEdit(true);
  };

  React.useEffect(() => {
    dispatch({ type: GET_STATE_DROPDOWN });
    dispatch({ type: GET_STRATEGY_DROPDOWN });
    dispatch({ type: GET_TESTKITTYPE_DROPDOWN });
  }, [dispatch]);
  // React.useEffect(() => {
  //   dispatch({ type: STRATEGY_DROPDOWN });
  // }, [dispatch]);strategy

  const getEntries = React.useMemo(() => {
    return entries.map((value, id) => {
      if (value.entryName === 'State') {
        value.data = _.assign(dropdownList.stateData);
      }
      if (value.entryName === 'District') {
        // if (dropdownList.districtData) value.disabled = false;
        value.data = _.assign(dropdownList.districtData);
      }
      if (value.entryName === 'Test_Type') {
        value.data = _.assign(dropdownList.testKitType);
      }
      if (value.entryName === 'Strategy') {
        value.data = _.assign(dropdownList.strategy);
      }

      return value;
    });
  }, [dropdownList, entries]);

  /**
   * @param {number} data - data
   * @param {string} name - name
   * @function getSelectedDropdown
   */

  /**
   * @param {number} index entry index
   * @param {number} e entry index
   */
  const removeRow = (index, e) => {
    // const value = keyValue.placeholder.slice(keyValue.placeholder.length - 1, keyValue.placeholder.length);
    const counter = randomCount !== 1 ? randomCount - 1 : randomCount;
    countRangeData[1].defaultCount = counter;
    setRandomCount(counter);
    entries.splice(index - 2, 3);
    for (let i = index - 2; i <= entries.value; i += 1) {
      let oldEntryName = entries[i].entryName;
      let oldPlaceholder = entries[i].placeholder;
      const count = oldEntryName.replace(/[^0-9]/g, '');
      oldEntryName = oldEntryName.replace(count, Number(count) - 1);
      oldPlaceholder = oldPlaceholder.replace(count, Number(count) - 1);
      entries[i].entryName = oldEntryName;
      entries[i].placeholder = oldPlaceholder;
    }
    setEntries(entries);
  };
  return (
    <Grid container item md={12}>
      <Grid item md={12} className="formContainer">
        <CustomForm
          btn={btn}
          Block={block}
          entries={!isEdit ? entries : PreTASFormEntries}
          onViewHandle={OnView}
          defaultValues={!isEdit ? EditDefaults : PreTASFormDefaultValues}
          dataInput
          onReceiveData={onSubmitData}
          separator
          countRange={countRangeData}
          blackCount={countBlock}
          randomCount={randomCount}
          // getSelectedDropdown={(e, entryName) => {
          //   getValues(e, entryName);
          // }}
          plus={handlePositiveCount}
          minus={handleNegativeCount}
          getSelectedDropdown={getValues}
          removeItem={removeRow}
          onCancelAction={() => {
            handleCancel();
            setEdit(false);
          }}
          screen="input"
        />
      </Grid>
    </Grid>
  );
};
export default PreTASFormInput;
PreTASFormInput.propTypes = {
  OnView: PropTypes.func,
  notify: PropTypes.func,
};
PreTASFormInput.defaultProps = {
  OnView: () => {},
  notify: () => {},
};
