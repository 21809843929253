/* eslint-disable no-unused-vars */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from './layouts/main';
import Login from './pages/LoginScreen';
/**
 *
 * @returns {React.ReactElement} - router page
 */
const RouterPage = () => {
  return (
    // <Router>
    <Router basename="/">
      <Switch>
        <Route path="/" component={Login} exact />
        <Layout />
      </Switch>
    </Router>
  );
};
export default RouterPage;
