import React from 'react';
import './Edit.css';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CustomIcons from '../../../utils/icons';
import CustomTypography from '../Typography';

/**
 *
 * @param {object} props  - required props in edit box component
 * @returns {React.ReactElement} - returns the edit box component
 */
const EditBox = (props) => {
  const { handleClick } = props;
  return (
    <Grid className="paperGrid">
      <Grid onClick={handleClick} className="EditGrid">
        <img src={CustomIcons.Edit} alt="edit" className="editPencil" />
        <CustomTypography type="caption" text="Edit" colorType="tertiary" />
      </Grid>
    </Grid>
  );
};
export default EditBox;
EditBox.propTypes = {
  handleClick: PropTypes.func,
};

EditBox.defaultProps = {
  handleClick: () => {},
};
