import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import './Table.css';
import { useDispatch, useSelector } from 'react-redux';
import { thDatas } from './ThersholdEnteries';
import Table from '../../../../components/organisms/Table/index';
import { GET_THRESHOLD_CONFIG } from '../../../../constants/actionType';

/**
 * @param {object} props - required props in threshold table
 * @returns {React.ReactElement} return the user role component
 */
const ThersholdTable = (props) => {
  const { downloadFile, downloadUrl } = props;
  const [configTable, setConfigTable] = useState([]);
  const dispatch = useDispatch();
  const { getThreshold } = useSelector((state) => state.thresholdConfig);

  React.useEffect(() => {
    dispatch({ type: GET_THRESHOLD_CONFIG });
  }, [dispatch]);

  useEffect(() => {
    if (getThreshold?.length) {
      const thresholdArr = [];
      getThreshold?.map((value, index) => {
        return thresholdArr.push({
          sno: index + 1,
          BulkUploadFilename: value.file_name,
          BulkUploadby: '',
          FormulaConfiguration: value.file_attachment,
          FromDate: value.from_date,
          ToDate: value.to_date,
          Status: value.status,
        });
      });

      setConfigTable(thresholdArr);
    }
  }, [dispatch, getThreshold]);

  return (
    <Grid>
      <Grid md={12}>
        <Table
          header={thDatas}
          rows={configTable}
          masterSetup={['Downloader', 'Search']}
          userActivity="Threshold Configuration"
          threshold
          proved
          downloadData={downloadFile}
          downloadUrl={downloadUrl}
          hideKeyFields={['created_by', 'created_on']}
          Activity="Threshold Config"
          screen="masters"
        />
      </Grid>
    </Grid>
  );
};
export default ThersholdTable;
ThersholdTable.propTypes = {
  downloadFile: PropTypes.func,
  downloadUrl: PropTypes.string,
};
ThersholdTable.defaultProps = {
  downloadFile: () => null,
  downloadUrl: '',
};
