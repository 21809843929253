import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '../Typography';
// import PropTypes from 'prop-types';
/**
 *
 * @param {object} props - required props in AddRemoveCounter
 * @returns {React.ReactElement}- returns the AddRemoveCounter component
 */
const AddRemoveCounter = (props) => {
  const { labelText, count, NegativeCount, PositiveCount } = props;

  return (
    <Grid>
      <Typography text={labelText} type="link" customClass="countText" colorType="quaternary" />
      <Grid className="rootView">
        <Grid
          //   className={count > 1 ? 'buttonViewDelete' : 'buttonViewSeperate'}
          className="buttonViewDelete"
          onClick={NegativeCount}
        >
          -
        </Grid>

        <Typography text={count} colorType="quaternary" customClass="countStyle" disabled />
        <Grid className="buttonViewAdd" onClick={PositiveCount}>
          +
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddRemoveCounter;
AddRemoveCounter.propTypes = {
  labelText: PropTypes.string,
  count: PropTypes.number,
  NegativeCount: PropTypes.func,
  PositiveCount: PropTypes.func,
};
AddRemoveCounter.defaultProps = {
  labelText: '',
  count: 1,
  NegativeCount: () => {},
  PositiveCount: () => {},
};
