export const thDatas = [
  'S.No',
  'State',
  'District',
  'Total District Population',
  'Total District Endemic Population',
  'From Date',
  'To Date',
  'Status',
  'Action',
];

export const tdDatas = [
  {
    sno: '1 ',
    state: 'Tamil Nadu',
    District: 'Chennai',
    TotalDistrictPopulation: '2000',
    TotalDistrictEndemic: '500',
    FormDate: '09 Mar 2022',
    ToDate: '10 Jul 2021',
    Status: 'Active',
  },
  {
    sno: '2',
    state: 'Delhi',
    District: 'Kuala',
    TotalDistrictPopulation: '2000',
    TotalDistrictEndemic: '500',
    FormDate: '09 Mar 2022',
    ToDate: '10 Jul 2021',
    Status: 'Active',
  },
];
