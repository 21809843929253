import React from 'react';
import { PropTypes } from 'prop-types';
import {
  LineChart,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  Label,
} from 'recharts';
import colors from '../../../utils/colors';
import Typography from '../../atoms/Typography';

/**
 * @param {string} value legend text
 * @returns {React.Component} customized legend span
 */
const LegendText = (value) => {
  return <span className="legend-text">{value}</span>;
};

/**
 * @param {*} props props
 * @returns {React.ReactElement} returns line graph
 */
const LineGraph = (props) => {
  const {
    title,
    chartData,
    Xkey,
    labelKey,
    toolTip,
    XTickLine,
    YTickLine,
    legends,
    XGridLine,
    YGridLine,
    style,
    Y1Label,
    XLabel,
  } = props;
  /**
   * @param {*} index index
   * @returns {string} code
   */
  const getStroke = (index) => {
    switch (index) {
      case 0:
        return colors.blue.quaternary;
      case 1:
        return colors.pink.tertiary;
      case 2:
        return colors.blue.octonary;
      case 3:
        return colors.violet.primary;
      case 4:
        return colors.green.secondary;
      default:
        return colors.black.primary;
    }
  };
  return (
    <center>
      {title && <Typography type="header" colorType="text" text={title} customStyle={{ marginBottom: 2 }} />}
      {/* this Typography has no usage just used for stacked bar chart height match purpose */}
      <Typography type="title" colorType="text" customStyle={{ visibility: 'hidden' }} text="No of Districts" />
      <div style={style}>
        <ResponsiveContainer width="95%" height={300}>
          <LineChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid stroke="#ccc" vertical={XGridLine} horizontal={YGridLine} />
            <XAxis dataKey={Xkey} tickLine={XTickLine} tick={chartData?.length > 0} style={{ fill: colors.graph }}>
              <Label value={XLabel} dy={10} style={{ fill: colors.graph }} />
            </XAxis>
            <YAxis tickLine={YTickLine} style={{ fill: colors.graph }}>
              <Label value={Y1Label} angle={-90} dx={-9} style={{ fill: colors.graph }} />
            </YAxis>
            {legends && <Legend formatter={LegendText} />}
            <Tooltip cursor={toolTip} />
            {chartData?.length > 0 &&
              Object.keys(chartData[0])
                .filter((data) => data !== Xkey)
                .map((key, index) => (
                  <Line
                    dataKey={key}
                    stroke={getStroke(index)}
                    strokeWidth={2}
                    dot={{ strokeWidth: 2, fill: getStroke(index) }}
                  >
                    {labelKey && <LabelList dataKey={labelKey} position="top" style={{ fill: '#9475FF' }} />}
                  </Line>
                ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </center>
  );
};

export default LineGraph;
LineGraph.propTypes = {
  title: PropTypes.string,
  chartData: PropTypes.arrayOf.isRequired,
  Xkey: PropTypes.string.isRequired,
  labelKey: PropTypes.string,
  XTickLine: PropTypes.bool,
  YTickLine: PropTypes.bool,
  legends: PropTypes.bool,
  toolTip: PropTypes.bool,
  XGridLine: PropTypes.bool,
  YGridLine: PropTypes.bool,
  style: PropTypes.objectOf,
  Y1Label: PropTypes.string,
  XLabel: PropTypes.string,
};
LineGraph.defaultProps = {
  title: null,
  labelKey: null,
  XTickLine: false,
  YTickLine: false,
  legends: true,
  toolTip: null,
  XGridLine: true,
  YGridLine: true,
  style: {},
  Y1Label: '',
  XLabel: '',
};
