import {
  DISTRICT_DROPDOWN,
  STATE_DROPDOWN,
  STATE_DROPDOWN_FAILED,
  PROGRAM_ACTIVITY_TYPE,
  PROGRAM_ACTIVITY_TYPE_FAILED,
  STRATEGY_DROPDOWN,
  STRATEGY_DROPDOWN_FAILED,
  TESTKITTYPE_DROPDOWN,
  TESTKITTYPE_DROPDOWN_FAILED,
} from '../../constants/actionType';
import { dropdownList } from '../initialStates';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const dropDownReducer = (state = dropdownList, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case DISTRICT_DROPDOWN:
      return {
        ...state,
        districtData: payload.data,
      };
    case STATE_DROPDOWN:
      return {
        ...state,
        stateData: payload.data,
      };

    case STATE_DROPDOWN_FAILED:
    case TESTKITTYPE_DROPDOWN_FAILED:
    case STRATEGY_DROPDOWN_FAILED:
      return {
        ...state,
        failedStatus: payload.data,
      };
    case PROGRAM_ACTIVITY_TYPE:
      return {
        ...state,
        activityType: payload.data,
      };
    case PROGRAM_ACTIVITY_TYPE_FAILED:
      return {
        ...state,
        failedStatus: payload.data,
      };
    case STRATEGY_DROPDOWN:
      return {
        ...state,
        strategy: payload.data,
      };
    case TESTKITTYPE_DROPDOWN:
      return {
        ...state,
        testKitType: payload.data,
      };
    default:
      return state;
  }
};
export default dropDownReducer;
