import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Select, MenuItem, Grid, InputLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './CustomDropdown.css';
/**
 *
 * @param {object} props - props required in normal dropdown
 * @returns {React.ReactElement} - returns the normal dropdown
 */
const CustomDropdown = (props) => {
  const { label, data, handleChange, value, disabled, name, returnValue } = props;
  return (
    <Grid className="box" item md={12} sm={12}>
      <FormControl fullWidth disabled={disabled}>
        <InputLabel id="demo-simple-select-label" color="primary" className="fontSize">
          {label}
        </InputLabel>
        <Select
          name={name}
          IconComponent={ExpandMoreIcon}
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value || ''}
          data={data || []}
          label={label}
          onChange={(e) => handleChange(e)}
          sx={{
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #aeafaf',
            },
          }}
        >
          {data?.length &&
            data?.map((item) => {
              return (
                <MenuItem
                  value={returnValue ? item?.value : item?.id}
                  key={item?.id}
                  className="menuItem"
                  sx={{ fontFamily: 'Roboto_medium' }}
                >
                  {item?.value}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default CustomDropdown;
CustomDropdown.propTypes = {
  label: PropTypes.string,
  data: PropTypes.arrayOf.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  returnValue: PropTypes.bool,
};
CustomDropdown.defaultProps = {
  label: '',
  value: '',
  disabled: false,
  name: '',
  returnValue: false,
};
