/* eslint-disable default-param-last */
import {
  DOSSIER_STATUS,
  GET_DOSSIER_RESULT,
  INSERT_DOSSIER_RESULT,
  UPDATE_DOSSIER_RESULT,
  UPLOAD_DOSSIER_RESULT,
} from '../../constants/actionType';
import { dossierInitialState } from '../initialStates';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const dossierReducer = (state = dossierInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DOSSIER_RESULT:
      return {
        ...state,
        getDossier: payload?.data,
      };
    case INSERT_DOSSIER_RESULT:
      return {
        ...state,
        status: payload.data,
      };
    case UPDATE_DOSSIER_RESULT:
      return {
        ...state,
        insertDossier: payload.data,
      };
    case DOSSIER_STATUS:
      return {
        ...state,
        status: payload,
        loading: false,
      };
    case UPLOAD_DOSSIER_RESULT:
      return {
        ...state,
        uploadDossier: payload.data,
      };
    default:
      return state;
  }
};
export default dossierReducer;
