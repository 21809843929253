/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { Button, MenuItem, ListItemIcon, ListItemText, Grid, ListItem } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Typography from '../../atoms/Typography';
import colors from '../../../utils/colors';
import './ListItems.css';

/**
 * @name ListingItem
 * @param {object} props - required props
 * @returns {React.ReactElement} return the listing Items for the customAppBar
 */
const ListingItem = (props) => {
  const {
    content,
    profileItems,
    dashboardContent,
    value,
    handleChange,
    menuClick,
    masterDataItem,
    masterSelect,
    onChooseListItem,
  } = props;
  const activeRef = useRef([]);
  const Location = useLocation();

  /**
   *
   * @param {number} selectedId - specific id of an array elements
   */
  const onMenuItemSelect = (selectedId) => {
    masterSelect(selectedId);
  };

  /**
   *
   * @param {string} data -passing data
   */
  const onContentClick = (data) => {
    menuClick(data);
  };

  useEffect(() => {
    content.find((item, index) => {
      if (item?.path === Location?.pathname) {
        onContentClick(item?.path, index);
      }
    });
  }, []);
  /**
   *
   * @param {number} id - passing id
   */
  const onClickListItem = (id) => {
    onChooseListItem(id);
  };
  /**
   * @param {string} element selected element
   * @function handleActive handle active menu
   */
  const handleActive = (element) => {
    const ele = document.querySelector('.dash-active');
    if (ele !== null) {
      ele.classList.remove('dash-active');
    }
    activeRef.current[element].classList.add('dash-active');
    handleChange(element);
  };
  useEffect(() => {
    if (value) {
      handleActive(value);
    }
  }, [value]);
  const dashboardPath = ['/dashboard'];
  const masterPath = [
    '/master-setup/user-role',
    '/master-setup/new-user',
    '/master-setup/program-activity',
    '/master-setup/program-status',
    '/master-setup/threshold',
    '/master-setup/geography',
    '/master-setup/formula',
    '/master-setup/demographic',
    '/master-setup/drug-type',
  ];
  const dataPath = [
    '/data-input/IDA',
    '/data-input/DA',
    '/data-input/Pre-TAS',
    '/data-input/TAS1?type=TAS1',
    '/data-input/TAS2?type=TAS2',
    '/data-input/TAS3?type=TAS3',
    '/data-input/Dossier',
    '/data-input/DrugAvailability',
  ];

  /**
   *
   * @param {*} path - the path of array elements
   * @param {*} url - URL location
   * @returns {Function} - returns the color type of selected menu
   */
  const getColorType = (path, url) => {
    switch (path) {
      case '/dashboard':
        if (dashboardPath.includes(url)) {
          return 'tertiary';
        }
        return 'secondary';

      case '/data-input/DrugAvailability':
        if (dataPath.includes(url)) {
          return 'tertiary';
        }
        return 'secondary';
      case '/master-setup/user-role':
        if (masterPath.includes(url)) {
          return 'tertiary';
        }
        return 'secondary';
      default:
        return 'secondary';
    }
  };

  return (
    <Grid>
      {content && (
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          {content.map((item, index) => {
            const key = index;

            return (
              <Grid key={key} onClick={() => onContentClick(item.path, index)}>
                <Typography
                  text={item?.label}
                  customClass="whiteText"
                  colorType={getColorType(item?.path, Location?.pathname)}
                  type="title"
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      {profileItems && (
        <Grid>
          {profileItems.map((item, index) => {
            const key = index;
            return (
              <Grid key={key} onClick={() => onClickListItem(item.id)}>
                <MenuItem>
                  <ListItemIcon>{item.menuIcon}</ListItemIcon>
                  <ListItemText>{item.menuText}</ListItemText>
                </MenuItem>
              </Grid>
            );
          })}
        </Grid>
      )}
      {masterDataItem &&
        masterDataItem.map((item, index) => {
          const key = index;
          return (
            <Grid key={key} onClick={() => onMenuItemSelect(item.id)}>
              <NavLink
                to={item.nav}
                style={{ textDecoration: 'none', color: colors.gray.octonary }}
                activeClassName="masterMenuItem"
              >
                <ListItem>
                  <ListItemText className="userLitItem">{item.menuText}</ListItemText>
                </ListItem>
              </NavLink>
            </Grid>
          );
        })}

      {dashboardContent && (
        <Grid className="flex-grid">
          {dashboardContent.map((item, index) => {
            const key = index;
            return (
              <Grid
                key={key}
                ref={(el) => {
                  activeRef.current[key] = el;
                }}
              >
                <Button onClick={() => handleActive(key)} className={`menuBtn ${index === 0 && 'dash-active'}`}>
                  <DashboardIcon className="icon" />
                  <Typography text={item.dashBoardText} customClass="dashboardText" type="header" />
                </Button>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};
export default ListingItem;
ListingItem.propTypes = {
  content: PropTypes.arrayOf(PropTypes.objectOf),
  profileItems: PropTypes.arrayOf(PropTypes.objectOf),
  masterDataItem: PropTypes.arrayOf(PropTypes.objectOf),
  dashboardContent: PropTypes.arrayOf(PropTypes.objectOf),
  value: PropTypes.string,
  handleChange: PropTypes.func,
  menuClick: PropTypes.func,
  masterSelect: PropTypes.func,
  onChooseListItem: PropTypes.func,
};
ListingItem.defaultProps = {
  content: [],
  profileItems: [],
  dashboardContent: [],
  masterDataItem: [],
  value: '',
  handleChange: () => {},
  menuClick: () => {},
  masterSelect: () => {},
  onChooseListItem: () => {},
};
