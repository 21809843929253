import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import './CountRangePreTas.css';
/**
 * @name CountRange
 * @param {string} props  required props
 * @returns {React.ReactElement} returns the count range component
 */
const CountRangePreTas = (props) => {
  const { labelText, PCount, NCount, value } = props;
  //   const [count, setCount] = React.useState(1);

  //   useEffect(() => {
  //     if (initialCount) {
  //       setCount(1);
  //     }
  //   }, [initialCount]);
  //   useEffect(() => {
  //     if (defaultCount) {
  //       setCount(defaultCount);
  //     }
  //   }, [defaultCount]);
  /**
   *
   */
  const PositiveCount = () => {
    PCount();
  };
  /**
   *
   */
  const NegativeCount = () => {
    NCount();
  };

  return (
    <Grid>
      <Typography text={labelText} type="link" customClass="countText" colorType="quaternary" />
      <Grid className="rootView">
        <Grid
          className={value > 1 ? 'buttonViewDelete' : 'buttonViewSeperate'}
          onClick={(index) => NegativeCount(index)}
        >
          -
        </Grid>

        <Typography text={value} colorType="quaternary" customClass="countStyle" disabled />
        <Grid className="buttonViewAdd" onClick={PositiveCount}>
          +
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CountRangePreTas;
CountRangePreTas.propTypes = {
  labelText: PropTypes.string.isRequired,
  PCount: PropTypes.func,
  NCount: PropTypes.func,
  value: PropTypes.number,
  //   defaultCount: PropTypes.number,
};
CountRangePreTas.defaultProps = {
  PCount: () => {},
  NCount: () => {},
  value: null,
  //   defaultCount: null,
};
