/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import BaseUrl from '../../config/config';
import { webLogin, forgetPassword, webLogout, changePassword } from '../../utils/api';

/**
 * @param {object} data payload data
 * @name LoginRequest function which make api request to update program activities
 * @returns {*} returns axios response data
 */
export const LoginRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${webLogin}`,
    data: {
      email: data.email,
      password: data.password,
    },
  });
};

/**
 * @param {object} data payload data
 * @name forgetPasswordRequest function which make api request to update program activities
 * @returns {*} returns axios response data
 */
export const forgetPasswordRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${forgetPassword}`,
    data: {
      email: data.email,
    },
  });
};
/**
 * @param {object} data payload data
 * @name userLogoutRequest function which make api request to update program activities
 * @returns {*} returns axios response data
 */
export const userLogoutRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${webLogout}`,
    data: {
      user_id: data,
    },
  });
};
/**
 * @param {object} data payload data
 * @name changePasswordRequest function which make api request to update program activities
 * @returns {*} returns axios response data
 */
export const changePasswordRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${changePassword}`,
    data,
  });
};
