/* eslint-disable default-param-last */
import { FILTER_DRIVEN_EXTRACT_REPORT_RESULT, CUSTOMREPORT_BY_TYPE_RESULT } from '../../constants/actionType/index';
import { customReportInitialStates } from '../initialStates/index';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const customReportReducer = (state = customReportInitialStates, action) => {
  const { type, payload } = action;
  switch (type) {
    case FILTER_DRIVEN_EXTRACT_REPORT_RESULT:
      return {
        ...state,
        fitlerExtractReports: payload.data,
      };
    case CUSTOMREPORT_BY_TYPE_RESULT:
      return {
        ...state,
        customReportsType: payload.data,
      };
    default:
      return state;
  }
};
export default customReportReducer;
