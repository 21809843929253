/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '../../../../components/organisms/Table';
import { thData } from './TableEntries';
import { GET_DOSSIER, UPDATE_DOSSIER } from '../../../../constants/actionType';
import './TableDossier.css';

/**
 *
 * @returns {React.ReactElement} - returns the IDA input screen
 */
const DossierTable = () => {
  const { getDossier } = useSelector((state) => state.dossier);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({ type: GET_DOSSIER });
  }, [dispatch]);

  /**
   * @param key
   */
  const onSendUpdatedValue = React.useCallback(
    async (key, rowData) => {
      if (key === 'Update') {
        await dispatch({ type: UPDATE_DOSSIER, payload: rowData });
        dispatch({ type: GET_DOSSIER });
      }
    },
    [dispatch],
  );
  return (
    <Table
      header={thData}
      rows={getDossier}
      slideData
      inputTable
      onSendUpdatedData={(key, rowData) => onSendUpdatedValue(key, rowData)}
      hideKeyFields={['state_id', 'dossier_id', 'district_id', 'block']}
      showSno
      getById="dossier_id"
    />
  );
};

export default DossierTable;
