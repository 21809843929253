import { Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import './FormTitle.css';
/**
 * @name FormTitle
 * @param {object} props  required props
 * @returns {React.ReactElement} return the formTitle Component
 */
const FormTitle = (props) => {
  const { labelText } = props;
  return (
    <Grid className="mainView">
      <Typography text={labelText} type="subHeading" customClass="textStyling" />
    </Grid>
  );
};
export default FormTitle;
FormTitle.propTypes = {
  labelText: PropTypes.string.isRequired,
};
