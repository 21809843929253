import axios from 'axios';
import BaseUrl from '../../config/config';
import { masterTemplates } from '../../utils/api';

/**
 * @name getMasterTemplates function which make api request to get master templates
 * @returns {*} returns axios response data
 */
const getMasterTemplates = () => {
  return axios.get(`${BaseUrl}/${masterTemplates}`);
};
export default getMasterTemplates;
