/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable import/prefer-default-export */

import { ConstructionOutlined } from '@mui/icons-material';

/**
 * @param {Array} userRoles -userroles response payload
 * @name userRoleTableData
 * @returns {Array} returns table arr data
 */
export const userRoleTableData = (userRoles) => {
  const userRoleTableArr = [];
  const userRoleIdArr = [];
  if (userRoles && userRoles.length > 0) {
    userRoles.map((item, index) => {
      const key = index + 1;

      userRoleIdArr.push({ sno: key, rolePrivilegeId: item.role_privilege_id });
      return userRoleTableArr.push({
        sno: key,
        UserGroup: item.user_group,
        UserType: item.user_type,
      });
    });
  }
  return { userRoleTableArr, userRoleIdArr };
};

/**
 *
 * @param {*} arr
 * @param defRowObj
 * @returns
 */
const getCapitalizeWords = (arr) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  const str2 = arr.join(' ');
  return str2;
};

/**
 *
 * @param {Array} arr
 * @param {string} screen
 * @returns
 */
const getOverrideDataObj = (arr, screen) => {
  const tempObj = {};
  console.log(tempObj, 'tempsss');
  tempObj.screen_name = screen;
  arr.map((item) => {
    const { screenName } = item;
    const str = [];
    Object.entries(item).map(([k, v]) => {
      if (v === true) str.push(k);
      return false;
    });
    tempObj[`${screenName.value}_fields`] = str.toString();
    tempObj[`${screenName.value}`] = str.length > 0 ? '1' : '0';
    return false;
  });
  return tempObj;
};

/**
 
 * @param dta
 * @param defRowObj
 * @param tabRowObj
 * @returns
 */
const getOverrideDataArr = (dta, defRowObj, tabRowObj) => {
  const subArr = [];
  const tabArr = [];
  let tabObj = {};

  Object.entries(dta).map(([key, val], index) => {
    const tempObj = {};

    //  defRowObj;
    if (key.includes('_fields')) {
      const fieldsArr = val ? val.split(',') : [];
      tabObj[key.replace('_fields', '').trimEnd()] = fieldsArr.length > 0;
      tempObj.screenName = {
        label: getCapitalizeWords(key.replace('_fields', '').split('_')),
        value: key.replace('_fields', '').trimEnd(),
        show: fieldsArr.length > 0,
      };
      fieldsArr.map((item) => {
        tempObj[item] = true;
        // return false;
      });
      subArr.push({ ...defRowObj, ...tempObj });
    }

    // return false;
  });
  tabObj = { ...tabRowObj, ...tabObj };
  tabArr.push(tabObj);

  return { tabArr, subArr };
};

/**
 * @param {Array} arr input array
 * @returns {Array} returns payload
 */
const getUserRoleTableData = (arr) => {
  const tempArr = [];
  const accessIdArr = [];

  if (arr && arr.length > 0) {
    arr.map((item) => {
      console.log(item, 'sdlkfjruirt');
      accessIdArr.push({
        role_privilege_access_id: item.role_privilege_access_id,
      });
      return tempArr.push({
        screenName: {
          label: getCapitalizeWords(item.screen_name.split('_')),
          value: item.screen_name,
        },
        view: item.view !== 0,
        download: item.download !== 0,
        edit: item.edit !== 0,
        manualDataInput: item.manual_data_input !== 0,
        bulk_upload: item.bulk_upload !== 0,
      });
    });
  }
  return { userRoleRightsTableData: tempArr, userRoleTableIdData: accessIdArr };
};

/**
 * @name addUserRolePayload
 * @param {object} data data needed for payload
 * @param {boolean} isRoleEdit used
 * @returns {Array} returns payload to add a new user role
 */
export const UserRolePrivilegePayload = (data, isRoleEdit) => {
  const {
    userGroup,
    userType,
    privilegeRightsData,
    fieldOverrideDashBoard,
    rolePrivilegeId,
    rolePrivilegeAccessDashboardId,
    roleRightAccessId,
    role_privilege_ids,
  } = data;
  console.log(roleRightAccessId, 'skdkdkdddr');

  const payload = {
    user_group_id: userGroup,
    user_type_id: userType,
    national_dashboard: [],
    state_dashboard: [],
    prediction_dashboard: [],
    created_by: '1',
  };

  let tempPrivilege = [];
  if (privilegeRightsData && privilegeRightsData.length > 0) {
    privilegeRightsData.map((item, index) => {
      const { screenName, view, download, edit, manualDataInput, bulkUpload } = item;
      console.log(item, 'sdkfjsdiriew');

      return tempPrivilege.push({
        screen_name: screenName.value,
        view: view ? '1' : '0',
        download: download ? '1' : '0',
        edit: edit ? '1' : '0',
        manual_data_input: manualDataInput ? '1' : '0',
        bulk_upload: item.bulk_upload ? '1' : '0',
        role_privilege_access_id: roleRightAccessId?.[index]?.role_privilege_access_id,
      });
    });
  }
  const { national, state, prediction } = fieldOverrideDashBoard;

  const natObj = getOverrideDataObj(national, 'national');
  const stateObj = getOverrideDataObj(state, 'state');
  const predObj = getOverrideDataObj(prediction, 'prediction');
  const userAurhId = localStorage.getItem('UserLoginId');

  if (isRoleEdit) {
    payload.role_privilege_id = rolePrivilegeId;
    payload.user_id = '1';
    payload.updated_by = userAurhId && userAurhId;
    payload.role_privilege_id = role_privilege_ids;
    natObj.role_privilege_access_dashboard_id = rolePrivilegeAccessDashboardId.national;
    stateObj.role_privilege_access_dashboard_id = rolePrivilegeAccessDashboardId.state;
    predObj.role_privilege_access_dashboard_id = rolePrivilegeAccessDashboardId.prediction;
    tempPrivilege = tempPrivilege.map((item, i) => ({ ...item, ...roleRightAccessId[i] }));
  }

  payload.privilege_dashboard = tempPrivilege;

  payload.national_dashboard.push(natObj);
  payload.state_dashboard.push(stateObj);
  payload.prediction_dashboard.push(predObj);
  console.log(payload, 'kpayload');
  return payload;
};

/**
 * @param {*} data
 * @returns
 */
export const extractReadUserRoleData = (data) => {
  const national = data.national_dashboard[0];
  const prediction = data.prediction_dashboard[0];
  const state = data.state_dashboard[0];
  const rolePrivilegeAccess = data.role_privilege_access;
  const rolePrivilege = data.role_Privilege;

  const nationalTableArrData = getOverrideDataArr(
    national,
    {
      endemicDistrict: false,
      da: false,
      ia: false,
      underTas1: false,
      underTas2: false,
      underTas3: false,
      tasCleared1: false,
      dossierProp: false,
      dossierProp: false,
    },
    {
      screenName: { label: 'National DashBoard', value: 'national_dashboard' },
      country_profile: false,
      progress_status: false,
      activity_calender: false,
      pre_tas_overview: false,
      tas_decision_matrix: false,
      tas_summary: false,
      mda_coverage: false,
      data_quality: false,
    },
  );

  const predictionTableArrData = getOverrideDataArr(
    prediction,
    {
      endemicDistrict: false,
      da: false,
      ia: false,
      underTas1: false,
      underTas2: false,
      underTas3: false,
      tasCleared1: false,
      dossierProp: false,
    },
    {
      screenName: { label: 'Prediction DashBoard', value: 'prediction_dashboard' },
      prediction_countryProfile: false,
      prediction_expectedActivites: false,
      prediction_activityCalender: false,
    },
  );

  const stateTableArrData = getOverrideDataArr(
    state,
    {
      endemicDistrict: false,
      da: false,
      ia: false,
      underTas1: false,
      underTas2: false,
      underTas3: false,
      tasCleared1: false,
      dossierProp: false,
    },
    {
      screenName: { label: 'State DashBoard', value: 'state_dashboard', show: false },
      stateProfile: false,
      state_progress_status: false,
      state_activity_calender: false,
      state_tasDecisionMatrix: false,
      state_districtProfileWidget: false,
      state_districtTasProfile: false,
      state_districtDecisionMatrix: false,
      state_dataQuality: false,
    },
  );

  const { userRoleRightsTableData, userRoleTableIdData } = getUserRoleTableData(rolePrivilegeAccess);

  const dropdownValues = {
    userGroup: rolePrivilege.user_group_id,
    userType: rolePrivilege.user_type_id,
  };

  const rolePrivilegeAccessDashboardId = {
    national: national?.role_privilege_access_dashboard_id,
    state: state?.role_privilege_access_dashboard_id,
    prediction: prediction?.role_privilege_access_dashboard_id,
  };

  return {
    nationalTableArrData,
    predictionTableArrData,
    stateTableArrData,
    userRoleRightsTableData,
    userRoleTableIdData,
    dropdownValues,
    rolePrivilegeAccessDashboardId,
  };
};
