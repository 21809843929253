export const CreateFormEntries = [
  {
    count: true,
    textField: true,
    entryName: 'Program_Activity_Type',
    placeholder: 'Program Activity Type Proposed',
    textInputType: 'default',
    validation: [{ isRequired: true }],
  },
  {
    count: 'true',
    date: true,
    entryName: 'from_date',
    placeholder: 'From Date',
    validation: [{ isRequired: true }, { min: false }, { dateValidation: true }],
  },
  {
    count: 'true',
    date: true,
    entryName: 'to_date',
    placeholder: ' To Date ',
    validation: [{ isRequired: true }],
  },
  {
    count: true,
    radioButton: true,
    entryName: 'Status',
    placeholder: 'Status',
    labelText: 'Status',
    radioData: [{ name: 'Active' }, { name: 'Inactive' }],
    validation: [{ isRequired: true }],
  },
];
export const CreateFormDefaultValues = {
  Program_Activity_Type: '',
  from_date: '',
  to_date: '',
  Status: 'Active',
};

export const thDatas = [
  'S.No',
  'Program Activity Type',
  'Old Program Activity Type',
  'From Date',
  'To Date',
  'Status',
  'Action',
];

export const tdDatas = [
  {
    sno: '2',
    ProgramActivityType: 'blood level',
    ToDate: '22-1-199',
    FromDate: '22-1-199',
    Status: 'Active',
  },
  {
    sno: '3',
    ProgramActivityType: 'dOSSIER level',
    FromDate: '22-1-1999',
    ToDate: '22-1-199',
    Status: 'Active',
  },
  {
    sno: '4',
    ProgramActivityType: 'TAS1 BLOOD',
    FromDate: '22-1-1999',
    ToDate: '22-1-199',
    Status: 'Active',
  },
  {
    sno: '5',
    ProgramActivityType: 'blood level',
    FromDate: '22-1-1999',
    ToDate: '22-1-1999',
    Status: 'Active',
  },
  {
    sno: '6',
    ProgramActivityType: 'TAS1 ',
    FromDate: '22-1-1999',
    ToDate: '22-1-1999',
    Status: 'Active',
  },
  {
    sno: '7',
    ProgramActivityType: 'MDA level',
    FromDate: '22-1-1999',
    ToDate: '22-1-199',
    Status: 'Active',
  },
  {
    sno: '8',
    ProgramActivityType: 'TAS1 level',
    FromDate: '22-1-1999',
    ToDate: '22-1-199',
    Status: 'Active',
  },
];
