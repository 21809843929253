import axios from 'axios';
import BaseUrl from '../../config/config';
import {
  getUserMaster,
  updateUserMaster,
  insertUserMaster,
  deleteUserMaster,
  getUserGroup,
  getUserType,
  getUserMasterById,
  insertUserMasterAccess,
  updateUserMasterAssess,
} from '../../utils/api';

const userAurhId = localStorage.getItem('UserLoginId');

/**
 * @name getProgramActivitesRequest function which make api request to get program activities
 * @returns {*} returns axios response data
 */
export const getNewUserRequest = () => {
  return axios.get(`${BaseUrl}/${getUserMaster}`);
};

/**
 * @param {object} data payload data
 * @name updateProgramActivitesRequest function which make api request to update program activities
 * @returns {*} returns axios response data
 */
export const updateNewUserRequest = (data) => {
  console.log(data, 'testData');

  return axios({
    method: 'put',
    url: `${BaseUrl}/${updateUserMaster}`,
    data: {
      user_group_id: data.User_Group,
      user_type_id: data.UserType,
      user_name: data.Name,
      state_id: data?.state_id,
      email: data.Email,
      address: data.Address,
      active_status: data.Status === 'Inactive' ? 2 : 1,
      updated_by: userAurhId && userAurhId,
      user_id: data.UserId,
      ip_address: '123',
    },
  });
};

/**
 * @param {object} data payload data
 * @name insertProgramActivitesRequest function which make api request to add program activity
 * @returns {*} returns axios response data
 */
export const insertNewUserRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertUserMaster}`,
    data: {
      user_group_id: data.User_Group,
      user_type_id: data.UserType,
      user_name: data.Name,
      email: data.Email,
      state_id: data?.state_id,
      address: data.Address,
      active_status: data.Status === 'Active' ? 1 : 0,
      created_by: userAurhId && userAurhId,
    },
  });
};

/**
 * @param {object} data payload data
 * @name deleteUserMaster function which make api request to update program activities
 * @returns {*} returns axios response data
 */
export const deleteNewUserRequest = (data) => {
  return axios({
    method: 'PUT',
    url: `${BaseUrl}/${deleteUserMaster}`,
    data: {
      user_id: data.UserId,
    },
  });
};

/**
 * @name getUserGroupRequest function which make api request to get program activities
 * @returns {*} returns axios response data
 */
export const getUserGroupRequest = () => {
  return axios.get(`${BaseUrl}/${getUserGroup}`);
};

/**
 * @name getUserTypeRequest function which make api request to get program activities
 * @returns {*} returns axios response data
 */
export const getUserTypeRequest = () => {
  return axios.get(`${BaseUrl}/${getUserType}`);
};

/**
 * @param {object} userInfo payload data
 * @name getUserMasterRequest function which make api request to add program activity
 * @returns {*} returns axios response data
 */
export const getUserMasterRequest = (userInfo) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getUserMasterById}`,
    data: {
      // user_group_id: 2,
      // user_type_id: 2,
      // user_id: 35,
      user_id: userInfo.UserId,
      user_group_id: userInfo.UserGroupId,
      user_type_id: userInfo.UserTypeId,
    },
  });
};

/**
 * @param {object} data payload data
 * @name insertUserMasterAccessRequest function which make api request to add program activity
 * @returns {*} returns axios response data
 */
export const insertUserMasterAccessRequest = (data) => {
  const { inputScreens, userPrivilege, userMaster } = data;
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertUserMasterAccess}`,
    data: {
      ...userPrivilege,
      user_id: userMaster[0].user_id,
      user_group_id: userMaster[0].user_group_id,
      user_type_id: userMaster[0].user_type_id,
      input_screen: inputScreens,
      created_by: userAurhId && userAurhId,
    },
  });
};

/**
 * @param {object} data payload data
 * @name updateUserMasterAccessRequest function which make api request to add program activity
 * @returns {*} returns axios response data
 */
export const updateUserMasterAccessRequest = (data) => {
  const { inputScreens, userPrivilege, userMaster } = data;
  return axios({
    method: 'put',
    url: `${BaseUrl}/${updateUserMasterAssess}`,
    data: {
      ...userPrivilege,
      user_id: userMaster[0].user_id,
      user_group_id: userMaster[0].user_group_id,
      user_type_id: userMaster[0].user_type_id,
      input_screen: inputScreens,
      updated_by: userAurhId && userAurhId,
    },
  });
};
