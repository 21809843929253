const TELANGANA = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/telangana.json';
const ANDHRA_PRADHESH =
  'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/andhrapradesh.json';
const ANDAMAN_NICOBAR =
  'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/andamannicobarislands.json';
const ARUNACHAL_PRADHESH =
  'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/arunachalpradesh.json';
const ASSAM = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/assam.json';
const BIHAR = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/bihar.json';
const CHANDIGARH =
  'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/chandigarh.json';
const CHHATISGARH =
  'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/chhattisgarh.json';
const DADRANAGAR =
  'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/dnh-and-dd.json';
const DELHI = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/delhi.json';
const GOA = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/goa.json';
const GUJARAT = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/gujarat.json';
const HARYANA = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/haryana.json';
const HIMACHAL_PRADESH =
  'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/himachalpradesh.json';
const JAMMA_KASHMIR =
  'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/jammukashmir.json';
const JHARKHAND = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/jharkhand.json';
const KARNATAKA = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/karnataka.json';
const KERALA = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/kerala.json';
const LADAKH = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/ladakh.json';
const LAKSHADWEEP =
  'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/lakshadweep.json';
const MADHA_PRADESH =
  'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/madhyapradesh.json';
const MAHARASHTRA =
  'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/maharashtra.json';
const MANIPUR = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/manipur.json';
const MEGHALAYA = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/meghalaya.json';
const MIZORAM = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/mizoram.json';
const NAGALAND = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/nagaland.json';
const ODISHA = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/odisha.json';
const PUDUCHERRY =
  'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/puducherry.json';
const PUNJAB = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/punjab.json';
const RAJASTHAN = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/rajasthan.json';
const SIKKIM = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/sikkim.json';
const TAMIL_NADU =
  'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/tamilnadu.json';
const TRIPURA = 'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/tripura.json';
const UTTARAKHAND =
  'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/uttarakhand.json';
const UTTAR_PRADESH =
  'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/uttarpradesh.json';
const WEST_BENGAL =
  'https://raw.githubusercontent.com/covid19india/covid19india-react/master/public/maps/westbengal.json';
export {
  TELANGANA,
  ANDHRA_PRADHESH,
  ANDAMAN_NICOBAR,
  ARUNACHAL_PRADHESH,
  ASSAM,
  BIHAR,
  CHANDIGARH,
  CHHATISGARH,
  DADRANAGAR,
  DELHI,
  GOA,
  GUJARAT,
  HARYANA,
  HIMACHAL_PRADESH,
  JAMMA_KASHMIR,
  JHARKHAND,
  KARNATAKA,
  KERALA,
  LADAKH,
  LAKSHADWEEP,
  MADHA_PRADESH,
  MANIPUR,
  MAHARASHTRA,
  MEGHALAYA,
  MIZORAM,
  NAGALAND,
  ODISHA,
  PUDUCHERRY,
  PUNJAB,
  RAJASTHAN,
  SIKKIM,
  TAMIL_NADU,
  TRIPURA,
  UTTARAKHAND,
  UTTAR_PRADESH,
  WEST_BENGAL,
};
/**
 * @function getTopoUrl - return
 * @param {string} state - params
 * @returns {string} - string
 */
export const getTopoUrl = (state) => {
  switch (state) {
    case 'TELANGANA':
    case 'TS':
      return TELANGANA;
    case 'ANDHRA PRADESH':
    case 'AP':
      return ANDHRA_PRADHESH;
    case 'ANDAMAN AND NICOBAR':
    case 'AN':
      return ANDAMAN_NICOBAR;
    case 'ARUNACHAL PRADHESH':
    case 'AR':
      return ARUNACHAL_PRADHESH;
    case 'ASSAM':
    case 'AS':
      return ASSAM;
    case 'BIHAR':
    case 'BH':
      return BIHAR;
    case 'CHANDIGARH':
    case 'CH':
      return CHANDIGARH;
    case 'CHHATTISGARH':
    case 'CG':
      return CHHATISGARH;
    case 'DADRA AND NAGAR HAVELI AND DAMAN AND DIU':
    case 'DD':
      return DADRANAGAR;
    case 'DELHI':
    case 'DL':
      return DELHI;
    case 'GOA':
    case 'GA':
      return GOA;
    case 'GUJARAT':
    case 'GJ':
      return GUJARAT;
    case 'HARYANA':
    case 'HR':
      return HARYANA;
    case 'HIMACHAL PRADESH':
    case 'HP':
      return HIMACHAL_PRADESH;
    case 'JAMMA AND KASHMIR':
    case 'JK':
      return JAMMA_KASHMIR;
    case 'JHARKHAND':
    case 'JH':
      return JHARKHAND;
    case 'KARNATAKA':
    case 'KA':
      return KARNATAKA;
    case 'KERALA':
    case 'KL':
      return KERALA;
    case 'LADAKH':
    case 'LA':
      return LADAKH;
    case 'LAKSHADWEEP':
    case 'LD':
      return LAKSHADWEEP;
    case 'MADHYA PRADESH':
    case 'MP':
      return MADHA_PRADESH;
    case 'MANIPUR':
    case 'MN':
      return MANIPUR;
    case 'MAHARASHTRA':
    case 'MH':
      return MAHARASHTRA;
    case 'MEGHALAYA':
    case 'ME':
      return MEGHALAYA;
    case 'MIZORAM':
    case 'MI':
      return MIZORAM;
    case 'NAGALAND':
    case 'NL':
      return NAGALAND;
    case 'ODISHA':
    case 'OD':
      return ODISHA;
    case 'PUDUCHERRY':
    case 'PY':
      return PUDUCHERRY;
    case 'PUNJAB':
    case 'PB':
      return PUNJAB;
    case 'RAJASTHAN':
    case 'RJ':
      return RAJASTHAN;
    case 'SIKKIM':
    case 'SK':
      return SIKKIM;
    case 'TAMIL NADU':
    case 'TN':
      return TAMIL_NADU;
    case 'TRIPURA':
    case 'TR':
      return TRIPURA;
    case 'UTTARAKHAND':
    case 'UK':
      return UTTARAKHAND;
    case 'UTTAR PRADESH':
    case 'UP':
      return UTTAR_PRADESH;
    case 'WEST BENGAL':
    case 'WB':
      return WEST_BENGAL;
    default:
      return null;
  }
};
