import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../../../components/atoms/CustomDropdown';
import { USER_TYPE, USER_GROUP } from '../../../../constants/actionType';
/**
 *
 * @param  {object} props - required props in user role form
 * @returns {React.ReactElement} - returns the user role form
 */
const UserRoleForm = (props) => {
  const dispatch = useDispatch();
  const { onChangeHandler, grpValue, typeValue, readOnly } = props;
  const { userGroups, userTypes } = useSelector((state) => state.newUserDetails);
  console.log(grpValue, typeValue, 'grpValue');

  useEffect(() => {
    dispatch({ type: USER_GROUP });
    dispatch({ type: USER_TYPE });
  }, [dispatch]);
  return (
    <Grid className="formContainer">
      <Grid container item md={12} sm={12} xs={12} spacing={3}>
        <Grid item md={3} sm={6} xs={6}>
          <Dropdown
            handleChange={(e) => onChangeHandler('userGroup', e)}
            label="User Group"
            name="userGroup"
            data={userGroups}
            value={grpValue}
            disabled={readOnly}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <Dropdown
            handleChange={(e) => onChangeHandler('userType', e)}
            label="User Type"
            name="userType"
            data={userTypes}
            value={typeValue}
            disabled={readOnly}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default UserRoleForm;
UserRoleForm.propTypes = {
  onChangeHandler: PropTypes.func,
  grpValue: PropTypes.string,
  typeValue: PropTypes.string,
  readOnly: PropTypes.bool,
};

UserRoleForm.defaultProps = {
  onChangeHandler: () => null,
  grpValue: '',
  typeValue: '',
  readOnly: false,
};
