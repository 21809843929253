/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes, { bool } from 'prop-types';
import Typography from '../../atoms/Typography';
import Checkbox from '../../atoms/CustomCheckbox';
import CustomButton from '../../atoms/CustomButton';
import CustomIcons from '../../../utils/icons';
import './RightPanel.css';

/**
 * @param {*} props --
 * @returns {React.ReactElement} returns the userRightPanel Component
 */
const UserRightPanel = (props) => {
  const {
    header,
    hideButton,
    fullWidth,
    onCheckValues,
    countryProfile,
    onChangeCheckbox,
    rowData,
    headerData,
    hideFields,
  } = props;
  const [cbValue, setCbValue] = useState([]);

  useEffect(() => {
    const tempArr = [];
    header.map((value) => {
      tempArr.push(value?.values);
    });
    setCbValue(tempArr);
  }, [header]);

  /**
   *
   * @param {*} e -- event on on change
   * @param {*} key -- key of obeject
   * @param {*} index --index of array
   * @param {*} idSub -- index
   */
  const onHandleChange = (e, key, index, idSub) => {
    if (headerData) {
      onCheckValues(index, key, e);
    } else {
      cbValue[index][idSub][key] = e;
      onCheckValues(index, header[index].header, e, header[0].values[0]);
      setCbValue([...cbValue]);
    }
  };
  useEffect(() => {}, [cbValue]);
  /**
   *
   */
  const onChangeHandle = () => {};
  /**
   *
   * @param {*} k --
   * @param {*} v --
   * @param {*} idxMain --
   * @param {*} idxSub --
   * @returns {Function} --
   */
  const renderCheckbox = (k, v, idxMain, idxSub) => {
    if (k !== 'placeholder') {
      return onChangeCheckbox ? (
        <div className="check_icon">
          <Checkbox onHandleChange={(e) => onHandleChange(e, k, idxMain, idxSub)} checked={v} />
        </div>
      ) : (
        v && (
          <div className="tick_icon">
            <img src={CustomIcons.Check} alt="check" />
          </div>
        )
      );
    }
    return null;
  };
  return (
    <Grid>
      <Grid item container md={12} sm={12} direction="row" sx={{ p: 2 }} columnGap={fullWidth ? 18 : 3}>
        {header.map((label, index) => {
          return (
            <Grid>
              <Grid item sx={{ pt: 3 }}>
                <Typography
                  text={label.header}
                  type="link"
                  colorType={countryProfile ? 'primary' : 'tertiary'}
                  customStyle={{ fontSize: '12px' }}
                />
              </Grid>
              {label?.labels &&
                label?.values?.map((value) => {
                  return (
                    <Grid item sx={{ pt: 3.4 }}>
                      <Typography
                        text={value}
                        type="link"
                        customStyle={{ fontSize: '12px' }}
                        colorType={countryProfile ? 'tertiary' : 'senary'}
                      />
                    </Grid>
                  );
                })}
              {!label?.labels &&
                label?.values?.map((value, idx) => {
                  return (
                    <Grid container item className="checkboxStyle">
                      {Object.entries(value).map(([k, v]) => {
                        return renderCheckbox(k, v, index, idx);
                      })}
                    </Grid>
                  );
                })}
            </Grid>
          );
        })}
      </Grid>
      {rowData && (
        <table className="tablePanel">
          <thead>
            {headerData?.map((label) => (
              <th>
                <Typography text={label} type="link" customStyle={{ fontSize: '12px' }} colorType="tertiary" />
              </th>
            ))}
          </thead>
          <tbody>
            {rowData.map((row, index) => (
              <tr>
                {Object.entries(row).map(([key, value]) => {
                  if (!hideFields.includes(key)) {
                    if (typeof value === 'boolean') return <td>{renderCheckbox(key, value, index)}</td>;
                    return (
                      <td>
                        <Typography text={value} type="link" customStyle={{ fontSize: '12px' }} colorType="senary" />
                      </td>
                    );
                  }
                  return <> </>;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!hideButton && (
        <Grid container item md={12} className="footBtn" sx={{ display: 'flex' }}>
          <Grid sx={{ pr: 2 }}>
            <CustomButton
              btnTitle="Submit"
              color="info"
              ellipse
              onClickHandle={onChangeHandle}
              customClass="buttonStyle"
            />
          </Grid>
          <Grid>
            <CustomButton btnTitle="Close" color="disable" ellipse customClass="buttonStyle" />{' '}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default UserRightPanel;
UserRightPanel.propTypes = {
  header: PropTypes.arrayOf,
  hideButton: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onCheckValues: PropTypes.func,
  countryProfile: PropTypes.bool,
  onChangeCheckbox: PropTypes.bool,
  rowData: PropTypes.arrayOf,
  headerData: PropTypes.arrayOf,
  hideFields: PropTypes.arrayOf,
};
UserRightPanel.defaultProps = {
  header: [],
  hideButton: false,
  fullWidth: false,
  onCheckValues: () => null,
  countryProfile: false,
  onChangeCheckbox: false,
  rowData: null,
  headerData: null,
  hideFields: [],
};
