/* eslint-disable default-param-last */
import {
  GET_PROGRAM_ACTIVITY_RESULT,
  PROGRAM_ACTIVITY_TYPE_LOADING,
  PROGRAM_ACTIVITY_TYPE_STATUS,
} from '../../constants/actionType';
import { programActivityState } from '../initialStates';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const programActivityReducer = (state = programActivityState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PROGRAM_ACTIVITY_RESULT:
      return {
        ...state,
        getProgramActivity: payload.data,
        loading: false,
      };
    case PROGRAM_ACTIVITY_TYPE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case PROGRAM_ACTIVITY_TYPE_STATUS:
      return {
        ...state,
        status: payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default programActivityReducer;
