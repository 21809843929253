/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/default */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { browserHistory } from 'react-router';
import { store } from './services/store';
import App from './App';
import './index.css';

// const initialState = {};
// const store = configureStore(initialState, browserHistory);
// const state = configureStore.default.getState();
// const store = Store();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
