/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../../components/organisms/Table';
import { thDataDis, thData } from './tableData';
import './PreTAS-Table.css';
import { GET_MASTER_TEMPLATES, GET_PRE_TAS, PRE_TAS_UPLOAD, UPDATE_PRE_TAS } from '../../../../constants/actionType';
/**
 *
 * @returns {React.ReactElement} - returns the IDA input screen
 */
const PreTASTableInput = (props) => {
  const { testCancel, jsonData, fieldValue, isJson, setIsJson } = props;
  console.log(jsonData, 'srtg');
  const vals = [
    'year',
    'block',
    'random',
    'state_id',
    'pre_tas_id',
    'district_id',
    'state_code',
    'strategy_type_id',
    'strategy_type',
    'block_strategy_id',
    'pre tas sites blocks id',
    'proposed_program_activities',
    'created_by',
    'updated_by',
    'BSE R6',
    'created_on',
    'updated_on',
    'pre_tas_status',
    'pre_tas_sites_blocks_id1',
    'pre_tas_sites_blocks_id2',
    'pre_tas_sites_blocks_id3',
    'pre_tas_sites_blocks_id4',
    'pre_tas_sites_blocks_id5',
    'pre_tas_sites_blocks_id6',
    'pre_tas_sites_blocks_id7',
    'block_count',
    'endemic_population',
    'mail_status',
    'mail_date',
    'test_type_id',
    'test_type',
    'Block Name',
    // 'Test Type',
    'Covered Population',
    'pre_tas_survey_date',
  ];
  const vals1 = [
    'year',
    'block',
    'random',
    'state_id',
    'pre_tas_id',
    'district_id',
    'state_code',
    'strategy_type_id',
    'strategy_type',
    'block_strategy_id',
    'pre tas sites blocks id',
    'proposed_program_activities',
    'created_by',
    'updated_by',
    'BSE R6',
    'created_on',
    'updated_on',
    'pre_tas_status',
    'pre_tas_sites_blocks_id1',
    'pre_tas_sites_blocks_id2',
    'pre_tas_sites_blocks_id3',
    'pre_tas_sites_blocks_id4',
    'pre_tas_sites_blocks_id5',
    'pre_tas_sites_blocks_id6',
    'pre_tas_sites_blocks_id7',
    'block_count',
    'endemic_population',
    'population',
    'mail_status',
    'mail_date',
    'test_type_id',
    // 'test_type',
    // 'pre_tas_survey_date',
  ];
  React.useEffect(() => {
    if (fieldValue !== 'Block Strategy') {
      vals.push('population');
    }
  }, [fieldValue]);
  const dispatch = useDispatch();
  // const [tablehead, setTableHead] = useState();
  const [refresh, setRefresh] = useState(false);
  const { getPreTas } = useSelector((state) => state?.preTas);
  // useEffect(() => {
  //   if (fieldValue === 'District Strategy') {
  //     setTableHead(thDataDis);
  //   } else {
  //     setTableHead(thDataBlk);
  //   }
  // }, [fieldValue]);
  useEffect(() => {
    dispatch({ type: GET_PRE_TAS, payload: { strategy: fieldValue } });
  }, [dispatch]);

  const tableElements = React.useMemo(() => {
    let sitesHead = {};
    return getPreTas.map((data) => {
      let sitesRow = {};
      console.log(data, 'ksdijijtijt');
      // eslint-disable-next-line array-callback-return
      data.preTasDetails?.map((item, index) => {
        console.log(item, 'sdkiet');
        const i = index + 1;
        if (fieldValue === 'District Strategy') {
          console.log(fieldValue, 'ssd');
          sitesRow = {
            ...sitesRow,
            [`Block Name${i}`]: item.block_name,
            // 'NBS Survey Date': item.pre_tas_survey_date,
            [`R${i} Name`]: item.random_name,
            [`BSE R${i}`]: item.random_blood_count,
            [`Positive R${i}`]: item.random_possitive,
          };
        } else {
          console.log(fieldValue, 'ssd');

          sitesRow = {
            ...sitesRow,
            'Block Name': item.block_name,
            'Test Type': item.test_type,
            'NBS Survey Date': item.pre_tas_survey_date,
            // [`R${i} Name`]: item.pre_tas_id,
            // [`BSE R${i}`]: item.pre_tas_id,
            // [`Positive R${i}`]: item.pre_tas_id,
          };
          item?.preTasBlocks.map((val) => {
            console.log(val, 'lksmdljoit');

            sitesRow = {
              ...sitesRow,
              [`Block Name${i}`]: item.block_name,
              // 'Test Type': 'NBS',
              // ' NBS Survey Date': item.pre_tas_survey_date,

              [`R${i} Name`]: val.random_name,
              [`BSE R${i}`]: val.random_blood_count,
              [`Positive R${i}`]: val.random_possitive,
            };
          });
        }

        sitesHead = { ...sitesHead, ...sitesRow };
      });
      if (fieldValue === 'District Strategy') {
        // setTableHead([...tablehead, ...Object.keys(sitesHead)]);
      }
      delete data.preTasDetails;
      return { ...data, ...sitesRow };
    });
  }, [getPreTas, refresh]);
  console.log(tableElements, 'tableElements');
  /**
   *
   * @returns
   */
  const getPreTasUpload = () => {
    const arr1 = [];
    jsonData?.map((item) => {
      const arr = [];
      for (let i = 1; i <= item?.random; i += 1) {
        arr.push({
          block_name: item[`Block Name${i}`],
          random_name: item[`R${i} Name`],
          random_blood_count: item[`BSE R${i}`],
          random_possitive: item[`Positive R${i}`],
          site_id: `R${i}`,
          mf_rate: 1,
        });
      }
      arr1.push({
        proposed_program_activities: 'Pre-TAS',
        state_id: item?.state,
        district_id: item?.district,
        block_strategy_id: item?.block_strategy,
        random: item?.random,
        block_count: 0,
        date_proposed: moment(new Date((item?.date_proposed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
        date_planned: moment(new Date((item?.date_planned - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
        date_completed: moment(new Date((item?.date_completed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
        test_type_id: item?.test_type,
        survey_date: moment(new Date((item?.pre_tas_survey_date - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
        population: '2000',
        preTasDistrictSurvey: arr,
      });
    });
    return arr1;
  };
  /**
   *
   * @returns
   */
  const getPretasUploadBlock = () => {
    const finalObj = [];
    jsonData?.map((item) => {
      const arr = [];
      for (let i = 1; i <= item?.random; i += 1) {
        arr.push({
          site_id: `R${i}`,
          random_name: item[`R${i} Name`] || '',
          random_blood_count: item[`BSE R${i}`] || 0,
          random_possitive: item[`Positive R${i}`] || 0,
          mf_rate: 4,
        });
      }
      const arr1 = [];
      for (let i = 1; i <= item?.block_count; i += 1) {
        console.log(item[`Block Name${i}`], 'sdkfjsoidjer');
        arr1.push({
          block_name: item[`Block Name${i}`] || '',
          total_population: 1000,
          test_type_id: item?.test_type || 'NBS',
          pre_tas_survey_date:
            moment(new Date((item[`NBS Survey Date`] - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
          block_survey: arr,
        });
      }
      finalObj.push({
        proposed_program_activities: 'Pre-TAS',
        state_id: item?.state,
        district_id: item?.district,
        block_strategy_id: item?.block_strategy,
        random: item?.random,
        block_count: 10,
        date_proposed: moment(new Date((item?.date_proposed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
        date_planned: moment(new Date((item?.date_planned - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
        date_completed: moment(new Date((item?.date_completed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
        population: '2000',
        preTasBlockSurvey: arr1,
      });
    });
    return finalObj;
  };

  /**
   * @param {string} key update key
   * @param {Array} EditedItems edited row items
   */
  const onSendUpdatedValue = (key, EditedItems) => {
    const updatedItems = EditedItems.map((entry) => {
      const preTasSites = [...Array(entry.random).keys()].map((val) => {
        const count = val + 1;
        setRefresh(!refresh);
        return {
          site_id: '10',
          total_population: entry.population,
          block_name: entry[`Block Name${count}`],
          pre_tas_sites_blocks_id: entry[`pre_tas_sites_blocks_id${count}`] || null,
          test_type: entry.test_type || null,
          pre_tas_survey_date: entry.NBS_Survey_Date || null,
          random_name: entry[`R${count} Name`] || null,
          random_blood_count: entry[`BSE R${count}`] || null,
          random_possitive: entry[`Positive R${count}`] || null,
          mf_rate: '12',
        };
      });
      return {
        population: entry.population,
        block_count: 10,
        proposed_program_activities: 'Pre-Tas',
        state: entry.state,
        district: entry.district,
        block_strategy: entry.block_strategy,
        block: entry.block,
        random: entry.random,
        date_proposed: entry.date_proposed,
        date_planned: entry.date_planned,
        date_completed: entry.date_completed,
        pre_tas_id: entry.pre_tas_id,
        preTasSurvey: preTasSites,
      };
    });
    if (jsonData?.length > 0) {
      dispatch({
        type: PRE_TAS_UPLOAD,
        payload: {
          data: jsonData?.[0]?.block_strategy === 'Block Strategy' ? getPretasUploadBlock() : getPreTasUpload(),
          strategy: jsonData?.[0]?.block_strategy,
        },
      });
      dispatch({ type: GET_MASTER_TEMPLATES });
    } else {
      dispatch({
        type: UPDATE_PRE_TAS,
        payload: updatedItems,
      });
    }
    setTimeout(() => {
      dispatch({ type: GET_PRE_TAS, payload: { strategy: fieldValue } });
      setIsJson(false);
    }, 500);
  };

  return (
    <Table
      header={fieldValue === 'Block Strategy' ? thData : thDataDis}
      rows={isJson ? jsonData : tableElements}
      slideData
      showSno
      inputTable
      Activity="PreTas"
      onCancel={() => testCancel()}
      onSendUpdatedData={(key, rowData) => onSendUpdatedValue(key, rowData)}
      uniqueKey="pre_tas_id"
      hideKeyFields={fieldValue === 'Block Strategy' ? vals : vals1}
      IsExcelData={isJson}
      screen="input"
    />
  );
};
export default PreTASTableInput;
