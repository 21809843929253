export const thDatas = ['S.No', 'User Group', 'User Type', 'Action'];

export const tdDatas = [
  {
    sno: '1',
    UserGroup: 'Blood level',
    UserType: 'Data Entry',
  },
  {
    sno: '2',
    UserGroup: 'District level',
    UserType: 'Data Entry',
  },
  {
    sno: '3',
    UserGroup: 'State level',
    UserType: 'Monitor',
  },
];
