/* eslint-disable no-unused-vars */
import axios from 'axios';
import BaseUrl from '../../config/config';
import {
  getCountryProfile,
  getNationalProgressStatus,
  getCountryProfileMapAfterHyper,
  getNationalStatesDashboardNewAfterHyper,
  getNationalProgressStatusGraphAfterHyper,
  getActivityCalendar,
  getActivityCalendarTable,
  getDataQuality,
  getTasDecisionMatrix,
  getDataQualityGraph,
  getStateCode,
  getActivitesLegends,
  getCountryProfileChart,
} from '../../utils/api';

/**
 * @param {object} data payload
 * @name nationalDashboardDetails used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const getCountryProfileRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getCountryProfile}`,
    data: {
      years: data?.Year || '',
      state: data?.State || '',
      district: data?.District || '',
      activity: data.Activity || '',
      sort: data?.Sort || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @param {object} data payload
 * @name nationalProgressStatus used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const getCountryProfileMapAfterHyperRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getCountryProfileMapAfterHyper}`,
    data: {
      type: data.Type || '',
      years: data.Year || '',
      state: data.State || '',
      activity: data.Activity || data['Country Profile Activity'] || '',
      statusLevel: data.Status || '',
      district: data.District || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @name getCountryProfileChartRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getCountryProfileChartRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getCountryProfileChart}`,
    data: {
      years: data?.Year || '',
      state: data?.State || '',
      activity: data.Activity || data['Country Profile Activity'] || '',
      district: data?.District || '',
      type: data?.Type || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @name nationalStatesDashboard used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getNationalStatesDashboardRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getNationalStatesDashboardNewAfterHyper}`,
    data: {
      stateId: data.Type || '0',
      year: data.Year || '',
      state: data.State || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @param {object} data payload
 * @name nationalProgressStatus used for dashboard api call
 * @returns {Array} returns the axios output
 */
export const nationalProgressStatus = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getNationalProgressStatus}`,
    data: {
      years: data.Year || '',
      state: data.State || '',
      activity: data.Activity || '',
      status: data.Status || '',
      sort: data.Sort || '',
      type: data.Type || '',
      district: data.District || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @name nationalStatesDashboard used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getNationalProgressStatusGraphRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getNationalProgressStatusGraphAfterHyper}`,
    data: {
      type: data.Type || '',
      years: data.Year || '',
      state: data.State || '',
      activity: data.Activity || '',
      status: data.Status || '',
      district: data.District || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @name getActivityCalenderRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getActivityCalenderRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getActivityCalendar}`,
    data: {
      type: data.type || '',
      year: data.Year || '',
      month: data.Month || '',
      state: data.State || '',
      district: data.District || '',
      activity: data.Activity || '',
      status: data['Calendar Status'] || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @name getActivityCalenderTableRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getActivityCalenderTableRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getActivityCalendarTable}`,
    data: {
      type: data.type || '',
      year: data.Year || '',
      month: data.Month || '',
      state: data.State || '',
      district: data.District || '',
      activity: data.Activity || '',
      status: data['Calendar Status'] || '',
    },
  });
};

/**
 * @name getDataQualityRequest used for dashboard api call
 * @param {string} data data
 * @returns {Array} returns the axios output
 */
export const getDataQualityRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getDataQuality}`,
    data: {
      years: data?.Year || '',
      state: data?.State || '',
      activity: data.Activity || data.Activity_PreTAS_TAS || '',
      sort: data?.Sort || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @name getTasDecisionMatrixRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getTasDecisionMatrixRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getTasDecisionMatrix}`,
    data: {
      years: data.Year || '',
      state: data.State || '',
      activity: data.Activity || data.Activity_PreTAS_TAS || '',
      status: data.Status || '',
      sort: data.Sort || '',
      district: data.District || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @name getDataQualityGraphRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getDataQualityGraphRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getDataQualityGraph}`,
    data: {
      years: data.Year || '',
      state: data.State || '',
      district: data.District || '',
      activity: data.Activity || data.Activity_PreTAS_TAS || '',
      strategy: data.Strategy || '',
    },
  });
};
/**
 * @name getStateCodeRequest function which make api request to get state and state code
 * @returns {*} returns axios response data
 */
export const getStateCodeRequest = () => {
  return axios.get(`${BaseUrl}/${getStateCode}`);
};

/**
 * @name getActivitesLegendsRequest function which make api request to get state and state code
 * @returns {*} returns axios response data
 */
export const getActivitesLegendsRequest = () => {
  return axios.get(`${BaseUrl}/${getActivitesLegends}`);
};
