/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import {
  GET_NEWUSER_LIST_RESULT,
  USER_GROUP_RESULT,
  USER_TYPE_RESULT,
  GET_USER_MASTER_RESULT,
  LOADER,
  STATUS,
  GET_NEWUSER_LIST,
} from '../../constants/actionType';
import {
  getNewUserRequest,
  updateNewUserRequest,
  insertNewUserRequest,
  deleteNewUserRequest,
  getUserGroupRequest,
  getUserTypeRequest,
  getUserMasterRequest,
  insertUserMasterAccessRequest,
  updateUserMasterAccessRequest,
} from '../request/newUserRequest';
import {
  CATCH,
  ERROR,
  SUCCESS,
  FAILED,
  UPDATED,
  SUBMITTED,
  ALREADY_EXIST,
  DELETED,
  INITIAL,
  INITIAL_LOAD,
} from '../../constants/common';
/**
 * @name statusClear used for clear the status
 * @yields statusClear
 */
export function* statusClear() {
  yield put({
    type: STATUS,
    payload: { type: INITIAL, message: INITIAL_LOAD },
  });
}

/**
 * @function getNewUserHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields getNewUserHandler
 */
export function* getNewUserHandler() {
  try {
    const response = yield call(getNewUserRequest);
    yield put({
      type: GET_NEWUSER_LIST_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @param {object} action payload data
 * @function insertNewUserHandler handler function to handle program activity post request
 * @yields insertNewUserHandler
 */
export function* insertNewUserHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(insertNewUserRequest, payload);

    if (response.status === 200)
      if (response?.data.status === 0)
        yield put({
          type: STATUS,
          payload: { type: ERROR, message: ALREADY_EXIST },
        });
      else {
        yield put({
          type: STATUS,
          payload: { type: SUCCESS, message: SUBMITTED },
        });
      }
  } catch (err) {
    yield put({
      type: STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
  yield put({
    type: GET_NEWUSER_LIST,
  });
}
/**
 * @param {object} action payload data
 * @function updateNewUserHandler handler function to handle program activity update request
 * @yields updateNewUserHandler
 */
export function* updateNewUserHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(updateNewUserRequest, payload);
    if (response.status === 200)
      yield put({
        type: STATUS,
        payload: { type: SUCCESS, message: UPDATED },
      });
  } catch (err) {
    yield put({
      type: STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
  yield put({
    type: GET_NEWUSER_LIST,
  });
}
/**
 * @param {object} action payload data
 * @function deleteNewUserHandler handler function to handle program activity update request
 * @yields deleteNewUserHandler
 */
export function* deleteNewUserHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(deleteNewUserRequest, payload);
    if (response.status === 200) {
      yield put({
        type: STATUS,
        payload: { type: ERROR, message: DELETED },
      });

      const getUserList = yield call(getNewUserRequest);
      yield put({
        type: GET_NEWUSER_LIST_RESULT,
        payload: getUserList?.data,
      });
    }
  } catch (err) {}
}

/**
 * @function getUserGroupHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields getUserGroupHandler
 */
export function* getUserGroupHandler() {
  try {
    const response = yield call(getUserGroupRequest);
    yield put({
      type: USER_GROUP_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @function getUserTypeHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields getUserTypeHandler
 */
export function* getUserTypeHandler() {
  try {
    const response = yield call(getUserTypeRequest);
    yield put({
      type: USER_TYPE_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @param {object} action payload data
 * @function getUserMasterHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields getUserMasterHandler
 */
export function* getUserMasterHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getUserMasterRequest, payload);
    yield put({
      type: GET_USER_MASTER_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @param {object} action payload data
 * @function insertUserMasterHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields insertUserMasterHandler
 */
export function* insertUserMasterHandler(action) {
  const { payload } = action;
  try {
    yield put({ type: LOADER, payload: true });
    const response = yield call(insertUserMasterAccessRequest, payload);
    if (response.status === 200) {
      yield put({
        type: STATUS,
        payload: { type: SUCCESS, message: SUBMITTED },
      });
    } else {
      yield put({
        type: STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: STATUS,
      payload: { type: CATCH, message: FAILED },
    });
  }
}

/**
 * @param {object} action payload data
 * @function updateUserMasterHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields updateUserMasterHandler
 */
export function* updateUserMasterHandler(action) {
  const { payload } = action;
  try {
    yield put({ type: LOADER, payload: true });
    const response = yield call(updateUserMasterAccessRequest, payload);
    if (response.status === 200) {
      yield put({
        type: STATUS,
        payload: { type: SUCCESS, message: UPDATED },
      });
    } else {
      yield put({
        type: STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: STATUS,
      payload: { type: CATCH, message: FAILED },
    });
  }
}
