/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import { GET_PROGRAM_STATUS_RESULT, PROGRAM_STATUS } from '../../constants/actionType';
import {
  getProgramStatusRequest,
  updateProgramStatusRequest,
  insertProgramStatusRequest,
} from '../request/programStatusRequest';
import { CATCH, ERROR, SUBMITTED, SUCCESS, UPDATED } from '../../constants/common';

/**
 * @function getProgramStatusHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields getProgramStatusHandler
 */
export function* getProgramStatusHandler() {
  try {
    const response = yield call(getProgramStatusRequest);
    yield put({
      type: GET_PROGRAM_STATUS_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @param {object} action payload data
 * @function insertProgramStatusHandler handler function to handle program activity post request
 * @yields insertProgramStatusHandler
 */
export function* insertProgramStatusHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(insertProgramStatusRequest, payload);
    if (response.status === 200)
      yield put({
        type: PROGRAM_STATUS,
        payload: { type: SUCCESS, message: SUBMITTED },
      });
  } catch (err) {
    yield put({
      type: PROGRAM_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function updateProgramStatusHandler handler function to handle program activity update request
 * @yields updateProgramStatusHandler
 */
export function* updateProgramStatusHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(updateProgramStatusRequest, payload);
    if (response.status === 200)
      yield put({
        type: PROGRAM_STATUS,
        payload: { type: SUCCESS, message: UPDATED },
      });
  } catch (err) {
    yield put({
      type: PROGRAM_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
