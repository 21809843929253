/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CustomModal from '../Modal';
import ModalContent from './modalContent';
import CustomButton from '../../atoms/CustomButton';
import { CHANGE_PASSWORD, USER_LOGIN_RESULT } from '../../../constants/actionType';
import Toaster from '../../atoms/ToastMessage';
import { ERROR, INCORRECT_PASSWORD, INITIAL_LOAD, MISMATCH_FIELD } from '../../../constants/common';

/**
 *
 * @param {object} props - required props in change password component
 * @returns {React.ReactElement} - returns the change password component
 */
const ChangePassword = (props) => {
  const { onClickCancel } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [getPassword, setGetPassword] = useState();
  const Email = localStorage.getItem('Email');
  const [toaster, setToaster] = React.useState(false);
  const { loginDetails, forgetPasseordDetails, status } = useSelector((state) => state?.loginDetails);
  /**
   *
   */
  const onToasterClose = () => {
    setToaster(false);
  };

  
  useEffect(() => {
    setTimeout(() => {
      if (status?.type === 'success') {
        history.push('/');
      }
    }, 2000);
  }, [history, status]);
  useEffect(() => {
    dispatch({ type: INITIAL_LOAD });
  }, [dispatch]);
  /**
   *
   */
  const handleSubmit = () => {
    if (getPassword?.confirm !== '' && getPassword?.newPassword !== '') {
      if (getPassword?.confirm === getPassword?.newPassword) {
        dispatch({
          type: CHANGE_PASSWORD,
          payload: {
            email: JSON.parse(Email),
            password: getPassword?.oldPassword,
            new_password: getPassword?.newPassword,
          },
        });
        setTimeout(() => {
          onClickCancel(false);
        }, 2000);
      } else {
        dispatch({
          type: USER_LOGIN_RESULT,
          payload: { type: ERROR, message: MISMATCH_FIELD },
        });
      }
    } else {
      dispatch({
        type: USER_LOGIN_RESULT,
        payload: { type: ERROR, message: INCORRECT_PASSWORD },
      });
    }

    // setGetPassword();
  };
  React.useEffect(() => {
    if (status !== null) {
      if (status.type === 'INITIAL') setToaster(false);
      setToaster(true);
    }
  }, [status]);
  /**
   * @param {object} data - details
   */
  const getInputValues = (data) => {
    setGetPassword(data);
  };
  return (
    <CustomModal
      openModal
      modalTitle="Change Password"
      modalContent={<ModalContent getInputValues={(data) => getInputValues(data)} />}
      actionType={
        <Grid className="changeAction">
          {toaster && <Toaster open severity={status?.type} message={status?.message} close={onToasterClose} />}
          <Grid sx={{ paddingRight: 1.2 }}>
            <CustomButton
              variants="contained"
              btnTitle="Submit"
              ellipse
              color="info"
              onClickHandle={() => handleSubmit()}
              customClass="btnWidth"
            />
          </Grid>
          <Grid sx={{ paddingRight: 1.2 }}>
            <CustomButton
              variants="contained"
              btnTitle="Cancel"
              ellipse
              color="disable"
              customClass="btnWidth"
              onClickHandle={() => onClickCancel(false)}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default ChangePassword;
ChangePassword.propTypes = {
  onClickCancel: PropTypes.func,
};
ChangePassword.defaultProps = {
  onClickCancel: () => {},
};
