import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Box';
/**
 * @name TabPanel
 * @param {object} props - required props
 * @returns {React.ReactElement} - returns the tab panel content
 */
const TabPanel = (props) => {
  const { children, value, index } = props;

  return <div hidden={value !== index}>{value === index && <Grid>{children}</Grid>}</div>;
};

export default TabPanel;
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

TabPanel.defaultProps = {
  children: <> </>,
};
