import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, LabelList, Label, CartesianGrid } from 'recharts';
import Typography from '../../atoms/Typography';
import colors from '../../../utils/colors';
import './horizondal.css';

/**
 * chart Component
 *
 * @param {*} props all props
 * @param {Array[]} props.data used for chart data
 * @param {Array[]} props.title used for chart title
 * @param {string} props.XLabel used for chart XLabel
 * @param {string} props.YLabel used for chart YLabel
 * @returns {React.ReactElement} - return chart
 */
const HorizontalBarChart = ({ chartData, title, XLabel, YLabel, YKey, BarKey }) => {
  return (
    <>
      {title && (
        <center>
          <Typography type="header" colorType="text" text={title} />
        </center>
      )}
      <ResponsiveContainer height={370} width="100%">
        <BarChart layout="vertical" data={chartData}>
          <CartesianGrid stroke="#D4D3D3" vertical horizontal={false} />
          <XAxis type="number" tickLine={false} axisLine={false} style={{ fill: colors.graph }}>
            <Label value={XLabel} dy={15} style={{ fill: colors.graph }} />
          </XAxis>
          <YAxis type="category" dataKey={YKey} stroke="#FFFFFF" fontSize="12">
            <Label value={YLabel} angle={-90} dx={-30} style={{ fill: colors.graph }} />
          </YAxis>
          <Bar dataKey={BarKey} fill="#348FDC" stackId="a">
            <LabelList
              isAnimationActive={false}
              dataKey="value"
              position="insideRight"
              style={{ fill: 'white', fontSize: 10 }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

HorizontalBarChart.propTypes = {
  title: PropTypes.string,
  chartData: PropTypes.arrayOf().isRequired,
  XLabel: PropTypes.string,
  YLabel: PropTypes.string,
  YKey: PropTypes.string.isRequired,
  BarKey: PropTypes.arrayOf.isRequired,
};
HorizontalBarChart.defaultProps = { XLabel: '', YLabel: '', title: null };
export default HorizontalBarChart;
