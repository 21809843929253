import { takeLatest } from 'redux-saga/effects';
import {
  GET_DRUG,
  INSERT_DRUG_MASTERS,
  GET_DRUG_REGIME,
  GET_DRUG_TYPE,
  UPDATE_DRUG,
  BULK_UPLOAD_DRUG,
} from '../../constants/actionType/index';
import {
  getDrugHandler,
  insertDrugMastersHandler,
  getDrugRegimeHandler,
  getDrugTypeHandler,
  updateDrugHandler,
  bulkUploadDrugHandler,
} from '../handler/drugTypeHandler';

/**
 * @name nationalBoardSaga used for dashboard api call
 * @yields nationalPretasOverviewHandler
 */
function* DrugTypeSaga() {
  yield takeLatest(GET_DRUG, getDrugHandler);
  yield takeLatest(INSERT_DRUG_MASTERS, insertDrugMastersHandler);
  yield takeLatest(GET_DRUG_REGIME, getDrugRegimeHandler);
  yield takeLatest(GET_DRUG_TYPE, getDrugTypeHandler);
  yield takeLatest(UPDATE_DRUG, updateDrugHandler);
  yield takeLatest(BULK_UPLOAD_DRUG, bulkUploadDrugHandler);
}
export default DrugTypeSaga;
