/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import * as XLSX from 'xlsx';
import { Grid, Paper } from '@mui/material';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import DrugFormInput from './DrugForm';
import Toaster from '../../../components/atoms/ToastMessage';
import DrugTable from './DrugTable';
import FileUpload from '../../../components/molecules/FileUpload';
import { UPLOAD_DRUG_AVILABILITY, GET_MASTER_TEMPLATES } from '../../../constants/actionType';

/**
 *
 * @param  {object} props -required props in form data input
 * @returns  {React.ReactElement} - returns the input data
 */
const Form = (props) => {
  const { onViewTable, onClickHandleView, setJsonData } = props;
  const { getMasterTemplate } = useSelector((state) => state.masterTemplate);
  console.log(getMasterTemplate, 'getMasterTemplate');
  const dispatch = useDispatch();
  const [file, setFile] = React.useState();
  const [upload, setUpload] = React.useState(false);
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload } = Actionvalue?.input?.[0];
  /**
   * @param {*} files - sending files
   */
  const sendSelectedFiles = async (files) => {
    setFile(files);
    const data = await files.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const json = XLSX.utils.sheet_to_json(worksheet);
    setJsonData(json);
  };
  React.useEffect(() => {
    dispatch({ type: GET_MASTER_TEMPLATES });
  }, [dispatch]);
  /**
   *
   */
  const onSubmitFiles = async () => {
    await dispatch({ type: UPLOAD_DRUG_AVILABILITY, payload: file });
    dispatch({ type: GET_MASTER_TEMPLATES });
  };
  return (
    <Grid container item md={12} spacing={3} sx={{ paddingTop: 2, height: '100%' }}>
      <Grid item md={upload === true ? 9 : 11.4} className="layoutFormat">
        <Paper elevation={3} className="splitPage">
          <DrugFormInput onViewHandle={onViewTable} />
        </Paper>
      </Grid>
      {upload === false && (
        <Tooltip title="File Upload " placement="top">
          <Grid
            onClick={() => BulkUpload === 1 && setUpload(true)}
            padding="10px"
            marginTop="40px"
            className="uploadContainer"
          >
            {/* <img src={CustomIcons.LeftArrow} alt="upload" className="uploadImgFile" /> */}
            {/* <p className="uploadFile">File Upload</p> */}
            <KeyboardDoubleArrowLeftIcon fontSize="large" color="primary" className="uploadImgFile" />
          </Grid>
        </Tooltip>
      )}
      {upload === true && (
        <Grid item md={3} sm={12} xs={12} className="layoutFormat">
          <Paper elevation={3} className="splitPage">
            <Grid>
              <FileUpload
                downloadTemplate
                excelUploader
                sendSelectedFile={(files) => sendSelectedFiles(files)}
                onSubmitFiles={() => onSubmitFiles()}
                handleCancel={() => setUpload(false)}
                onClickHandleView={onClickHandleView}
                templateUrl={getMasterTemplate.drugAvailablity}
              />
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
Form.propTypes = {
  onViewTable: PropTypes.func.isRequired,
};
/**
 *
 * @returns  {React.ReactElement} - returns the input table
 */
const Table = (props) => {
  const { onCancel, onClickHandleView, setJsonData, jsonData, isJson, setIsJson } = props;
  const dispatch = useDispatch();
  const [file, setFile] = React.useState();
  // const [upload, setUpload] = React.useState();
  const ValidationGroup = localStorage.getItem('userGroupId');
  const ValidationType = localStorage.getItem('userTypeId');

  /**
   * @param {*} files - sending files
   */
  const sendSelectedFiles = async (files) => {
    setFile(files);
    const data = await files.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const json = XLSX.utils.sheet_to_json(worksheet);
    setJsonData(json);
  };

  // React.useEffect(() => {
  //   if (
  //     (ValidationGroup === '4' && ValidationType === '1') ||
  //     (ValidationGroup === '7' && ValidationType === '1') ||
  //     (ValidationGroup === '8' && ValidationType === '5')
  //   ) {
  //     setUpload(true);
  //   }
  //   if (
  //     (ValidationGroup === '5' && ValidationType === '2') ||
  //     (ValidationGroup === '4' && ValidationType === '3') ||
  //     (ValidationGroup === '0' && ValidationType === '4')
  //   ) {
  //     setUpload(false);
  //   }
  // }, [ValidationGroup, ValidationType]);
  /**
   *
   */
  const onSubmitFiles = async () => {
    await dispatch({ type: UPLOAD_DRUG_AVILABILITY, payload: file });
    dispatch({ type: GET_MASTER_TEMPLATES });
  };
  const [editableDefaultValue, setEditableDefaultValue] = React.useState({});
  const [upload, setUpload] = React.useState(false);
  const { getMasterTemplate } = useSelector((state) => state.masterTemplate);
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload, Download, Edit, ManualDataInput, View } = Actionvalue?.input?.[0];
  console.log(BulkUpload, Download, Edit, ManualDataInput, View, 'sdkjsr');

  /**
   *
   * @param {*} e -- Table Data
   * @param ids All ids from the table
   * @returns {object} new defaultValues for edit option
   */
  const editableDefaultValues = (e, ids) => {
    // console.log(e,"EventValues");
    
    return setEditableDefaultValue({
      drug_id: ids[e.sno - 1],
      State: e.state,
      district: e.District,
      drug_available_date_DEC: e.drug_available_date_DEC,
      drug_available_date_ALB: e.drug_available_date_ALB,
      drug_available_date_IVR: e.drug_available_date_IVR,
    });
  };
  return (
    <Grid container item md={12} spacing={3} sx={{ paddingTop: 2, height: '100%' }}>
      <Grid item md={upload ? 9 : 11.4} className="layoutFormat">
        <Paper elevation={3} className="splitPage">
          <DrugTable
            onEditClick={(e, ids) => editableDefaultValues(e, ids)}
            onCancel={onCancel}
            Activity="DrugAvailability"
            jsonData={jsonData}
            isJson={isJson}
            setIsJson={setIsJson}
          />
        </Paper>
      </Grid>
      {upload === false && (
        <Tooltip title="File Upload " placement="top">
          <Grid
            onClick={() => BulkUpload === 1 && setUpload(true)}
            padding="10px"
            marginTop="40px"
            className="uploadContainer"
          >
            {/* <img src={CustomIcons.LeftArrow} alt="upload" className="uploadImgFile" /> */}
            {/* <p className="uploadFile">File Upload</p> */}
            <KeyboardDoubleArrowLeftIcon fontSize="large" color="primary" className="uploadImgFile" />
          </Grid>
        </Tooltip>
      )}
      {upload && (
        <Grid item md={3} sm={12} xs={12} className="layoutFormat">
          <Paper elevation={3} className="splitPage">
            <Grid>
              <FileUpload
                downloadTemplate
                excelUploader
                sendSelectedFile={(files) => sendSelectedFiles(files)}
                onSubmitFiles={() => onSubmitFiles()}
                handleCancel={() => setUpload(false)}
                onClickHandleView={onClickHandleView}
                templateUrl={getMasterTemplate.drugAvailablity}
                screen="input"
              />
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

/**
 *
 * @returns {React.ReactElement} - returns the drug availability data input
 */
const DrugAvailability = () => {
  const [showTable, setShowTable] = React.useState('Form');
  // console.log(showTable,"showTable");
  
  const [toaster, setToaster] = React.useState(false);
  const [jsonData, setJsonData] = React.useState();
  const [isJson, setIsJson] = React.useState(false);
  // console.log(jsonData, 'sadmoiyse');
  const { status } = useSelector((state) => state?.insertDrugDetails);
  // console.log(status, 'sadmoiyse');

  /**
   *
   */
  const onViewTableData = () => {
    setShowTable('Table');
  };
  React.useEffect(() => {
    if (status && status?.message !== '') setToaster(true);
    else setToaster(false);
  }, [status]);
  /**
   *
   */
  const onClickHandleView = () => {
    setShowTable('Table');
    setIsJson(true);
  };

  const ValidationGroup = localStorage.getItem('userGroupId');
  const ValidationType = localStorage.getItem('userTypeId');

  // React.useEffect(() => {
  //   if (
  //     (ValidationGroup === '4' && ValidationType === '1') ||
  //     (ValidationGroup === '7' && ValidationType === '1') ||
  //     (ValidationGroup === '8' && ValidationType === '5')
  //   ) {
  //     setShowTable('Table');
  //   }
  //   if (
  //     (ValidationGroup === '5' && ValidationType === '2') ||
  //     (ValidationGroup === '4' && ValidationType === '3') ||
  //     (ValidationGroup === '0' && ValidationType === '4')
  //   ) {
  //     setShowTable('Form');
  //   }
  // }, [setShowTable, ValidationGroup, ValidationType]);
  /**
   *
   * @param {string} type - type of IDA input format
   * @returns {Function} - returns the required type
   */
  const getDrugAvailabilityModule = (type) => {
    switch (type) {
      case 'Form':
        return <Form onViewTable={onViewTableData} onClickHandleView={onClickHandleView} setJsonData={setJsonData} />;
      case 'Table':
        return (
          <Table
            onCancel={() => setShowTable('Form')}
            onClickHandleView={onClickHandleView}
            setJsonData={setJsonData}
            jsonData={jsonData}
            isJson={isJson}
            setIsJson={setIsJson}
          />
        );
      default:
        return <Form onClickHandleView={onClickHandleView} setJsonData={setJsonData} />;
    }
  };
  return (
    <Grid className="dataLayout">
      {toaster && <Toaster open severity={status?.type} message={status?.message} close={() => setToaster(false)} />}
      {getDrugAvailabilityModule(showTable)}
    </Grid>
  );
};

export default DrugAvailability;
// DrugAvailability.propTypes = {
//   onCancel: PropTypes.func,
// };
// DrugAvailability.defaultProps = {
//   onCancel: () => null,
// };
