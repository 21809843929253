/* eslint-disable default-param-last */
import {
  GET_DRUG,
  GET_DRUG_RESULT,
  // INSERT_DRUG_MASTERS,
  INSERT_DRUG_MASTERS_RESULT,
  GET_DRUG_REGIME,
  GET_DRUG_REGIME_RESULT,
  GET_DRUG_TYPE,
  GET_DRUG_TYPE_RESULT,
  UPDATE_DRUG,
  UPDATE_DRUG_RESULT,
  BULK_UPLOAD_DRUG_RESULT,
  DRUG_TYPE_STATUS,
} from '../../constants/actionType/index';
import { drugType } from '../initialStates';

/**
 * @param {object} action used for payload and type
 * @param {string} state defines the state
 * @returns {Array} action response state
 */
const drugTypeReducer = (state = drugType, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DRUG:
      return {
        ...state,
        payload,
      };
    case GET_DRUG_RESULT:
      return {
        ...state,
        getDrugType: payload.data,
      };
    // case INSERT_DRUG_MASTERS:
    //   return {
    //     ...state,
    //     payload,
    //   };

    case INSERT_DRUG_MASTERS_RESULT:
      return {
        ...state,
        status: payload.data,
      };
    case GET_DRUG_REGIME:
      return {
        ...state,
        payload,
      };

    case GET_DRUG_REGIME_RESULT:
      return {
        ...state,
        getDrugRegime: payload.data,
      };
    case GET_DRUG_TYPE:
      return {
        ...state,
        payload,
      };

    case GET_DRUG_TYPE_RESULT:
      return {
        ...state,
        getDrugTypeDropdown: payload.data,
      };
    case UPDATE_DRUG:
      return {
        ...state,
        payload,
      };

    case UPDATE_DRUG_RESULT:
      return {
        ...state,
        updateDrug: payload.data,
      };
    case BULK_UPLOAD_DRUG_RESULT:
      return {
        ...state,
        bulkuploaddrug: payload.data,
      };
    case DRUG_TYPE_STATUS:
      return {
        ...state,
        status: payload,
        loading: false,
      };

    default:
      return state;
  }
};
export default drugTypeReducer;
