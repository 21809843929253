import { takeLatest } from 'redux-saga/effects';
import { GET_FORMULA_CONFIG, INSERT_FORMULA_CONFIG, FORMULA_APPROVAL_ATTACHMENT } from '../../constants/actionType';
import { getFormulaHandler, insertFormulaHandler, formulaApprovalHandler } from '../handler/formulaHandler';

/**
 * @name formulaConfigSaga used for program activities api call
 * @yields formulaConfigSaga
 */
function* formulaConfigSaga() {
  yield takeLatest(GET_FORMULA_CONFIG, getFormulaHandler);
  yield takeLatest(INSERT_FORMULA_CONFIG, insertFormulaHandler);
  yield takeLatest(FORMULA_APPROVAL_ATTACHMENT, formulaApprovalHandler);
}
export default formulaConfigSaga;
