/* eslint-disable import/prefer-default-export */
/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import {
  USER_LOGIN_RESULT,
  FORGET_PASSWORD_RESULT,
  USER_LOGOUT_RESULT,
  CHANGE_PASSWORD_RESULT,
} from '../../constants/actionType/index';
import { LoginRequest, forgetPasswordRequest, userLogoutRequest, changePasswordRequest } from '../request/loginRequest';
import { ERROR, INITIAL, INITIAL_LOAD, INVALID_MAIL, PASSWORD_CHANGED, SUCCESS } from '../../constants/common';

/**
 * @name statusClear used for clear the status
 * @yields statusClear
 */
export function* statusClear() {
  // alert('testt');
  yield put({
    type: USER_LOGIN_RESULT,
    payload: {
      type: INITIAL,
      message: INITIAL_LOAD,
    },
  });
}
/**
 * @name LoginHandler used for dashboard api call
 * @param {object} action payload
 * @yields LoginHandler
 */
export function* LoginHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(LoginRequest, payload);
    console.log(response, 'sdlkfjsoir');
    if (response?.data?.status === 0) {
      yield put({
        type: USER_LOGIN_RESULT,
        payload: { type: ERROR, message: INVALID_MAIL },
      });
    }
    if (response.data?.status === 1) {
      yield put({
        type: USER_LOGIN_RESULT,
        payload: response?.data,
      });
    }
  } catch (err) {}
}

/**
 * @name forgetPasswordHandler used for dashboard api call
 * @param {object} action payload
 * @yields forgetPasswordHandler
 */
export function* forgetPasswordHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(forgetPasswordRequest, payload);
    yield put({
      type: FORGET_PASSWORD_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @name forgetPasswordHandler used for dashboard api call
 * @param {object} action payload
 * @yields forgetPasswordHandler
 */
export function* userLogoutHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(userLogoutRequest, payload);
    yield put({
      type: USER_LOGOUT_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @name forgetPasswordHandler used for dashboard api call
 * @param {object} action payload
 * @yields forgetPasswordHandler
 */
export function* changePasswordHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(changePasswordRequest, payload);
    console.log(response?.data?.msg, 'response');
    if (response?.data?.status === 1) {
      yield put({
        type: CHANGE_PASSWORD_RESULT,
        payload: response?.data,
      });
      yield put({
        type: USER_LOGIN_RESULT,
        payload: { type: SUCCESS, message: PASSWORD_CHANGED },
      });
    }
    if (response?.data?.status === 0) {
      yield put({
        type: USER_LOGIN_RESULT,
        payload: { type: ERROR, message: response?.data?.msg },
      });
    }
  } catch (err) {}
}
