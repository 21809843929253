const INSERTED = 'Inserted Sucessfully';
const SUBMITTED = 'Submitted Successfully';
const UPDATED = 'Updated Sucessfully';
const FAILED = 'Something went wrong. Please try again';
const ALREADY_ENTERED = 'Block Name Already Entered';
const CONDITIONAL = 'Covered Population Should be Less Then Total Population';
// const SRCONDITIONAL = 'Sentinal and Random sites Should be Less Then Covered Population';

const PROPOSED_DATE_POPULATED = 'ProposedDatePublished';
const SUCCESS = 'success';
const ERROR = 'error';
const INITIAL = 'INITIAL';
const INITIAL_LOAD = 'On page load';
const CATCH = 'Action not held';
const UPLOAD_SUCCESS = 'Uploaded Successfully';
const BULK_UPLOAD_SUCCESS = 'Bulk Uploaded Successfully';
const APPROVAL_SUCCESS = 'Approval Attachment Successfully';
const ALREADY_EXIST = 'Mail Already exist';
const ACTIVITY_EXIST = 'This Program Activity Already Exist';
const DELETED = 'Deleted Successfully';
const INVALID_MAIL = 'Email and Password does not match';
const PASSWORD_CHANGED = 'Password Updated Successfully';
const INCORRECT_PASSWORD = 'New Password And Confirm Password is Required';
const MISMATCH_FIELD = 'Password Does Not Match';

export {
  CATCH,
  ERROR,
  SUCCESS,
  FAILED,
  UPDATED,
  INSERTED,
  SUBMITTED,
  INITIAL,
  INITIAL_LOAD,
  UPLOAD_SUCCESS,
  BULK_UPLOAD_SUCCESS,
  APPROVAL_SUCCESS,
  ALREADY_EXIST,
  ACTIVITY_EXIST,
  CONDITIONAL,
  // SRCONDITIONAL,
  DELETED,
  INVALID_MAIL,
  ALREADY_ENTERED,
  PROPOSED_DATE_POPULATED,
  PASSWORD_CHANGED,
  INCORRECT_PASSWORD,
  MISMATCH_FIELD,
};
