import { takeLatest } from 'redux-saga/effects';
import { GET_PROGRAM_STATUS, UPDATE_PROGRAM_STATUS, INSERT_PROGRAM_STATUS } from '../../constants/actionType';
import {
  getProgramStatusHandler,
  insertProgramStatusHandler,
  updateProgramStatusHandler,
} from '../handler/programStatusHandler';

/**
 * @name programStatusSaga used for program activities api call
 * @yields programStatusSaga
 */
function* programStatusSaga() {
  yield takeLatest(GET_PROGRAM_STATUS, getProgramStatusHandler);
  yield takeLatest(INSERT_PROGRAM_STATUS, insertProgramStatusHandler);
  yield takeLatest(UPDATE_PROGRAM_STATUS, updateProgramStatusHandler);
}
export default programStatusSaga;
