import axios from 'axios';
import BaseUrl from '../../config/config';
import {
  getPredictionActivityCalendar,
  getPredictionActivityCalendarTable,
  getExpectedActivites,
  getPredictionCountryProfile,
  getPredictionCountryProfileMap,
  getPredictionDashboard,
  getExpectedActivitesGraph,
} from '../../utils/api';

/**
 * @name getPredictionActivityCalendarRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getPredictionActivityCalendarRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getPredictionActivityCalendar}`,
    data: {
      year: data.Year || '',
      month: data.Month || '',
      state: data.State || '',
      activity: data.Activity || '',
      status: data.Status || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @name getPredictionActivityCalendarRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getPredictionActivityCalendarTableRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getPredictionActivityCalendarTable}`,
    data: {
      sort: data.Sort || '',
      year: data.Year || '',
      month: data?.State?.Month || '',
      state: data.State?.State || '',
      activity: data?.State?.Activity || '',
      status: data.Status || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @name getPredictionCountryProfileRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getPredictionCountryProfileRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getPredictionCountryProfile}`,
    data: {
      sort: data.Sort || '',
      state: data.State || '',
      activity: data.Activity_Prediction || '',
      status: data.Status || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @name getPredictionCountryProfileMapRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getPredictionCountryProfileMapRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getPredictionCountryProfileMap}`,
    data: {
      state: data.State || '',
      activity: data.Activity_Prediction || '',
      status: data.Status || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @name getPredictionDashboardRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getPredictionDashboardRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getPredictionDashboard}`,
    data: { state: data.State || '', strategy: data.Strategy || '' },
  });
};

/**
 * @name getExpectedActivitesGraphRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getExpectedActivitesGraphRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getExpectedActivitesGraph}`,
    data: {
      state: data.State || '',
      district: data.District || '',
      activity: data.Activity || '',
      status: data.Status || '',
      strategy: data.Strategy || '',
    },
  });
};

/**
 * @name getExpectedActivitesRequest used for dashboard api call
 * @param {number} data payload data
 * @returns {Array} returns the axios output
 */
export const getExpectedActivitesRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${getExpectedActivites}`,
    data: {
      sort: data.Sort || '',
      state: data.State || '',
      district: data.District || '',
      activity: data.Activity || '',
      status: data.Status || '',
      strategy: data.Strategy || '',
    },
  });
};
