import { Table } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import TableGrid from './TableRow';
import TableHeader from './TableHeader';

/**
 * @param {*} props --
 * @returns {React.ReactElement} returns the userRightRightTablePanel Component
 */
const UserRightRightTablePanel = (props) => {
  const { header, gridConfig, onCheckValues, isSubType, readOnly, isFilter, form } = props;
  return (
    <Table>
      <TableHeader heads={header} isSubType={isSubType} />
      <TableGrid
        data={gridConfig}
        onUpdateValues={onCheckValues}
        isSubType={isSubType}
        readOnly={readOnly}
        isFilter={isFilter}
        form={form}
      />
    </Table>
  );
};
export default UserRightRightTablePanel;
UserRightRightTablePanel.propTypes = {
  header: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  gridConfig: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  isSubType: PropTypes.bool,
  readOnly: PropTypes.bool,
  isFilter: PropTypes.bool,
  onCheckValues: PropTypes.func,
  form: PropTypes.bool,
};
UserRightRightTablePanel.defaultProps = {
  onCheckValues: () => null,
  isSubType: false,
  isFilter: false,
  readOnly: false,
  form: false,
};
