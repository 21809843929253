import { takeLatest } from 'redux-saga/effects';
import {
  INSERT_TAS,
  INSERT_TAS_DISRICT_STRATEGY,
  INSERT_TAS_BLOCK_STRATEGY,
  GET_TAS,
  GET_TAS_PROPOSED,
  INSERT_TAS_CHECK,
  BULKUPLOAD_TAS,
} from '../../constants/actionType/index';
import {
  insertTAS1Handler,
  insertTASDistrictHandler,
  insertTASBlockHandler,
  getTAS1Handler,
  getTASProposedHandler,
  statusClear,
  uploadTasHandler,
} from '../handler/TASHandler';

/**
 * @name TASSaga used for dashboard api call
 * @yields TASSaga
 */
function* TASSaga() {
  yield takeLatest(INSERT_TAS_CHECK, statusClear);
  yield takeLatest(INSERT_TAS, insertTAS1Handler);
  yield takeLatest(INSERT_TAS_DISRICT_STRATEGY, insertTASDistrictHandler);
  yield takeLatest(INSERT_TAS_BLOCK_STRATEGY, insertTASBlockHandler);
  yield takeLatest(GET_TAS, getTAS1Handler);
  yield takeLatest(GET_TAS_PROPOSED, getTASProposedHandler);
  yield takeLatest(BULKUPLOAD_TAS, uploadTasHandler);
}
export default TASSaga;
