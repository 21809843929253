/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import { GET_THRESHOLD_CONFIG_RESULT, THRESHOLD_STATUS, APPROVAL_STATUS } from '../../constants/actionType';

import {
  getThresholdConfigRequest,
  insertThresholdConfigRequest,
  approvalAttachment,
} from '../request/thresholdConfigRequest';
import { CATCH, ERROR, BULK_UPLOAD_SUCCESS, SUCCESS, APPROVAL_SUCCESS } from '../../constants/common';

/**
 * @function getThresholdHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields  getThresholdHandler
 */
export function* getThresholdHandler() {
  try {
    const response = yield call(getThresholdConfigRequest);
    yield put({
      type: GET_THRESHOLD_CONFIG_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @param {object} action payload data
 * @function insertThresholdHandler handler function to handle program activity post request
 * @yields insertThresholdHandler
 */
export function* insertThresholdHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(insertThresholdConfigRequest, payload);

    if (response.status === 200)
      yield put({
        type: THRESHOLD_STATUS,
        payload: { type: SUCCESS, message: BULK_UPLOAD_SUCCESS },
      });
  } catch (err) {
    yield put({
      type: THRESHOLD_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function thresholdApproval handler function to handle program activity post request
 * @yields thresholdApproval
 */
export function* thresholdApprovalHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(approvalAttachment, payload);

    if (response.status === 200)
      yield put({
        type: APPROVAL_STATUS,
        payload: { type: SUCCESS, message: APPROVAL_SUCCESS },
      });
  } catch (err) {
    yield put({
      type: APPROVAL_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
