/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Table from '../../../../components/organisms/Table';
import { thData } from './TableEntries';
import { GET_LYMPHEDEMA, UPDATE_LYMPHEDEMA, UPLOAD_LYMPHEDEMA } from '../../../../constants/actionType';
import './TableLymphedema.css';

/**
 *
 * @returns {React.ReactElement} - returns the IDA input screen
 */
const LymphedemaTable = (props) => {
  const { Activity, jsonData, onCancel, isJson, setIsJson } = props;
  console.log(jsonData, 'jsonDatamm');
  const { getLymphedema } = useSelector((state) => state.lymphedema);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({ type: GET_LYMPHEDEMA });
  }, [dispatch]);
  console.log(getLymphedema, 'sdfasdf');
  /**
   * @param key
   */
  const onSendUpdatedValue = React.useCallback(
    async (key, rowData) => {
      const arr = [];
      if (jsonData?.length > 0) {
        jsonData?.map((item) => {
          console.log(item, 'itemconsksole');  
          arr.push({
            state: item?.State,
            district: item?.District,
            lymphedema_cases: item?.lymphedema_cases,
            hydrocele_case : item?.hydrocele_case,
            hydrocele_surgeries: item?.hydrocele_surgeries        
          });
        });
        dispatch({ type: UPLOAD_LYMPHEDEMA, payload: arr });
      } else {
        await dispatch({ type: UPDATE_LYMPHEDEMA, payload: rowData });
        dispatch({ type: GET_LYMPHEDEMA });
      }
      setIsJson(false);
    },
    [dispatch, jsonData, setIsJson],
  );
  return (
    <Table
      header={thData}
      rows={isJson ? jsonData : getLymphedema}
      slideData
      inputTable
      onSendUpdatedData={(key, rowData) => onSendUpdatedValue(key, rowData)}
      hideKeyFields={['state_id', 'lymhyd_id', 'district_id', 'block', 'created_on', 'updated_on']}
      showSno
      onCancel={onCancel}
      getById="lymphedema_id"
      Activity={Activity}
      uniqueKey="lymphedema_id"
      IsExcelData={isJson}
      screen="input"
    />
  );
};

export default LymphedemaTable;
LymphedemaTable.propTypes = {
  Activity: PropTypes.string,
};
LymphedemaTable.defaultProps = {
  Activity: '',
};
