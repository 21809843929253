/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import { GET_MASTER_TEMPLATES_RESULT } from '../../constants/actionType';

import getMasterTemplates from '../request/masterTemplateRequest';

/**
 * @function getMasterTemplateHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields  getMasterTemplateHandler
 */
export default function* getMasterTemplateHandler() {
  try {
    const response = yield call(getMasterTemplates);
    yield put({
      type: GET_MASTER_TEMPLATES_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
