/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import { IDA_DATA_RESULT, IDA_STATUS, PROPOSED_DATE_IDA, LOADER } from '../../constants/actionType';
import {
  getIDARequest,
  insertIDARequest,
  updateIDARequest,
  getProposedDateIDARequest,
  updateIDAByIdRequest,
  bulkUploadMdaIdaRequest,
  insertMdaBlockRequest,
} from '../request/IDARequest';
import {
  CATCH,
  ERROR,
  SUCCESS,
  FAILED,
  UPDATED,
  SUBMITTED,
  BULK_UPLOAD_SUCCESS,
  PROPOSED_DATE_POPULATED,
  INITIAL,
  INITIAL_LOAD,
} from '../../constants/common';

/**
 * @name statusClear used for clear the status
 * @yields statusClear
 */
export function* statusClear() {
  yield put({
    type: IDA_STATUS,
    payload: { type: INITIAL, message: INITIAL_LOAD },
  });
}

/**
 * @param {object} action payload data
 * @function getIDAHandler handler function to handle IDA input data get request and dispatch response data to reducer state
 * @yields getIDAHandler
 */
export function* getIDAHandler(action) {
  const { payload } = action;
  try {
    yield put({ type: LOADER, payload: true });
    const response = yield call(getIDARequest, payload);
    if (response.status === 200)
      yield put({
        type: IDA_DATA_RESULT,
        payload: response?.data,
      });
    else
      yield put({
        type: IDA_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
  } catch (err) {
    yield put({
      type: IDA_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}

/**
 * @param {object} action payload data
 * @function getIDAHandler handler function to handle IDA input data get request and dispatch response data to reducer state
 * @yields getIDAHandler
 */
export function* insertBlockHandler(action) {
  const { payload } = action;
  try {
    yield put({ type: LOADER, payload: true });
    const response = yield call(insertMdaBlockRequest, payload);
    if (response.status === 200)
      yield put({
        type: IDA_STATUS,
        payload: { type: SUCCESS, message: SUBMITTED },
      });
    // yield put({
    //   type: IDA_DATA_RESULT,
    //   payload: response?.data,
    // });
    else
      yield put({
        type: IDA_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
  } catch (err) {
    yield put({
      type: IDA_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}

/**
 * @param {object} action payload data
 * @function insertIDAHandler handler function to handle post IDA input data
 * @yields insertIDAHandler
 */
export function* insertIDAHandler(action) {
  const { payload } = action;
  try {
    yield put({ type: LOADER, payload: true });
    const response = yield call(insertIDARequest, payload);
    if (response.status === 200) {
      yield put({
        type: IDA_STATUS,
        payload: { type: SUCCESS, message: SUBMITTED },
      });
    } else {
      yield put({
        type: IDA_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: IDA_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}

/**
 * @param {object} action payload data
 * @function updateIDAHandler handler function to handle post IDA input data
 * @yields updateIDAHandler
 */
export function* updateIDAHandler(action) {
  const { payload } = action;
  try {
    yield put({ type: LOADER, payload: true });
    const response = yield call(updateIDARequest, payload);
    if (response.status === 200) {
      yield put({
        type: IDA_STATUS,
        payload: { type: SUCCESS, message: UPDATED },
      });
    } else {
      yield put({
        type: IDA_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: IDA_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function getProposedDateIDAHandler handler function to handle getProposedDateIDA input data get request and dispatch response data to reducer state
 * @yields getProposedDateHandler
 */
export function* getProposedDateIDAHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getProposedDateIDARequest, payload);
    if (response.status !== 200) {
      yield put({
        type: IDA_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    } else {
      yield put({
        type: PROPOSED_DATE_IDA,
        payload: response?.data,
      });
      if (response.data.status === 0) {
        yield put({
          type: IDA_STATUS,
          payload: { type: ERROR, message: response.data.msg },
        });
      }
      if (response.data.status === 1) {
        yield put({
          type: IDA_STATUS,
          payload: { type: SUCCESS, message: PROPOSED_DATE_POPULATED },
        });
      }
    }
  } catch (err) {
    yield put({
      type: IDA_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}

/**
 * @param {object} action payload data
 * @function updateIDAByIdHandler handler function to handle update particular IDA data
 * @yields updateIDAByIdHandler
 */
export function* updateIDAByIdHandler(action) {
  const { payload } = action;
  try {
    yield put({ type: LOADER, payload: true });
    const response = yield call(updateIDAByIdRequest, payload);
    if (response.status === 200) {
      yield put({
        type: IDA_STATUS,
        payload: { type: SUCCESS, message: UPDATED },
      });
    } else {
      yield put({
        type: IDA_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: IDA_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function updatePreTasHandler handler function to handle program activity update request
 * @yields updateDossierHandler
 */
export function* uploadMdaIdaHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(bulkUploadMdaIdaRequest, payload);
    if (response.status === 200) {
      yield put({
        type: IDA_STATUS,
        payload: { type: SUCCESS, message: BULK_UPLOAD_SUCCESS },
      });
    } else {
      yield put({
        type: IDA_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: IDA_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
