/* eslint-disable import/prefer-default-export */
/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import {
  PRETAS_OVERVIEW_RESULT,
  MDA_COVERAGE_RESULT,
  GET_DISTRICT_MOVEDTO_RESULT,
  GET_TAS_SUMMERY_RESULT,
} from '../../constants/actionType/index';
import {
  getPreTasOverviewRequest,
  getMdaCoverageRequest,
  getDistrictMovedtoTASvsTotalEndemicDistrictRequest,
  getTASsummaryRequest,
} from '../request/nationalDashboardRequest';

/**
 * @name nationalPretasOverviewHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalPretasOverviewHandler
 */
export function* nationalPretasOverviewHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getPreTasOverviewRequest, payload);
    yield put({
      type: PRETAS_OVERVIEW_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name nationalMdaCoverageHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* nationalMdaCoverageHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getMdaCoverageRequest, payload);
    yield put({
      type: MDA_COVERAGE_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name getDistrictMovedtoHandler used for dashboard api call
 * @param {number} action payload data
 * @yields nationalStatesDashboardCardHandler
 */
export function* getDistrictMovedtoHandler(action) {
  const { payload, dashboardType } = action;
  try {
    const response = yield call(getDistrictMovedtoTASvsTotalEndemicDistrictRequest, payload);
    yield put({
      type: GET_DISTRICT_MOVEDTO_RESULT,
      payload: { dashboardType, data: response?.data?.data },
    });
  } catch (err) {}
}

/**
 * @name getTASsummaryRequest used for dashboard api call
 * @param {number} action payload data
 * @yields nationalStatesDashboardCardHandler
 */
export function* getTasSummeryHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getTASsummaryRequest, payload);
    yield put({
      type: GET_TAS_SUMMERY_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
