/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import './dropdown.css';

/**
 *
 * @param {object} props - required props in dropdown component
 * @returns {React.ReactElement} - returns the date dropdown component
 */
const Dropdown = (props) => {
  const { value, dropdown, onChangeData, disabled, error, errmsg, placeholder, className, defaultOption } = props;
  return (
    <div className="dropdown">
      <div>
        <select
          disabled={disabled && disabled}
          onChange={(e) => onChangeData && onChangeData(e.target.value)}
          className={`dropdownStyling ${className}`}
        >
          {!defaultOption && (
            <option value="" selected>
              {placeholder}
            </option>
          )}
          {dropdown?.map((data) => {
            return (
              <option value={data.value} selected={value === data.id || value === data.value} className="options">
                {data.value}
              </option>
            );
          })}
        </select>
      </div>
      <div className="Errormsg">
        <div>{error && errmsg}</div>
      </div>
    </div>
  );
};
export default Dropdown;
Dropdown.propTypes = {
  value: PropTypes.string.isRequired,
  dropdown: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChangeData: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errmsg: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  defaultOption: PropTypes.bool,
};
Dropdown.defaultProps = {
  onChangeData: null,
  disabled: false,
  error: false,
  errmsg: '',
  placeholder: '',
  className: '',
  defaultOption: false,
};
