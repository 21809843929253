/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react';
import { Grid, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from '../../../components/atoms/DatePicker';
import Typography from '../../../components/atoms/Typography/index';
import Table from '../../../components/organisms/Table';
import Toaster from '../../../components/atoms/ToastMessage';
import { GET_PROGRAM_STATUS, UPDATE_PROGRAM_STATUS, INSERT_PROGRAM_STATUS } from '../../../constants/actionType';
import CustomForm from '../../../components/organisms/CustomForm';
import { CreateFormEntries, CreateFormDefaultValues, thDatas } from './entries';

/**
 * @returns {React.ReactElement} return the user role component
 */
const CreateProgramStatus = () => {
  const [date, setDate] = useState(new Date());
  const [toaster, setToaster] = React.useState(null);
  const dispatch = useDispatch();
  const [defaultFormValues, setDefaultForm] = useState(CreateFormDefaultValues);
  const [formMode, setFormMode] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [userDetails, setUserDetails] = React.useState({
    Program_Activity_Type: '',
    Data_Status: '',
    Program_Status: '',
    userId: '',
    ResultYear: '',
  });
  const { getProgramStatus, status } = useSelector((state) => state.programStatus);
  const [formControl, setFormControl] = React.useState();
  const ValidationGroup = localStorage.getItem('userGroupId');
  const ValidationType = localStorage.getItem('userTypeId');

  useEffect(() => {
    dispatch({ type: GET_PROGRAM_STATUS });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload, Download, Edit, ManualDataInput, View } = Actionvalue?.masters?.[0];
  console.log(BulkUpload, Download, Edit, ManualDataInput, View, 'aslkdjqqfasoihr');

  useEffect(() => {
    if (getProgramStatus) {
      const row = [];
      getProgramStatus?.map((data) => {
        row.push({
          ProgramActivityType: data.program_activities,
          program_activities_id: data.program_activities_id,
          ProgramStatus: data.program_status,
          OldProgramStatus: data.old_program_status,
          Criteria: data.criteria,
          DataStatus: data.date_status,
          OldDataStatus: data.old_date_status,
          FromDate: data.from_date,
          ToDate: data.to_date,
          Status: data.status,
          StatusId: data.program_status_id,
          ResultYear: data.result_for_next_year,
        });
        return null;
      });
      setRowData(row);
    }
  }, [getProgramStatus]);

  React.useEffect(() => {
    if (status && status?.message !== '') setToaster(true);
    else setToaster(false);
  }, [status]);
  React.useEffect(() => {
    if ((ValidationGroup === '0' && ValidationType === '4') || (ValidationGroup === '8' && ValidationType === '5')) {
      setFormControl(true);
    }
  }, [ValidationGroup, ValidationType]);
  /**
   *
   * @param {*} data returns form data
   * @function handleFormSubmit
   */
  const handleFormSubmit = async (data) => {
    const dynObj = {
      Program_Activity_Type:
        data.Program_Activity_Type !== '' ? data.Program_Activity_Type : userDetails.Program_Activity_Type,
      ProgramStatus: data.Program_Status,
      Criteria: data.Criteria,
      DataStatus: data.Data_Status,
      Result_For_Next_Year: data.Result_For_Next_Year,
      FromDate: data.from_date,
      ToDate: data.to_date,
      Certificate: data.Certificate,
      old_program_status: userDetails?.Program_Status,
      old_date_status: userDetails?.Data_Status,
      StatusId: userDetails?.userId,
      ResultYear: userDetails?.ResultYear,
    };

    if (formMode) {
      await dispatch({ type: INSERT_PROGRAM_STATUS, payload: dynObj });
      dispatch({ type: GET_PROGRAM_STATUS });
      setDefaultForm(CreateFormDefaultValues);
    } else {
      await dispatch({
        type: UPDATE_PROGRAM_STATUS,
        payload: { data: dynObj, id: userDetails.userId },
      });
      dispatch({ type: GET_PROGRAM_STATUS });
      setFormMode(true);
      setDefaultForm(CreateFormDefaultValues);
    }
  };

  /**
   *
   * @param {*} data returns form data
   * @function handleEdit
   */
  const handleEdit = (data) => {
    setUserDetails((prevState) => ({
      ...prevState,
      Program_Activity_Type: data.Program_Activity_Type,
      userId: data.StatusId,
      Data_Status: data.DataStatus,
      Program_Status: data.ProgramStatus,
      ResultYear: data.ResultYear,
    }));

    const activityType = CreateFormEntries.find((items) => {
      return items.entryName === 'Program_Activity_Type' ? items.data : '';
    });

    const Group = activityType.data.find((val) => {
      return val.value === data.ProgramActivityType ? val.id : '';
    });

    setDefaultForm({
      Program_Activity_Type: Group?.id,
      Program_Status: data.ProgramStatus,
      Criteria: data.Criteria,
      Data_Status: data.DataStatus,
      Result_For_Next_Year: data.ResultYear,
      to_date: data.ToDate,
      from_date: data.FromDate,
      Certificate: data.Status,
    });
    setFormMode(false);
  };

  /**
   * @function handleCancel
   */
  const handleCancel = () => {
    setDefaultForm(CreateFormDefaultValues);
    setFormMode(true);
  };
  /**
   * @param {number} data - data
   * @param {string} name - name
   * @function getSelectedDropdown
   */
  const getSelectedDropdown = (data, name) => {
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: data,
    }));
  };
  return (
    <Grid container item md={12} sm={12} xs={12} spacing={3} sx={{ paddingTop: 2 }} className="dataLayout">
      {toaster && <Toaster open severity={status?.type} message={status?.message} close={() => setToaster(false)} />}
      <Grid item md={9} sm={12} xs={12} className="noPadding">
        <Paper elevation={3} className="splitPage">
          {formControl && (
            <Grid>
              <Typography text="Create Program Status" colorType="primary" type="caption" customClass="userText" />
            </Grid>
          )}
          {formControl && (
            <Grid sx={{ p: 2 }}>
              <CustomForm
                entries={CreateFormEntries}
                defaultValues={defaultFormValues}
                masterSetup
                onReceiveData={(data) => handleFormSubmit(data)}
                onCancelAction={handleCancel}
                SubmitBtnTitle={formMode ? 'Submit' : 'Update'}
                getSelectedDropdown={(data, name) => getSelectedDropdown(data, name)}
                screen="masters"
              />
            </Grid>
          )}
          {View === 1 && (
            <Table
              header={thDatas}
              rows={rowData}
              masterSetup={['Downloader', 'Search']}
              userActivity="View Program Activity"
              optional
              edit
              hideKeyFields={['StatusId', 'ResultYear', 'program_activities_id']}
              showSno
              onEditData={(data) => handleEdit(data)}
              Activity="ProgramStatus"
              screen="masters"
            />
          )}
        </Paper>
      </Grid>

      <Grid item md={3} sm={12} xs={12} sx={{ height: '100%' }} className="paddingTop">
        <Paper elevation={3} className="splitPage">
          <Grid sx={{ pt: 2 }}>
            <DatePicker calendarPicker date={date} onHandleChange={(newDate) => setDate(newDate)} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default CreateProgramStatus;
