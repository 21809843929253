/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import { GET_GEOGRAPHIC_DIMENSION_RESULT, GEOGRAPHY_STATUS } from '../../constants/actionType';
import {
  getGeographicDimensionRequest,
  updateGeographicDimensionRequest,
  insertGeographicDimensionRequest,
  bulkUploadGeographicDimensions,
} from '../request/geographicDimension';
import { CATCH, ERROR, SUCCESS, SUBMITTED, BULK_UPLOAD_SUCCESS, UPDATED } from '../../constants/common';

/**
 * @function getGeographicHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields get GeographicHandler
 */
export function* getGeographicHandler() {
  try {
    const response = yield call(getGeographicDimensionRequest);
    yield put({
      type: GET_GEOGRAPHIC_DIMENSION_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @param {object} action payload data
 * @function insertGeographicHandler handler function to handle program activity post request
 * @yields insertGeographicHandler
 */
export function* insertGeographicHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(insertGeographicDimensionRequest, payload);
    if (response.status === 200)
      yield put({
        type: GEOGRAPHY_STATUS,
        payload: { type: SUCCESS, message: SUBMITTED },
      });
  } catch (err) {
    yield put({
      type: GEOGRAPHY_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function updateGeographicHandler handler function to handle program activity update request
 * @yields updateGeographicHandler
 */
export function* updateGeographicHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(updateGeographicDimensionRequest, payload);
    if (response.status === 200)
      yield put({
        type: GEOGRAPHY_STATUS,
        payload: { type: SUCCESS, message: UPDATED },
      });
  } catch (err) {
    yield put({
      type: GEOGRAPHY_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function bulkUploadGeographicHandler handler function to handle bulk upload request
 * @yields bulkUploadGeographicHandler
 */
export function* bulkUploadGeographicHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(bulkUploadGeographicDimensions, payload);
    if (response.status === 200)
      yield put({
        type: GEOGRAPHY_STATUS,
        payload: { type: SUCCESS, message: BULK_UPLOAD_SUCCESS },
      });
  } catch (err) {
    yield put({
      type: GEOGRAPHY_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
