/* eslint-disable default-param-last */
import {
  PRETAS_OVERVIEW,
  PRETAS_OVERVIEW_RESULT,
  MDA_COVERAGE,
  MDA_COVERAGE_RESULT,
  GET_DISTRICT_MOVEDTO_RESULT,
  GET_TAS_SUMMERY_RESULT,
} from '../../constants/actionType/index';
import { nationalDashboard } from '../initialStates/index';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const nationalBoardReducer = (state = nationalDashboard, action) => {
  const { type, payload } = action;
  switch (type) {
    case PRETAS_OVERVIEW:
      return {
        ...state,
        payload,
      };
    case PRETAS_OVERVIEW_RESULT:
      return {
        ...state,
        preTasOverview: payload.data,
      };
    case MDA_COVERAGE:
      return {
        ...state,
        payload,
      };
    case MDA_COVERAGE_RESULT:
      return {
        ...state,
        MdaCoverage: payload.data,
      };
    case GET_DISTRICT_MOVEDTO_RESULT:
      return {
        ...state,
        DistrictMovedToData: {
          ...state.DistrictMovedToData,
          [payload.dashboardType]: payload.data,
        },
      };
    case GET_TAS_SUMMERY_RESULT:
      return {
        ...state,
        getTasSummery: payload.data,
      };

    default:
      return state;
  }
};
export default nationalBoardReducer;
