import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import CustomButton from '../../atoms/CustomButton';
import CustomTypography from '../../atoms/Typography';
import ActivityData from './activityData';
import './ActivitySelector.css';
/**
 *
 * @returns {React.ReactElement} - returns the activity selector component
 */
const ActivitySelector = () => {
  const [isActive, setIsActive] = React.useState(0);
  const [activeTitle, setActiveTitle] = React.useState(null);
  const Location = useLocation();

  /**
   * @name onClickHandle
   * @param {number} selectedId - id of activity selector title
   * @param {number} index - index of button
   */
  const onClickHandle = (selectedId, index) => {
    setIsActive({ [`btn${selectedId}`]: 'outlined' }, selectedId);
    setActiveTitle({ [`btn${index}`]: 'info' });
  };
  useEffect(() => {
    ActivityData.find((item, index) => {
      return item.sub.find((val) => {
        if (val.NavLink === `${Location?.pathname}${Location?.search}`) {
          return onClickHandle(val.id, index);
        }
        return null;
      });
    });
  }, [Location.pathname, Location.search]);
  return (
    <Grid>
      <Grid>
        <Grid container item md={12} sm={12} xs={12}>
          {ActivityData.map((data, index) => {
            const key = index;
            return (
              <Grid key={key}>
                <CustomTypography
                  text={data.text}
                  type="link"
                  colorType={activeTitle && activeTitle[`btn${index}`] ? activeTitle[`btn${index}`] : 'quaternary'}
                  customClass="headerText"
                />
                <Grid container className="dividerRight">
                  {data.sub.map((value) => {
                    return (
                      <Grid item className="btnGrids">
                        <NavLink to={value?.NavLink} activeClassName="activeNav" style={{ textDecoration: 'none' }}>
                          <CustomButton
                            variants={isActive && isActive[`btn${value.id}`] ? isActive[`btn${value.id}`] : 'contained'}
                            color={value.color}
                            btnTitle={value.title}
                            onClickHandle={() => onClickHandle(value.id, index)}
                            customStyle={{ padding: '9px 4px', boxShadow: 'unset' }}
                            customClass="font"
                          />
                        </NavLink>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActivitySelector;
