/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Table from './Table';
import DatePicker from '../../../components/atoms/DatePicker';
import Typography from '../../../components/atoms/Typography/index';
import CustomButton from '../../../components/atoms/CustomButton/index';
import UserRolePrivilege from './userRolePrivilege';
import Toaster from '../../../components/atoms/ToastMessage';
import './User.css';
import { INSERT_TAS_CHECK, USER_GROUP, USER_ROLE_TOGGLE_FORM, USER_TYPE } from '../../../constants/actionType';
import { INITIAL_LOAD } from '../../../constants/common';

/**
 * @returns {React.ReactElement} return the user role component
 */
const UserRole = () => {
  const { toggleForm, status } = useSelector((store) => store.userRole);
  const [date, setDate] = React.useState(new Date());
  const [toaster, setToaster] = React.useState(false);
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload, Download, Edit, ManualDataInput, View } = Actionvalue?.masters?.[0];
  console.log(BulkUpload, Download, Edit, ManualDataInput, View, 'aslkdjqqfasoihr');

  const [form, setForm] = React.useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: USER_GROUP });
    dispatch({ type: USER_TYPE });
  }, [dispatch]);
  console.log(form, 'ksjdfsurefa');
  React.useEffect(() => {
    setToaster(false);
  }, []);
  React.useEffect(() => {
    dispatch({
      type: INITIAL_LOAD,
    });
  }, [dispatch]);
  console.log(status, 'statusstatus');
  /**
   * @name switchForm
   */
  const switchForm = () => {
    dispatch({
      type: USER_ROLE_TOGGLE_FORM,
      payload: {
        toggleForm: true,
      },
    });
    setForm(true);
  };
  React.useEffect(() => {
    if (status && status?.message !== '') setToaster(true);
    else setToaster(false);
  }, [status]);

  return (
    <Grid item container md={12} sm={12} xs={12} spacing={3} sx={{ paddingTop: 2 }} className="dataLayout">
      {toaster && <Toaster open severity={status?.type} message={status?.message} close={() => setToaster(false)} />}
      <Grid item md={9} sm={12} xs={12} className="noPadding">
        <Paper elevation={3} className="splitPage" sx={{ height: '100%' }}>
          {!toggleForm ? (
            <Grid>
              <Grid>
                <Typography
                  text="User Role Privilege"
                  type="caption"
                  customStyle={{ fontWeight: 'bold' }}
                  customClass="userText"
                />
              </Grid>
              <Grid sx={{ display: 'flex', justifyContent: 'flex-end', pr: 3 }}>
                <Grid>
                  <CustomButton
                    variants="contained"
                    color="info"
                    btnTitle="Role Privilege"
                    ellipse
                    startIcon
                    iconType="plus"
                    onClickHandle={switchForm}
                    customStyle={{ width: '150px' }}
                  />
                </Grid>
              </Grid>
              {View === 1 && (
                <Grid item md={12}>
                  <Table setForm={setForm} />
                </Grid>
              )}
            </Grid>
          ) : (
            <UserRolePrivilege form={form} />
          )}
        </Paper>
      </Grid>
      <Grid item md={3} sm={12} xs={12} sx={{ height: '100%' }} className="paddingView">
        <Paper elevation={3} className="splitPage">
          <Grid sx={{ pt: 2 }}>
            <DatePicker calendarPicker date={date} onHandleChange={(newDate) => setDate(newDate)} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UserRole;
