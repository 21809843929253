/* eslint-disable import/prefer-default-export */
/// //*****Dashboard*****///////
export const dashboardInitialstate = {
  data: [],
  countryProfileTable: [],
  progressStatus: [],
  countryProfileMap: { national: [], state: [] },
  nationalStateDashboardCard: { national: [], state: [] },
  nationalStateProgressStatusbar: { national: [], state: [] },
  dataQuality: { national: [], state: [] },
  dataQualityGraph: { national: [], state: [] },
  tasDecisionMatrix: { national: [], state: [] },
  getCountryProfileChart: { national: [], state: [] },
  activityCalendar: { national: [], state: [] },
  activityCalendarTable: { national: [], state: [] },
  filterData: {
    national: {
      initialLoad: false,
    },
    state: {
      initialLoad: false,
    },
  },
  activityLegends: [],
  loading: false,
  stateCode: [],
};

/// //*****National Dashboard*****///////
export const nationalDashboard = {
  data: [],
  ProgressOverview: [],
  MdaCoverage: [],
  DistrictMovedToData: { national: [], state: [] },
  loading: false,
};

/// //*****State Dashboard*****///////
export const stateDashboard = {
  data: [],
  ProgressStatus: [],
  StateProfile: [],
  districtMdaProfile: [],
  districtProfileWidget: [],
  districtProfileWidgetGraph: {},
  districtTasProfile: [],
  districtDecisionMatrix: [],
  stateProfileChart: [],
  stateDataQuality: [],
  districtMDABlocks: [],
  loading: false,
};

/// //*****Prediction Dashboard*****///////
export const predictionDashboard = {
  data: [],
  ActivityCalendar: [],
  ActivityCalendarTable: [],
  getExpectedActivitesData: [],
  getPredictionCountryProfileData: [],
  gettPredictionCountryProfileMap: [],
  getPredictionDashboard: [],
  getExpectedActivitesGraph: [],
  loading: false,
  error: null,
  success: null,
};
// Data Input
// Dossier

export const dossierInitialState = {
  data: [],
  getDossier: [],
  updateDossier: [],
  insertDossier: [],
  loading: false,
  status: null,
};

export const dropdownSearch = {
  data: [],
  getStateSearch: [],
  getDistrictSearch: [],
  loading: false,
  status: null,
};
// Lymphedema

export const lymphedemaInitialState = {
  data: [],
  getLymphedema: [],
  updateLymphedema: [],
  insertLymphedema: [],
  loading: false,
  status: null,
};
// Master Setup

export const masterTemplate = {
  data: [],
  getMasterTemplate: [],
  loading: false,
};

export const programActivityState = {
  data: [],
  getProgramActivity: [],
  insertProgramActivity: [],
  updateProgramActivity: [],
  loading: false,
};
export const insertDrug = {
  data: [],
  insertDrug: [],
  getDrug: [],
  updateDrug: [],
  loading: false,
  status: null,
};
export const drugType = {
  data: [],
  getDrugType: [],
  insertDrugMasters: [],
  getDrugRegime: [],
  getDrugTypeDropdown: [],
  loading: false,
  status: null,
};

export const programStatusState = {
  data: [],
  getProgramStatus: [],
  insertProgramStatus: [],
  updateProgramStatus: [],
  status: null,
  loading: false,
};

export const newUserState = {
  data: [],
  getNewUser: [],
  insertNewUser: [],
  updateNewUser: [],
  deleteNewUser: [],
  userTypes: [],
  userGroups: [],
  userMaster: [],
  loading: false,
};

export const loginInfo = {
  data: [],
  loginInfo: [],
  forgetPasseordDetails: [],
  userlogout: [],
  loading: false,
  changePassword: [],
  status: null,
};

export const userRoleState = {
  userRoleTableArr: [],
  userRoleIdArr: [],
  toggleForm: false,
  isRoleEdit: false,
  roleReadOnly: false,
  updateResValue: false,
};

// IDA Input
export const idaInput = {
  getIDA: [],
  loading: false,
  error: null,
  success: null,
};
export const geographicInitialState = {
  data: [],
  getGeographicDimension: [],
  updateGeographicDimension: [],
  insertGeographicDimension: [],
  bulkUploadGeographicDimension: [],
  loading: false,
  status: null,
};
// Master
// Demographic
export const demographic = {
  data: [],
  loading: true,
  insertedData: [],
  updatedData: [],
  status: null,
};

export const preTas = {
  data: [],
  getPreTas: [],
  insertPreTas: [],
  updatePreTasId: [],
  updatePreTas: [],
  ProposedData: [],
  blockName: [],
  EUName: [],
  loading: false,
  error: null,
  status: null,
};

export const dropdownList = {};
// TAS1 , TAS2 ,TAS3 configuration
export const TASInfo = {
  data: [],
  loading: false,
  TAS1InsertedData: [],
  TASInsertDistrictData: [],
  TASInsertBlockData: [],
  TASGetData: [],
  TAS1UpdatedData: [],
};

// Threshold configuration
export const thresholdInitialState = {
  data: [],
  loading: true,
  insertConfig: [],
  thresholdApproval: [],
  bulkStatus: null,
  approvalStatus: null,
};

// Formula configuration
export const formulaInitialState = {
  data: [],
  loading: true,
  insertFormula: [],
  formulaApproval: [],
  status: null,
};

// Custom Reports
export const customReportInitialStates = {
  fitlerExtractReports: [],
  customReportsType: [],
};
