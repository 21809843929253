import {
  DEMOGRAPHIC_LISTED_DATA,
  DEMOGRAPHIC_UPDATED_DATA,
  DEMOGRAPHIC_INSERTED_DATA,
  INSERT_DEMOGRAPHIC_DIMENSIONS_FAILED,
  DEMO_STATUS,
} from '../../constants/actionType';
import { demographic } from '../initialStates';

/**
 *  @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const DemographicDimensionsReducer = (state = demographic, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case DEMOGRAPHIC_INSERTED_DATA:
      return {
        ...state,
        status: payload.data,
      };
    case DEMOGRAPHIC_UPDATED_DATA:
      return {
        ...state,
        updateDemographicDimensions: payload.data,
      };
    case DEMOGRAPHIC_LISTED_DATA:
      return {
        ...state,
        loading: false,
        getDemographicDimensions: payload.data,
      };
    case INSERT_DEMOGRAPHIC_DIMENSIONS_FAILED:
      return {
        ...state,
        failedStatus: payload.data,
      };
    case DEMO_STATUS:
      return {
        ...state,
        status: payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default DemographicDimensionsReducer;
