/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable use-isnan */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable spaced-comment */
/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable require-jsdoc */
/* eslint-disable no-alert */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import * as _ from 'loadsh';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/CustomButton';
import Dropdown from '../../atoms/CustomDropdown';
import TextField from '../../atoms/TextField';
import RadioButton from '../../atoms/RadioButton';
import DatePicker from '../../atoms/DatePicker';
import FormTitle from '../../atoms/FormTitle';
import FileFormat from '../../atoms/FileFormat';
import CountRange from '../../atoms/CountRange';
import CrossIcon from '../../../assets/icon/CrossIcon.svg';
import './styles.css';
import CountRangePreTas from '../../atoms/CounterRangePreTas/PreTasRange';
import { BLOCK_NAME, EU_NAME, IDA_STATUS, PRE_TAS_STATUS } from '../../../constants/actionType';
import { ALREADY_ENTERED, ERROR, FAILED, CONDITIONAL } from '../../../constants/common';
import ComboBox from '../../molecules/ComboBox';

/**
 *
 * @param {*} props defaultValues to set form ,entries to get display,onReceiveData to submit data
 * @returns { React.ReactElement} forms
 */
const CustomForm = React.memo((props) => {
  const {
    Block,
    strategy,
    btn,
    blackCount,
    randomCount,
    daItemCount,
    defaultValues,
    entries,
    onReceiveData,
    onViewHandle,
    onCancelAction,
    masterSetup,
    countRange,
    separator,
    dataInput,
    getSelectedDropdown,
    plus,
    minus,
    // eslint-disable-next-line no-unused-vars
    floatingEnd,
    SubmitBtnTitle,
    removeItem,
    Activity,
    mdaSentinal,
    BlockActivity,
    countS,
    countR,
    districtValue,
    stateValue,
    statergyId,
    screen,
  } = props;

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: {errors},
    setValue,
  } = useForm();
  const dispatch = useDispatch();
  const [dropdownKeyPairs, setDropdownKeyPairs] = React.useState({});
  const { proposedDateIDA } = useSelector((state) => state.IDADetails);

  const { blockName } = useSelector((state) => state?.preTas);
  const [blockCount1, setBlockCount1] = React.useState(1);
  const [count1, setCount1] = React.useState(1);
  const [error, setError] = React.useState({});
  const [constValue, setConstValue] = React.useState();

  useEffect(() => {
    reset({});
  }, [Activity]);
  useEffect(() => {
    reset({
      state: stateValue,
      district: districtValue,
      mf_rate_R1_B1: '',
      mf_rate_S1_B1: '',
    });
  }, [strategy]);
  useEffect(() => {
    setTimeout(() => {
      reset(
        {
          state: stateValue,
          district: districtValue,
          strategy: statergyId,
          mf_rate_R1_B1: '',
          mf_rate_S1_B1: '',
        },
        100,
      );
    });
  }, [strategy]);
  const DashBoard = 'DashBoard';
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);

  const { BulkUpload, Download, Edit, ManualDataInput, View } =
    Actionvalue?.[screen !== undefined ? screen : DashBoard]?.[0];

  const { ProposedData } = useSelector((state) => state?.preTas);
  const datePopulatePreTas = ProposedData && ProposedData[0]?.date_proposed;
  useEffect(() => {
    setValue(`Date_Proposed_by_NVBDCP`, datePopulatePreTas);
  }, [ProposedData]);
  // useEffect(() => {
  //   setValue(`date_proposed_by_nvbdcp`, '2022-03-03');
  // }, [proposedDateIDA]);

  //////date-validation
  /////ida
  // const datePopulation = watch('date_proposed_by_nvbdcp');
  // const datePlanned = watch('date_planned_by_state');
  // const dateCompletedField = watch('mda_date_completed');
  // const same = datePlanned.getTime() > dateCompletedField.getTime();
  // console.log(same, 'datePopulation');

  ///// PreTas //////
  const watchFields = watch(['from_date', 'to_date']);
  const watchFields211 = watch(`Block_R${count1 + 1}`);
  const watchFields21 = watch(`Positive_R${count1}`);
  const watchFields3 = watch(`Positive_R${count1}_B${blockCount1}`);
  const watchBlockSiteName = watch(`Site_R${count1 + 1}_B${blockCount1}`);
  const watchBlockName = watch(`Block_Name_B${blockCount1 + 1}`);
  const watchFields4 = watch(`Positive_R${randomCount}_B${blackCount}`);
  const watchFieldsBlock = watch(`Block_R${count1}`);
  const watchFieldsRandom = watch(`Block_R${randomCount}_B${blackCount}`);
  const IDAChangeValueS = watch(`block_name_S${daItemCount}`);
  const IDAChangeValueR = watch(`block_name_R${randomCount}`);
  const [population, setPopulation] = React.useState([]);
  const watchIDA = watch(`bse_S${daItemCount}`);
  const watchIDA1 = watch(`positive_R${daItemCount}`);
  const surveyDate = watch('survey_Date_B1');
  const [const1, setConst1] = React.useState([]);
  const [sentinal, setSentinal] = React.useState(0);
  const [random, setRandom] = React.useState(0);
  const [sentinalValue, setSentinalValue] = React.useState([]);
  const [randomEnteredValues, setRandomEnteredValues] = React.useState([]);
  const [DASentinalValue, setDASentinalValues] = React.useState([]);
  const [constBlock, setConstBlock] = React.useState([]);
  const [const1Value, setConst1Value] = React.useState();
  const [randomValues, setRandomValues] = React.useState([]);
  const result = const1.includes(watchFieldsBlock);
  const resultRandom = randomEnteredValues.includes(watchFieldsRandom);
  const sentinalDuplicateChecker = DASentinalValue.includes(IDAChangeValueS);
  useEffect(() => {
    setValue(`Survey_Date_Completed`, surveyDate);
  }, [surveyDate]);
  React.useEffect(() => {
    if (btn === 2) {
      if (result) {
        setValue(`Block_R${count1}`, '');
        if (const1[0] !== undefined) {
          dispatch({
            type: PRE_TAS_STATUS,
            payload: { type: ERROR, message: ALREADY_ENTERED },
          });
        }
      }
    }

    if (resultRandom) {
      setValue(`Block_R${randomCount}_B${blackCount}`, '');
    }
    if (sentinalDuplicateChecker) {
      setValue('block_name_S2', '');
      // dispatch({
      //   type: PRE_TAS_STATUS,
      //   payload: { type: ERROR, message: ALREADY_ENTERED },
      // });
    }
  }, [blackCount, count1, randomCount, result, resultRandom, sentinalDuplicateChecker, setValue]);
  const datePopulate = proposedDateIDA && proposedDateIDA[0]?.date_proposed;
  useEffect(() => {
    setValue('date_proposed_by_nvbdcp', datePopulate);
  }, [proposedDateIDA]);
  React.useEffect(() => {
    if (watchBlockName !== undefined) {
      setBlockCount1(blockCount1 + 1);
      setCount1(1);
    }
  }, [blockCount1, watchBlockName]);

  React.useEffect(() => {
    if (watchFields211 || watchBlockSiteName !== undefined) {
      setConst1([...const1, watchFieldsBlock]);
      setCount1(count1 + 1);
    }
  }, [watchFields211, watchBlockSiteName, count1, const1, watchFieldsBlock]);
  const BlockRes = watchFields4 / watchFields3 / 100;
  const IDARes = watchIDA1 / watchIDA / 100;
  ////////PreTas////////
  const watchPopulationBlick = watch(`Total_population_of_block_B${blockCount1 - 1}`);
  const watchPopulationBlick1 = watch(`Total_population_of_block_B${blockCount1}`);
  // const watchPopulationBlick1 = watch(`Total_population_of_block_B${blockCount1}`);
  const watchPreTasBlockPopulation = watch(`Block_Name_B${blockCount1}`);
  const valuues = watch(`Population_Under_Pre_TAS`);
  const res11 = parseInt(population, 10) + parseInt(watchPopulationBlick1, 10);
  useEffect(() => {
    setValue('total_population1', '');
    setValue('covered_population', '');
  }, [strategy]);
  useEffect(() => {
    if (watchPopulationBlick1 !== undefined) {
      const arr2 = [];
      for (let i = 1; i <= blockCount1; i++) {
        const watchPopulationBlicks = watch(`Total_population_of_block_B${i}`);
        const watchPopulatio22nBlicks = watch(`Population_Under_Pre_TAS${i}`);

        arr2.push(parseInt(watchPopulationBlicks, 10));
        const sum2 = arr2.reduce(function (a, b) {
          return a + b;
        }, 0);
        if (strategy !== `District Strategy`) {
          if (sum2 > 0) {
            setValue('Population_Under_Pre_TAS', sum2);
          }
          // entries[7].value = _.assign(sum1);
        }
        // setValue('total_population1', res1 >= 0 ? res1 : mdaCount1);
      }
    }
  }, [watchPopulationBlick1, setValue]);
  React.useEffect(() => {
    if (watchPreTasBlockPopulation) {
      if (blockCount1 > 1) {
        setCount1(count1 + 1);
        setPopulation([...population, watchPopulationBlick]);
      }
    }
  }, [watchPopulationBlick1]);
  // useEffect(() => {
  //   if (Activity === 'MDA') {
  //     entries[6].value = _.assign('');
  //     entries[7].value = _.assign('');
  //   }
  // }, [strategy]);
  // React.useEffect(() => {
  //   setPopulation(res11);
  // }, [watchPreTasBlockPopulation]);
  // React.useEffect(() => {
  //   if (watchPopulationBlick !== '') {
  //     setValue(`Population_Under_Pre_TAS`, blockCount1 > 1 ? res11 : watchPopulationBlick);
  //   }
  // }, [watchPopulationBlick]);
  // BLOCK
  React.useEffect(() => {
    // alert('yrs');
    if (btn === 2) {
      for (let i = 1; i <= randomCount; i++) {
        const watchFields1 = watch(`BSE_R${i}`);
        const watchFields2 = watch(`Positive_R${i}`);
        const res = (watchFields2 / watchFields1) * 100;

        if (watchFields2 !== undefined) {
          setValue(`MF_Rate_R${i}`, res >= 0 ? res : '');
          // if (randomCount > i) {
          //   entries.find((item) => item?.entryName === `Block_R${i + 1}`).disabled = false;
          //   entries.find((item) => item?.entryName === `Site_R${i + 1}`).disabled = false;
          //   entries.find((item) => item?.entryName === `BSE_R${i + 1}`).disabled = false;
          //   entries.find((item) => item?.entryName === `Positive_R${i + 1}`).disabled = false;
          //   // entries.find((item) => item?.entryName === `MF_Rate_R${i + 1}`).disabled = false;
          // }
        }
      }
    }
    if (btn === 1) {
      for (let j = 1; j <= blackCount; j++) {
        for (let i = 1; i <= randomCount; i++) {
          const watchFields1 = watch(`BSE_R${i}_B${j}`);
          const watchFields2 = watch(`Positive_R${i}_B${j}`);
          const res = (watchFields2 / watchFields1) * 100;
          if (watchFields2 !== undefined) {
            setValue(`MF_Rate_R${i}_B${j}`, res >= 0 ? res : '');
            if (i < randomCount) {
              // entries.find((item) => item?.entryName === `Block_R${i + 1}`).disabled = false;
              // entries.find((item) => item?.entryName === `Site_R${i + 1}_B${j + 1}`).disabled = false;
              // entries.find((item) => item?.entryName === `BSE_R${i + 1}_B${j + 1}`).disabled = false;
              // entries.find((item) => item?.entryName === `Positive_R${i + 1}_B${j + 1}`).disabled = false;
              // entries.find((item) => item?.entryName === `MF_Rate_R${i + 1}`).disabled = false;
            }
          }
        }
      }
    }
  }, [watchFields21, setValue, watchFields3, btn, randomCount, watch, entries, blackCount]);

  React.useEffect(() => {
    setValue(`mf_rate_R${daItemCount}`, IDARes);
    // setValue(`MF_Rate_R${randomCount}_B${blackCount}`, BlockRes);
  }, [BlockRes, IDARes, blackCount, daItemCount, randomCount, setValue, watchFields4, watchIDA1]);
  const watchBlockName1 = watch(`Block_Name_B${blockCount1 - 1}`);
  const watchBlockName2 = watch(`Block_Name_B${blockCount1}`);
  React.useEffect(() => {
    if (watchBlockName1 !== undefined) {
      setConstBlock([...constBlock, watchBlockName1]);
    }
  }, [blockCount1]);
  const resultBlock = constBlock.includes(watchBlockName2);
  React.useEffect(() => {
    if (resultBlock) {
      dispatch({
        type: PRE_TAS_STATUS,
        payload: { type: ERROR, message: ALREADY_ENTERED },
      });
      setValue(`Block_Name_B${blockCount1}`, '');
    }
  }, [watchBlockName2]);

  //// mda ///
  /// mdaBlock  ////
  const [mdaCount, setMdaCount] = React.useState(0);
  const [mdaCount1, setMdaCount1] = React.useState(0);
  const mdaWatchBlock = watch(`block_name_B${count1 + 1}`);
  const mdaWatchCoveredPopulation = watch(`covered_population_B${count1}`);
  const mdaWatchTotalPopulation = watch(`total_population_B${count1}`);
  const res = parseInt(mdaCount, 10) + parseInt(mdaWatchCoveredPopulation, 10);
  const res1 = parseInt(mdaCount1, 10) + parseInt(mdaWatchTotalPopulation, 10);
  const WatchBseSentinal = watch(`bse_S1_B${count1}`);
  const WatchPositiveSentinal = watch(`positive_S1_B${count1}`);
  const WatchBseRandom = watch(`bse_R1_B${count1}`);
  const WatchPositiveRandom = watch(`positive_R1_B${count1}`);
  const fielswatxh = watch(`Mf_Rate_S2`);
  const dateCompleted = watch(`mda_date_completed`);
  useEffect(() => {
    for (let i = 1; i <= blackCount; i++) {
      setValue(`date_completed_B${i}`, dateCompleted);
    }
  }, [dateCompleted, count1, blackCount]);
  // useEffect(() => {
  const dateCompletedValues = [];
  for (let i = 1; i <= blackCount; i++) {
    const resDate = watch(`date_completed_B${i}`);
    dateCompletedValues.push(resDate);
  }
  const arr1 = [];
  const arr = [];
  const [populationValidation, setPopulationValidation] = React.useState(false);
  const coveredpopulation = watch('covered_population');
  const TotalPopulation = watch('total_population1');
  useEffect(() => {
    if (parseInt(coveredpopulation, 10) > parseInt(TotalPopulation, 10)) {
      dispatch({
        type: IDA_STATUS,
        payload: { type: ERROR, message: CONDITIONAL },
      });
      setPopulationValidation(true);
    } else {
      setPopulationValidation(false);
    }
  }, [coveredpopulation, TotalPopulation]);

  

  useEffect(() => {
    if (mdaWatchCoveredPopulation !== undefined) {
      for (let i = 1; i <= count1; i++) {
        arr.slice(0, 3);
        const mdaWatchCoveredPopulationres = watch(`covered_population_B${i}`);
        arr.push(parseInt(mdaWatchCoveredPopulationres, 10));
        const sum = arr.reduce(function (a, b) {
          return a + b;
        }, 0);
        if (strategy !== `District Strategy`) {
          // entries[6].value = _.assign(sum);
          if (sum > 0) {
            setValue('covered_population', sum);
          }
        }
        // setValue('total_population1', res1 >= 0 ? res1 : mdaCount1);
      }
    }

    const mdaWatchTotalPopulationRes123 = watch(`total_population_B1`);

    if (mdaWatchTotalPopulation !== undefined) {
      for (let i = 1; i <= count1; i++) {
        const mdaWatchTotalPopulationRes = watch(`total_population_B${i}`);
        arr1.push(parseInt(mdaWatchTotalPopulationRes, 10));
        const sum1 = arr1.reduce(function (a, b) {
          return a + b;
        }, 0);

        if (strategy !== `District Strategy`) {
          if (sum1 > 0) {
            setValue('total_population1', sum1);
          }
          // entries[7].value = _.assign(sum1);
        }
        // setValue('total_population1', res1 >= 0 ? res1 : mdaCount1);
      }
    }
  }, [mdaWatchCoveredPopulation, mdaWatchTotalPopulation]);

  // }, [mdaWatchCoveredPopulation, mdaWatchTotalPopulation, setValue]);
  React.useEffect(() => {
    if (mdaWatchBlock) {
      setCount1(count1 + 1);
      setMdaCount(mdaWatchCoveredPopulation);
      setMdaCount1(mdaWatchTotalPopulation);
    }
  }, [mdaWatchBlock]);
  const finalValueSentinal = (WatchPositiveSentinal / WatchBseSentinal) * 100;
  const finalValueRandom = (WatchPositiveRandom / WatchBseRandom) * 100;
  React.useEffect(() => {
    if (BlockActivity === 'MDA') {
      // const val1 = count1 * 16 + 3;
      // const val = count1 * 16 + 7;
      // entries[val1].value = _.assign(finalValueSentinal);
      // entries[val].value = _.assign(finalValueRandom);
      setValue(`mf_rate_S1_B${count1}`, finalValueSentinal >= 0 ? finalValueSentinal : '');
      setValue(`mf_rate_R1_B${count1}`, finalValueRandom >= 0 ? finalValueRandom : '');
    }
  }, [WatchPositiveSentinal, WatchBseRandom, WatchBseSentinal, WatchPositiveRandom]);
  const [test, setTest] = React.useState('');
  const testData = watch('block_name_S1');
  ///mdaDistrict///
  const [pase, setPase] = React.useState(0);
  const mdabBseS = watch(`bse_S${sentinal}`);
  const mdaPositiveS = watch(`positive_S${sentinal}`);
  const mdaPositiveS111 = watch(`positive_S${sentinal + 1}`);
  const mdabBseR = watch(`bse_R${random}`);
  const mdaPositiveR = watch(`positive_R${random}`);
  // console.log('changedValues', mdaPositiveS,  mdabBseS)
  const sentinalPopulation = (mdaPositiveS / mdabBseS) * 100;
  const randomPopulation = (mdaPositiveR / mdabBseR) * 100;
  const blockS1 = watch(`block_name_S${sentinal - 1}`);
  const Rblock = watch(`block_name_R${random}`);
  const block = watch(`block_name_S${sentinal}`);
  const blockR1 = watch(`block_name_R${random - 1}`);
  const blockS = watch(`block_name_S${sentinal + 1}`);
  const blockR = watch(`block_name_R${random + 1}`);
  const BlockDuplicate = sentinalValue.includes(block);
  // console.log('sentinal', sentinal, random);
  useEffect(() => {
    if (blockS !== '') {
      setSentinalValue([...sentinalValue, blockS1]);
    }
  }, [sentinal]);
  useEffect(() => {
    setPase(pase + 1);
    if (pase > 3) {
      if (BlockDuplicate) {
        // setValue(`block_name_S${sentinal}`, '');
        dispatch({
          type: IDA_STATUS,
          payload: { type: ERROR, message: ALREADY_ENTERED },
        });
      }
    }
  }, [blockS1, BlockDuplicate]);

  useEffect(() => {
    // console.log(entries, 'entriess');
  }, [entries]);
  /**
   * @param {*} e - event on onChange text value
   * @param {number} index - entry index
   * @param {object} keyValue - entry object
   * @param pattern
   * @param message
   */
  const onChangeValue = (e, index, keyValue, pattern) => {
    // console.log("test");
    if (keyValue.value !== undefined) {
      entries[index].value = e.target.value;
    }
    const patternReg = pattern && !pattern.test(e.target.value);
    setError({ ...error, [`err_${index}`]: patternReg, [`errmsg_${index}`]: keyValue.helperText });
  };
  /////////
  const RandomDuplicate = sentinalValue.includes(Rblock);
  useEffect(() => {
    if (blockR !== '') {
      setRandomValues([...sentinalValue, blockR1]);
    }
  }, [random]);
  useEffect(() => {
    if (RandomDuplicate) {
      // setValue(`block_name_R${random}`, '');
      dispatch({
        type: IDA_STATUS,
        payload: { type: ERROR, message: ALREADY_ENTERED },
      });
    }
  }, [Rblock]);

  useEffect(() => {
    setTest(sentinalPopulation);
  }, [sentinalPopulation]);
  useEffect(() => {
    setTest('');
  }, [countS]);
  const mdaDatePlaned = watch(`mda_date_completed`);
  useEffect(() => {
    // if (BlockActivity === 'MDA') {
    //   if (sentinalPopulation > 0) {
    //     setConstValue(sentinalPopulation);
    //   }

    //   if (sentinalPopulation > 0) {
    //     entries[sentinal * 5 + 12].value = test >= 0 ? test : '';
    //   }

    //   // setValue(`MF_Rate_S${sentinal}`, sentinalPopulation >= 0 ? sentinalPopulation : '');
    //   // setValue(`MF_Rate_R${random}`, randomPopulation >= 0 ? randomPopulation : '');
    // }
    for (let i = 1; i <= countS; i++) {
      const mdaPositiveS1 = entries[i * 5 + 11].value;
      const mdabBseS1 = entries[i * 5 + 10].value;
      const sentinalPopulation1 = (parseInt(mdaPositiveS1, 10) / parseInt(mdabBseS1, 10)) * 100;

      entries[i * 5 + 12].value = sentinalPopulation1.toString() >= 0 ? sentinalPopulation1.toString() : '';
      setValue(`MF_Rate_S${i}`, sentinalPopulation1 >= 0 ? sentinalPopulation1 : '');
    }
  }, [mdaPositiveS, mdabBseS, sentinalPopulation, test]);
  useEffect(() => {
    // if (BlockActivity === 'MDA') {
    //   if (randomPopulation >= 0) {
    //     setConst1Value(randomPopulation);
    //   }
    //   if (randomPopulation >= 0) {
    //     const InsertPos = entries.findIndex((p) => p.entryName === 'block_name_R1');
    //     const values = InsertPos - 1;
    //     const value = _.assign(randomPopulation > 0 ? randomPopulation : '');
    //     entries[random * 5 + values].value = randomPopulation >= 0 ? randomPopulation : '';
    //     setValue(`MF_Rate_R${random}`, randomPopulation >= 0 ? randomPopulation : '');
    //   }
      for (let i = 1; i <= countR; i++) {
        const InsertPos = entries.findIndex((p) => p.entryName === 'block_name_R1');
        const values = InsertPos - 1;
        const mdabBseR1 = watch(`bse_R${i}`);
        const mdaPositiveR1 = watch(`positive_R${i}`);
        const randomPopulation1 = mdaPositiveR1 === "" ? -1 :(mdaPositiveR1 / mdabBseR1) * 100;
        if(mdabBseR1){
          entries[random * 5 + values].value = randomPopulation1.toString() >= 0 ? randomPopulation1.toString() : '';
          setValue(`MF_Rate_R${random}`, randomPopulation1 >= 0 ? randomPopulation1 : '');
        }
      }
  }, [random, randomPopulation]);

  // useEffect(() => {
  //   console.log('sentinal', sentinal);
  //   if (blockS !== undefined) {
  //     if (blockS !== '') {
  //       setSentinal(sentinal + 1);
  //     }
  //   }
  // }, [blockS]);
  // useEffect(() => {
  //   console.log('sentinal1', random);

  //   if (blockR !== undefined) {
  //     if (block !== '') {
  //       setRandom(random + 1);
  //     }
  //   }
  // }, [blockR]);

  // const arr = [];
  React.useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);
 
  /**
   * @param {*} data data which we get onSubmit
   */
  function onSubmit(data) {
    const customData = data;

    onReceiveData(customData);
    reset(defaultValues);
  }

  /**
   *
   * @param {*} e - event on onChange function
   * @param {*} entryName - entry name of form entries
   */
  const onChangeHandle = (e, entryName) => {
    // console.log("test2");
    
    // getSelectedDropdown(e.target.value, entryName, { ...dropdownKeyPairs, [entryName]: e.target.value });
    setDropdownKeyPairs({ ...dropdownKeyPairs, [entryName]: e.target.value });
    // defaultValues[entryName] = e.target.value;
  };
  /**
   * @function handleCancel - function on cancel
   */
  const handleCancel = () => {
    reset({});
    setSentinalValue([]);
  };

  /**
   *
   * @param {string} pattern - passing pattern
   * @returns {string} - returns the pattern regex
   */
  const patternValidation = (pattern) => {
    switch (pattern) {
      case 'email':
        return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      case 'number':
        return /^([0-9][0-9]{9,14})$/;
      case 'text':
        return /^[a-zA-Z ]*$/;
      case 'address':
        return /^.{1,11}$/;
      case 'date':
        return /^(([1-9]|0[1-9]|1[0-9]|2[1-9]|3[0-1])[-]([JAN|FEB|MAR|APR|MAY|JUN|JULY|AUG|SEP|OCT|NOV|DEC])[-](d{4}))$/;
      default:
        return null;
    }
  };

  const getCountValue = (a, b) => {
    // console.log('calcValue', a, b);
    const arrIndex = [];
    entries?.map((value, index) => {
      if (value.divider === true) {
        arrIndex.push(index);
      }
    });
    const found = arrIndex.find((element) => element > b);
    const randomRowCount = found - b;
    const finalValue = (randomRowCount - 4) / 4;
    const lastVal = entries.length - b;

    if (finalValue > 0) {
      return finalValue;
    }
    return (lastVal - 4) / 4;
  };

  return (
    <Grid container md={12} className="activityFormat">
      {dataInput && (
        <Grid container>
          <Grid item md={11}>
            <FormTitle labelText="Activity Update" />
          </Grid>

          {/* <Grid className="downloadFormat" item md={1}>
            <FileFormat />
          </Grid> */}
        </Grid>
      )}

      <Grid sx={{ display: 'flex', width: '100%' }}>
        <Grid
          className="root"
          container
          item
          md={separator ? (countRange.length === 1 ? 11 : 10) : 12}
          sm={12}
          xs={12}
          rowSpacing={2}
          columnSpacing={3}
        >
          {entries &&
            entries.map((keyValue, index) => {
              const key = index;
              const rendererName = keyValue?.entryName;
              const labelName = keyValue.placeholder;
              const regPattern = patternValidation(keyValue.pattern);
              return (
                <Grid
                  key={key}
                  item
                  container
                  sm={(keyValue.fullView && 12) || (keyValue.isCombined ? 6 : 12)}
                  xs={(keyValue.fullView && 12) || (keyValue.isCombined ? 6 : 12)}
                  md={
                    (keyValue.fullView && 12) ||
                    (keyValue.isCombined && 2) ||
                    (keyValue.grouped && 1) ||
                    (keyValue.default && 4) ||
                    (keyValue.count && 3) ||
                    (keyValue.criteria && 6)
                  }
                  className={(keyValue.hide && 'hide') || (keyValue.rowCount && 'relative-grid nopadding')}
                >
                  <Grid container item md={12}>
                    {keyValue.hide
                      ? null
                      : keyValue.formTitle && (
                          <Grid item md={keyValue.formTitle && 12} container>
                            <Grid item md={11}>
                              <FormTitle labelText={`${keyValue.labelText}`} />
                            </Grid>
                          </Grid>
                        )}
                  </Grid>
                  <Grid container item md={12} className={keyValue.rowCount && 'moveRight'}>
                    <Controller
                      control={control}
                      // name={keyValue?.entryName || ''}
                      rules={{
                        // regex: keyValue.validation?.[0]?.isRequired || keyValue.validation?.isRequired,

                        required: keyValue.validation?.[0]?.isRequired || keyValue.validation?.isRequired,
                        min: keyValue.validation?.[1]?.min || keyValue.validation?.min,
                        pattern: keyValue.pattern,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        
                        <Grid item md={12} className="space">
                        {console.log(value,"Vvaluevalue")}

                          {keyValue.textField && (
                            <Grid sx={{ width: '100%' }} className="relative">
                              <TextField
                                label={`${keyValue.placeholder}`}
                                placeholder={`${keyValue.placeholder}`}
                                value={keyValue.value !== undefined ? keyValue.value : value}
                                onChange={(e, pattern) => {
                                  // console.log('textInput', keyValue);
                                  onChange(e);
                                  onChangeValue(e, index, keyValue, pattern);
                                }}
                                onFocus={()=>{
                                  // console.log('focus', keyValue);
                                  if(keyValue?.onFocusSetDefault){
                                    if(keyValue?.sentinal){
                                      setSentinal(keyValue.defaultValue);
                                    }
                                    if(keyValue?.random){
                                      setRandom(keyValue.defaultValue);
                                    }
                                  }
                                }}
                                onBlur={onBlur}
                                type={keyValue.textInputType}
                                disabled={keyValue.disabled}
                                maxLength={keyValue.validation.maxLength}
                                numberOfLines={keyValue.numberOfLines}
                                multiline={keyValue.multiline}
                                pattern={keyValue.pattern}
                                // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0')"
                                error={error?.[`err_${index}`] || false}
                                helperText={error?.[`err_${index}`] ? error?.[`errmsg_${index}`] : ''}
                              />

                              {keyValue.remove && (
                                <div className="remove-icon">
                                  <img
                                    src={CrossIcon}
                                    alt="x"
                                    className="remove-img"
                                    onClick={(e) => removeItem(index, e, keyValue)}
                                  />
                                </div>
                              )}
                            </Grid>
                          )}
                          {keyValue.dropdown && (
                            <Grid item container md={12}>
                              <Dropdown
                                handleChange={async (e) => {
                                  await onChangeHandle(e, keyValue.entryName);
                                  getSelectedDropdown(e.target.value, keyValue.entryName, {
                                    ...dropdownKeyPairs,
                                    [keyValue.entryName]: e.target.value,
                                  });
                                  onChange(e);
                                }}
                                value={value || ''}
                                data={keyValue.data}
                                label={`${keyValue.placeholder}`}
                                disabled={keyValue.disabled}
                                returnValue={keyValue.returnValue === true}
                              />
                            </Grid>
                          )}
                          {keyValue.ComboBox && (
                            <Grid item container md={12}>
                              <ComboBox
                                onFocus={()=>{
                                  // console.log('focus', keyValue)
                                  if(keyValue?.onFocusSetDefault){
                                    if(keyValue?.sentinal){
                                      setSentinal(keyValue.defaultValue);
                                    }
                                    if(keyValue?.random){
                                      setRandom(keyValue.defaultValue);
                                    }
                                  }
                                }}
                                onBlur={onBlur}
                                datas={keyValue.data}
                                label={keyValue.placeholder}
                                value={keyValue.value !== undefined ? keyValue.value : value}
                                onChangeAutocomplete={(e, pattern) => {
                                  // console.log('combobox')
                                  onChange(e);
                                  onChangeValue(e, index, keyValue, pattern);
                                }}
                              />
                            </Grid>
                          )} 
                          {keyValue.date && (
                            <Grid className='date_grid'>
                                {console.log(value,"valuevalue")}
                              <DatePicker
                              className="date_mm"
                                disabled={keyValue.disabled}
                                label={`${keyValue.placeholder}`}
                                onHandleChange={(selectedDate, pattern) => {
                                  console.log(selectedDate,(moment(selectedDate).format('YYYY-MM-DD')),"selectedDate");
                                  onChange(moment(selectedDate).format('YYYY-MM-DD'));
                                }}
                                date={new Date(value)}
                                
                                minDate={new Date('1920/12/20')}
                                maxDate={
                                  keyValue.entryName === 'from_date'
                                    ? watchFields[1]
                                    : keyValue.entryName === 'to_date' && new Date('2100/12/20')
                                }
                              />
                            </Grid>
                          )}
                          {keyValue.radioButton && (
                            <Grid sx={{ pl: 1 }}>
                              <RadioButton
                                radioData={keyValue.radioData}
                                selectValue={value}
                                labelText={`${keyValue.labelText}`}
                                handleChange={onChange}
                              />
                            </Grid>
                          )}
                          {keyValue.CountRange && (
                            <Grid item container md={12} className={keyValue.disabled && 'disable'}>
                              <CountRange
                                Block
                                labelText={keyValue.labelText}
                                PCount={(count) =>
                                  plus(
                                    count,
                                    keyValue,
                                    index,
                                    setDASentinalValues([...DASentinalValue, IDAChangeValueS]),
                                  )
                                }
                                NCount={(count) => minus(count, keyValue, index)}
                                initialCount={keyValue.initialCount}
                                defaultCount={keyValue.defaultCount}
                                strategy={strategy}
                              />
                            </Grid>
                          )}
                          {keyValue.CountRangePreTas && (
                            <Grid item container md={12} className={keyValue.disabled && 'disable'}>
                              <CountRangePreTas
                                Block
                                labelText={keyValue.labelText}
                                value={getCountValue(keyValue, index)}
                                PCount={() =>
                                  plus(
                                    getCountValue(keyValue, index),
                                    keyValue,
                                    index,
                                    setRandomEnteredValues([...randomEnteredValues, watchFieldsRandom]),
                                  )
                                }
                                NCount={() => minus(getCountValue(keyValue, index), keyValue, index)}
                                initialCount={keyValue.initialCount}
                                defaultCount={keyValue.defaultCount}
                              />
                            </Grid>
                          )}
                          {keyValue.verticalDivider && <Grid className="verticalDivider" />}
                          {keyValue.divider && <Grid className="dividerStyle" />}
                          {keyValue.isEmptySpace && <Grid item md={keyValue.breakpoint} />}
                        </Grid>
                      )}
                      name={keyValue?.entryName || ''}
                    />
                    {/* {// Check once and remove this code....!!!!} */}
                    {/* {errors[rendererName] && (
                      <Typography
                        colorType="senary"
                        text={`${rendererName} is required`}
                        customStyle={{ fontSize: '12px', pl: 2, color: 'red' }}
                      />
                    )} */}
                  </Grid>
                  {errors[rendererName] && labelName && errors[rendererName]?.type === 'required' && (
                    <Typography colorType="error" text={`${labelName} ${'is required'}`} type="link" />
                  )}
                  {errors[rendererName] && labelName && errors[rendererName]?.type === 'min' && (
                    <Typography colorType="error" text={`${labelName} ${'should be greater than 1'}`} type="link" />
                  )}
                </Grid>
              );
            })}
        </Grid>
        <Grid>
          {separator && (
            <Grid container item columnSpacing={2} className={countRange.length !== 1 && 'countRange'}>
              {countRange &&
                countRange.map((item, index) => {
                  return (
                    <Grid
                      direction="row"
                      item
                      md={5}
                      className={index === 1 && item.labelText === 'Block Count' && item.disabled && 'disable'}
                    >
                      <CountRange
                        labelText={item.labelText}
                        PCount={(count) =>
                          plus(
                            count,
                            item,
                            setConst1([...const1, watchFieldsBlock]),
                            setRandomEnteredValues([...randomEnteredValues, watchFieldsRandom]),
                            setDASentinalValues([...DASentinalValue, IDAChangeValueS]),
                          )
                        }
                        NCount={(count) => minus(count, item)}
                        initialCount={item.initialCount}
                        defaultCount={item.defaultCount}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        sx={{ justifyContent: { xs: 'space-evenly', sm: 'space-evenly', md: 'flex-end' } }}
        // justifyContent="flex-end"
        style={{ paddingTop: 25 }}
        columnGap={2}
      >
        {populationValidation && (
          <div style={{ color: 'red', position: 'relative' }}>
            Covered Population should be less then Total Population
          </div>
        )}
        <Grid item lg={1} md={1} sm={1} xs={1}>
          <Button
            disabled={populationValidation}
            variants="contained"
            btnTitle={SubmitBtnTitle}
            ellipse
            color="info"
            onClickHandle={handleSubmit(onSubmit)}
            customClass="buttonWidth"
          />
        </Grid>
        {!masterSetup && View === 1 && (
          <Grid item lg={1} md={1} sm={1} xs={1}>
            <Button
              variants="contained"
              btnTitle="View"
              ellipse
              color="quaternary"
              customClass="buttonWidth"
              onClickHandle={onViewHandle}
            />
          </Grid>
        )}
        <Grid item lg={1} md={1} sm={1} xs={1}>
          <Button
            variants="contained"
            btnTitle="Cancel"
            ellipse
            color="disable"
            customClass="buttonWidth"
            onClickHandle={() => {
              onCancelAction();
              handleCancel();
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});
export default CustomForm;
CustomForm.propTypes = {
  defaultValues: PropTypes.objectOf().isRequired,
  entries: PropTypes.arrayOf({}).isRequired,
  onReceiveData: PropTypes.func,
  onViewHandle: PropTypes.func,
  onCancelAction: PropTypes.func,
  masterSetup: PropTypes.bool,
  dataInput: PropTypes.bool,
  getSelectedDropdown: PropTypes.func,
  countRange: PropTypes.arrayOf(),
  separator: PropTypes.bool,
  floatingEnd: PropTypes.bool,
  SubmitBtnTitle: PropTypes.string,
  // counts: PropTypes.func,
  plus: PropTypes.func,
  minus: PropTypes.func,
  removeItem: PropTypes.func,
  Block: PropTypes.bool,
  blackCount: PropTypes.number,
  randomCount: PropTypes.number,
};
CustomForm.defaultProps = {
  onViewHandle: () => null,
  onCancelAction: () => null,
  onReceiveData: () => null,
  plus: () => null,
  // counts: () => null,
  minus: () => null,
  masterSetup: false,
  dataInput: false,
  getSelectedDropdown: () => {},
  countRange: [],
  separator: false,
  floatingEnd: false,
  Block: true,
  SubmitBtnTitle: 'Submit',
  removeItem: () => null,
  blackCount: 1,
  randomCount: 1,
};
