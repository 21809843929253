const MasterData = [
  {
    id: 1,
    menuText: 'User Role ',
    nav: '/master-setup/user-role',
  },
  {
    id: 2,
    menuText: 'New User ',
    nav: '/master-setup/new-user',
  },
  {
    id: 3,
    menuText: 'Program Activity',
    nav: '/master-setup/program-activity',
  },
  {
    id: 4,
    menuText: 'Program Status',
    nav: '/master-setup/program-status',
  },
  {
    id: 5,
    menuText: 'Threshold Config',
    nav: '/master-setup/threshold',
  },
  {
    id: 6,
    menuText: 'Geography ',
    nav: '/master-setup/geography',
  },
  {
    id: 7,
    menuText: 'Formula Config',
    nav: '/master-setup/formula',
  },
  {
    id: 8,
    menuText: 'Demographic ',
    nav: '/master-setup/demographic',
  },
  {
    id: 9,
    menuText: 'Drug Type ',
    nav: '/master-setup/drug-type',
  },
];
export default MasterData;
