import { Grid } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'loadsh';
import PropTypes from 'prop-types';
import CustomForm from '../../../../components/organisms/CustomForm';
import { formEntries, formDefaultValues } from './formEntries-Drug';
import './Drug-Form.css';
import { INSERT_DRUG, GET_STATE_DROPDOWN, GET_DISTRICT_DROPDOWN } from '../../../../constants/actionType';
/**
 * @param {object} props - required props in drug form input
 * @returns {React.ReactComponent} BuyForm
 */
const DrugFormInput = (props) => {
  const { onViewHandle } = props;
  const dispatch = useDispatch();
  const dropdownList = useSelector((state) => state.dropDownReducer);
  /**
   * @param {*} data - passing data
   */
  const onSubmitData = (data) => {
    // console.log(data,"drugData");
    
    dispatch({ type: INSERT_DRUG, payload: data });
  };
  const getEntries = React.useMemo(() => {
    return formEntries.map((value) => {
      if (value.entryName === 'state') {
        value.data = _.assign(dropdownList.stateData);
      }
      if (value.entryName === 'district') {
        if (dropdownList.districtData) value.disabled = false;
        value.data = _.assign(dropdownList.districtData);
      }

      return value;
    });
  }, [dropdownList]);
  React.useEffect(() => {
    dispatch({ type: GET_STATE_DROPDOWN });
  }, [dispatch]);

  /**
   * @param {number} data - data
   * @param {string} name - name
   * @function getSelectedDropdown
   */
  const getSelectedDropdown = React.useCallback(
    (data, name) => {
      if (name === 'state') {
        const param = { state: data };
        dispatch({ type: GET_DISTRICT_DROPDOWN, payload: param });
      }
    },
    [dispatch],
  );

  return (
    <Grid container item md={12}>
      <Grid item md={12} className="formContainer">
        <CustomForm
          entries={getEntries}
          onViewHandle={onViewHandle}
          defaultValues={formDefaultValues}
          onReceiveData={onSubmitData}
          dataInput
          getSelectedDropdown={(data, name) => getSelectedDropdown(data, name)}
          screen="input"
        />
      </Grid>
    </Grid>
  );
};
export default DrugFormInput;
DrugFormInput.propTypes = {
  onViewHandle: PropTypes.func,
};
DrugFormInput.defaultProps = {
  onViewHandle: () => {},
};
