/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import ActivityCalendar from '../ActivityCalendar';
import Legends from '../ActivityCalendar/Legends';
import './Schedule.css';
import CommonTitledTable from '../CommonTitledTable';
import FileGenerator from '../FileGenerator';

/**
 * @name Schedule
 * @param {object} props required props
 * @returns {React.ReactElement} - Schedule
 */
const Schedule = (props) => {
  const [showTable, setShowTable] = useState(null);
  const {
    tableContent,
    tableHeader,
    tableSortList,
    legends,
    calendarHeader,
    calendarData,
    DropdownList,
    getSelectValue,
    onCalenderClick,
    getTableSelectValue,
    districtDropdown,
    getDistrictList,
    ChartDropdownSelectedValues,
    TableDropdownSelectedValues,
  } = props;

  return (
    <div className="activity-section">
      <Grid container xs={12} lg={12} md={12} direction="row" justifyContent="space-between">
        <Grid item xs={8} lg={8} md={8}>
          <FileGenerator
            DropdownList={DropdownList}
            DropdownSelectedValues={ChartDropdownSelectedValues}
            showExporter={false}
            getSelectValue={(obj) => {
              getSelectValue(obj, 'activity');
              setShowTable(null);
            }}
            districtDropdown={districtDropdown}
            getDistrictList={getDistrictList}
            fileData={tableContent}
          />
        </Grid>
        <Grid item xs={4} lg={4} md={4}>
          <Legends legend={legends} />
        </Grid>
      </Grid>

      <Grid sx={{ paddingTop: 0 }} className="fixed-activity-height">
        <ActivityCalendar
          heading={calendarHeader}
          data={calendarData}
          onCalenderClick={(state) => {
            onCalenderClick(state, 'activityCalendarClick');
            setShowTable(state);
          }}
        />
      </Grid>
      {showTable && (
        <Grid className="tableView">
          <CommonTitledTable
            tableSortList={tableSortList}
            tableContent={tableContent}
            tableHeader={tableHeader}
            TableDropdownSelectedValues={TableDropdownSelectedValues}
            getSelectValue={(obj) => getTableSelectValue(obj, 'activityTable')}
          />
        </Grid>
      )}
    </div>
  );
};

export default Schedule;
Schedule.propTypes = {
  tableContent: PropTypes.array,
  tableHeader: PropTypes.array,
  tableSortList: PropTypes.array,
  legends: PropTypes.arrayOf.isRequired,
  calendarHeader: PropTypes.arrayOf.isRequired,
  calendarData: PropTypes.arrayOf.isRequired,
  DropdownList: PropTypes.array,
  getSelectValue: PropTypes.func,
  getTableSelectValue: PropTypes.func,
  onCalenderClick: PropTypes.func,
  districtDropdown: PropTypes.objectOf(),
  getDistrictList: PropTypes.objectOf(),
  ChartDropdownSelectedValues: PropTypes.objectOf(),
  TableDropdownSelectedValues: PropTypes.objectOf(),
};
Schedule.defaultProps = {
  tableContent: null,
  tableHeader: null,
  tableSortList: null,
  DropdownList: null,
  getSelectValue: () => {},
  getTableSelectValue: () => {},
  onCalenderClick: () => {},
  districtDropdown: null,
  getDistrictList: () => {},
  ChartDropdownSelectedValues: {},
  TableDropdownSelectedValues: {},
};
