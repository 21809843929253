export const tabContent = [
  {
    label: 'Dashboard',
    content: '',
  },
  {
    label: 'Data Input',
    content: '',
  },
];

// National
export const nationalDashboardHeader = [
  {
    label: '',
    value: 'national',
  },
  {
    label: 'Country Profile',
    value: 'countryProfile',
  },
  {
    label: 'Progress Status',
    value: 'progressStatus',
  },
  {
    label: 'Activity Calendar',
    value: 'activityCalender',
  },
  {
    label: 'Pre-TAS/TAS Overview',
    value: 'preTasOverview',
  },
  {
    label: 'TAS Decision Matrix',
    value: 'tasDecisionMatrix',
  },
  {
    label: 'TAS Summary',
    value: 'tasSummary',
  },
  {
    label: 'MDA Coverage',
    value: 'mdaCoverage',
  },
  {
    label: 'Data Quality',
    value: 'data_quality',
  },
];

export const nationalDashboardGridConfig = [
  {
    screenName: { label: 'National DashBoard', value: 'national_dashboard' },
    country_profile: false,
    progress_status: false,
    activity_calender: false,
    pre_tas_overview: false,
    tas_decision_matrix: false,
    tas_summary: false,
    mda_coverage: false,
    data_quality: false,
  },
];

export const nationalSubDashboardHeader = [
  {
    label: '',
    value: 'national',
  },
  {
    label: 'Endemic District',
    value: 'endemicDistrict',
  },
  {
    label: 'DA',
    value: 'da',
  },
  {
    label: 'IDA',
    value: 'ia',
  },
  {
    label: 'Under TAS1',
    value: 'underTas1',
  },
  {
    label: 'Under TAS2',
    value: 'underTas2',
  },
  {
    label: 'Under TAS3',
    value: 'underTas3',
  },
  {
    label: 'TAS Cleared1',
    value: 'tasCleared1',
  },
  {
    label: 'Dossier Prep',
    value: 'dossierProp',
  },
];

export const nationalSubDashboardGridConfig = [
  {
    screenName: { label: 'Country Profile', value: 'country_profile', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
  {
    screenName: { label: 'Progress Status', value: 'progress_status', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
  {
    screenName: { label: 'Activity Calender', value: 'activity_calender', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
  {
    screenName: { label: 'Pre-TAS/TAS Overview', value: 'pre_tas_overview', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
  {
    screenName: { label: 'TAS Decision Matrix', value: 'tas_decision_matrix', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
  {
    screenName: { label: 'TAS Summary', value: 'tas_summary', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
  {
    screenName: { label: 'MDA Coverage', value: 'mda_coverage', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
  {
    screenName: { label: 'Data Quality', value: 'data_quality', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
];

// State

export const stateDashboardHeader = [
  {
    label: '',
    value: 'state',
  },
  {
    label: 'State Profile',
    value: 'state_profile',
  },
  {
    label: 'State Progress Status',
    value: 'state_progress_status',
  },
  {
    label: 'State Activity Calendar',
    value: 'state_activity_calender',
  },
  {
    label: 'State TAS Decision Matrix',
    value: 'state_tasDecisionMatrix',
  },
  {
    label: 'State District Profile Widget',
    value: 'state_districtProfileWidget',
  },
  {
    label: 'State District Profile',
    value: 'state_districtTasProfile',
  },
  {
    label: 'District Decision Matrix',
    value: 'state_districtDecisionMatrix',
  },
  {
    label: 'State Data Quality',
    value: 'state_dataQuality',
  },
];

export const stateDashboardGridConfig = [
  {
    screenName: { label: 'State DashBoard', value: 'state_dashboard', show: false },
    stateProfile: false,
    state_progress_status: false,
    state_activity_calender: false,
    state_tasDecisionMatrix: false,
    state_districtProfileWidget: false,
    state_districtTasProfile: false,
    state_districtDecisionMatrix: false,
    state_dataQuality: false,
  },
];

export const stateSubDashboardGridConfig = [
  {
    screenName: { label: 'State Profile', value: 'state_profile', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
  {
    screenName: { label: 'State Progress Status', value: 'state_progress_status', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
  {
    screenName: { label: 'State Activity Calender', value: 'state_activity_calender', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
  {
    screenName: { label: 'State TAS Decision Matrix', value: 'state_tasDecisionMatrix', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
  {
    screenName: { label: 'State District Profile Widget', value: 'state_districtProfileWidget', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
  {
    screenName: { label: 'State District Profile', value: 'state_districtTasProfile', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
  {
    screenName: { label: 'District Decision Matrix', value: 'state_districtDecisionMatrix', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
  {
    screenName: { label: 'State Data Quality', value: 'state_dataQuality', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
];
// Prediction

export const predictionDashboardHeader = [
  {
    label: '',
    value: 'prediction',
  },
  {
    label: 'Prediction Country Profile',
    value: 'prediction_countryProfile',
  },
  {
    label: 'Prediction Progress Status',
    value: 'prediction_expectedActivites',
  },
  {
    label: 'Prediction Activity Calendar',
    value: 'prediction_activityCalender',
  },
];

export const predictionDashboardGridConfig = [
  {
    screenName: { label: 'Prediction DashBoard', value: 'prediction_dashboard' },
    prediction_countryProfile: false,
    prediction_expectedActivites: false,
    prediction_activityCalender: false,
  },
];

export const predictionSubDashboardGridConfig = [
  {
    screenName: { label: 'Country Profile', value: 'prediction_countryProfile', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
  {
    screenName: { label: 'Expected Activities', value: 'prediction_expectedActivites', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
  {
    screenName: { label: 'Prediction Activity Calendar', value: 'prediction_activityCalender', show: false },
    endemicDistrict: false,
    da: false,
    ia: false,
    underTas1: false,
    underTas2: false,
    underTas3: false,
    tasCleared1: false,
    dossierProp: false,
  },
];

// Privilege Rights

export const privilegeRightsHeader = [
  {
    label: 'Screen Name',
    value: 'screenName',
  },
  {
    label: 'View',
    value: 'view',
  },
  {
    label: 'Download',
    value: 'download',
  },
  {
    label: 'Edit',
    value: 'edit',
  },
  {
    label: 'Manual Data Input',
    value: 'manualDataInput',
  },
  {
    label: 'Bulk Upload',
    value: 'bulkUpload',
  },
];
export const privilegeRightsGridConfig = [
  {
    screenName: { label: 'Data Input', value: 'data_input' },
    view: false,
    download: false,
    edit: false,
    manualDataInput: false,
    bulkUpload: false,
  },
  {
    screenName: { label: 'Output Dashboard', value: 'output_dashboard' },
    view: false,
    download: false,
    edit: false,
    manualDataInput: false,
    bulkUpload: false,
  },
  {
    screenName: { label: 'Master Setup', value: 'master_setup' },
    view: false,
    download: false,
    edit: false,
    manualDataInput: false,
    bulkUpload: false,
  },
  {
    screenName: { label: 'Send Mail', value: 'send_mail' },
    view: false,
    download: false,
    edit: false,
    manualDataInput: false,
    bulkUpload: false,
  },
];
