import { createTheme } from '@mui/material/styles';
import colors from '../../utils/colors';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto',
    h4: {
      fontSize: '1.5625rem', // 25px
    },
    h5: {
      fontSize: '1.25rem', // 20px
    },
    h6: {
      fontSize: '1.375rem', // 22px
    },
    subtitle1: {
      fontSize: '1.25rem', // 20px
    },
    subtitle2: {
      fontSize: '1rem', // 16px
    },
    body1: {
      fontSize: '0.875rem', // 14px
    },
    body2: {
      fontSize: '0.75rem', // 12px
    },
  },
  palette: {
    primary: {
      main: colors.blue.secondary,
      contrastText: colors.white.primary,
    },
    secondary: {
      main: colors.gray.quaternary,
    },
    tertiary: {  
      main: colors.white.primary,
    },
    quaternary: {
      main: colors.green.septenary,
    },
    quinary: {
      main: colors.green.octonary,
    },
    senary: {
      main: colors.violet.primary,
    },
    warning: {
      main: colors.orange.secondary,
    },
    info: {
      main: colors.blue.nonary,
    },
    success: {
      main: colors.green.senary,
    },
    disable: {
      main: colors.gray.quinary,
    },
    error: {
      main: colors.pink.tertiary,
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.7rem',
          fontFamily: 'Roboto_medium',
          textTransform: 'none',
          padding: '5px 4px',
          flexWrap: 'nowrap',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto_medium',
          lineHeight: 1.25,
        },
        caption: {},
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: colors.white.primary,
          width: 400,
          padding: 0,
          borderRadius: 8,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          padding: 10,
        },
        root: {
          fontSize: 14,
          color: colors.gray.septenary,
        },
        icon: {
          color: colors.gray.septenary,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: 12,
          fontsize: 15,
          color: colors.gray.tertiary,
          // border: '1px solid #aeafaf !important',
        },
        root: {
          fontSize: '15px !important',
          ':focus-visible': {
            outline: 'none !important',
          },
          ':hover': {
            outline: 'none !important',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          border: '1px solid whites !important',
          fontSize: 12,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontFamily: 'Roboto_medium',
          top: -4,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: '0px 3px 0px 11px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto_medium !important',
          '&:hover': {
            backgroundColor: 'white !important',
          },
          // background: 'white !important',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: 'Roboto_medium !important',
          fontSize: 14,
        },
      },
    },

    MuiListItemButton: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto_medium !important',
          color: 'red',
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          color: colors.gray.septenary,
        },
      },
    },
  },
});
export default theme;
