import axios from 'axios';
import BaseUrl from '../../config/config';
import { getProgramStatus, insertProgramStatus, updateProgramStatus } from '../../utils/api';

const userAurhId = localStorage.getItem('UserLoginId');
/**
 * @name getProgramStatusRequest function which make api request to get program activities
 * @returns {*} returns axios response data
 */
export const getProgramStatusRequest = () => {
  return axios.get(`${BaseUrl}/${getProgramStatus}`);
};

/**
 * @param {object} data payload data
 * @name updateProgramStatusRequest function which make api request to update program activities
 * @returns {*} returns axios response data
 */
export const updateProgramStatusRequest = (data) => {
  return axios({
    method: 'put',
    url: `${BaseUrl}/${updateProgramStatus}`,
    data: {
      program_activities_id: data.data.Program_Activity_Type,
      program_status: data.data.ProgramStatus,
      criteria: data.data.Criteria,
      date_status: data.data.DataStatus,
      from_date: data.data.FromDate,
      to_date: data.data.ToDate,
      status: data.data.Certificate === 'Active' ? 1 : 0,
      old_program_status: data.data.old_program_status,
      old_date_status: data.data.old_date_status,
      program_status_id: data.id,
      updated_by: userAurhId && userAurhId,
    },
  });
};

/**
 * @param {object} data payload data
 * @name insertProgramStatusRequest function which make api request to add program activity
 * @returns {*} returns axios response data
 */
export const insertProgramStatusRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertProgramStatus}`,
    data: {
      program_activities_id: data.Program_Activity_Type,
      program_status: data.ProgramStatus,
      criteria: data.Criteria,
      result_for_next_year: data.Result_For_Next_Year,
      date_status: data.DataStatus,
      from_date: data.FromDate,
      to_date: data.ToDate,
      status: data.Certificate === 'Active' ? 1 : 0,
      created_by: userAurhId && userAurhId,
    },
  });
};
