/* eslint-disable require-jsdoc */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Divider } from '@mui/material';
import Typography from '../../Typography';
import './TooltipContent.css';
/**
 *
 * @param {object} props - required props in tooltip title
 * @returns {React.ReactElement} - returns the tooltip title content
 */
const TooltipTitle = (props) => {
  const { contentTitle, districts, singleRow } = props;
  return (
    <Paper elevation={2}>
      <Grid className="content">
        <Grid>
          <Typography type="SubHeading" text={contentTitle} customClass="title" />
        </Grid>
        <Divider />

        <Grid style={{ overflowWrap: 'break-word' }}>
          {singleRow ? (
            <Typography type="title" text={districts?.join()?.replace(',', ', ') || ''} customClass="paddingTop" />
          ) : (
            districts?.map((item) => {
              return <Typography type="title" text={item} customClass="paddingTop" />;
            })
          )}
        </Grid>

        <Grid />
      </Grid>
    </Paper>
  );
};

export default TooltipTitle;
TooltipTitle.propTypes = {
  contentTitle: PropTypes.string,
  districts: PropTypes.arrayOf(PropTypes.objectOf),
  singleRow: PropTypes.bool,
};
TooltipTitle.defaultProps = {
  contentTitle: '',
  districts: [],
  singleRow: false,
};
