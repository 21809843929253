/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
/// //**************Dashboard******///////////////

export const getCountryProfile = 'getCountryProfile';
export const getNationalProgressStatus = 'getNationalProgressStatus';
export const getCountryProfileMapAfterHyper = 'getCountryProfileMapAfterHyper';

export const getNationalStatesDashboard = 'getNationalStatesDashboard';
export const getNationalStatesDashboardNewAfterHyper = 'getNationalStatesDashboardNewAfterHyper';
export const getNationalProgressStatusGraphAfterHyper = 'getNationalProgressStatusGraphAfterHyper';
export const getActivityCalendar = 'getActivityCalendar';
export const getActivityCalendarTable = 'getNationalStateActivityCalenderTable';


export const getDistrictProfileWidgetGraph = 'getDistrictProfileWidgetGraph';
export const getCountryProfileChart = 'getCountryProfileChart';
export const getDistrictMovedtoTASvsTotalEndemicDistrict = 'getDistrictMovedtoTASvsTotalEndemicDistrict';
export const getTASsummary = 'getTASsummary';

export const getPredictionActivityCalendar = 'getPredictionActivityCalendar';
export const getPredictionActivityCalendarTable = 'getPredictionActivityCalendarTable';
export const getPredictionCountryProfile = 'getPredictionCountryProfile';
export const getExpectedActivites = 'getExpectedActivites';
export const getDistrictDecisionMatrix = 'getDistrictDecisionMatrix';
export const getStateProfileChart = 'getStateProfileChart';

export const getPredictionCountryProfileMap = 'getPredictionCountryProfileMap';
export const getPredictionDashboard = 'getPredictionDashboardFinal';
export const getExpectedActivitesGraph = 'getExpectedActivitesGraph';
export const getStateDataQuality = 'getStateDataQuality';
export const getActivitesLegends = 'getActivitesLegends';
export const getDistrictMDABlocks = 'getDistrictMDABlocks';

/// //**************Data Input******///////////////
/// //******* Dossier *////////
export const getDossier = 'getDossier';
export const insertDossier = 'insertDossier';
export const updateDossier = 'updateDossier';  
// export const bulkUploadDossier = 'bulkUploadDossier';
export const bulkInsertDossier = 'bulkInsertDossier';



// ********** Lymphedema ****** //
export const createLymphedemaHydrocele = 'createLymphedemaHydrocele';
export const updateLymphedemaHydrocele = 'updateLymphedemaHydrocele';
export const bulkInsertLymphedemaHydrocele = 'bulkInsertLymphedemaHydrocele';
export const getLymphedemaHydrocele = 'getLymphedemaHydrocele';


// ///******************User Master ************//////

export const getProgramActivity = 'getProgramActivites';
export const insertProgramActivites = 'insertProgramActivites';
export const updateProgramActivites = 'updateProgramActivites';

// newUser

export const getUserMaster = 'getUserMaster';
export const insertUserMaster = 'insertUserMaster';
export const updateUserMaster = 'updateUserMaster';
export const deleteUserMaster = 'deleteUserMaster';
export const getUserMasterById = 'getUserMasterById';
export const insertUserMasterAccess = 'insertUserMasterAccess';
export const updateUserMasterAssess = 'updateUserMasterAssess';

// program status

export const getProgramStatus = 'getProgramStatus';
export const insertProgramStatus = 'insertProgramStatus';
export const updateProgramStatus = 'updateProgramStatus';

export const getDataQuality = 'getDataQuality';
export const getTasDecisionMatrix = 'getTasDecisionMatrix';
export const getDataQualityGraph = 'getDataQualityGraph';
export const getDistrictProfileWidget = 'getDistrictProfileWidget';
export const getDistrictTasProfile = 'getDistrictTASProfile_NEW';

/// //**************national Dashboard******///////////////
export const getPreTasAndTasOverView = 'getPreTasAndTasOverView';
export const getMdaCoverage = 'getMdaCoverage';

/// //**************state Dashboard******///////////////
export const getStateProgressStatus = 'getStateProgressStatus';
export const getStateProfile = 'getStateProfile';
export const getDistrictMdaProfile = 'getDistrictMdaProfile';

/// //**************drug Avilability******///////////////
export const insertDrugAvailability = 'insertDrugAvailability';
export const getDrugAvailability = 'getDrugAvailability';
export const updateDrugAvailability = 'updateDrugAvailability';
export const bulkUploadDrugAvailability = 'bulkInsertDrugAvailability';

// /***************drug Type MasterSetup**********************////////////
export const getDrug = 'getDrug';
export const insertDrug = 'insertDrug';
export const getDrugRegime = 'getDrugRegime';
export const getDrugType = 'getDrugType';
export const updateDrug = 'updateDrug';
export const webLogin = 'webLoginAuth';
export const forgetPassword = 'forgetPassword';
export const webLogout = 'webLogout';
export const changePassword = 'changePassword';
export const getUserGroup = 'getUserGroup';
export const getUserType = 'getUserType';
export const bulkUploadDrug = 'bulkUploadDrug';
// export const BulkInsertDrug = 'bulkInsertDrugAvailability';
export const BulkInsertDrug = 'bulkInsertDrugType';

// User Role

export const getRolePrivilege = 'getRolePrivilege';
export const insertRolePrivilege = 'insertRolePrivilege';
export const updateRolePrivilege = 'updateRolePrivilege';
export const getRolePrivilegeById = 'getRolePrivilegeById';
export const deleteRolePrivilege = 'deleteRolePrivilege';
/// //**************IDA Inputs******///////////////
export const getMdaIdaDistrict = 'getMdaIdaDistrict';
export const getMdaIdaBlock = 'getMdaIdaBlock';
export const insertMdaIdaDistrictStrategy = 'insertMdaIdaDistrictStrategy';
export const updateMdaIda = 'updateMda';
export const getProposedDateIDA = 'getProposedDateIDANew';
export const getProposedDateDA = 'getProposedDateDANew';
export const updateMdaIdaById = 'updateMdaIdaById';
export const bulkUploadMdaIda = 'bulkUploadMdaIda';
export const bulkInsertMdaIdaBlock = 'bulkInsertMdaIdaBlock';
export const bulkInsertMdaIdaDistrict = 'bulkInsertMdaIdaDistrict';
export const insertMdaIdaBlockStrategy = 'insertMdaIdaBlockStrategy';

/// //**************geographic Dimension******///////////////

export const getGeographicDimension = 'getGeographicDimension';
export const insertGeographicDimension = 'insertGeographicDimension';
export const updateGeographicDimension = 'updateGeographicDimension';
// export const bulkUploadGeographicDimension = 'bulkUploadGeographicDimension';
export const bulkUploadGeographicDimension = 'bulkInsertGeographicDimension';

// <MASTER>
// MASTER TEMPLATES
export const masterTemplates = 'masterTemplates';
// ///**************Demographics******///////////////
export const insertDemoghrapicDimension = 'insertDemoghrapicDimension';
export const updateDemoghrapicDimension = 'updateDemoghrapicDimension';
export const getDemoghrapicDimension = 'getDemoghrapicDimension';
// export const uploadDemoghrapicDimension = 'uploadDemoghrapicDimension';
export const uploadDemoghrapicDimension = 'bulkInsertDemoghrapicDimension';

// ///DROPDOWNLIST///
export const getStateDropdown = 'getState';
export const getDistrictDropdown = 'getDistrict';
export const getStrategyType = 'getStrategyType';
export const getTestKitType = 'getTestKitType';

// PROGRAM ACTIVITY TYPE
export const getProgramActivityType = 'getProgramActivityType  ';

// PRE_TAS
export const getPreTas = 'getPreTasDistrictBlock';
export const insertPreTasDistrictStrategy = 'insertPreTasDistrictStrategy';
export const insertPreTasBlockStrategy = 'insertPreTasBlockStrategy';
export const updatePreTasId = 'updatePreTasId';
export const updatePreTas = 'updatePreTas';
export const getProposedDatePreTas = 'getProposedDatePreTasNew';
// export const bulkUploadPreTAS = 'bulkUploadPre-TAS';
export const bulkUploadPreTAS = 'bulkInsertPreTasDistrict';
export const bulkUploadPreTasBlock = 'bulkInsertPreTasBlock';
export const getBlockName = 'getBlockName';
export const getEuName = 'getEuName';
// THRESHOLD CONFIGURATION
// TAS
export const TASInsert = 'insertTas';
export const TASGet = 'getTasDistrictBlock';
export const TASUpdate = 'updateTas';
export const TASUpdateById = 'updateTasId';
export const TAS1Proposed = 'getProposedDateTAS1New';
export const TAS2Proposed = 'getProposedDateTAS2New';
export const TAS3Proposed = 'getProposedDateTAS3New';
// export const bulkUploadTas = 'bulkUploadTas';
export const bulkUploadTas = 'bulkInsertTasDistrict';
export const bulkInsertTasBlock = 'bulkInsertTasBlock';
export const insertTASDistrictStrategy = 'insertTasDistrictStrategy';
export const insertTasBlockStrategy = 'insertTasBlockStrategy';

// THRESHOLD CONFIGURATION
export const getThresholdConfiguration = 'getThresholdConfiguration';
export const insertThresholdConfiguration = 'insertThresholdConfiguration';
export const thresholdApprovalAttachment = 'thresholdApprovalAttachment';

// FORMULA CONFIGURATION

export const getFormulaConfiguration = 'getFormulaConfiguration';
export const insertFormulaConfiguration = 'insertFormulaConfiguration';
export const formulaApprovalAttachment = 'formulaApprovalAttachment';

// Masters
export const getStateCode = 'getStateCode';

// CUSTOM REPORT
export const getFilterDrivenExtractReports = 'getFilterDrivenExtractReports';
export const getCustomReportsByType = 'getCustomReportsByType';
export const customStandardReport = 'customStandardReport';

//search dropdown

export const getStateBySearch = 'getStateBySearch';
export const getDistrictBySearch = 'getDistrictBySearch';