/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '../../atoms/Typography';
import HeaderSection from '../../molecules/HeaderSection';
import FileGenerator from '../FileGenerator';
import Table from '../Table';
import './commontitle.css';

/**
 * @name CommonTitledTable
 * @param {object} props required props
 * @returns {React.ReactElement} -- CommonTitledTable
 */
const CommonTitledTable = (props) => {
  const {
    tableContent,
    tableHeader,
    title,
    tableSortList,
    showExporter,
    tableType,
    subTitle,
    getSelectValue,
    rowClick,
    hideKeyFields,
    districtDropdown,
    getDistrictList,
    blockDropdown,
    TableDropdownSelectedValues,
  } = props;
  const [isTableEdit, setTableEdit] = React.useState(false);
  return (
    <>
      {title && <HeaderSection label={title} />}
      <Grid className="inside-spacing">
        {subTitle && <Typography text={subTitle} type="header" colorType="text" customClass="sub-title" />}
        <FileGenerator
          DropdownList={tableSortList}
          DropdownSelectedValues={TableDropdownSelectedValues}
          showExporter={showExporter}
          getSelectValue={(obj) => getSelectValue(obj)}
          districtDropdown={districtDropdown}
          blockDropdown={blockDropdown}
          getDistrictList={getDistrictList}
          handleUpdate={(isEdit) => setTableEdit(!isEdit)}
          fileData={tableContent}
          title={title}
        />
        <Table
          header={tableHeader}
          rows={tableContent}
          type={tableType}
          rowClick={rowClick}
          hideKeyFields={hideKeyFields}
          dashboardEdit={isTableEdit}
        />
      </Grid>
    </>
  );
};
export default CommonTitledTable;
CommonTitledTable.propTypes = {
  tableContent: PropTypes.arrayOf.isRequired,
  tableHeader: PropTypes.arrayOf.isRequired,
  showExporter: PropTypes.bool,
  title: PropTypes.string,
  tableSortList: PropTypes.array,
  tableType: PropTypes.string,
  subTitle: PropTypes.string,
  getSelectValue: PropTypes.func,
  rowClick: PropTypes.func,
  hideKeyFields: PropTypes.array,
  districtDropdown: PropTypes.objectOf(),
  blockDropdown: PropTypes.objectOf(),
  getDistrictList: PropTypes.func,
  TableDropdownSelectedValues: PropTypes.objectOf(),
};
CommonTitledTable.defaultProps = {
  title: null,
  tableSortList: null,
  showExporter: true,
  tableType: 'normal',
  subTitle: null,
  getSelectValue: () => {},
  rowClick: () => {},
  hideKeyFields: null,
  districtDropdown: null,
  blockDropdown: null,
  getDistrictList: () => {},
  TableDropdownSelectedValues: {},
};
