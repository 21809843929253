/* eslint-disable default-param-last */
import {
  LYMPHEDEMA_STATUS,
    GET_LYMPHEDEMA_RESULT,
    INSERT_LYMPHEDEMA_RESULT,
    UPDATE_LYMPHEDEMA_RESULT,
    UPLOAD_LYMPHEDEMA_RESULT,
  } from '../../constants/actionType';
  import { lymphedemaInitialState } from '../initialStates';
  
  /**
   * @param {string} state defines the state
   * @param {object} action used for payload and type
   * @returns {Array} action response state
   */
  const lymphedemaReducer = (state = lymphedemaInitialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case GET_LYMPHEDEMA_RESULT:
        return {
          ...state,
          getLymphedema: payload?.data,
        };
      case INSERT_LYMPHEDEMA_RESULT:
        return {
          ...state,
          status: payload.data,
        };
      case UPDATE_LYMPHEDEMA_RESULT:
        return {
          ...state,
          insertLymphedema: payload.data,
        };
      case LYMPHEDEMA_STATUS:
        return {
          ...state,
          status: payload,
          loading: false,
        };
      case UPLOAD_LYMPHEDEMA_RESULT:
        return {
          ...state,
          uploadLymphedema: payload.data,
        };
      default:
        return state;
    }
  };
  export default lymphedemaReducer;
  