/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import {
  GET_TAS_RESULT,
  GET_TAS1_PROPOSED_RESULT,
  GET_TAS2_PROPOSED_RESULT,
  GET_TAS3_PROPOSED_RESULT,
  INSERT_TAS_STATUS,
  INSERT_TAS_DISRICT_STRATEGY_STATUS,
  INSERT_TAS_BLOCK_STRATEGY_STATUS,
  LOADER,
  TAS_STATUS,
  GET_TAS,
} from '../../constants/actionType/index';
import {
  insertTAS1Request,
  getTAS1Request,
  getTASProposedRequest,
  bulkUploadTasRequest,
  insertTAS1DistrictRequest,
  insertTAS1BlockRequest,
} from '../request/TASRequest';
import {
  CATCH,
  ERROR,
  SUCCESS,
  FAILED,
  UPDATED,
  SUBMITTED,
  INITIAL,
  INITIAL_LOAD,
  BULK_UPLOAD_SUCCESS,
} from '../../constants/common';

/**
 * @name statusClear used for clear the status
 * @yields statusClear
 */
export function* statusClear() {
  yield put({
    type: INSERT_TAS_STATUS,
    payload: { type: INITIAL, message: INITIAL_LOAD },
  });
}

/**
 * @name insertTASHandler used for dashboard api call
 * @param {object} action payload
 * @yields insertTASHandler
 */
export function* insertTAS1Handler(action) {
  const { payload } = action;
  try {
    yield put({ type: LOADER, payload: true });
    const response = yield call(insertTAS1Request, payload);
    if (response.status === 200) {
      yield put({
        type: INSERT_TAS_STATUS,
        payload: { type: SUCCESS, message: payload.type === 'insert' ? SUBMITTED : UPDATED },
      });
    } else {
      yield put({
        type: INSERT_TAS_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: INSERT_TAS_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @name insertTASHandler used for dashboard api call
 * @param {object} action payload
 * @yields insertTASHandler
 */
export function* insertTASDistrictHandler(action) {
  const { payload } = action;
  try {
    // yield put({ type: LOADER, payload: true });
    const response = yield call(insertTAS1DistrictRequest, payload);
    if (response.status === 200) {
      yield put({
        type: INSERT_TAS_STATUS,
        payload: { type: SUCCESS, message: payload.type === 'insert' ? SUBMITTED : UPDATED },
      });
    } else {
      yield put({
        type: INSERT_TAS_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: INSERT_TAS_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @name insertTASHandler used for dashboard api call
 * @param {object} action payload
 * @yields insertTASHandler
 */
export function* insertTASBlockHandler(action) {
  const { payload } = action;
  try {
    yield put({ type: LOADER, payload: true });
    const response = yield call(insertTAS1BlockRequest, payload);
    if (response.status === 200) {
      yield put({
        type: INSERT_TAS_BLOCK_STRATEGY_STATUS,
        payload: { type: SUCCESS, message: payload.type === 'insert' ? SUBMITTED : UPDATED },
      });
    } else {
      yield put({
        type: INSERT_TAS_BLOCK_STRATEGY_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: INSERT_TAS_BLOCK_STRATEGY_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}

/**
 * @name getTASProposedHandler used for dashboard api call
 * @param {object} action payload
 * @yields getTASProposedHandler
 */
export function* getTASProposedHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getTASProposedRequest, payload);
    if (response.status !== 200) {
      yield put({
        type: INSERT_TAS_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    } else {
      yield put({
        type:
          payload.type === 'TAS1'
            ? GET_TAS1_PROPOSED_RESULT
            : payload.type === 'TAS2'
            ? GET_TAS2_PROPOSED_RESULT
            : GET_TAS3_PROPOSED_RESULT,
        payload: response?.data,
      });
      if (response.data.status === 0) {
        yield put({
          type: INSERT_TAS_STATUS,
          payload: { type: ERROR, message: response.data.msg },
        });
      }
    }
  } catch (err) {
    yield put({
      type: INSERT_TAS_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}

/**
 * @name insertTASHandler used for dashboard api call
 * @param {object} action payload
 * @yields insertTASHandler
 */
export function* getTAS1Handler(action) {
  const { payload } = action;
  try {
    const response = yield call(getTAS1Request, payload);
    if (response.status !== 200) {
      yield put({
        type: INSERT_TAS_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    } else {
      yield put({
        type: GET_TAS_RESULT,
        payload: response?.data,
      });
      if (response.data.status === 0) {
        yield put({
          type: INSERT_TAS_STATUS,
          payload: { type: ERROR, message: response.data.mes },
        });
      }
    }
  } catch (err) {
    yield put({
      type: INSERT_TAS_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function updateDossierHandler handler function to handle program activity update request
 * @yields updateDossierHandler
 */
export function* uploadTasHandler(action) {
  const { payload } = action;
  console.log(payload, 'sdfsijri');
  try {
    const response = yield call(bulkUploadTasRequest, payload);
    if (response.status === 200) {
      yield put({
        type: INSERT_TAS_STATUS,
        payload: { type: SUCCESS, message: BULK_UPLOAD_SUCCESS },
      });
    } else {
      yield put({
        type: INSERT_TAS_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: INSERT_TAS_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
  // yield put({
  //   type: GET_TAS,
  //   payload: payload?.data?.[0],
  // });
}
