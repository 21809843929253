import React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import CustomTypography from '../../../atoms/Typography';
import './Legends.css';
/**
 *
 * @param {object} props - required props in activity calender legends
 * @returns {React.ReactElement}  -returns the Legend
 */
const Legends = (props) => {
  const { legend } = props;
  return (
    <Grid className="category">
      {legend &&
        Object.keys(legend)?.map((data) => {
          return (
            <Grid className="category">
              <Grid sx={{ backgroundColor: legend[data] }} className="legend" />
              <CustomTypography type="title" text={data} colorType="text" customClass="legendTypo" />
            </Grid>
          );
        })}
    </Grid>
  );
};
Legends.propTypes = {
  legend: PropTypes.arrayOf.isRequired,
};
export default Legends;
