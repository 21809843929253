/* eslint-disable import/prefer-default-export */
/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import {
  GET_DRUG_RESULT,
  GET_DRUG_REGIME_RESULT,
  GET_DRUG_TYPE_RESULT,
  DRUG_TYPE_STATUS,
  GET_DRUG,
} from '../../constants/actionType/index';
import {
  getDrugRequest,
  insertDrugMasterRequest,
  getDrugRegimeRequest,
  getDrugTypeRequest,
  updateDrugRequest,
  bulkUploadDrugRequest,
} from '../request/drugTypeRequest';
import { CATCH, ERROR, SUBMITTED, SUCCESS, UPDATED, BULK_UPLOAD_SUCCESS } from '../../constants/common';

/**
 * @name getDrugHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* getDrugHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getDrugRequest, payload);
    yield put({
      type: GET_DRUG_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name insertDrugMasters used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* insertDrugMastersHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(insertDrugMasterRequest, payload);
    if (response.status === 200)
      yield put({
        type: DRUG_TYPE_STATUS,
        payload: { type: SUCCESS, message: SUBMITTED },
      });
  } catch (err) {
    yield put({
      type: DRUG_TYPE_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @name getDrugRegimeHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* getDrugRegimeHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getDrugRegimeRequest, payload);
    yield put({
      type: GET_DRUG_REGIME_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @name insertDrugMasters used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* getDrugTypeHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getDrugTypeRequest, payload);
    yield put({
      type: GET_DRUG_TYPE_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @name UpdateDrugHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* updateDrugHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(updateDrugRequest, payload);
    if (response.status === 200)
      yield put({
        type: DRUG_TYPE_STATUS,
        payload: { type: SUCCESS, message: UPDATED },
      });

    const response1 = yield call(getDrugRequest, payload);
    yield put({
      type: GET_DRUG_RESULT,
      payload: response1?.data,
    });
  } catch (err) {
    yield put({
      type: DRUG_TYPE_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @name UpdateDrugHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* bulkUploadDrugHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(bulkUploadDrugRequest, payload);
    if (response.status === 200)
      yield put({
        type: DRUG_TYPE_STATUS,
        payload: { type: SUCCESS, message: BULK_UPLOAD_SUCCESS },
      });
  } catch (err) {
    yield put({
      type: DRUG_TYPE_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
  yield put({
    type: GET_DRUG,
  });
}
