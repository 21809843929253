/// //////*******national dashboard********///////
export const SET_GET_DASHBOARD_FILTER_DATA = 'SET_GET_DASHBOARD_FILTER_DATA';
export const SET_GET_DASHBOARD_FILTER_DATA_RESULT = 'SET_GET_DASHBOARD_FILTER_DATA_RESULT';
export const COUNTRY_PROFILE_TABLE = 'COUNTRY_PROFILE_TABLE';
export const COUNTRY_PROFILE_TABLE_RESULT = 'COUNTRY_PROFILE_TABLE_RESULT';

export const GET_PREDICTION_ACTIVITY_CALENDAR = 'GET_PREDICTION_ACTIVITY_CALENDAR';
export const GET_PREDICTION_ACTIVITY_CALENDAR_RESULT = 'GET_PREDICTION_ACTIVITY_CALENDAR_RESULT';

export const GET_PREDICTION_ACTIVITY_CALENDAR_TABLE = 'GET_PREDICTION_ACTIVITY_CALENDAR_TABLE';
export const GET_PREDICTION_ACTIVITY_CALENDAR_TABLE_RESULT = 'GET_PREDICTION_ACTIVITY_CALENDAR_TABLE_RESULT';

export const GET_EXPECTED_ACTIVITIES = 'GET_EXPECTED_ACTIVITIES';
export const GET_EXPECTED_ACTIVITIES_RESULT = 'GET_EXPECTED_ACTIVITIES_RESULT';

export const GET_ACTIVITY_LEGENDS = 'GET_ACTIVITY_LEGENDS';
export const GET_ACTIVITY_LEGENDS_RESULT = 'GET_ACTIVITY_LEGENDS_RESULT';

export const GET_PREDICTION_COUNTRY_PROFILE = 'GET_PREDICTION_COUNTRY_PROFILE';
export const GET_PREDICTION_COUNTRY_PROFILE_RESULT = 'GET_PREDICTION_COUNTRY_PROFILE_RESULT';

export const GET_PREDICTION_COUNTRY_PROFILE_MAP = 'GET_PREDICTION_COUNTRY_PROFILE_MAP';
export const GET_PREDICTION_COUNTRY_PROFILE_MAP_RESULT = 'GET_PREDICTION_COUNTRY_PROFILE_MAP_RESULT';

export const GET_PREDICTION_DASHBOARD = 'GET_PREDICTION_DASHBOARD';
export const GET_PREDICTION_DASHBOARD_RESULT = 'GET_PREDICTION_DASHBOARD_RESULT';

export const GET_EXPECTED_ACTIVITIES_GRAPH = 'GET_EXPECTED_ACTIVITIES_GRAPH';
export const GET_EXPECTED_ACTIVITIES_GRAPH_RESULT = 'GET_EXPECTED_ACTIVITIES_GRAPH_RESULT';

export const NATIONAL_PROGRESS_STATUS = 'NATIONAL_PROGRESS_STATUS';
export const NATIONAL_PROGRESS_STATUS_RESULT = 'NATIONAL_PROGRESS_STATUS_RESULT';

export const COUNTRY_PROFILE_MAP = 'COUNTRY_PROFILE_MAP';
export const COUNTRY_PROFILE_MAP_RESULT = 'COUNTRY_PROFILE_MAP_RESULT';
export const COUNTRY_PROFILE_MAP_AFTER_HYPER = 'COUNTRY_PROFILE_MAP_AFTER_HYPER';

export const NATIONAL_STATES_DASHBOARD_CARD = 'NATIONAL_STATES_DASHBOARD_CARD';
export const NATIONAL_STATES_DASHBOARD_CARD_RESULT = 'NATIONAL_STATES_DASHBOARD_CARD_RESULT';

export const NATIONAL_PROGRESS_STATUSBAR_GRAPH = 'NATIONAL_PROGRESS_STATUSBAR_GRAPH';
export const NATIONAL_PROGRESS_STATUSBAR_GRAPH_RESULT = 'NATIONAL_PROGRESS_STATUSBAR_GRAPH_RESULT';

export const ACTIVITY_CALENDAR = 'ACTIVITY_CALENDAR';
export const ACTIVITY_CALENDAR_RESULT = 'ACTIVITY_CALENDAR_RESULT';

export const ACTIVITY_CALENDAR_TABLE = 'ACTIVITY_CALENDAR_TABLE';
export const ACTIVITY_CALENDAR_TABLE_RESULT = 'ACTIVITY_CALENDAR_TABLE_RESULT';

export const GET_PROGRAM_ACTIVITY = 'GET_PROGRAM_ACTIVITY';
export const GET_PROGRAM_ACTIVITY_RESULT = 'GET_PROGRAM_ACTIVITY_RESULT';

export const UPDATE_PROGRAM_ACTIVITY = 'UPDATE_PROGRAM_ACTIVITY';
export const UPDATE_PROGRAM_ACTIVITY_RESULT = 'UPDATE_PROGRAM_ACTIVITY_RESULT';

export const INSERT_PROGRAM_ACTIVITY = 'INSERT_PROGRAM_ACTIVITY';
export const INSERT_PROGRAM_ACTIVITY_RESULT = 'INSERT_PROGRAM_ACTIVITY_RESULT';

export const GET_DATA_QUALITY_GRAPH = 'GET_DATA_QUALITY_GRAPH';
export const GET_DATA_QUALITY_GRAPH_RESULT = 'GET_DATA_QUALITY_GRAPH_RESULT';

export const PRETAS_OVERVIEW = 'PRETAS_OVERVIEW';
export const PRETAS_OVERVIEW_RESULT = 'PRETAS_OVERVIEW_RESULT';

export const MDA_COVERAGE = 'MDA_COVERAGE';
export const MDA_COVERAGE_RESULT = 'MDA_COVERAGE_RESULT';

export const GET_DATA_QUALITY = 'GET_DATA_QUALITY';
export const GET_DATA_QUALITY_RESULT = 'GET_DATA_QUALITY_RESULT';

export const GET_TAS_DECISION_MATRIX = 'GET_TAS_DECISION_MATRIX';
export const GET_TAS_DECISION_MATRIX_RESULT = 'GET_TAS_DECISION_MATRIX_RESULT';

export const GET_COUNTRY_PROFILE_CHART = 'GET_COUNTRY_PROFILE_CHART';
export const GET_COUNTRY_PROFILE_CHART_RESULT = 'GET_COUNTRY_PROFILE_CHART_RESULT';

export const GET_DISTRICT_MOVEDTO = 'GET_DISTRICT_MOVEDTO';
export const GET_DISTRICT_MOVEDTO_RESULT = 'GET_DISTRICT_MOVEDTO_RESULT';

export const GET_TAS_SUMMERY = 'GET_TAS_SUMMERY';
export const GET_TAS_SUMMERY_RESULT = 'GET_TAS_SUMMERY_RESULT';

// State Dashboard
export const GET_STATE_PROGRESS_STATUS = 'GET_STATE_PROGRESS_STATUS';
export const GET_STATE_PROGRESS_STATUS_RESULT = 'GET_STATE_PROGRESS_STATUS_RESULT';

export const GET_STATE_PROFILE = 'GET_STATE_PROFILE';
export const GET_STATE_PROFILE_RESULT = 'GET_STATE_PROFILE_RESULT';

export const DISTRICT_MDA_PROFILE = 'DISTRICT_MDA_PROFILE';
export const DISTRICT_MDA_PROFILE_RESULT = 'DISTRICT_MDA_PROFILE_RESULT';
export const GET_DISTRICT_PROFILE_WIDGET = 'GET_DISTRICT_PROFILE_WIDGET';
export const GET_DISTRICT_PROFILE_WIDGET_RESULT = 'GET_DISTRICT_PROFILE_WIDGET_RESULT';
export const GET_DISTRICT_PROFILE_WIDGET_GRAPH = 'GET_DISTRICT_PROFILE_WIDGET_GRAPH';
export const GET_DISTRICT_PROFILE_WIDGET_GRAPH_RESULT = 'GET_DISTRICT_PROFILE_WIDGET_GRAPH_RESULT';
export const GET_DISTRICT_TAS_PROFILE = 'GET_DISTRICT_TAS_PROFILE';
export const GET_DISTRICT_TAS_PROFILE_RESULT = 'GET_DISTRICT_TAS_PROFILE_RESULT';
export const GET_DISTRICT_DECISION_MATRIX = 'GET_DISTRICT_DECISION_MATRIX';
export const GET_DISTRICT_DECISION_MATRIX_RESULT = 'GET_DISTRICT_DECISION_MATRIX_RESULT';

// DrugAvilability
export const INSERT_DRUG = 'INSERT_DRUG';
export const INSERT_DRUG_RESULT = 'INSERT_DRUG_RESULT';

export const GET_DRUG_AVILABILITY = 'GET_DRUG_AVILABILITY';
export const GET_DRUG_AVILABILITY_RESULT = 'GET_DRUG_AVILABILITY_RESULT';

export const UPDATE_DRUG_AVILABILITY = 'UPDATE_DRUG_AVILABILITY';
export const UPDATE_DRUG_AVILABILITY_RESULT = 'UPDATE_DRUG_AVILABILITY_RESULT';

export const UPLOAD_DRUG_AVILABILITY = 'UPLOAD_DRUG_AVILABILITY';
export const UPLOAD_DRUG_AVILABILITY_RESULT = 'UPLOAD_DRUG_AVILABILITY_RESULT';
export const DRUG_STATUS = 'DRUG_STATUS';

// DrugType
export const GET_DRUG = 'GET_DRUG';
export const GET_DRUG_RESULT = 'GET_DRUG_RESULT';

export const INSERT_DRUG_MASTERS = 'INSERT_DRUG_MASTERS';
export const INSERT_DRUG_MASTERS_RESULT = 'INSERT_DRUG_MASTERS_RESULT';

export const GET_DRUG_REGIME = 'GET_DRUG_REGIME';
export const GET_DRUG_REGIME_RESULT = 'GET_DRUG_REGIME_RESULT';

export const GET_DRUG_TYPE = 'GET_DRUG_TYPE';
export const GET_DRUG_TYPE_RESULT = 'GET_DRUG_TYPE_RESULT';

export const UPDATE_DRUG = 'UPDATE_DRUG';
export const UPDATE_DRUG_RESULT = 'UPDATE_DRUG_RESULT';

export const BULK_UPLOAD_DRUG = 'BULK_UPLOAD_DRUG';
export const BULK_UPLOAD_DRUG_RESULT = 'BULK_UPLOAD_DRUG_RESULT';

export const DRUG_TYPE_STATUS = 'DRUG_TYPE_STATUS';
// Data Input

// Dossier

export const GET_DOSSIER = 'GET_DOSSIER';
export const GET_DOSSIER_RESULT = 'GET_DOSSIER_RESULT';

export const INSERT_DOSSIER = 'INSERT_DOSSIER';
export const INSERT_DOSSIER_RESULT = 'INSERT_DOSSIER_RESULT';

export const UPDATE_DOSSIER = 'UPDATE_DOSSIER';
export const UPDATE_DOSSIER_RESULT = 'UPDATE_DOSSIER_RESULT';

export const UPLOAD_DOSSIER = 'UPLOAD_DOSSIER';
export const UPLOAD_DOSSIER_RESULT = 'UPLOAD_DOSSIER_RESULT';
export const DOSSIER_STATUS = 'DOSSIER_STATUS';


// Lymphedema


export const GET_LYMPHEDEMA = 'GET_LYMPHEDEMA';
export const GET_LYMPHEDEMA_RESULT = 'GET_LYMPHEDEMA_RESULT';

export const INSERT_LYMPHEDEMA = 'INSERT_LYMPHEDEMA';
export const INSERT_LYMPHEDEMA_RESULT = 'INSERT_LYMPHEDEMA_RESULT';

export const UPDATE_LYMPHEDEMA = 'UPDATE_LYMPHEDEMA';
export const UPDATE_LYMPHEDEMA_RESULT = 'UPDATE_LYMPHEDEMA_RESULT';

export const UPLOAD_LYMPHEDEMA = 'UPLOAD_LYMPHEDEMA';
export const UPLOAD_LYMPHEDEMA_RESULT = 'UPLOAD_LYMPHEDEMA_RESULT';

export const LYMPHEDEMA_STATUS = 'LYMPHEDEMA_STATUS';
// New User

export const GET_NEWUSER_LIST = 'GET_NEWUSER_LIST';
export const GET_NEWUSER_LIST_RESULT = 'GET_NEWUSER_LIST_RESULT';

export const INSERT_NEWUSER_LIST = 'INSERT_NEWUSER_LIST';
export const INSERT_NEWUSER_LIST_RESULT = 'INSERT_NEWUSER_LIST_RESULT';

export const UPDATE_NEWUSER_LIST = 'UPDATE_NEWUSER_LIST';
export const UPDATE_NEWUSER_LIST_RESULT = 'UPDATE_NEWUSER_LIST_RESULT';

export const DELETE_NEWUSER_LIST = 'DELETE_NEWUSER_LIST';
export const DELETE_NEWUSER_LIST_RESULT = 'DELETE_NEWUSER_LIST_RESULT';

export const GET_USER_MASTER = 'GET_USER_MASTER';
export const GET_USER_MASTER_RESULT = 'GET_USER_MASTER_RESULT';

export const INSERT_USER_MASTER = 'INSERT_USER_MASTER';
export const INSERT_USER_MASTER_RESULT = 'INSERT_USER_MASTER_RESULT';

export const UPDATE_USER_MASTER = 'UPDATE_USER_MASTER';
export const UPDATE_USER_MASTER_RESULT = 'UPDATE_USER_MASTER_RESULT';

export const USER_STATUS = 'USER_STATUS';

// Login
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_RESULT = 'USER_LOGIN_RESULT';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_RESULT = 'USER_LOGOUT_RESULT';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_RESULT = 'FORGET_PASSWORD_RESULT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_RESULT = 'CHANGE_PASSWORD_RESULT';
export const PASSWORD_RESULT = 'PASSWORD_RESULT';

export const USER_GROUP = 'USER_GROUP';
export const USER_GROUP_RESULT = 'USER_GROUP_RESULT';

export const USER_TYPE = 'USER_TYPE';
export const USER_TYPE_RESULT = 'USER_TYPE_RESULT';

// User Role
export const GET_USERS_GROUP_AND_TYPE = 'GET_USERS_GROUP_AND_TYPE';
export const USERS_GROUP_AND_TYPE_RESULT = 'USERS_GROUP_AND_TYPE_RESULT';

export const CREATE_USERS_GROUP_AND_TYPE = 'CREATE_USERS_GROUP_AND_TYPE';
export const USERS_GROUP_AND_TYPE_CREATE_RESULT = 'USERS_GROUP_AND_TYPE_CREATE_RESULT';

export const READ_USERS_GROUP_AND_TYPE = 'READ_USERS_GROUP_AND_TYPE';
export const USERS_GROUP_AND_TYPE_READ_RESULT = 'USERS_GROUP_AND_TYPE_READ_RESULT';

export const UPDATE_USERS_GROUP_AND_TYPE = 'UPDATE_USERS_GROUP_AND_TYPE';
export const USERS_GROUP_AND_TYPE_UPDATE_RESULT = 'USERS_GROUP_AND_TYPE_UPDATE_RESULT';

export const DELETE_USERS_GROUP_AND_TYPE = 'DELETE_USERS_GROUP_AND_TYPE';
export const USERS_GROUP_AND_TYPE_DELETE_RESULT = 'USERS_GROUP_AND_TYPE_DELETE_RESULT';

export const USER_ROLE_TOGGLE_FORM = 'USER_ROLE_TOGGLE_FORM';
export const SET_TOGGLE_FORM = 'SET_TOGGLE_FORM';

//
// IDA
export const INSERT_IDA_CHECK = 'INSERT_IDA_CHECK';
export const GET_IDA = 'GET_IDA';
export const IDA_DATA_RESULT = 'IDA_DATA_RESULT';
export const INSERT_IDA = 'INSERT_IDA';
export const UPDATE_IDA = 'UPDATE_IDA';
export const GET_PROPOSED_DATE_IDA = 'GET_PROPOSED_DATE_IDA';
export const PROPOSED_DATE_IDA = 'PROPOSED_DATE_IDA';
export const UPDATE_IDA_BY_ID = 'UPDATE_IDA_BY_ID';
export const LOADER = 'LOADER';
export const IDA_STATUS = 'IDA_STATUS';
export const UPLOAD_MDA_IDA = 'UPLOAD_MDA_IDA';
export const UPLOAD_MDA_IDA_RESULT = 'UPLOAD_MDA_IDA_RESULT';
export const INSERT_MDA_CHECK = 'INSERT_MDA_CHECK';

export const INSERT_BLOCK_RESULT = 'INSERT_BLOCK_RESULT';
export const INSERT_BLOCK = 'INSERT_BLOCK';

export const GET_PROGRAM_STATUS = 'GET_PROGRAM_STATUS';
export const GET_PROGRAM_STATUS_RESULT = 'GET_PROGRAM_STATUS_RESULT';

export const UPDATE_PROGRAM_STATUS = 'UPDATE_PROGRAM_STATUS';
export const UPDATE_PROGRAM_STATUS_RESULT = 'UPDATE_PROGRAM_STATUS_RESULT';

export const INSERT_PROGRAM_STATUS = 'INSERT_PROGRAM_STATUS';
export const INSERT_PROGRAM_STATUS_RESULT = 'INSERT_PROGRAM_STATUS_RESULT';

export const PROGRAM_STATUS = 'PROGRAM_STATUS';
// Geographic
export const GET_GEOGRAPHIC_DIMENSION = 'GET_GEOGRAPHIC_DIMENSION';
export const GET_GEOGRAPHIC_DIMENSION_RESULT = 'GET_GEOGRAPHIC_DIMENSION_RESULT';

export const INSERT_GEOGRAPHIC_DIMENSION = 'INSERT_GEOGRAPHIC_DIMENSION';
export const INSERT_GEOGRAPHIC_DIMENSION_RESULT = 'INSERT_GEOGRAPHIC_DIMENSION_RESULT';

export const UPDATE_GEOGRAPHIC_DIMENSION = 'UPDATE_GEOGRAPHIC_DIMENSION';
export const UPDATE_GEOGRAPHIC_DIMENSION_RESULT = 'UPDATE_GEOGRAPHIC_DIMENSION_RESULT';

export const BULK_UPLOAD_GEOGRAPHIC_DIMENSION = 'BULK_UPLOAD_GEOGRAPHIC_DIMENSION';
export const BULK_UPLOAD_GEOGRAPHIC_DIMENSION_RESULT = 'BULK_UPLOAD_GEOGRAPHIC_DIMENSION_RESULT';

export const GEOGRAPHY_STATUS = 'GEOGRAPHY_STATUS';

// Master
// -- MASTER TEMPLATES
export const GET_MASTER_TEMPLATES = 'GET_MASTER_TEMPLATES';
export const GET_MASTER_TEMPLATES_RESULT = 'GET_MASTER_TEMPLATES_RESULT';
// --Demographics--
export const INSERT_DEMOGRAPHIC_DIMENSIONS = 'INSERT_DEMOGRAPHIC_DIMENSIONS';
export const INSERT_DEMOGRAPHIC_DIMENSIONS_FAILED = 'INSERT_DEMOGRAPHIC_DIMENSIONS_FAILED';
export const DEMOGRAPHIC_LIST = 'DEMOGRAPHIC_LIST';
export const DEMOGRAPHIC_LOADING = 'DEMOGRAPHIC_LOADING';
export const DEMOGRAPHIC_UPDATE = 'DEMOGRAPHIC_UPDATE';
export const DEMOGRAPHIC_UPDATED_DATA = 'DEMOGRAPHIC_UPDATED_DATA';
export const DEMOGRAPHIC_LISTED_DATA = 'DEMOGRAPHIC_LISTED_DATA';
export const DEMOGRAPHIC_INSERTED_DATA = 'DEMOGRAPHIC_INSERTED_DATA';
export const UPLOAD_DEMOGRAPHIC_DIMENSION = 'UPLOAD_DEMOGRAPHIC_DIMENSION';
export const DEMO_STATUS = 'DEMO_STATUS';

export const UPLOAD_DEMOGRAPHIC_DIMENSIONS = 'UPLOAD_DEMOGRAPHIC_DIMENSIONS';
export const UPLOAD_DEMOGRAPHIC_DIMENSIONS_RESULT = 'UPLOAD_DEMOGRAPHIC_DIMENSIONS_RESULT';

// // --Pre-Tas
// export const PRE_TAS = 'PRE_TAS_LIST';
// export const PRE_TAS_LIST = 'PRE_TAS_LIST';
// export const PRE_TAS_LIST_LOADING = 'PRE_TAS_LIST_LOADING';
// export const PRE_TAS_FAILED = 'PRE_TAS_LIST_FAILED';
// export const PRE_TAS_UPDATE = 'PRE_TAS_OVERVIEW';
// export const PRE_TAS_UPDATED_DATA = 'PRE_TAS_UPDATE_DATA';
// export const PRE_TAS_INSERT = 'PRE_TAS_INSERT';
// export const PRE_TAS_INSERT_FAILED = 'PRE_TAS_INSERT_FAILED';

// --DropDowns--

// --STATE
export const GET_STATE_DROPDOWN = 'GET_STATE_DROPDOWN';
export const STATE_DROPDOWN = 'STATE_DROPDOWN';
export const STATE_DROPDOWN_LOADING = 'STATE_DROPDOWN_LOADING';
export const STATE_DROPDOWN_FAILED = 'STATE_DROPDOWN_FAILED';

// --DISTRICT
export const GET_DISTRICT_DROPDOWN = 'GET_DISTRICT_DROPDOWN';
export const DISTRICT_DROPDOWN = 'DISTRICT_DROPDOWN';
export const DISTRICT_DROPDOWN_LOADING = 'DISTRICT_DROPDOWN_LOADING';
export const DISTRICT_DROPDOWN_FAILED = 'DISTRICT_DROPDOWN_FAILED';

// --STRATEGY
export const GET_STRATEGY_DROPDOWN = 'GET_STRATEGY_DROPDOWN';
export const STRATEGY_DROPDOWN = 'STRATEGY_DROPDOWN';
export const STRATEGY_DROPDOWN_LOADING = 'STRATEGY_DROPDOWN_LOADING';
export const STRATEGY_DROPDOWN_FAILED = 'STRATEGY_DROPDOWN_FAILED';

// --TEST KIT TYPE
export const GET_TESTKITTYPE_DROPDOWN = 'GET_TESTKITTYPE_DROPDOWN';
export const TESTKITTYPE_DROPDOWN = 'TESTKITTYPE_DROPDOWN';
export const TESTKITTYPE_DROPDOWN_LOADING = 'TESTKITTYPE_DROPDOWN_LOADING';
export const TESTKITTYPE_DROPDOWN_FAILED = 'TESTKITTYPE_DROPDOWN_FAILED';

// --PROGRAM ACTIVITY TYPE
export const GET_PROGRAM_ACTIVITY_TYPE = 'GET_PROGRAM_ACTIVITY_TYPE';
export const PROGRAM_ACTIVITY_TYPE = 'PROGRAM_ACTIVITY_TYPE';

export const PROGRAM_ACTIVITY_TYPE_LOADING = 'PROGRAM_ACTIVITY_TYPE_LOADING';
export const PROGRAM_ACTIVITY_TYPE_FAILED = 'PROGRAM_ACTIVITY_TYPE_FAILED';
export const PROGRAM_ACTIVITY_TYPE_STATUS = 'PROGRAM_ACTIVITY_TYPE_STATUS';

// --PRE-TAS
export const GET_PRE_TAS = 'GET_PRE_TAS';
export const GET_PRE_TAS_RESULT = 'GET_PRE_TAS_RESULT';
export const LOADER_PRE_TAS = 'LOADER_PRE_TAS';
export const PRE_TAS_STATUS = 'PRE_TAS_STATUS';
export const PRE_TAS_UPLOAD = 'PRE_TAS_UPLOAD';
export const PRE_TAS_UPLOAD_RESULT = 'PRE_TAS_UPLOAD_RESULT';

export const BLOCK_NAME = 'BLOCK_NAME';
export const BLOCK_NAME_RESULT = 'BLOCK_NAME_RESULT';

export const EU_NAME_RESULT = 'EU_NAME_RESULT';
export const EU_NAME = 'EU_NAME';

export const INSERT_PRE_TAS = 'INSERT_PRE_TAS';
export const INSERT_PRE_TAS_RESULT = 'INSERT_PRE_TAS_RESULT';

export const UPDATE_PRE_TAS_ID = 'UPDATE_PRE_TAS_ID';
export const UPDATE_PRE_TAS_ID_RESULT = 'UPDATE_PRE_TAS_ID_RESULT';

export const UPDATE_PRE_TAS = 'UPDATE_PRE_TAS';
export const UPDATE_PRE_TAS_RESULT = 'UPDATE_PRE_TAS_RESULT';

export const GET_PROPOSED_PRE_TAS = 'GET_PROPOSED_PRE_TAS';
export const GET_PROPOSED_PRE_TAS_RESULT = 'GET_PROPOSED_PRE_TAS_RESULT';

export const INSERT_PRE_TAS_BLOCK = 'LOADER_PRE_TAS_BLOCK';
export const INSERT_PRE_TAS_BLOCK_RESULT = 'LOADER_PRE_TAS_BLOCK';

// -- THRESHOLD CONFIGURATION
// -- TAS --
export const INSERT_TAS = 'INSERT_TAS';
export const INSERT_TAS_DISRICT_STRATEGY = 'INSERT_TAS_DISRICT_STRATEGY';
export const INSERT_TAS_BLOCK_STRATEGY = 'INSERT_TAS_BLOCK_STRATEGY';
export const GET_TAS_PROPOSED = 'GET_TAS_PROPOSED';
export const GET_TAS1_PROPOSED_RESULT = 'GET_TAS1_PROPOSED_RESULT';
export const GET_TAS2_PROPOSED_RESULT = 'GET_TAS2_PROPOSED_RESULT';
export const GET_TAS3_PROPOSED_RESULT = 'GET_TAS3_PROPOSED_RESULT';
export const GET_TAS_RESULT = 'GET_TAS_RESULT';
export const GET_TAS = 'GET_TAS';
export const INSERT_TAS_STATUS = 'INSERT_TAS_STATUS';
export const INSERT_TAS_DISRICT_STRATEGY_STATUS = 'INSERT_TAS_DISRICT_STRATEGY_STATUS';
export const INSERT_TAS_BLOCK_STRATEGY_STATUS = 'INSERT_TAS_BLOCK_STRATEGY_STATUS';
export const INSERT_TAS_CHECK = 'INSERT_TAS_CHECK';
export const TAS_STATUS = 'TAS_STATUS';
export const BULKUPLOAD_TAS = 'BULLUPLOAD_TAS';
export const BULKUPLOAD_TAS_RESULT = 'BULLUPLOAD_TAS_RESULT';

// -- THRESHOLD CONFIGURATION
export const GET_THRESHOLD_CONFIG = 'GET_THRESHOLD_CONFIG';
export const GET_THRESHOLD_CONFIG_RESULT = 'GET_THRESHOLD_CONFIG_RESULT';

export const INSERT_THRESHOLD_CONFIG = 'INSERT_THRESHOLD_CONFIG';
export const INSERT_THRESHOLD_CONFIG_RESULT = 'INSERT_THRESHOLD_CONFIG_RESULT';

export const THRESHOLD_APPROVAL_ATTACHMENT = 'THRESHOLD_APPROVAL_ATTACHMENT';
export const THRESHOLD_APPROVAL_ATTACHMENT_RESULT = 'THRESHOLD_APPROVAL_ATTACHMENT_RESULT';

export const THRESHOLD_STATUS = 'THRESHOLD_STATUS';
export const APPROVAL_STATUS = 'APPROVAL_STATUS';

// -- FORMULA CONFIGURATION

export const GET_FORMULA_CONFIG = 'GET_FORMULA_CONFIG';
export const GET_FORMULA_CONFIG_RESULT = 'GET_FORMULA_CONFIG_RESULT';

export const INSERT_FORMULA_CONFIG = 'INSERT_FORMULA_CONFIG';
export const INSERT_FORMULA_CONFIG_RESULT = 'INSERT_FORMULA_CONFIG_RESULT';

export const FORMULA_APPROVAL_ATTACHMENT = 'FORMULA_APPROVAL_ATTACHMENT';
export const FORMULA_APPROVAL_ATTACHMENT_RESULT = 'FORMULA_APPROVAL_ATTACHMENT_RESULT';

export const FORMULA_STATUS = 'FORMULA_STATUS';

//-----
export const STATUS = 'STATUS';
export const GET_STATE_CODE = 'GET_STATE_CODE';
export const GET_STATE_CODE_RESULT = 'GET_STATE_CODE_RESULT';

export const STATE_PROFILE_CHART = 'STATE_PROFILE_CHART';
export const STATE_PROFILE_CHART_RESULT = 'STATE_PROFILE_CHART_RESULT';

export const STATE_DATA_QUALITY_TABLE = 'STATE_DATA_QUALITY_TABLE';
export const STATE_DATA_QUALITY_TABLE_RESULT = 'STATE_DATA_QUALITY_TABLE_RESULT';

export const GET_DISTRICT_MDA_BLOCKS = 'GET_DISTRICT_MDA_BLOCKS';
export const GET_DISTRICT_MDA_BLOCKS_RESULT = 'GET_DISTRICT_MDA_BLOCKS_RESULT';

export const FILTER_DRIVEN_EXTRACT_REPORT = 'FILTER_DRIVEN_EXTRACT_REPORT';
export const FILTER_DRIVEN_EXTRACT_REPORT_RESULT = 'FILTER_DRIVEN_EXTRACT_REPORT_RESULT';

export const CUSTOMREPORT_BY_TYPE = 'CUSTOMREPORT_BY_TYPE';
export const CUSTOMREPORT_BY_TYPE_RESULT = 'CUSTOMREPORT_BY_TYPE_RESULT';

export const GET_STATE_BY_SEARCH = 'GET_STATE_BY_SEARCH';
export const GET_STATE_BY_SEARCH_RESULT = 'GET_STATE_BY_SEARCH_RESULT';

// --DISTRICT_SEARCH

// export const GET_DISTRICT_BY_SEARCH = 'GET_DISTRICT_BY_SEARCH';
// export const DISTRICT_BY_SEARCH = 'DISTRICT_BY_SEARCH';
// export const DISTRICT_BY_SEARCH_LOADING = 'DISTRICT_BY_SEARCH_LOADING';
// export const DISTRICT_BY_SEARCH_FAILED = 'DISTRICT_BY_SEARCH_FAILED';

export const GET_DISTRICT_BY_SEARCH = 'GET_DISTRICT_BY_SEARCH';
export const GET_DISTRICT_BY_SEARCH_RESULT = 'GET_DISTRICT_BY_SEARCH_RESULT';
