import { takeLatest } from 'redux-saga/effects';
import { USER_LOGIN, FORGET_PASSWORD, USER_LOGOUT, CHANGE_PASSWORD } from '../../constants/actionType/index';
import { INITIAL_LOAD } from '../../constants/common';
import {
  LoginHandler,
  forgetPasswordHandler,
  userLogoutHandler,
  changePasswordHandler,
  statusClear,
} from '../handler/loginHandler';

/**
 * @name loginSaga used for dashboard api call
 * @yields loginSaga
 */
function* loginSaga() {
  yield takeLatest(INITIAL_LOAD, statusClear);
  yield takeLatest(USER_LOGIN, LoginHandler);
  yield takeLatest(FORGET_PASSWORD, forgetPasswordHandler);
  yield takeLatest(USER_LOGOUT, userLogoutHandler);
  yield takeLatest(CHANGE_PASSWORD, changePasswordHandler);
}
export default loginSaga;
