/* eslint-disable import/prefer-default-export */
/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import {
  GET_PREDICTION_ACTIVITY_CALENDAR_RESULT,
  GET_PREDICTION_ACTIVITY_CALENDAR_TABLE_RESULT,
  GET_EXPECTED_ACTIVITIES_RESULT,
  GET_PREDICTION_COUNTRY_PROFILE_RESULT,
  GET_EXPECTED_ACTIVITIES_GRAPH_RESULT,
  GET_PREDICTION_DASHBOARD_RESULT,
  GET_PREDICTION_COUNTRY_PROFILE_MAP_RESULT,
} from '../../constants/actionType/index';
import {
  getPredictionActivityCalendarRequest,
  getPredictionActivityCalendarTableRequest,
  getExpectedActivitesRequest,
  getPredictionCountryProfileRequest,
  getPredictionCountryProfileMapRequest,
  getPredictionDashboardRequest,
  getExpectedActivitesGraphRequest,
} from '../request/predictionDashboardRequest';

/**
 * @name getPredictionActivityCalendarHandler used for dashboard api call
 * @param {number} action payload data
 * @yields nationalStatesDashboardCardHandler
 */
export function* getPredictionActivityCalendarHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getPredictionActivityCalendarRequest, payload);
    yield put({
      type: GET_PREDICTION_ACTIVITY_CALENDAR_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name getPredictionActivityCalendarTableHandler used for dashboard api call
 * @param {number} action payload data
 * @yields nationalStatesDashboardCardHandler
 */
export function* getPredictionActivityCalendarTableHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getPredictionActivityCalendarTableRequest, payload);
    yield put({
      type: GET_PREDICTION_ACTIVITY_CALENDAR_TABLE_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name getExpectedActivitesHandler used for dashboard api call
 * @param {number} action payload data
 * @yields nationalStatesDashboardCardHandler
 */
export function* getExpectedActivitesHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getExpectedActivitesRequest, payload);
    yield put({
      type: GET_EXPECTED_ACTIVITIES_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name getExpectedActivitesHandler used for dashboard api call
 * @param {number} action payload data
 * @yields nationalStatesDashboardCardHandler
 */
export function* getPredictionCountryProfileHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getPredictionCountryProfileRequest, payload);
    yield put({
      type: GET_PREDICTION_COUNTRY_PROFILE_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name getPredictionCountryProfileMapHandler used for dashboard api call
 * @param {number} action payload data
 * @yields getPredictionCountryProfileMapHandler
 */
export function* getPredictionCountryProfileMapHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getPredictionCountryProfileMapRequest, payload);
    yield put({
      type: GET_PREDICTION_COUNTRY_PROFILE_MAP_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @name getPredictionDashboardHandler used for dashboard api call
 * @param {number} action payload data
 * @yields getPredictionDashboardHandler
 */
export function* getPredictionDashboardHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getPredictionDashboardRequest, payload);
    yield put({
      type: GET_PREDICTION_DASHBOARD_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @name getExpectedActivitesGraphHandler used for dashboard api call
 * @param {number} action payload data
 * @yields getExpectedActivitesGraphHandler
 */
export function* getExpectedActivitesGraphHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getExpectedActivitesGraphRequest, payload);
    yield put({
      type: GET_EXPECTED_ACTIVITIES_GRAPH_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
