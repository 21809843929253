import { takeLatest } from 'redux-saga/effects';
import {
  GET_STATE_PROGRESS_STATUS,
  GET_STATE_PROFILE,
  DISTRICT_MDA_PROFILE,
  GET_DISTRICT_PROFILE_WIDGET,
  GET_DISTRICT_PROFILE_WIDGET_GRAPH,
  GET_DISTRICT_TAS_PROFILE,
  GET_DISTRICT_DECISION_MATRIX,
  STATE_PROFILE_CHART,
  STATE_DATA_QUALITY_TABLE,
  GET_DISTRICT_MDA_BLOCKS,
} from '../../constants/actionType/index';
import {
  stateProgressStatusHandler,
  stateProfileHandler,
  districtMdaProfileHandler,
  getDistrictProfileWidgetHandler,
  getDistrictProfileWidgetGraphHandler,
  getDistrictTasProfileHandler,
  getDistrictDecisionMatrixHandler,
  getStateProfileChartHandler,
  getStateDataQualityHandler,
  getDistrictMDABlocksHandler,
} from '../handler/stateDashboardHandler';

/**
 * @name stateDashboardSaga used for dashboard api call
 * @yields stateProgressStatusHandler
 */
function* stateDashboardSaga() {
  yield takeLatest(GET_STATE_PROGRESS_STATUS, stateProgressStatusHandler);
  yield takeLatest(GET_STATE_PROFILE, stateProfileHandler);
  yield takeLatest(DISTRICT_MDA_PROFILE, districtMdaProfileHandler);
  yield takeLatest(GET_DISTRICT_PROFILE_WIDGET, getDistrictProfileWidgetHandler);
  yield takeLatest(GET_DISTRICT_PROFILE_WIDGET_GRAPH, getDistrictProfileWidgetGraphHandler);
  yield takeLatest(GET_DISTRICT_TAS_PROFILE, getDistrictTasProfileHandler);
  yield takeLatest(GET_DISTRICT_DECISION_MATRIX, getDistrictDecisionMatrixHandler);
  yield takeLatest(STATE_PROFILE_CHART, getStateProfileChartHandler);
  yield takeLatest(STATE_DATA_QUALITY_TABLE, getStateDataQualityHandler);
  yield takeLatest(GET_DISTRICT_MDA_BLOCKS, getDistrictMDABlocksHandler);
}
export default stateDashboardSaga;
