/* eslint-disable import/prefer-default-export */
/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import {
  // INSERT_DRUG_RESULT,
  GET_DRUG_AVILABILITY_RESULT,
  // UPDATE_DRUG_AVILABILITY_RESULT,
  // UPLOAD_DRUG_AVILABILITY_RESULT,
  DRUG_STATUS,
  GET_DRUG_AVILABILITY,
} from '../../constants/actionType/index';
import {
  insertDrugRequest,
  getDrugAvailabilityRequest,
  updateDrugAvilabilityRequest,
  bulkUploadDrugAvilabilityRequest,
} from '../request/drugAvilabilityRequest';
import { CATCH, ERROR, SUCCESS, FAILED, UPDATED, SUBMITTED, BULK_UPLOAD_SUCCESS } from '../../constants/common';

/**
 * @name insertDrugHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* insertDrugHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(insertDrugRequest, payload);
    if (response.status === 200)
      yield put({
        type: DRUG_STATUS,
        payload: { type: SUCCESS, message: SUBMITTED },
      });
    else {
      yield put({
        type: DRUG_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: DRUG_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}

/**
 * @name getDrugAvailabilityHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* getDrugAvailabilityHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getDrugAvailabilityRequest, payload);
    yield put({
      type: GET_DRUG_AVILABILITY_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name updateDrugAvailabilityHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* updateDrugAvailabilityHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(updateDrugAvilabilityRequest, payload);
    if (response.status === 200) {
      yield put({
        type: DRUG_STATUS,
        payload: { type: SUCCESS, message: UPDATED },
      });
    } else {
      yield put({
        type: DRUG_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: DRUG_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}

/**
 * @name updateDrugAvailabilityHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalMdaCoverageHandler
 */
export function* uploadDrugAvailabilityHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(bulkUploadDrugAvilabilityRequest, payload);
    if (response.status === 200) {
      yield put({
        type: DRUG_STATUS,
        payload: { type: SUCCESS, message: BULK_UPLOAD_SUCCESS },
      });
      yield put({
        type: GET_DRUG_AVILABILITY,
      });
    } else {
      yield put({
        type: DRUG_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: DRUG_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
