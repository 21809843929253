/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { TableBody, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MuiTableCell from '@mui/material/TableCell';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Checkbox from '../../../atoms/CustomCheckbox';
import Typography from '../../../atoms/Typography';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

/**
 *
 * @param props
 * @returns {React.JSXElementConstructor}
 */
const TableGrid = (props) => {
  const { data, onUpdateValues, isSubType, readOnly, isFilter, form } = props;

  const [gridData, setGridData] = useState(data || []);

  useEffect(() => {
    if (form) {
      setGridData([
        {
          screenName: { label: 'Data Input', value: 'data_input' },
          view: false,
          download: false,
          edit: false,
          manualDataInput: false,
          bulkUpload: false,
        },
        {
          screenName: { label: 'Output Dashboard', value: 'output_dashboard' },
          view: false,
          download: false,
          edit: false,
          manualDataInput: false,
          bulkUpload: false,
        },
        {
          screenName: { label: 'Master Setup', value: 'master_setup' },
          view: false,
          download: false,
          edit: false,
          manualDataInput: false,
          bulkUpload: false,
        },
        {
          screenName: { label: 'Send Mail', value: 'send_mail' },
          view: false,
          download: false,
          edit: false,
          manualDataInput: false,
          bulkUpload: false,
        },
      ]);
    } else {
      setGridData(data);
    }
  }, [data, form]);

  useEffect(() => {
    onUpdateValues(gridData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData]);

  /**
   *
   * @param {boolean} val
   * @param {string} nam
   * @param {number} rowIn
   */
  const onChangeInCheckBox = (val, nam, rowIn) => {
    const temp = gridData;
    const tempObj = temp[rowIn];
    tempObj[nam] = val;
    temp.splice(rowIn, 1, tempObj);
    setGridData(temp);
    setGridData((prev) => [...prev]);
  };

  return (
    <TableBody>
      {gridData.map((row, index) => {
        const rowKey = index;
        const hideRow = isFilter ? row?.screenName?.show : true;
        return (
          <TableRow key={rowKey}>
            {hideRow &&
              Object.entries(row).map(([k, v], cellIndex) => {
                const cellKey = cellIndex;
                const name = k;
                const checkedValue = v;
                return (
                  <TableCell key={cellKey} component="th" scope="row" align={cellKey !== 0 ? 'center' : 'left'}>
                    {cellKey === 0 ? (
                      <Typography
                        text={checkedValue?.label}
                        type="link"
                        customStyle={{ fontSize: '12px' }}
                        colorType={isSubType ? 'tertiary' : 'senary'}
                      />
                    ) : (
                      <Checkbox
                        checked={checkedValue}
                        disabled={readOnly}
                        onHandleChange={(e) => onChangeInCheckBox(e, name, rowKey)}
                      />
                    )}
                  </TableCell>
                );
              })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default TableGrid;
TableGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
  onUpdateValues: PropTypes.func,
  isSubType: PropTypes.bool,
  isFilter: PropTypes.bool,
  readOnly: PropTypes.bool,
};
TableGrid.defaultProps = {
  onUpdateValues: () => null,
  isSubType: false,
  readOnly: false,
  isFilter: false,
};
