/* eslint-disable default-param-last */
import {
  GET_NEWUSER_LIST_RESULT,
  UPDATE_NEWUSER_LIST_RESULT,
  INSERT_NEWUSER_LIST_RESULT,
  DELETE_NEWUSER_LIST_RESULT,
  USER_GROUP_RESULT,
  USER_TYPE_RESULT,
  GET_USER_MASTER_RESULT,
  INSERT_USER_MASTER_RESULT,
  UPDATE_USER_MASTER_RESULT,
  LOADER,
  STATUS,
} from '../../constants/actionType';
import { newUserState } from '../initialStates';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const newUserReducer = (state = newUserState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_NEWUSER_LIST_RESULT:
      return {
        ...state,
        getNewUser: payload.data,
      };
    case UPDATE_NEWUSER_LIST_RESULT:
      return {
        ...state,
        updateNewUser: payload.data,
      };
    case INSERT_NEWUSER_LIST_RESULT:
      return {
        ...state,
        status: payload.data,
      };
    case DELETE_NEWUSER_LIST_RESULT:
      return {
        ...state,
        deleteNewUser: payload.data,
      };
    case USER_GROUP_RESULT:
      return {
        ...state,
        userGroups: payload.data,
      };
    case USER_TYPE_RESULT:
      return {
        ...state,
        userTypes: payload.data,
      };
    case GET_USER_MASTER_RESULT:
      return {
        ...state,
        userMaster: payload.data,
        loading: false,
      };
    case INSERT_USER_MASTER_RESULT:
      return {
        ...state,
        insertUserMaster: payload.data,
      };
    case UPDATE_USER_MASTER_RESULT:
      return {
        ...state,
        updateUserMaster: payload.data,
      };
    case LOADER:
      return {
        ...state,
        loading: payload,
      };
    case STATUS:
      return {
        ...state,
        status: payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default newUserReducer;
