import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Typography from '../Typography';
/**
 * @name CustomRadioButton
 * @param {*} props used for the props
 * @returns {React.ReactElement} return the radiobutton
 */
const CustomRadioButton = (props) => {
  const { handleChange, selectValue, radioData, labelText } = props;

  return (
    <Grid>
      <Grid>
        <Typography text={labelText} type="link" colorType="senary" customClass="radioButtonLabelStyles" />
      </Grid>
      <Grid>
        <FormControl>
          <RadioGroup row name="row-radio-buttons-group" value={selectValue} onChange={(e) => handleChange(e)}>
            {radioData &&
              radioData.map((data, index) => {
                const key = index;
                return (
                  <Grid>
                    <FormControlLabel
                      key={key}
                      color="error"
                      control={
                        <Radio
                          disableRipple
                          sx={{
                            '&:hover, &:focus': {
                              backgroundColor: 'transparent',
                            },
                          }}
                          color="primary"
                        />
                      }
                      label={<Typography text={data.name} type="link" colorType="senary" />}
                      value={data.name}
                    />
                  </Grid>
                );
              })}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};
export default CustomRadioButton;
CustomRadioButton.propTypes = {
  handleChange: PropTypes.func.isRequired,
  selectValue: PropTypes.string,
  radioData: PropTypes.arrayOf(PropTypes.objectOf()).isRequired,
  labelText: PropTypes.string,
};
CustomRadioButton.defaultProps = {
  selectValue: '',
  labelText: '',
};
