/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '../../atoms/Typography';
import CustomTextfield from '../../atoms/TextField';
import './styles.css';
/**
 *
 * @param {object} props - required props in modal content
 * @returns {React.ReactElement} - modal content
 */
const ModalContent = (props) => {
  const { getInputValues } = props;
  const [changePassword, setChangePassword] = useState({ oldPassword: '', newPassword: '', confirm: '' });
  useEffect(() => {
    getInputValues(changePassword);
  }, [changePassword]);
  /**
   *
   * @param {*} e - on change value
   * @param {*} key - passing value
   */
  const onChangePassword = (e, key) => {
    setChangePassword((prevState) => ({
      ...prevState,
      [key]: e.target.value,
    }));
  };
  return (
    <Grid>
      <Grid>
        <Grid sx={{ paddingBottom: 1 }}>
          <Typography type="link" text="Old Password" customClass="changePwText" />
        </Grid>
        <CustomTextfield
          required
          // helperText="Field is Required"
          value={changePassword.oldPassword}
          onChange={(e) => onChangePassword(e, 'oldPassword')}
        />
      </Grid>
      <Grid>
        <Grid sx={{ paddingBottom: 1 }}>
          <Typography type="link" text="New Password" />
        </Grid>
        <CustomTextfield
          required
          // helperText="Field is Required"
          value={changePassword.newPassword}
          onChange={(e) => onChangePassword(e, 'newPassword')}
        />
      </Grid>
      <Grid>
        <Grid sx={{ paddingBottom: 1 }}>
          <Typography type="link" text="Confirm Password" />
        </Grid>
        <CustomTextfield
          required
          // helperText="Field is Required"
          value={changePassword.confirm}
          onChange={(e) => onChangePassword(e, 'confirm')}
        />
      </Grid>
      <Grid />
      <Grid />
    </Grid>
  );
};

export default ModalContent;
ModalContent.propTypes = {
  getInputValues: PropTypes.func,
};
ModalContent.defaultProps = {
  getInputValues: () => {},
};
