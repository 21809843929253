import { takeLatest } from 'redux-saga/effects';
import { GET_LYMPHEDEMA, INSERT_LYMPHEDEMA, UPDATE_LYMPHEDEMA, UPLOAD_LYMPHEDEMA } from '../../constants/actionType';
import {
  getLymphedemaHandler,
  insertLymphedemaHandler,
  updateLymphedemaHandler,
  uploadLymphedemaHandler,
} from '../handler/lymphedemaHandler';

/**
 * @name lymphedemaSaga used for program activities api call
 * @yields lymphedemaSaga
 */
function* lymphedemaSaga() {
  yield takeLatest(GET_LYMPHEDEMA, getLymphedemaHandler);
  yield takeLatest(INSERT_LYMPHEDEMA, insertLymphedemaHandler);
  yield takeLatest(UPDATE_LYMPHEDEMA, updateLymphedemaHandler);
  yield takeLatest(UPLOAD_LYMPHEDEMA, uploadLymphedemaHandler);
}
export default lymphedemaSaga;
