import { call, put } from 'redux-saga/effects';
import {
  SET_TOGGLE_FORM,
  USERS_GROUP_AND_TYPE_RESULT,
  USERS_GROUP_AND_TYPE_CREATE_RESULT,
  USERS_GROUP_AND_TYPE_READ_RESULT,
  USERS_GROUP_AND_TYPE_UPDATE_RESULT,
  USERS_GROUP_AND_TYPE_DELETE_RESULT,
  STATUS,
} from '../../constants/actionType';
import {
  // ALREADY_EXIST,
  ERROR,
  FAILED,
  INITIAL,
  INITIAL_LOAD,
  SUBMITTED,
  SUCCESS,
  UPDATED,
} from '../../constants/common';
import { userRoleTableData, extractReadUserRoleData } from '../payload/userRole.payload';
import {
  getUserRoles,
  createUserRole,
  readUserRole,
  updateUserRole,
  deleteUserRole,
} from '../request/userRole.requests';
/**
 * @name statusClear used for clear the status
 * @yields statusClear
 */
export function* statusClear() {
  yield put({
    type: STATUS,
    payload: { type: INITIAL, message: INITIAL_LOAD },
  });
}

/**
 * @name getUserRoleHandler used for dashboard api call
 * @yields getUserRoleHandler
 */
export function* getUserRoleHandler() {
  try {
    const response = yield call(getUserRoles);
    const { userRoleTableArr, userRoleIdArr } = userRoleTableData(response?.data?.data);
    yield put({
      type: USERS_GROUP_AND_TYPE_RESULT,
      payload: { userRoleTableArr, userRoleIdArr, error: false },
    });
  } catch (err) {
    yield put({
      type: USERS_GROUP_AND_TYPE_RESULT,
      payload: {
        errorMsg: err,
        error: true,
        userRoleData: [],
      },
    });
  }
}

/**
 * @name createUserRoleHandler used for dashboard api call
 * @param {object} action payload
 * @yields createUserRoleHandler
 */
export function* createUserRoleHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(createUserRole, payload);
    console.log(response, 'response');
    const { status } = response.data;
    if (status === 1) {
      const res = yield call(getUserRoles);
      const { userRoleTableArr, userRoleIdArr } = userRoleTableData(res?.data?.data);
      yield put({
        type: USERS_GROUP_AND_TYPE_RESULT,
        payload: { userRoleTableArr, userRoleIdArr },
      });
      yield put({
        type: USERS_GROUP_AND_TYPE_CREATE_RESULT,
        payload: { error: false },
      });
      yield put({
        type: SET_TOGGLE_FORM,
        payload: { toggleForm: false },
      });
    }
    if (response.status === 200)
      if (response?.data.status === 0)
        yield put({
          type: STATUS,
          payload: { type: ERROR, message: response?.data.msg },
        });
      else {
        yield put({
          type: STATUS,
          payload: { type: SUCCESS, message: SUBMITTED },
        });
      }
  } catch (err) {
    yield put({
      type: USERS_GROUP_AND_TYPE_CREATE_RESULT,
      payload: {
        errorMsg: err,
        error: true,
      },
    });
  }
}

/**
 * @name readUserRoleHandler used for dashboard api call
 * @param {object} action payload
 * @yields readUserRoleHandler
 */
export function* readUserRoleHandler(action) {
  const { payload } = action;
  const { id, isRoleEdit } = payload;
  try {
    const response = yield call(readUserRole, id);
    console.log(response, 'sldkfjsoidrwe');
    const roleId = response?.data?.data?.role_Privilege?.role_privilege_id;
    const {
      nationalTableArrData,
      predictionTableArrData,
      stateTableArrData,
      userRoleRightsTableData,
      userRoleTableIdData,
      dropdownValues,
      rolePrivilegeAccessDashboardId,
      // role_privilege_access,
    } = extractReadUserRoleData(response?.data?.data);
    yield put({
      type: SET_TOGGLE_FORM,
      payload: {
        toggleForm: true,
      },
    });
    yield put({
      type: USERS_GROUP_AND_TYPE_READ_RESULT,
      payload: {
        nationalDashBoardById: nationalTableArrData,
        stateDashBoardById: stateTableArrData,
        PredictionDashBoardById: predictionTableArrData,
        roleRightAccessById: userRoleRightsTableData,
        roleRightAccessId: userRoleTableIdData,
        rolePrivilegeAccessDashboardId,
        rolePrivilegeId: roleId,
      },
    });
    yield put({
      type: SET_TOGGLE_FORM,
      payload: {
        ddValues: dropdownValues,
        roleReadOnly: !isRoleEdit,
        updateResValue: true,
        isRoleEdit,
      },
    });
  } catch (err) {
    yield put({
      type: USERS_GROUP_AND_TYPE_READ_RESULT,
      payload: {
        errorMsg: err,
        error: true,
        toggleForm: false,
      },
    });
  }
}

/**
 * @name updateUserRoleHandler used for dashboard api call
 * @param {object} action payload
 * @yields updateUserRoleHandler
 */
export function* updateUserRoleHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(updateUserRole, payload);
    console.log(response, 'kkdlkff');
    const { status } = response.data;
    if (response.status === 200) {
      yield put({
        type: STATUS,
        payload: { type: SUCCESS, message: UPDATED },
      });
    } else {
      yield put({
        type: STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
    if (status === 1) {
      const res = yield call(getUserRoles);
      const { userRoleTableArr, userRoleIdArr } = userRoleTableData(res?.data?.data);

      yield put({
        type: USERS_GROUP_AND_TYPE_RESULT,
        payload: { userRoleTableArr, userRoleIdArr },
      });
      yield put({
        type: USERS_GROUP_AND_TYPE_UPDATE_RESULT,
        payload: { error: false },
      });
      yield put({
        type: SET_TOGGLE_FORM,
        payload: { toggleForm: false },
      });
    }
  } catch (err) {
    yield put({
      type: USERS_GROUP_AND_TYPE_UPDATE_RESULT,
      payload: {
        errorMsg: err,
        error: true,
        userRoleData: [],
      },
    });
  }
}
/**
 * @name deleteUserRole used for dashboard api call
 * @param {object} action payload
 * @yields deleteUserRole
 */
export function* deleteUserRoleHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(deleteUserRole, payload);
    const resData = response?.data;
    yield put({
      type: USERS_GROUP_AND_TYPE_DELETE_RESULT,
      payload: { deleteRole: resData, deleteMessage: resData?.msg },
    });
    if (resData) {
      const userRoleData = yield call(getUserRoles);
      const { userRoleTableArr, userRoleIdArr } = userRoleTableData(userRoleData?.data?.data);
      yield put({
        type: USERS_GROUP_AND_TYPE_RESULT,
        payload: { userRoleTableArr, userRoleIdArr, error: false },
      });
    }
  } catch (err) {
    yield put({
      type: USERS_GROUP_AND_TYPE_DELETE_RESULT,
      payload: {
        error: err,
      },
    });
  }
}

/**
 * @name formToggleHandler toggle between table and form
 * @param {object} action payload
 * @yields formToggleHandler
 */
export function* formToggleHandler(action) {
  const { payload } = action;
  try {
    const { toggleForm, isRoleEdit, roleReadOnly } = payload;
    yield put({
      type: SET_TOGGLE_FORM,
      payload: { toggleForm, isRoleEdit, roleReadOnly },
    });
  } catch (err) {
    yield put({
      type: SET_TOGGLE_FORM,
      payload: {
        toggleForm: false,
      },
    });
  }
}
