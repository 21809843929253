/* eslint-disable import/prefer-default-export */
/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import {
  COUNTRY_PROFILE_TABLE_RESULT,
  NATIONAL_PROGRESS_STATUS_RESULT,
  COUNTRY_PROFILE_MAP_RESULT,
  NATIONAL_STATES_DASHBOARD_CARD_RESULT,
  NATIONAL_PROGRESS_STATUSBAR_GRAPH_RESULT,
  ACTIVITY_CALENDAR_RESULT,
  GET_DATA_QUALITY_RESULT,
  GET_TAS_DECISION_MATRIX_RESULT,
  GET_DATA_QUALITY_GRAPH_RESULT,
  GET_STATE_CODE_RESULT,
  GET_ACTIVITY_LEGENDS_RESULT,
  ACTIVITY_CALENDAR_TABLE_RESULT,
  SET_GET_DASHBOARD_FILTER_DATA_RESULT,
  GET_COUNTRY_PROFILE_CHART_RESULT,
} from '../../constants/actionType/index';
import {
  getCountryProfileRequest,
  nationalProgressStatus,
  getCountryProfileMapAfterHyperRequest,
  getNationalStatesDashboardRequest,
  getNationalProgressStatusGraphRequest,
  getActivityCalenderRequest,
  getDataQualityRequest,
  getTasDecisionMatrixRequest,
  getDataQualityGraphRequest,
  getStateCodeRequest,
  getActivitesLegendsRequest,
  getActivityCalenderTableRequest,
  getCountryProfileChartRequest,
} from '../request/dashboardRequest';

/**
 * @name nationalStatesDashboardCardHandler used for dashboard api call
 * @param {number} action payload data
 * @yields nationalStatesDashboardCardHandler
 */
export function* nationalStatesDashboardCardHandler(action) {
  const { payload, dashboardType } = action;
  try {
    const response = yield call(getNationalStatesDashboardRequest, payload);
    yield put({
      type: NATIONAL_STATES_DASHBOARD_CARD_RESULT,
      payload: { dashboardType, data: response?.data?.data },
    });
  } catch (err) {}
}

/**
 * @name countryProfileHandler used for dashboard api call
 * @param {object} action payload
 * @yields countryProfileHandler
 */
export function* countryProfileHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(getCountryProfileRequest, payload);
    yield put({
      type: COUNTRY_PROFILE_TABLE_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name getCountryProfileChartHandler used for dashboard api call
 * @param {number} action payload data
 * @yields nationalStatesDashboardCardHandler
 */
export function* getCountryProfileChartHandler(action) {
  const { payload, dashboardType } = action;
  try {
    const response = yield call(getCountryProfileChartRequest, payload);
    yield put({
      type: GET_COUNTRY_PROFILE_CHART_RESULT,
      payload: { dashboardType, data: response?.data?.data },
    });
  } catch (err) {}
}
/**
 * @name countryProfileMapHandler used for dashboard api call
 * @param {object} action payload
 * @yields countryProfileMapHandler
 */
export function* countryProfileMapHandler(action) {
  const { payload, dashboardType } = action;
  try {
    const response = yield call(getCountryProfileMapAfterHyperRequest, payload);
    yield put({
      type: COUNTRY_PROFILE_MAP_RESULT,
      payload: { dashboardType, data: response?.data?.data },
    });
  } catch (err) {}
}

/**
 * @name nationalProgressStatusHandler used for dashboard api call
 * @param {object} action payload
 * @yields nationalProgressStatusHandler
 */
export function* nationalProgressStatusHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(nationalProgressStatus, payload);
    yield put({
      type: NATIONAL_PROGRESS_STATUS_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name nationalProgressStatusbarHandler used for dashboard api call
 * @param {number} action payload data
 * @yields nationalStatesDashboardCardHandler
 */
export function* nationalProgressStatusbarHandler(action) {
  const { payload, dashboardType } = action;

  try {
    const response = yield call(getNationalProgressStatusGraphRequest, payload);
    yield put({
      type: NATIONAL_PROGRESS_STATUSBAR_GRAPH_RESULT,
      payload: { dashboardType, data: response?.data?.data },
    });
  } catch (err) {}
}

/**
 * @name nationalProgressStatusbarHandler used for dashboard api call
 * @param {number} action payload data
 * @yields nationalStatesDashboardCardHandler
 */
export function* getActivityCalenderHandler(action) {
  const { payload, dashboardType } = action;

  try {
    const response = yield call(getActivityCalenderRequest, payload);
    yield put({
      type: ACTIVITY_CALENDAR_RESULT,
      payload: { dashboardType, data: response?.data?.data },
    });
  } catch (err) {}
}

/**
 * @name getActivityCalenderTableHandler used for dashboard api call
 * @param {number} action payload data
 * @yields getActivityCalenderTableHandler
 */
export function* getActivityCalenderTableHandler(action) {
  const { payload, dashboardType } = action;

  try {
    const response = yield call(getActivityCalenderTableRequest, payload);
    yield put({
      type: ACTIVITY_CALENDAR_TABLE_RESULT,
      payload: { dashboardType, data: response?.data?.data },
    });
  } catch (err) {}
}

/**
 * @name getDataQualityHandler used for dashboard api call
 * @param {number} action payload data
 * @yields nationalStatesDashboardCardHandler
 */
export function* getDataQualityHandler(action) {
  const { payload, dashboardType } = action;
  try {
    const response = yield call(getDataQualityRequest, payload);
    yield put({
      type: GET_DATA_QUALITY_RESULT,
      payload: { dashboardType, data: response?.data?.data },
    });
  } catch (err) {}
}

/**
 * @name getTasDecisionMatrixHandler used for dashboard api call
 * @param {number} action payload data
 * @yields nationalStatesDashboardCardHandler
 */
export function* getTasDecisionMatrixHandler(action) {
  const { payload, dashboardType } = action;
  try {
    const response = yield call(getTasDecisionMatrixRequest, payload);
    yield put({
      type: GET_TAS_DECISION_MATRIX_RESULT,
      payload: { dashboardType, data: response?.data?.data },
    });
  } catch (err) {}
}

/**
 * @name getDataQualityGraphHandler used for dashboard api call
 * @param {number} action payload data
 * @yields nationalStatesDashboardCardHandler
 */
export function* getDataQualityGraphHandler(action) {
  const { payload, dashboardType } = action;
  try {
    const response = yield call(getDataQualityGraphRequest, payload);
    yield put({
      type: GET_DATA_QUALITY_GRAPH_RESULT,
      payload: { dashboardType, data: response?.data?.data },
    });
  } catch (err) {}
}
/**
 * @name getStateCodeHandler used for dashboard api call
 * @yields getStateCodeHandler
 */
export function* getStateCodeHandler() {
  try {
    const response = yield call(getStateCodeRequest);
    yield put({
      type: GET_STATE_CODE_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name getStateCodeHandler used for dashboard api call
 * @yields getStateCodeHandler
 */
export function* getActivitesLegendsRequestHandler() {
  try {
    const response = yield call(getActivitesLegendsRequest);
    yield put({
      type: GET_ACTIVITY_LEGENDS_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}

/**
 * @name getDashboardFilterHandler used for storing the dashboard filter data
 * @param {number} action payload data
 * @yields getDashboardFilterHandler
 */
export function* getDashboardFilterHandler(action) {
  const { payload } = action;
  try {
    yield put({
      type: SET_GET_DASHBOARD_FILTER_DATA_RESULT,
      payload: payload.data,
    });
  } catch (err) {}
}
