import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from './TabPanel';
/**
 * @name CustomTabBar
 * @param {object} props - required props
 * @returns {React.ReactElement} return the CustomTabBar Element
 */
const CustomTabBar = (props) => {
  const { TabDetails, onTabClick } = props;
  const [value, setValue] = useState(0);
  /**
   * @name handleChange
   * @param {*} event - on change event
   * @param {*} newValue - get new value from event
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        {TabDetails.map((item, index) => {
          const key = index;
          return <Tab label={item.label} key={key} onClick={() => onTabClick(key)} />;
        })}
      </Tabs>

      {TabDetails.map((item, index) => {
        const key = index;
        return (
          <TabPanel value={value} index={key} key={key}>
            {item.content}
          </TabPanel>
        );
      })}
    </>
  );
};
export default CustomTabBar;

CustomTabBar.propTypes = {
  TabDetails: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onTabClick: PropTypes.func,
};
CustomTabBar.defaultProps = { onTabClick: () => null };
