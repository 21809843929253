/* eslint-disable import/prefer-default-export */
import React from 'react';

/**
 *
 * @param {number} start start number
 * @param {number} stop stop number
 * @param {number} step step number
 * @returns {React.ReactElement} - returns the resize observer
 */
export const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
