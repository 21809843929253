import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'loadsh';
import CustomForm from '../../../../components/organisms/CustomForm';
import {
  INSERT_DEMOGRAPHIC_DIMENSIONS,
  GET_STATE_DROPDOWN,
  GET_DISTRICT_DROPDOWN,
  DEMOGRAPHIC_UPDATE,
  DEMOGRAPHIC_LIST,
} from '../../../../constants/actionType';
import './Domo.css';

/**
 * @param {object} props -- form props from index page
 * @returns {React.ReactComponent} BuyForm
 */
const FormInput = (props) => {
  const { entries, defaultValues, isEdit, setIsFormEdited } = props;
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({ type: GET_STATE_DROPDOWN });
  }, [dispatch]);
  React.useEffect(() => {
    if (isEdit) {
      const param = { state: defaultValues.state_id };
      dispatch({ type: GET_DISTRICT_DROPDOWN, payload: param });
    }
  }, [isEdit, defaultValues.state_id, dispatch]);
  const dropdownList = useSelector((state) => state.dropDownReducer);
  /**
   * @param {number} data - data
   * @param {string} name - name
   * @function getSelectedDropdown
   */
  const getSelectedDropdown = React.useCallback(
    (data, name) => {
      if (name === 'state_id') {
        const param = { state: data };
        dispatch({ type: GET_DISTRICT_DROPDOWN, payload: param });
      }
    },
    [dispatch],
  );
  const countRangeData = React.useCallback(() => {
    return [({ labelText: 'EU Count' }, { labelText: 'Block Count' })];
  }, []);

  const getEntries = React.useMemo(() => {
    return entries.map((value) => {
      if (value.entryName === 'state_id') {
        value.data = _.assign(dropdownList.stateData);
      }
      if (value.entryName === 'district_id') {
        if (dropdownList.districtData) value.disabled = false;
        value.data = _.assign(dropdownList.districtData);
      }

      return value;
    });
  }, [entries, dropdownList]);
  /**
   *
   * @param {*} data -- Form Entries data
   */
  const formSubmitHandle = React.useCallback(
    async (data) => {
      if (isEdit) {
        dispatch({ type: DEMOGRAPHIC_UPDATE, payload: data });
        setIsFormEdited();
        dispatch({ type: DEMOGRAPHIC_LIST });
      } else {
        await dispatch({ type: INSERT_DEMOGRAPHIC_DIMENSIONS, payload: data });
        dispatch({ type: DEMOGRAPHIC_LIST });
      }
      getEntries();
    },
    [dispatch, isEdit, setIsFormEdited, getEntries],
  );

  // Memorized form (desponds every entry change)
  const form = React.useMemo(() => {
    return (
      <CustomForm
        item
        entries={getEntries}
        defaultValues={defaultValues}
        countRange={countRangeData}
        masterSetup
        onReceiveData={(d) => formSubmitHandle(d)}
        getSelectedDropdown={(data, name) => getSelectedDropdown(data, name)}
        SubmitBtnTitle={!isEdit ? 'Submit' : 'Update'}
        screen="masters"
      />
    );
  }, [countRangeData, defaultValues, formSubmitHandle, getEntries, getSelectedDropdown, isEdit]);

  return (
    <Grid container item md={12}>
      <Grid>{form}</Grid>
    </Grid>
  );
};
export default FormInput;

FormInput.propTypes = {
  entries: PropTypes.arrayOf({}),
  defaultValues: PropTypes.shape(),
  isEdit: PropTypes.bool,
  setIsFormEdited: PropTypes.func.isRequired,
};

FormInput.defaultProps = {
  entries: [],
  defaultValues: {},
  isEdit: false,
};
