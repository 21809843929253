/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect } from 'react';
import { Grid, Paper } from '@mui/material';
import './Program.css';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from '../../../components/atoms/DatePicker';
import Typography from '../../../components/atoms/Typography/index';
import Table from '../../../components/organisms/Table';
import Loader from '../../../components/atoms/Loader';
import Toaster from '../../../components/atoms/ToastMessage';
import { GET_PROGRAM_ACTIVITY, UPDATE_PROGRAM_ACTIVITY, INSERT_PROGRAM_ACTIVITY } from '../../../constants/actionType';
import CustomForm from '../../../components/organisms/CustomForm';
import { CreateFormEntries, CreateFormDefaultValues, thDatas } from './Entries';
import { INITIAL_LOAD } from '../../../constants/common';

/**
 * @returns {React.ReactElement} return the user role component
 */
const CreateProgram = () => {
  const [date, setDate] = React.useState(new Date());
  const dispatch = useDispatch();
  const [defaultFormValues, setDefaultForm] = React.useState(CreateFormDefaultValues);
  const [formMode, setFormMode] = React.useState(true);
  const [existRecord, setExistRecord] = React.useState(null);
  const [toaster, setToaster] = React.useState(false);
  const { getProgramActivity, status, loading } = useSelector((state) => state.programActivity);
  const [formControl, setFormControl] = React.useState();
  const ValidationGroup = localStorage.getItem('userGroupId');
  const ValidationType = localStorage.getItem('userTypeId');
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload, Download, Edit, ManualDataInput, View } = Actionvalue?.masters?.[0];
  console.log(BulkUpload, Download, Edit, ManualDataInput, View, 'aslkdjqqfasoihr');
  console.log(status, 'sldkfjrewe');
  React.useEffect(() => {
    if (status) setToaster(true);
  }, [status]);
  React.useEffect(() => {
    dispatch({
      type: INITIAL_LOAD,
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: GET_PROGRAM_ACTIVITY });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (status && status?.message !== '') setToaster(true);
    else setToaster(false);
  }, [status]);
  React.useEffect(() => {
    if ((ValidationGroup === '0' && ValidationType === '4') || (ValidationGroup === '8' && ValidationType === '5')) {
      setFormControl(true);
    }
  }, [ValidationGroup, ValidationType]);

  /**
   *
   * @param {*} data returns form data
   * @function handleFormSubmit
   */
  const handleFormSubmit = async (data) => {
    if (formMode) {
      dispatch({ type: INSERT_PROGRAM_ACTIVITY, payload: data });
      setDefaultForm(CreateFormDefaultValues);
    } else {
      dispatch({ type: UPDATE_PROGRAM_ACTIVITY, payload: { ...existRecord, ...data } });
      setFormMode(true);
      setDefaultForm(CreateFormDefaultValues);
    }
  };

  /**
   *
   * @param {*} data returns form data
   * @function handleEdit
   */
  const handleEdit = (data) => {
    setDefaultForm({
      Program_Activity_Type: data.program_activities,
      from_date: data.from_date,
      to_date: data.to_date,
      Status: data.active_status,
    });
    setFormMode(false);
    setExistRecord(data);
  };

  /**
   * @function handleCancel
   */
  const handleCancel = () => {
    setDefaultForm(CreateFormDefaultValues);
    setFormMode(true);
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <Grid container item md={12} spacing={3} sx={{ paddingTop: 2 }} className="dataLayout">
      {toaster && <Toaster open severity={status?.type} message={status?.message} close={() => setToaster(false)} />}
      <Grid item md={9} sm={12} xs={12} className="noPadding">
        <Paper elevation={3} className="splitPage">
          {formControl && (
            <Grid>
              <Typography text="Create Program Activity" colorType="primary" type="caption" customClass="userText" />
            </Grid>
          )}
          {/* {formControl && ( */}
          <Grid sx={{ p: 2 }}>
            <Grid container item md={12}>
              <Grid item md={12}>
                <CustomForm
                  entries={CreateFormEntries}
                  defaultValues={defaultFormValues}
                  masterSetup
                  onReceiveData={(data) => handleFormSubmit(data)}
                  onCancelAction={handleCancel}
                  SubmitBtnTitle={formMode ? 'Submit' : 'Update'}
                  screen="masters"
                />
              </Grid>
            </Grid>
          </Grid>
          {/* )} */}
          {View === 1 && (
            <Grid item md={12}>
              <Table
                header={thDatas}
                rows={getProgramActivity}
                masterSetup={['Downloader', 'Search']}
                userActivity="View Program Activity"
                optional
                edit
                hideKeyFields={['program_activities_id']}
                showSno
                onEditData={(data) => handleEdit(data)}
                Activity="Program Activity"
                screen="masters"
              />
            </Grid>
          )}
        </Paper>
      </Grid>

      <Grid item md={3} sm={12} xs={12} sx={{ height: '100%' }} className="paddingView">
        <Paper elevation={3} className="splitPage">
          <Grid sx={{ pt: 2 }}>
            <DatePicker calendarPicker date={date} onHandleChange={(newDate) => setDate(newDate)} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default CreateProgram;
