import React from 'react';
import { Grid, Divider } from '@mui/material';
import ActivitySelector from '../../components/molecules/Activity-selector';
import Typography from '../../components/atoms/Typography';
import './DataInput.css';
/**
 *
 * @returns {React.ReactElement} - returns the data input screens
 */
const DataInput = () => {
  return (
    <Grid className="breadCrumbsView" container item md={12} sx={{ display: 'flex' }}>
      <Grid className="heading " item md={1} sm={12} xs={12}>
        <Typography type="subHeading" text="Data Input" customClass="divider" colorType="quinary" />
      </Grid>
      <Divider orientation="vertical" flexItem sx={{ border: 1, opacity: 0.1 }} item md={1} />

      <Grid item md={10} sm={12} xs={12}>
        <ActivitySelector />
      </Grid>
    </Grid>
  );
};

export default DataInput;
