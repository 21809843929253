/* eslint-disable no-empty */
import { call, put } from 'redux-saga/effects';
import {
  GET_LYMPHEDEMA_RESULT,
  LYMPHEDEMA_STATUS,
  GET_LYMPHEDEMA,
} from '../../constants/actionType';

import {
  getLymphedemaRequest,
  updateLymphedemaRequest,
  insertLymphedemaRequest,
  bulkUploadLymphedemaRequest,
} from '../request/lymphedemaRequest';
import { CATCH, ERROR, SUCCESS, FAILED, UPDATED, SUBMITTED, BULK_UPLOAD_SUCCESS } from '../../constants/common';

/**
 * @function getLymphedemaHandler handler function to handle program activity get request and dispatch response data to reducer state
 * @yields  getLymphedemaHandler
 */
export function* getLymphedemaHandler() {
  try {
    const response = yield call(getLymphedemaRequest);
    yield put({
      type: GET_LYMPHEDEMA_RESULT,
      payload: response?.data,
    });
  } catch (err) {}
}
/**
 * @param {object} action payload data
 * @function insertLymphedemaHandler handler function to handle program activity post request
 * @yields insertLymphedemaHandler
 */
export function* insertLymphedemaHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(insertLymphedemaRequest, payload);

    if (response.status === 200) {
      yield put({
        type: LYMPHEDEMA_STATUS,
        payload: { type: SUCCESS, message: SUBMITTED },
      });
    } else {
      yield put({
        type: LYMPHEDEMA_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: LYMPHEDEMA_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function updateLymphedemaHandler handler function to handle program activity update request
 * @yields updateLymphedemaHandler
 */
export function* updateLymphedemaHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(updateLymphedemaRequest, payload);
    if (response.status === 200) {
      yield put({
        type: LYMPHEDEMA_STATUS,
        payload: { type: SUCCESS, message: UPDATED },
      });
    } else {
      yield put({
        type: LYMPHEDEMA_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: LYMPHEDEMA_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
/**
 * @param {object} action payload data
 * @function uploadLymphedemaHandler handler function to handle program activity update request
 * @yields uploadLymphedemaHandler
 */
export function* uploadLymphedemaHandler(action) {
  const { payload } = action;
  try {
    const response = yield call(bulkUploadLymphedemaRequest, payload);
    if (response.status === 200) {
      yield put({
        type: LYMPHEDEMA_STATUS,
        payload: { type: SUCCESS, message: BULK_UPLOAD_SUCCESS },
      });
      yield put({
        type: GET_LYMPHEDEMA,
      });
    } else {
      yield put({
        type: LYMPHEDEMA_STATUS,
        payload: { type: ERROR, message: FAILED },
      });
    }
  } catch (err) {
    yield put({
      type: LYMPHEDEMA_STATUS,
      payload: { type: ERROR, message: CATCH },
    });
  }
}
