import colors from '../utils/colors';

/**
 * @function getStateCode To get state code of respective state
 * @param {Array} list array which contains state and state code
 * @param {string} state state to get respective state code
 * @returns {string} state code
 */
export const getStateCode = (list, state) => {
  return list?.find((item) => item.state === state)?.state_code || 'OD';
};
/**
 * @function useTypeColor To get state code of respective state
 * @param {string} type state to get respective state code
 * @returns {string} color code
 */
export const getTypeColor = (type) => {
  const colorCode = {
    MDA: colors.typeCode.halfRed,
    TAS1: colors.typeCode.seablue,
    TAS2: colors.typeCode.skyblue,
    TAS3: colors.typeCode.peachGreen,
    PRE_TAS: colors.typeCode.yellow,
    default: colors.typeCode.default,
    transparent: colors.typeCode.transparent,
  };
  return type ? colorCode[type] || colorCode.default : colorCode.transparent;
};
