import { takeLatest } from 'redux-saga/effects';
import {
  GET_PRE_TAS,
  INSERT_PRE_TAS,
  UPDATE_PRE_TAS_ID,
  UPDATE_PRE_TAS,
  GET_PROPOSED_PRE_TAS,
  PRE_TAS_UPLOAD,
  INSERT_PRE_TAS_BLOCK,
  BLOCK_NAME,
  EU_NAME,
} from '../../constants/actionType';
import {
  getPreTasHandler,
  insertPreTasHandler,
  updatePreTasIdHandler,
  updatePreTasHandler,
  getProposedPreTasHandler,
  uploadPreTasHandler,
  insertPreTasBlockHandler,
  blockNameHandler,
  euNameHandler,
} from '../handler/preTasHandler';

/**
 * @name preTasSaga used for program activities api call
 * @yields programActivitySaga
 */
function* preTasSaga() {
  yield takeLatest(GET_PRE_TAS, getPreTasHandler);
  yield takeLatest(INSERT_PRE_TAS, insertPreTasHandler);
  yield takeLatest(UPDATE_PRE_TAS_ID, updatePreTasIdHandler);
  yield takeLatest(UPDATE_PRE_TAS, updatePreTasHandler);
  yield takeLatest(GET_PROPOSED_PRE_TAS, getProposedPreTasHandler);
  yield takeLatest(PRE_TAS_UPLOAD, uploadPreTasHandler);
  yield takeLatest(INSERT_PRE_TAS_BLOCK, insertPreTasBlockHandler);
  yield takeLatest(BLOCK_NAME, blockNameHandler);
  yield takeLatest(EU_NAME, euNameHandler);
}
export default preTasSaga;
